import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import LoginRoute from "./components/hocs/LoginRoute";
import MainPrivateRoute from "./components/hocs/MainPrivateRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import MyaccountRoute from "./components/hocs/MyaccountRoute";
import Login from "./components/pages/login";
import CommonLoginPage from "./components/pages/login/cLogin";
import recrutersLogin from "./components/pages/login/recruterlogin";
import candidatesLogin from "./components/pages/login/candidatelogin";
// import forgetPassword from "./components/pages/forgetPassword";
// import resetPassword from "./components/pages/forgetPassword/resetPassword";
//import VisitWebsite from './components/pages/visitwebsite/VisitWebsite';

import Visitsite from "./components/pages/visitsites/Visitsite";
import AllModules from "./components/pages/module/Module";
//Start VistWebsite Module
// import DKGVisitWebsite from './components/pages/visitwebsite/VisitWebsite'
// End VisitWebsite Module

// Start DK Global Admin Pages

import AdminDashboard from "./components/pages/dkglobaladmin/admindashboard/AdminDashboard";

// End

// Start Dk Global Finance Page

import FinanceDashboard from "./components/pages/dkglobalfinance/financedashboard/FinanceDashboard";

// Start Dk Global Finance Accounting Page
import AccountingDashboard from "./components/pages/dkglobalfinance/financeaccounting/dashboard/AccountingDashboard";
import ExpIncomeSummary from "./components/pages/dkglobalfinance/financeaccounting/income/summary/IncomeSummary";
import ExpIncome2023 from "./components/pages/dkglobalfinance/financeaccounting/income/clients";
import ExpIncomeTwentyTwo from "./components/pages/dkglobalfinance/financeaccounting/2022/IncomeTwentyTwo";

// import ExpenseSummary from './components/pages/dkglobalfinance/financeaccounting/expense/summary/ExpenseSummary';
import ExpenseSummary from "./components/pages/dkglobalfinance/financeaccounting/expense/summary/ExpenseSummary";
import PLSummary from "./components/pages/dkglobalfinance/financeaccounting/pl/summary/PlSummary";
// End

// Start Dk Global Finance Payroll Page

import FinancePayrollDashboard from "./components/pages/dkglobalfinance/financepayroll/dashboard/PayrollDashboard";

// End
// Start DK Global Finanace  Clients Invoices
import ClientsinovoicesSummary from "./components/pages/dkglobalfinance/clientsinovoice/summary/ClientinovoiceSummary";
import ClientsinvoicesTwentytwo from "./components/pages/dkglobalfinance/clientsinovoice/2022/InvoiceTwentyTwo";
//End DK Global Finanace  Clients Invoices
// Start Dk Global Finance Vat Files Page

import FinanceVatFilesSummary from "./components/pages/dkglobalfinance/financevatfiles/summary/VatFilesSummary";
import TaxReturnCorporateTax from "./components/pages/dkglobalfinance/financevatfiles/corporatetax/TaxReturnCorporateTax";

// End

// Start Dk Global Finance Banking Page

import FinanceBankingSummary from "./components/pages/dkglobalfinance/financebanking/dashboard/BankingDashboard";
import BankingStatements from "./components/pages/dkglobalfinance/financebanking/statements/BankingStatements";
import StatementTwentyTwo from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentyTwo";
import StatementTwentyOne from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentyOne";
import StatementTwentyTwenty from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentyTwenty";
import StatementTwentyNinteen from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentyNinteen";
import StatementTwentyEighteen from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentyEighteen";
import StatementTwentySeventeen from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentySeventeen";
import StatementTwentySixteen from "./components/pages/dkglobalfinance/financebanking/statements/StatementTwentySixteen";

import BankingTwentyTwo from "./components/pages/dkglobalfinance/financebanking/BankingTwentyTwo";
import BankingTwentyThree from "./components/pages/dkglobalfinance/financebanking/BankingTwentyThree";
import BankingTwentyFour from "./components/pages/dkglobalfinance/financebanking/BankingTwentyFour";
import BankingTwentyFive from "./components/pages/dkglobalfinance/financebanking/BankingTwentyFive";

// End

// Start Dk Global Finance Cashflow Page

import FinanceCashFlowSummary from "./components/pages/dkglobalfinance/financecashflow/dashboard/CashFlowDashboard";
import CashFlowComponent from "./components/pages/dkglobalfinance/financecashflow/dashboard/CashFlowTwentyTwo";

// End

// Start Dk Global Finance Business Overview Page
import FinanceBusinessOverviewDashboard from "./components/pages/dkglobalfinance/financebusinessoverview/dashboard/BusinessOverviewDashboard";
import BusinessOverviewSummary from "./components/pages/dkglobalfinance/financebusinessoverview/summary/BusinessOverviewSummary";
import BusinessTwentytwosuMM from "./components/pages/dkglobalfinance/financebusinessoverview/2022/summary/BusinessOverviewSummary";

import YearTwentyTwo from "./components/pages/dkglobalfinance/financebusinessoverview/2022/interviews";
import businessBookJobOffer from "./components/pages/dkglobalfinance/financebusinessoverview/2022/jobOffer";
import businessBookJoined from "./components/pages/dkglobalfinance/financebusinessoverview/2022/joined";
import businessFinalFee from "./components/pages/dkglobalfinance/financebusinessoverview/2022/FinalFee";
import businessQualified from "./components/pages/dkglobalfinance/financebusinessoverview/2022/qualified";
import BusinessSebdouts from "./components/pages/businessoveriew/sendouts";
import BusinessSelected from "./components/pages/businessoveriew/selected";
import BusinessNewCase from "./components/pages/businessoveriew/newcase";

//import YearTwentyThree from './components/pages/dkglobalfinance/financebusinessoverview/2023';
// Start Dk Global Finance Income Page
import FinanceIncomeDashboard from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialSummary from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialStatement from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialOpenInv from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialSubmited from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialBibbyFee from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialDebetors from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialContract from "./components/pages/dkglobalfinance/financeincome/dashboard/IncomeDashboard";
import BibbyFinancialTermsStepOne from "./components/pages/dkglobalfinance/financeincome/terms/BibbytermsProcessOverview";
import BibbyFinancialTerms from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepOne";
import BibbyFinancialTermsStepTwo from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepTwo";
import BibbyFinancialTermsStepThree from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepThree";
import BibbyFinancialTermsStepFour from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepFour";
import BibbyFinancialTermsStepFive from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepFive";
import BibbyFinancialTermsStepSix from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepSix";
import BibbyFinancialTermsStepSeven from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepSeven";
import BibbyFinancialTermsStepEight from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepEight";
import BibbyFinancialTermsStepNine from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepNine";
import BibbyFinancialTermsStepTen from "./components/pages/dkglobalfinance/financeincome/terms/FinanceProcessStepTen";

import BibbyFinancialFAQ from "./components/pages/dkglobalfinance/financeincome/faq/BibbyFaq";

// End

// Start Dk Global Finance Expense Page

import FinanceExpenseDashboard from "./components/pages/dkglobalfinance/financeexpense/dashboard/ExpenseDashboard";
import FinanceExpenseSavedInvoices from "./components/pages/dkglobalfinance/financeexpense/saved/SavedInvoices";
import FinanceProcessOverview from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessOverview";
import FinanceProcessStepOne from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepOne";
import FinanceProcessStepTwo from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepTwo";
import FinanceProcessStepThree from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepThree";
import FinanceProcessStepFour from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepFour";
import FinanceProcessStepFive from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepFive";
import FinanceProcessStepSix from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepSix";
import FinanceProcessStepSeven from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepSeven";
import FinanceProcessStepEight from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepEight";
import FinanceProcessStepNine from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepNine";
import FinanceProcessStepTen from "./components/pages/dkglobalfinance/financeexpense/financeprocess/FinanceProcessStepTen";

import FinanceClientsOverview from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsOverview";
import FinanceClientsStepOne from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepOne";
import FinanceClientsStepTwo from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepTwo";
import FinanceClientsStepThree from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepThree";
import FinanceClientsStepFour from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepFour";
import FinanceClientsStepFive from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepFive";
import FinanceClientsStepSix from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepSix";
import FinanceClientsStepSeven from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepSeven";
import FinanceClientsStepEight from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepEight";
import FinanceClientsStepNine from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepNine";
import FinanceClientsStepTen from "./components/pages/dkglobalfinance/financeexpense/financeclients/FinanceClientsStepTen";

import FinanceJobBoardOverview from "./components/pages/dkglobalfinance/financeexpense/financejobboard/FinanceJobBoardOverview";
import FinanceJobBoardStepOne from "./components/pages/dkglobalfinance/financeexpense/financejobboard/FinanceJobBoardStepOne";

import FinanceItServicesOverview from "./components/pages/dkglobalfinance/financeexpense/financeitservices/FinanceItServicesOverview";
import FinanceItServicesStepOne from "./components/pages/dkglobalfinance/financeexpense/financeitservices/FinanceItServicesStepOne";

import FinanceSoftwareOverview from "./components/pages/dkglobalfinance/financeexpense/financesoftware/FinanceSoftwareOverview";
import FinanceSoftwareStepOne from "./components/pages/dkglobalfinance/financeexpense/financesoftware/FinanceSoftwareStepOne";

import FinanceCallSmsOverview from "./components/pages/dkglobalfinance/financeexpense/financecallsms/FinanceCallSmsOverview";
import FinanceCallSmsStepOne from "./components/pages/dkglobalfinance/financeexpense/financecallsms/FinanceCallSmsStepOne";

import FinanceAccountantOverview from "./components/pages/dkglobalfinance/financeexpense/financeaccountant/FinanceAccountantOverview";
import FinanceAccountantStepOne from "./components/pages/dkglobalfinance/financeexpense/financeaccountant/FinanceAccountantStepOne";

import FinanceDkStaffOverview from "./components/pages/dkglobalfinance/financeexpense/financedkstaff/FinanceDkStaffOverview";
import FinanceDkStaffStepOne from "./components/pages/dkglobalfinance/financeexpense/financedkstaff/FinanceDkStaffStepOne";

import FinanceDkGlobalOverview from "./components/pages/dkglobalfinance/financeexpense/financedkglobal/FinanceDkGlobalOverview";
import FinanceDkGlobalStepOne from "./components/pages/dkglobalfinance/financeexpense/financedkglobal/FinanceDkGlobalStepOne";

import FinanceAlerts from "./components/pages/dkglobalfinance/financeexpense/financealerts/FinanceAlerts";

// End

// Start Dk Global Finance Profit & Loss Page

import FinanceProfitLossDashboard from "./components/pages/dkglobalfinance/financeprofitloss/dashboard/ProfitLossDashboard";

// End

// Start Dk Global Finance Contracts Page

import FinanceContractsDashboard from "./components/pages/dkglobalfinance/financecontracts/dashboard/ContractsDashboard";
import ContractsDkgStaff from "./components/pages/dkglobalfinance/financecontracts/dkgstaff/ContractsDkgStaff";

// End

// Start Dk Global Finance Knowleadge Base Page

import FinanceAccountingFaq from "./components/pages/dkglobalfinance/financefaq/AccountingFaq";

// End

// Start Dk Global Finance Legal Page

import FinanceLegalDashboard from "./components/pages/dkglobalfinance/financelegal/dashboard/LegalDashboard";
import LegalRegistration from "./components/pages/dkglobalfinance/financelegal/registration/LegalRegistration";

// End

// End

// Start DK Global Admin Pages Module

// Start Dk Global Admin Resource Page

import AdminResourceSummary from "./components/pages/dkglobaladmin/adminresource/summary/ResourceSummary";

// End
// Start Marketing Budget Module
//
//import MarketBudgetSummary from './components/pages/marketing/budget/summary';
import MarketLiveAdsSummary from "./components/pages/marketing/liveads/summary";
import MarketSavedAdsSummary from "./components/pages/marketing/savedads/summary";
import AdvertiseAdsPlan from "./components/pages/marketing/savedads/adsplan/AdsPlan";
import AdvertiseSavedAds from "./components/pages/marketing/savedads/SavednewAds";
import MarketSampleAdsSummary from "./components/pages/marketing/knowleadge/dashboard/KnowleadgeDashboard";
import MarketAdsTrackertSummary from "./components/pages/marketing/adstracker/summary";
import MarketbannersSummary from "./components/pages/marketing/banners/summary";
//import MarketCampaignsSummary from './components/pages/marketing/campaigns/summary';
import MarketJobboardsSummary from "./components/pages/marketing/jobboards/summary";
import MarketknowledgeSummary from "./components/pages/marketing/knowleadge/summary";

// End Marketing Budget Module
// Start Dk Global Admin Personal Relations Page

import AdminPrDashboard from "./components/pages/dkglobaladmin/adminpersonalrelations/dashboard/PersonalRelationsDashboard";

// End

// Start Dk Global Admin Marketing Page

import AdminMarketingDashboard from "./components/pages/dkglobaladmin/adminmarketing/marketingdashboard/MarketingDashboard";

// End

// Start Dk Global Admin Clients Page

import AdminClientsDashboard from "./components/pages/dkglobaladmin/adminclients/clientsdashboard/ClientsDashboard";

// End

// Start Dk Global Admin Supplier Page

import AdminSupplierDashboard from "./components/pages/dkglobaladmin/adminsupplier/supplierdashboard/SupplierDashboard";

// End

// Start Dk Global Admin Business Develop Page

// import AdminBDDashboard from "./components/pages/dkglobaladmin/adminbusinessdevelop/businessdevelopdashboard/BusinessDevelopDashboard";
import NewBusinessModule from "./components/pages/newbusiness/module/BusinessModule";
import NewBusinessNLeads from "./components/pages/newbusiness/newleads";

// End

// Legal Module Start

import LegalModule from "./components/pages/legalmodule/LegalModule";
import LegalDebtDashboard from "./components/pages/legalmodule/debt/DebtDashboard";
import LegalDirectorDashboard from "./components/pages/legalmodule/legaldirector/LegalDirector";

// End

// Start Dk Global Admin Hr Page

import AdminHrDashboard from "./components/pages/dkglobaladmin/adminhr/hrdashboard/HrDashboard";
import Hronboardingdashboard from "./components/pages/dkglobaladmin/adminhr/Onboarding";
import HrJobOffer from "./components/pages/dkglobaladmin/adminhr/HrJobOffer";
import HrContracts from "./components/pages/dkglobaladmin/adminhr/HrContracts";
import HrDocuments from "./components/pages/dkglobaladmin/adminhr/HrDocuments";
import HrRefrences from "./components/pages/dkglobaladmin/adminhr/HrRefrences";
import HrWorkStations from "./components/pages/dkglobaladmin/adminhr/HrWorkStations";
import HrTechCheck from "./components/pages/dkglobaladmin/adminhr/HrTechCheck";
import HrAccounts from "./components/pages/dkglobaladmin/adminhr/HrAccounts";
import HrInstructions from "./components/pages/dkglobaladmin/adminhr/HrInstructions";
import HrnewLeaves from "./components/pages/hr/holidays/Holidays";

// End

// Start Dk Global Admin My Files Page

import AdminMyFilesDashboard from "./components/pages/dkglobaladmin/adminmyfiles/myfilesdashboard/MyFilesDashboard";

// End

// Start Dk Global Admin Management Page

import AdminManagementDashboard from "./components/pages/dkglobaladmin/adminmanagement/managementdashboard/ManagementDashboard";

// End

// Start Dk Global Admin Knowleadge Base Page

import AdminKnowledgeBaseDashboard from "./components/pages/dkglobaladmin/adminknowledgebase/knowledgebasedashboard/KnowledgeBaseDashboard";

// End

// End

//Start For Mailbox
import MainMailBox from "./components/pages/mainmenu/mailbox/Mailbox";
import SystemUserMailBox from "./components/pages/mainmenu/mailbox/SystemMailbox";
import CandidateMailbox from "./components/pages/mainmenu/cand-mailbox/CandidateMailbox";
// import MainMailBoxSent from "./components/pages/mainmenu/mailbox/MailboxSent";
// import MainMailBoxSpam from "./components/pages/mainmenu/mailbox/MailboxSpam";
// import MainMailBoxDraft from "./components/pages/mainmenu/mailbox/MailboxDraft";
// import MainMailBoxTrash from "./components/pages/mainmenu/mailbox/MailboxTrash";
// import MainMailBoxArchive from "./components/pages/mainmenu/mailbox/MailboxArchive";
import JobsMailboxReport from "./components/pages/mainmenu/mailbox/report/Report";
import JobsMailboxValidation from "./components/pages/mainmenu/mailbox/Validation";
import JobsMailboxMailshot from "./components/pages/mainmenu/mailbox/Mailshot";
import JobsMailboxTemplate from "./components/pages/mainmenu/mailbox/Template";
//End For Mailbox
// Start Workplan Pages
import TasksSummary from "./components/pages/tasks/summary/Summary";
import TasksToDo from "./components/pages/tasks/todo/ToDo";
import TasksInprogress from "./components/pages/tasks/inprogress/InProgress";
import TasksCompleted from "./components/pages/tasks/completed/Completed";
import TasksArchived from "./components/pages/tasks/archived";

import paymentsArchived from "./components/pages/tasks/payments/archive";
import TasksPayments from "./components/pages/tasks/payments/Payments";
import TasksPaidPayments from "./components/pages/tasks/payments/Paid";
import PayTasksSummary from "./components/pages/paytasks/PayTasksSummary";
// End Workplan Pages
// Start Mainmenu Payments Pages
// import PayTasksSummary from './components/pages/paytasks/PayTasksSummary';
import PaymentTasksSummary from "./components/pages/mainmenu/payments/PaymentTasksSummary";
import PaymentsTasksTopay from "./components/pages/mainmenu/payments/PaymentTasksTopay";
import PaymentsTasksPaid from "./components/pages/mainmenu/payments/PaymentTasksTopaid";
import PaymentsTasksArchived from "./components/pages/mainmenu/payments/PaymentTasksArchived";

// End Mainmenu Payments Pages
// Start Workplan Pages

import WorkplanModule from "./components/pages/workplan/index";

// End
// Start MainMenu WorkPlan Pages
import WorkplanMoods from "./components/pages/mainmenu/workplan/moods";
import MainPrincipales from "./components/pages/mainmenu/principales";
import WorkplanCheckIn from "./components/pages/mainmenu/workplan/moods/WorkplanCheckIn";
import WorkplanMainWorkFlow from "./components/pages/mainmenu/workflow/WorkFlow";
import MainDkgReoprts from "./components/pages/mainmenu/dkg-reoprts/DkgReoprts";
import BusinessMangementReoprts from "./components/pages/mainmenu/reoprts/DkgReoprts";

// import WorkplanRules from './components/pages/mainmenu/workplan/moods/WorkplanRules';
import WorkplanRequest from "./components/pages/mainmenu/workplan/moods/WorkplanRequest";
import WorkplanReqRequiters from "./components/pages/mainmenu/workplan/moods/WorkplanRequiters";
import WorkplanReqRecAdmin from "./components/pages/mainmenu/workplan/moods/requests/WorkplanAdmin";
import WorkplanReqAccManager from "./components/pages/mainmenu/workplan/moods/requests/WorkplanAccManager";
import WorkplanReqdkgAdmin from "./components/pages/mainmenu/workplan/moods/requests/WorkpladkgAdmin";
import GetstartedModelOverview from "./components/pages/dkglobal/workmodel/model/GetstartedModelOverview";
import WorkplanModelOne from "./components/pages/dkglobal/workmodel/model/GetstartedModelOne";
import WorkplanModelTwo from "./components/pages/dkglobal/workmodel/model/GetstartedModelTwo";
import WorkplanModelThree from "./components/pages/dkglobal/workmodel/model/GetstartedModelThree";

import WorkplanMyProfile from "./components/pages/mainmenu/workplan/moods/WorkplanJobProfile";
import MainWorkload from "./components/pages/mainmenu/workload";
import MainMoods from "./components/pages/mainmenu/get-started/blackbook/BlackBook";

//End MainMenu WorkPlan Pages
// Start Mainmenu Spec Pages
// import MainSpecSummary from "./components/pages/mainmenu/spec/summary/SpecSummary";
// import MainLiveSpec from "./components/pages/mainmenu/spec/livespec/TableLiveJobs";
import MainAddSpec from "./components/pages/mainmenu/spec/addnew/AddNew";
// import MainSavedSpec from "./components/pages/mainmenu/spec/active-saved/ActiveSaved";
import MainSavedSpec from "./components/pages/mainmenu/spec/active-saved/Folders";
import ApplyJob from "./components/pages/mainmenu/spec/apply-job/ApplyJob";
import MainSavedSpecTable from "./components/pages/mainmenu/spec/spec/Jobs";
// End Mainmenu Spec Pages

// Start Mainmenu Interview Spec Pages
import MainInterviewSpecSummary from "./components/pages/mainmenu/interview/summary/SpecSummary";
import MainInterviewLiveSpec from "./components/pages/mainmenu/interview/livespec/TableLiveJobs";
import MainInterviewAddSpec from "./components/pages/mainmenu/interview/addnew/AddNew";
// import MainSavedSpec from "./components/pages/mainmenu/spec/active-saved/ActiveSaved";
import MainInterviewSavedSpec from "./components/pages/mainmenu/interview/active-saved/Folders";
import MainInterviewSavedSpecTable from "./components/pages/mainmenu/interview/spec/Jobs";
// End Mainmenu Spec Pages

// Start Mainmenu Offer Spec Pages
import MainOfferSpecSummary from "./components/pages/mainmenu/offer/summary/SpecSummary";
// import MainOfferLiveSpec from "./components/pages/mainmenu/offer/livespec/TableLiveJobs";
import MainOfferAddSpec from "./components/pages/mainmenu/offer/addnew/AddNew";
import MainOfferSavedSpec from "./components/pages/mainmenu/offer/active-saved/Folders";
import MainOfferSavedSpecTable from "./components/pages/mainmenu/offer/spec/Jobs";
// End Mainmenu Offer Spec Pages

// Rc Wallet Start

import RcSummary from "./components/pages/mainmenu/rcwallet/summary/RcSummary";
import RcPerformance from "./components/pages/mainmenu/rcwallet/performance/RcPerformance";
import RcSendouts from "./components/pages/mainmenu/rcwallet/sendouts/RcSendouts";
import RcPlacements from "./components/pages/mainmenu/rcwallet/placements/RcPlacements";
import KpiRecruiters from "./components/pages/kpi/recuiters-new/KpiRecruiters";
import KpireqSummary from "./components/pages/kpi/recruiters/summary";
import RcInformation from "./components/pages/mainmenu/rcwallet/information/Information";

// End

// Start Day Closing Pages
import DayClsoingMoods from "./components/pages/mainmenu/dayclosing/moods";
import DayClsoingMoods2 from "./components/pages/mainmenu/dayclosing/moods/Moods2";
import DayClsoingMoods3 from "./components/pages/mainmenu/dayclosing/moods/Moods3";
import DayClsoingMoods4 from "./components/pages/mainmenu/dayclosing/moods/Moods4";
import DayClsoingMoods5 from "./components/pages/mainmenu/dayclosing/moods/Moods5";
//End Day Closing Pages
//Start Training Req Pages
import TrainingReq from "./components/pages/mainmenu/trainingreq";
//End Training Req Pages
//Start Announcements  Pages
import AnnouncementSummary from "./components/pages/annoucements/summary/Summary";
import MainModuleTrainingGetStarted from "./components/pages/mainmenu/training/GetStarted";

import MainModuleTrainingGetStartedtepOne from "./components/pages/mainmenu/training/getstarted/GetStartedtepOne";
import MainModuleTrainingGetStartedtepTwo from "./components/pages/mainmenu/training/getstarted/GetStartedtepTwo";
import MainModuleTrainingGetStartedtepThree from "./components/pages/mainmenu/training/getstarted/GetStartedtepThree";
import MainModuleTrainingGetStartedtepFour from "./components/pages/mainmenu/training/getstarted/GetStartedtepFour";
import MainModuleTrainingGetStartedtepFive from "./components/pages/mainmenu/training/getstarted/GetStartedtepFive";
import MainModuleTrainingGetStartedtepSix from "./components/pages/mainmenu/training/getstarted/GetStartedtepSix";
import MainModuleTrainingGetStartedtepSeven from "./components/pages/mainmenu/training/getstarted/GetStartedtepSeven";
import MainModuleTrainingGetStartedtepEight from "./components/pages/mainmenu/training/getstarted/GetStartedtepEight";

import MainModuleTrainingWorkplanSampleOne from "./components/pages/mainmenu/training/workplan/WorkPlanSampleOne";
import MainModuleTrainingWorkplanSampleTwo from "./components/pages/mainmenu/training/workplan/WorkPlanSampleTwo";
import MainModuleTrainingWorkplanSampleThree from "./components/pages/mainmenu/training/workplan/WorkPlanSampleThree";

import MainModuleTrainingLiveJobsOne from "./components/pages/mainmenu/training/livejobs/LiveJobsSampleOne";
import MainModuleTrainingLiveJobsTwo from "./components/pages/mainmenu/training/livejobs/LiveJobsSampleTwo";
import MainModuleTrainingLiveJobsThree from "./components/pages/mainmenu/training/livejobs/LiveJobsSampleThree";

import MainModuleTrainingJobSpecsOne from "./components/pages/mainmenu/training/jobspecs/JobSpecsSampleOne";
import MainModuleTrainingJobSpecsTwo from "./components/pages/mainmenu/training/jobspecs/JobSpecsSampleTwo";
import MainModuleTrainingJobSpecsThree from "./components/pages/mainmenu/training/jobspecs/JobSpecsSampleThree";

import MainModuleTrainingResumeOne from "./components/pages/mainmenu/training/resume/ResumeSampleOne";
import MainModuleTrainingResumeTwo from "./components/pages/mainmenu/training/resume/ResumeSampleTwo";
import MainModuleTrainingResumeThree from "./components/pages/mainmenu/training/resume/ResumeSampleThree";

import MainModuleTrainingPipelinesOne from "./components/pages/mainmenu/training/pipelines/PipelinesSampleOne";
import MainModuleTrainingPipelinesTwo from "./components/pages/mainmenu/training/pipelines/PipelinesSampleTwo";
import MainModuleTrainingPipelinesThree from "./components/pages/mainmenu/training/pipelines/PipelinesSampleThree";

import MainModuleTrainingSendOutsOne from "./components/pages/mainmenu/training/sendouts/SendOutsSampleOne";
import MainModuleTrainingSendOutsTwo from "./components/pages/mainmenu/training/sendouts/SendOutsSampleTwo";
import MainModuleTrainingSendOutsThree from "./components/pages/mainmenu/training/sendouts/SendOutsSampleThree";

import MainModuleTrainingPostAdsOne from "./components/pages/mainmenu/training/postads/PostAdsSampleOne";
import MainModuleTrainingPostAdsTwo from "./components/pages/mainmenu/training/postads/PostAdsSampleTwo";
import MainModuleTrainingPostAdsThree from "./components/pages/mainmenu/training/postads/PostAdsSampleThree";

import MainModuleTrainingMakeCallsOne from "./components/pages/mainmenu/training/makecalls/MakeCallsSampleOne";
import MainModuleTrainingMakeCallsTwo from "./components/pages/mainmenu/training/makecalls/MakeCallsSampleTwo";
import MainModuleTrainingMakeCallsThree from "./components/pages/mainmenu/training/makecalls/MakeCallsSampleThree";

import MainModuleTrainingWorkPlan from "./components/pages/mainmenu/training/WorkPlan";
import MainModuleTrainingLiveJobs from "./components/pages/mainmenu/training/LiveJobs";
import MainModuleTrainingJobSpecs from "./components/pages/mainmenu/training/JobSpecs";
import MainModuleTrainingResume from "./components/pages/mainmenu/training/Resume";
import MainModuleTrainingPipelines from "./components/pages/mainmenu/training/Pipelines";
import MainModuleTrainingSendOuts from "./components/pages/mainmenu/training/SendOuts";
import MainModuleTrainingPostAds from "./components/pages/mainmenu/training/PostAds";
import MainModuleTrainingMakeCalls from "./components/pages/mainmenu/training/MakeCalls";

//End Announcements  Pages
import DailyTracking from "./components/pages/dashboard/dailytracking/DailyTracking";
import DkgFaq from "./components/pages/dashboard/faq/dkgFaq";
import DkgTeam from "./components/pages/dashboard/dkgTeam";
// import JobProfile from "./components/pages/dashboard/jobProfiles";
import SalesKpi from "./components/pages/dashboard/saleskpi/SalesKpi";
import KpiSalesRefused from "./components/pages/kpi/refused/KpiSalesRefused";
import KpiSalesJoined from "./components/pages/kpi/joined/KpiSalesJoined";
import KpiSalesCredit from "./components/pages/kpi/credit/KpiSalesCredit";
import SalesKpiStatsDetails from "./components/pages/dashboard/saleskpi/SalesKpiStatsDetails";
import SalesCaseDetails from "./components/pages/dashboard/saleskpi/SalesCaseDetails";

import TasksStatsSummary from "./components/pages/kpitasks/TasksStatsSummary";
import TasksStatsReport from "./components/pages/kpitasks/TasksStatsReport";
import TasksStatsDetails from "./components/pages/kpitasks/TasksStatsDetails";
import TasksCaseDetails from "./components/pages/kpitasks/TasksCaseDetails";

import SalesKpiDashboard from "./components/pages/dashboard/saleskpi/SalesKpiDashboard";
import SalesKpiMonthly from "./components/pages/dashboard/saleskpi/SalesKpiMonthly";
import SalesKpiCaseOpened from "./components/pages/dashboard/saleskpi/caseopened/CaseOpened";
import SalesKpiJobSpec from "./components/pages/dashboard/saleskpi/jobspec/JobSpec";
import SalesKpiRefuseOffer from "./components/pages/dashboard/saleskpi/refusedoffer/RefusedOffer";
import CreditKpi from "./components/pages/dashboard/saleskpi/credit/CreditKpi";
import PlacementsSalesKpi from "./components/pages/dashboard/saleskpi/placements/Placements";
import AnnualSalesKpi from "./components/pages/dashboard/saleskpi/annualstats/AnnualSale";
import Savings from "./components/pages/dashboard/savings/Savings";
import TeamRoles from "./components/pages/dashboard/teamroles/TeamRoles";
import EditTeamRoles from "./components/pages/dashboard/teamroles/EditTeamRoles";
import JobDetails from "./components/pages/dashboard/jobdetails/JobDetails";
import PipelinesManagement from "./components/pages/dashboard/pipelines/PipelinesManagement";
import EditPipelinesManagement from "./components/pages/dashboard/pipelines/EditPipelines";
// import Holidays from "./components/pages/dashboard/holidays/index";
import LiveProjects from "./components/pages/dashboard/liveprojects/LiveProjects";
import ClientKpi from "./components/pages/dashboard/clientskpi/ClientKpi";
import SalestargetKpi from "./components/pages/dashboard/clientskpi/SalestargetKpi";
//import ClientKpiBackup from './components/pages/dashboard/clientskpi/ClientKpibackup';
import DailyTracker from "./components/pages/ads/appstracker/DailyTracker";
import WeeklyAppsTracker from "./components/pages/ads/appstracker/WeeklyTracker";
import MonthAppsTracker from "./components/pages/ads/appstracker/MonthTracker";
import YearlyTracker from "./components/pages/ads/appstracker/YearlyTracker";

// Recruitment Training Start

import RecOverview from "./components/pages/rectraining/overview/RecOverview";
import RecDashboard from "./components/pages/rectraining/dashboard/RecDashboard";
import ReWorkPlan from "./components/pages/rectraining/workplan/ReWorkPlan";
import ReNotifications from "./components/pages/rectraining/notifications/ReNotifications";
import ReCandidate from "./components/pages/rectraining/candidate/ReCandidate";
import ReJobs from "./components/pages/rectraining/jobs/ReJobs";
import ReCompany from "./components/pages/rectraining/company/ReCompany";
import ReKpi from "./components/pages/rectraining/kpi/ReKpi";
import ReDkgCloud from "./components/pages/rectraining/dkgcloud/ReDkgCloud";
import ReDatabase from "./components/pages/rectraining/database/ReDatabase";
import ReDkGlobal from "./components/pages/rectraining/dkglobal/ReDkGlobal";
import ReDkGlobalDkgTeams from "./components/pages/rectraining/dkglobal/ReDkGlobalDkgTeams";
import ReDkGlobalStructure from "./components/pages/rectraining/dkglobal/ReDkGlobalStructure";
import ReDkGlobalProcess from "./components/pages/rectraining/dkglobal/ReDkGlobalProcess";
import ReDkGlobalPipelines from "./components/pages/rectraining/dkglobal/ReDkGlobalPipelines";

// Recruitment Training End

//Start Notification Pages
import NotiSummary from "./components/pages/notification/summary/Summary";
import NotiLiveJobs from "./components/pages/notification/livejobs/LiveJobs";
import NotiCandidates from "./components/pages/notification/candidates/Candidates";
// End Notification Pages
// Start Comapines Pages
import CompanySummary from "./components/pages/companies/summary/Summary";
import CompanyListView from "./components/pages/companies/listview/ListView";
import ClientCompany from "./components/pages/clientsinfo/company/ClientCompany";
// import ClientTemplate from "./components/pages/clientsinfo/template/ClientTemplate";
import ClientTemplate from "./components/pages/clientsinfo/requirements/DkgClientProjects";
import DkgClientProject from "./components/pages/clientsinfo/projects/DkgClientProject";

import CandidateQuery from "./components/pages/clientsinfo/candquery/CandidateQuery";
import CompanyDetailView from "./components/pages/companies/detailview/DetailView";
import CompanyAddNew from "./components/pages/companies/addnew/AddNew";
import CompanyPolicies from "./components/pages/companies/policies/Policies";
import CompanySalaries from "./components/pages/companies/salaries";
import CompanyIncometax from "./components/pages/companies/income-tax";
import CompanyProfiling from "./components/pages/companies/profiling";
import CompanyWhAthens from "./components/pages/companies/profiling/WhAthens";
import CompanyBenfits from "./components/pages/companies/benefits";
import CompanyRelocation from "./components/pages/companies/relocation";
import CompanyInterview from "./components/pages/companies/interviews";
import CompanyClentsDetails from "./components/pages/companies/clients-details/ClientsDetails";

//End Comapines Pages

// Start Client Info
import RecClientinfoSuMM from "./components/pages/recruitment/clientsinfo/summary";
import MainClientinfoSuMM from "./components/pages/mainmenu/clientinfo/summary";
import MainModuleCompanyPolicies from "./components/pages/clientsinfo/policies/Policies";
// import MainClientinfoDemands from './components/pages/clientsinfo/demands/DkgDemands'
import MainClientinfoDemands from "./components/pages/training/commontraining/process/DkgProcess";

import DkgClientCompaines from "./components/pages/clientsinfo/requirements/DkgClientCompaines";
import MainDkgSalaries from "./components/pages/clientsinfo/salary/DkgSalaries";
// import MainModuleCompanySalaries from './components/pages/clientsinfo/salaries/index';
import MainModuleCompanyIncometax from "./components/pages/clientsinfo/income-tax/index";
import MainModuleCompanyProfiling from "./components/pages/clientsinfo/profiling/index";
import MainModuleCompanyWhAthens from "./components/pages/clientsinfo/profiling/WhAthens";
import MainModuleCompanyBenfits from "./components/pages/clientsinfo/benefits/DkgBenfilts";
import MainModuleDkgLanguage from "./components/pages/clientsinfo/language/DkgLanguage";
import MainModuleDkgPortal from "./components/pages/clientsinfo/portals/DkgPortals";
// import MainModuleCompanyRelocation from "./components/pages/clientsinfo/relocation/index";
import MainModuleCompanyInterview from "./components/pages/clientsinfo/interviews/DkgInterviews";
import MainModuleDkgFeedbacks from "./components/pages/clientsinfo/feedbacks/DkgFeedbacks";
// import ClientinfoJoboffer from "./components/pages/clientsinfo/invoices/index";
import ClientinfoSendouts from "./components/pages/clientsinfo/sendouts/DkgSendouts";
// import MainModuleCompanyInterview from './components/pages/clientsinfo/';
// import MainModuleCompanyInterview from './components/pages/clientsinfo/interviews/index';

// End

// Start Module For Logins Box
import LoginsSummary from "./components/pages/logins/summary";
import Loginsoffice365 from "./components/pages/logins/office";
import LoginsDKGCrm from "./components/pages/logins/dkg-crm";
import JobPortals from "./components/pages/logins/job-portals";
import LoginsClientsportal from "./components/pages/logins/clients-portals";
import LoginsSocailMedia from "./components/pages/logins/socialmedia";
import LoginsEmailAcc from "./components/pages/logins/emailacc";
import LoginsSoftware from "./components/pages/logins/software";
import LoginsDomain from "./components/pages/logins/domains";
import LoginsCommunications from "./components/pages/logins/communications";
import LoginsOthers from "./components/pages/logins/others";

// End Module For Logins Box

// Start Module For Visa info Box
// import VisainfoSummary from "./components/pages/visainfo/summary";
import RecuitmentLiveSpec from "./components/pages/recruitment/jobofferspec/livespec";
// import RecuitmentActiveSpec from "./components/pages/recruitment/jobofferspec/activespec";
// import RecuitmentInctiveSpec from "./components/pages/recruitment/jobofferspec/inactivespec";
// import RecuitmentCreateSpec from "./components/pages/recruitment/jobofferspec/createspec";

// End Module For Visa info Box

// Start Module For Sales Tips Box
import SalestipsSummary from "./components/pages/salestips/summary";
// End Module For Sales Tips Box

// Start Module For Recruitment Rules Box
import RecrulesSummary from "./components/pages/recruitmentrules/summary";
// End Module For Recruitment Rules Box
// Start Module For Datatable in Dkglobal
import DTDKGlobalDeepak from "./components/pages/databasetable/dkglobal/deepak";
//End Module For Datatable in Dkgloblal
//Start Module for Job Spec
import MainJobspecSumm from "./components/pages/mainmenu/jobspec/summary";
//End Module for Job Spec
// Start Jobs Pages
import RecDemand from "./components/pages/demand/Demand";
import JobsSummary from "./components/pages/jobs/summary/Summary";

import LiveJobs from "./components/pages/jobs/livejobs/LiveJobs";

import TableLiveJobs from "./components/pages/jobs/livejobs/TableLiveJobs";
import inactiveLiveJobsThree from "./components/pages/jobs/livejobs/InactiveLiveJobs";
import ClosedJobsOne from "./components/pages/jobs/livejobs/ClosedJobsOne";
import InactiveJobsFirst from "./components/pages/jobs/livejobs/InactiveJobs";
import LiveJobsThree from "./components/pages/jobs/jobsthree/LiveJobsThree";
import ClosedJobs from "./components/pages/jobs/livejobs/ClosedJob";
import ClosedclJobs from "./components/pages/jobs/livejobs/clj";
import JobsAdmin from "./components/pages/jobs/admin/category/JobsCategory";
import JobsAdminCompany from "./components/pages/jobs/admin/company/JobsCompany";
import JobsAddNew from "./components/pages/jobs/addnew/AddNew";
import Jobs from "./components/pages/jobs";
import Qualify from "./components/pages/jobs/qualify/Qualify";
import DKGCloud from "./components/pages/jobs/dkgcloud/DkgCloud";
import Campaigns from "./components/pages/ads/campaigns/Campaigns";
import CampaignsClosed from "./components/pages/ads/campaigns/CampaignsClosed";
import JobBoard from "./components/pages/jobs/livejobs/JobBoard";
import JobsDemand from "./components/pages/mainmenu/jobs/demand";
import JobsWorkplan from "./components/pages/mainmenu/jobs/workplan/JobsWorkplan";
//End Jobs Pages

// Start Moddule Sendouts

import SendoutsSummary from "./components/pages/sendouts/summary/SendoutsSummary";
// import SendoutsReports from './components/pages/sendouts/SendoutsReports';
import ReportJobOffer from "./components/pages/sendouts/ReportJobOffer";
// import ReportNewCase from './components/pages/dashboard/clientskpi/AssignedKpi';
import ReportRefused from "./components/pages/sendouts/ReportRefused";
import ReportJoined from "./components/pages/sendouts/ReportJoined";
import ReportCredit from "./components/pages/sendouts/ReportCredit";

// End
// START MODULE FOR RECUITERS KPI
import RecuitersClients from "./components/pages/kpitasks/recruiters/clients/RecuitersClients";
import RecuitersSummary from "./components/pages/kpitasks/recruiters/case/KpiCaseSummary";
import OperationM from "./components/pages/kpitasks/OperationM";
import AccountsM from "./components/pages/kpitasks/AccountsM";
import AdvertiseM from "./components/pages/kpitasks/AdvertiseM";
import AccountingM from "./components/pages/kpitasks/AccountingM";
import DkgAdmins from "./components/pages/kpitasks/DkgAdmins";
import ItTeams from "./components/pages/kpitasks/ItTeams";
import ReportNewCase from "./components/pages/kpitasks/recruiters/AssignedKpi";
import ReportQualified from "./components/pages/kpitasks/recruiters/QualifiedKpi";
import ReportJobSpecs from "./components/pages/kpitasks/recruiters/JobSpecKpi";
import RecuitersendoutsKpi from "./components/pages/kpitasks/recruiters/RecuitersendoutsKpi";
import RecuitersJoboffersKpi from "./components/pages/kpitasks/recruiters/JoboffersKpi";
import RecuitersRefeusedKpi from "./components/pages/kpitasks/recruiters/RecuitersRefusedKpi";
import RecuitersJoineddKpi from "./components/pages/kpitasks/recruiters/RecuitersJoinedKpi";
import RecuitersCreditdKpi from "./components/pages/kpitasks/recruiters/RecuitersCreditKpi";
//END MODULE FOR RECUITERS KPI
//START MODULE FOR TARGETS KPI dff
import MainTragetSummary from "./components/pages/mainmenu/targets/summary";
//END MODULE FOR TARGETS KPI
import CandidateSummary from "./components/pages/candidate/summary/Summary";
import CandidateAddNew from "./components/pages/candidate/addnew/AddNew";
//import CandidatePipelinesTable from './components/pages/candidate/pipeline'
import CandidatePipelinesTableAll from "./components/pages/candidate/pipeline/allCandidates";
import CandidatePipelinesTableNewCvs from "./components/pages/candidate/pipeline/newCvs";
import CandidatePipelinesTableJobSpec from "./components/pages/candidate/pipeline/jobSpec";
import CandidatePipelinesTableCalling from "./components/pages/candidate/pipeline/calling";
import CandidatePipelinesTableIntrested from "./components/pages/candidate/pipeline/intrested";
import CandidatePipelinesTableSendouts from "./components/pages/candidate/pipeline/sendouts";
import CandidatePipelinesTableJobOffer from "./components/pages/candidate/pipeline/jobOffer";
import CandidatePipelinesTableOnHold from "./components/pages/candidate/pipeline/onHold";
import CandidatePipelinesTableRecAdmin from "./components/pages/candidate/pipeline/recAdmin";
import CandidatePipelinesTableClosed from "./components/pages/candidate/pipeline/closed";
import CandidatePipelinesTableDBReady from "./components/pages/candidate/pipeline/dbReady";

import WorkplanWorkFlow from "./components/pages/candidate/workflow/Workflow";
import WorkplanWorkFlowEdit from "./components/pages/candidate/workflow/WorkflowEdit";
import CandidateDataTable from "./components/pages/candidate/datatable/DataTable";
import DBArchive from "./components/pages/candidate/dbarchive/DBArchive";
import CandDBSummary from "./components/pages/candidate/dbsummary/DBSummary";
import CandDBSearchCvs from "./components/pages/candidate/searchcvs/SearchCvs";
import DBSearchcv from "./components/pages/candidate/dbsearchcv/DBSearchcv";
import MailShoots from "./components/pages/candidate/mailshots";
import RecDatabaseBlocked from "./components/pages/candidate/blocked/Blocked";
import CandidateDuplicate from "./components/pages/candidate/duplicate/Duplicate";
import CandSavedProjects from "./components/pages/candidate/projects/savedprojects/";
import ProjectTalentPool from "./components/pages/candidate/projects/talentpool/TalentPool";
import SelectedProjects from "./components/pages/candidate/projects/talentpool/Selected";
import RecruitmentDB from "./components/pages/candidate/duplicate/Duplicate";
import CandBackedValues from "./components/pages/candidate/backendvalues/BkValues";
import CandBackedValuesTest from "./components/pages/candidate/backendvalues/new";
import RecruitmentDBDK from "./components/pages/recruitment/database/deepak";
import RecruitmentMaria from "./components/pages/recruitment/database/mariana";
import RecruitmentFilip from "./components/pages/recruitment/database/filip";
import RecruitmentElis from "./components/pages/recruitment/database/elis";
import RecruitmentTehreem from "./components/pages/recruitment/database/tehreem";
import RecruitmentNewHire from "./components/pages/recruitment/database/newhire";
import RecruitmentNewHireTwo from "./components/pages/recruitment/database/newhire/newhiretwo";
import RecruitmentDkGlobal from "./components/pages/recruitment/database/dkglobal";
// Start Job Apps
import JobappSummary from "./components/pages/mainmenu/jobapps/Summary";
import JobappSavedPdfTable from "./components/pages/mainmenu/jobapps/SavedPdfTable";
// End Job Apps
//Start KPI Page
import KpiSummary from "./components/pages/kpi/summary/Summary";
import KpiDashboard from "./components/pages/kpi/candidates/kpidashboard/KpiDashboard";
import KpiJobSpecs from "./components/pages/kpi/candidates/kpijobspecs/KpiJobSpecs";
import KpiNewCase from "./components/pages/kpi/candidates/kpijobspecs/NewCase";
import KpiSendouts from "./components/pages/kpi/candidates/kpijobspecs/Sendouts";
import KpiInterviews from "./components/pages/kpi/candidates/kpijobspecs/Interviews";
import KpiJobOffer from "./components/pages/kpi/candidates/kpijobspecs/JobOffer";
import KpiJoined from "./components/pages/kpi/candidates/kpijobspecs/KpiJoin";
import KpiRefused from "./components/pages/kpi/candidates/kpijobspecs/Refused";
import KpiInvoiced from "./components/pages/kpi/candidates/kpijobspecs/Invoiced";
import KpiCredits from "./components/pages/kpi/candidates/kpijobspecs/Credits";

import KpiCaseWeekOne from "./components/pages/kpi/case/KpiCaseWeekOne";
import KpiCaseWeekTwo from "./components/pages/kpi/case/KpiCaseWeekTwo";
import KpiCaseWeekThree from "./components/pages/kpi/case/KpiCaseWeekThree";
import KpiCaseWeekFour from "./components/pages/kpi/case/KpiCaseWeekFour";

import KpiQualified from "./components/pages/kpi/kpiqualified/KpiQualified";

import KpiJobs from "./components/pages/kpi/jobs/KpiJobs";
import ReportsAssigned from "./components/pages/kpi/reportsassign/ReportsAssigned";
import ReportsQualifed from "./components/pages/kpi/reportsqualify/ReportsQualifed";
import ModuleKpiJobSepcs from "./components/pages/kpi/jobspecs/KpiJobSepcs";
import JobSpecsWeekOne from "./components/pages/kpi/jobspecs/JobSpecsWeekOne";
import JobSpecsWeekTwo from "./components/pages/kpi/jobspecs/JobSpecsWeekTwo";
import JobSpecsWeekThree from "./components/pages/kpi/jobspecs/JobSpecsWeekThree";
import JobSpecsWeekFour from "./components/pages/kpi/jobspecs/JobSpecsWeekFour";
import KpiSales from "./components/pages/kpi/sales/KpiSales";
import KpiSalesSendouts from "./components/pages/kpi/sendouts/KpiSalesSendouts";
import SalesSummary from "./components/pages/kpi/sendouts/SalesSummary";
import RCSendouts from "./components/pages/kpi/sendouts/RCSendouts";
import SalesSendoutsWeekOne from "./components/pages/kpi/sendouts/SalesSendoutsWeekOne";
import SalesSendoutsWeekTwo from "./components/pages/kpi/sendouts/SalesSendoutsWeekTwo";
import SalesSendoutsWeekThree from "./components/pages/kpi/sendouts/SalesSendoutsWeekThree";
import SalesSendoutsWeekFour from "./components/pages/kpi/sendouts/SalesSendoutsWeekFour";
import StatsDetails from "./components/pages/kpi/sendouts/StatsDetails";
import CaseDetails from "./components/pages/kpi/sendouts/CaseDetails";
//End KPI Page

//Start Mypipelines Page
import RecMypipelinesSumm from "./components/pages/mypipelines/summary";
import RecMypipelinesNewCase from "./components/pages/mypipelines/pipelines/newcase";
import RecMypipelinesJobSpec from "./components/pages/mypipelines/pipelines/job-spec";
import RecMypipelinesCalling from "./components/pages/mypipelines/pipelines/calling";
import RecMypipelinesIntrested from "./components/pages/mypipelines/pipelines/interested";
import RecMypipelinesSendouts from "./components/pages/mypipelines/pipelines/sendouts";
import RecMypipelinesjobffer from "./components/pages/mypipelines/pipelines/joboffer";
import RecMypipelinesNotReachable from "./components/pages/mypipelines/pipelines/notreachable";
import RecMypipelinesRecAdmin from "./components/pages/mypipelines/pipelines/rec-admin";
import RecMypipelinesClosed from "./components/pages/mypipelines/pipelines/closed";
import RecMypipelinesDBReady from "./components/pages/mypipelines/pipelines/dbready";

//End Mypipelines Page
//Start Ads Page
import AdsSummary from "./components/pages/ads/summary/Summary";
import AdsTemplates from "./components/pages/ads/templates/Templates";
import AdsResources from "./components/pages/ads/resources/Resources";
import AdsTitles from "./components/pages/ads/titles/Titles";
import AdsSavedAds from "./components/pages/ads/savedads/SavedAds";
import todaysAds from "./components/pages/ads/todayads/TodayAds";
import AdsCredits from "./components/pages/ads/adscredits/AdsCredits";
import liveAds from "./components/pages/ads/liveads/LiveAds";

// Start Ads Training

import AdsOverview from "./components/pages/ads/adstraining/overview/AdsOverview";
import AdsDashboard from "./components/pages/ads/adstraining/dashboard/AdsDashboard";
import AdsWorkPlan from "./components/pages/ads/adstraining/workplan/AdsWorkPlan";
import AdsNotifications from "./components/pages/ads/adstraining/notifications/AdsNotifications";
import AdsCandidate from "./components/pages/ads/adstraining/candidate/AdsCandidate";
import AdsJobs from "./components/pages/ads/adstraining/jobs/AdsJobs";
import AdsCompany from "./components/pages/ads/adstraining/company/AdsCompany";
import AdsKpi from "./components/pages/ads/adstraining/kpi/AdsKpi";
import AdsDkgCloud from "./components/pages/ads/adstraining/dkgcloud/AdsDkgCloud";
import AdsDatabase from "./components/pages/ads/adstraining/database/AdsDatabase";
import AdsDkGlobal from "./components/pages/ads/adstraining/dkglobal/AdsDkGlobal";

// End Ads Training

//End Ads Page
// Start Settings Page
import SettingsSummary from "./components/pages/settings/summary/Summary";
import SettingsDkgFaq from "./components/pages/dashboard/faq/dkgFaq";
//import SettingsDkgFaq from './components/pages/settings/faq/DKGFaq';
// End Settings Page
//Start Mainmenu Notifications Page
import MainNotificationSummary from "./components/pages/mainmenu/notifications/summary/Summary";

import TodayMyTasks from "./components/pages/mainmenu/notifications/today/TodayMyTasks";
import TodayNewCase from "./components/pages/mainmenu/notifications/today/TodayNewCase";
import TodayComments from "./components/pages/mainmenu/notifications/today/TodayComments";
import TodayNewJobs from "./components/pages/mainmenu/notifications/today/TodayNewJobs";
import TodayLiveJobs from "./components/pages/mainmenu/notifications/today/TodayLiveJobs";
import TodayAdsPost from "./components/pages/mainmenu/notifications/today/TodayAdsPost";
import TodayResources from "./components/pages/mainmenu/notifications/today/TodayResources";
import TodayPayments from "./components/pages/mainmenu/notifications/today/TodayPayments";
import TodaySalesKpi from "./components/pages/mainmenu/notifications/today/TodaySalesKpi";
import TodayClients from "./components/pages/mainmenu/notifications/today/TodayClients";
import TodayDkGlobal from "./components/pages/mainmenu/notifications/today/TodayDkGlobal";

import OneDayAgoMyTasks from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoMyTasks";
import OneDayAgoNewCase from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoNewCase";
import OneDayAgoComments from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoComments";
import OneDayAgoNewJobs from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoNewJobs";
import OneDayAgoLiveJobs from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoLiveJobs";
import OneDayAgoAdsPost from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoAdsPost";
import OneDayAgoResources from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoResources";
import OneDayAgoPayments from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoPayments";
import OneDayAgoSalesKpi from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoSalesKpi";
import OneDayAgoClients from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoClients";
import OneDayAgoDkGlobal from "./components/pages/mainmenu/notifications/onedayago/OneDayAgoDkGlobal";

import TwoDayAgoMyTasks from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoMyTasks";
import TwoDayAgoNewCase from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoNewCase";
import TwoDayAgoComments from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoComments";
import TwoDayAgoNewjobs from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoNewjobs";
import TwoDayAgoLiveJobs from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoLiveJobs";
import TwoDayAgoAdsPost from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoAdsPost";
import TwoDayAgoResources from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoResources";
import TwoDayAgoPayments from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoPayments";
import TwoDayAgoSalesKpi from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoSalesKpi";
import TwoDayAgoClients from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoClients";
import TwoDayAgoDkGlobal from "./components/pages/mainmenu/notifications/twodayago/TwoDayAgoDkGlobal";

import ThreeDayAgoMyTasks from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoMyTasks";
import ThreeDayAgoNewCase from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoNewCase";
import ThreeDayAgoComments from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoComments";
import ThreeDayAgoNewJobs from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoNewJobs";
import ThreeDayAgoLiveJobs from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoLiveJobs";
import ThreeDayAgoAdsPost from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoAdsPost";
import ThreeDayAgoResources from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoResources";
import ThreeDayAgoPayments from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoPayments";
import ThreeDayAgoSalesKpi from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoSalesKpi";
import ThreeDayAgoClients from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoClients";
import ThreeDayAgoDkGlobal from "./components/pages/mainmenu/notifications/threedayago/ThreeDayAgoDkGlobal";

import FourDayAgoMyTasks from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoMyTasks";
import FourDayAgoNewCase from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoNewCase";
import FourDayAgoComments from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoComments";
import FourDayAgoNewJobs from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoNewJobs";
import FourDayAgoLiveJobs from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoLiveJobs";
import FourDayAgoAdsPost from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoAdsPost";
import FourDayAgoResources from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoResources";
import FourDayAgoPayments from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoPayments";
import FourDayAgoSalesKpi from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoSalesKpi";
import FourDayAgoClients from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoClients";
import FourDayAgoDkGlobal from "./components/pages/mainmenu/notifications/fourdayago/FourDayAgoDkGlobal";

import FiveDayAgoMyTasks from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoMyTasks";
import FiveDayAgoNewCase from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoNewCase";
import FiveDayAgoComments from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoComments";
import FiveDayAgoNewJobs from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoNewJobs";
import FiveDayAgoLiveJobs from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoLiveJobs";
import FiveDayAgoAdsPost from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoAdsPost";
import FiveDayAgoResources from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoResources";
import FiveDayAgoPayments from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoPayments";
import FiveDayAgoSalesKpi from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoSalesKpi";
import FiveDayAgoClients from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoClients";
import FiveDayAgoDkGlobal from "./components/pages/mainmenu/notifications/fivedayago/FiveDayAgoDkGlobal";

import ArchivedMyTasks from "./components/pages/mainmenu/notifications/archived/ArchivedMyTasks";
import ArchivedNewCase from "./components/pages/mainmenu/notifications/archived/ArchivedNewCase";
import ArchivedComments from "./components/pages/mainmenu/notifications/archived/ArchivedComments";
import ArchivedNewJobs from "./components/pages/mainmenu/notifications/archived/ArchivedNewJobs";
import ArchivedLiveJobs from "./components/pages/mainmenu/notifications/archived/ArchivedLiveJobs";
import ArchivedAdsPost from "./components/pages/mainmenu/notifications/archived/ArchivedAdsPost";
import ArchivedResources from "./components/pages/mainmenu/notifications/archived/ArchivedResources";
import ArchivedPayments from "./components/pages/mainmenu/notifications/archived/ArchivedPayments";
import ArchivedSalesKpi from "./components/pages/mainmenu/notifications/archived/ArchivedSalesKpi";
import ArchivedClients from "./components/pages/mainmenu/notifications/archived/ArchivedClients";
import ArchivedDkGlobal from "./components/pages/mainmenu/notifications/archived/ArchivedDkGlobal";

import MainNotificationAddNew from "./components/pages/mainmenu/notifications/addnew/AddNew";
//End Mainmenu Notifications Page

// Main Module Rules Pages

import ModuleDkGlobal from "./components/pages/mainmenu/rules/ModuleDkGlobal";
import ModuleTasks from "./components/pages/mainmenu/rules/ModuleTasks";
import ModuleClients from "./components/pages/mainmenu/rules/ModuleClients";
import ModuleCandidates from "./components/pages/mainmenu/rules/ModuleCandidates";
import ModulePipeline from "./components/pages/mainmenu/rules/ModulePipeline";
import ModuleJobs from "./components/pages/mainmenu/rules/ModuleJobs";
import ModuleRecruitment from "./components/pages/mainmenu/rules/ModuleRecruitment";
import ModuleAds from "./components/pages/mainmenu/rules/ModuleAds";
import ModulePartners from "./components/pages/mainmenu/rules/ModulePartners";

// End

// Start DK Global Page
import DkGlobalDkgTeam from "./components/pages/dkglobal/dkgTeam/Index";
import DkGlobalhierarchy from "./components/pages/dkglobal/hierarchy/DkGlobalStructure";
import DkGlobalStructure from "./components/pages/dkglobal/structure";
import DkGlobalJobprofiles from "./components/pages/dkglobal/jobprofiles";
import JobProfilesEstablished from "./components/pages/dkglobal/jobprofiles/JobProfilesEstablished";
import JobProfilesBusiness from "./components/pages/dkglobal/jobprofiles/JobProfilesBusiness";
import JobProfilesIndustry from "./components/pages/dkglobal/jobprofiles/JobProfilesIndustry";
import JobProfilesServices from "./components/pages/dkglobal/jobprofiles/JobProfilesServices";
import JobProfilesClients from "./components/pages/dkglobal/jobprofiles/JobProfilesClients";
import JobProfilesSuppliers from "./components/pages/dkglobal/jobprofiles/JobProfilesSuppliers";
import JobProfilesCompletion from "./components/pages/dkglobal/jobprofiles/JobProfilesCompletion";
import JobProfilesEmployees from "./components/pages/dkglobal/jobprofiles/JobProfilesEmployees";
import JobProfilesFuturePlan from "./components/pages/dkglobal/jobprofiles/JobProfilesFuturePlan";
import JobProfilesSocialMedia from "./components/pages/dkglobal/jobprofiles/JobProfilesSocialMedia";
// import DKGlobalInformation from "./components/pages/dkglobal/information/DKGlobalInformation";
import DKGlobalCompany from "./components/pages/dkglobal/company/DKGlobalCompany";
import DKGlobalEstablished from "./components/pages/dkglobal/company/DKGlobalEstablished";
import DKGlobalBuisness from "./components/pages/dkglobal/company/DKGlobalBuisness";
import DKGlobalIndustry from "./components/pages/dkglobal/company/DKGlobalIndustry";
import DKGlobalServices from "./components/pages/dkglobal/company/DKGlobalServices";
import DKGlobalClients from "./components/pages/dkglobal/company/DKGlobalClients";
import DKGlobalSuppliers from "./components/pages/dkglobal/company/DKGlobalSuppliers";
import DKGlobalCompletion from "./components/pages/dkglobal/company/DKGlobalCompletion";
import DKGlobalEmployees from "./components/pages/dkglobal/company/DKGlobalEmployees";
import DKGlobalFuturePlans from "./components/pages/dkglobal/company/DKGlobalFuturePlans";
import DKGlobalSocialMedia from "./components/pages/dkglobal/company/DKGlobalSocialMedia";
import DKGlobalContactDetails from "./components/pages/dkglobal/company/DKGlobalContactDetails";
import DKGlobalDepartmentSummary from "./components/pages/dkglobal/departments/DepartmentSummary";
import DepartmentRecruitment from "./components/pages/dkglobal/departments/DepartmentRecruitment";
import DepartmentAdvertisement from "./components/pages/dkglobal/departments/DepartmentAdvertisement";
import DepartmentAccountingFinance from "./components/pages/dkglobal/departments/DepartmentAccountingFinance";
import DepartmentBusinessDevelopment from "./components/pages/dkglobal/departments/DepartmentBusinessDevelopment";
import DepartmentSuppliers from "./components/pages/dkglobal/departments/DepartmentSuppliers";
import DepartmentHr from "./components/pages/dkglobal/departments/DepartmentHr";
import DepartmentManagement from "./components/pages/dkglobal/departments/DepartmentManagement";
import DepartmentTraining from "./components/pages/dkglobal/departments/DepartmentTraining";
import DepartmentLegal from "./components/pages/dkglobal/departments/DepartmentLegal";
import DepartmentItTeam from "./components/pages/dkglobal/departments/DepartmentItTeam";
import DkGlobalProcess from "./components/pages/dkglobal/process/teamroles/DkGlobalProcess";
import DkGlobalEditProcess from "./components/pages/dkglobal/process/teamroles/DkGlobalEditProcess";
import DkGlobalPipelines from "./components/pages/dkglobal/pipelines/PipelinesManagement";
import DkGlobalTeamSummary from "./components/pages/dkglobal/DkGlobalTeamSummary";
import DkGlobalTeamOne from "./components/pages/dkglobal/DkGlobalTeamOne";
import DkGlobalTeamTwo from "./components/pages/dkglobal/DkGlobalTeamTwo";
import DkGlobalReminder from "./components/pages/dkglobal/DkGlobalReminder";
import DkGlobalTeamThree from "./components/pages/dkglobal/DkGlobalTeamThree";
import DkGlobalTeamFour from "./components/pages/dkglobal/DkGlobalTeamFour";
import DkGlobalClients from "./components/pages/dkglobal/clients/DkGlobalClients";
import DkGlobalSuppliers from "./components/pages/dkglobal/suppliers/DkGlobalSuppliers";
import DkGlobalCandidates from "./components/pages/dkglobal/candidates/DkGlobalCandidates";
import DkGlobalDkgCrm from "./components/pages/dkglobal/dkgcrm/DkGlobalDkgCrm";
import EditDkGlobalPipelines from "./components/pages/dkglobal/pipelines/EditPipelines";
import MainSummary from "./components/pages/summary/Summary";
import MainMyMoods from "./components/pages/mainmenu/mymoods/MyMoods";
// End DK Global Page

// Tracking Pages Start

import TrackingSummary from "./components/pages/mainmenu/tracking/summary/Summary";
import TrackingAllTracking from "./components/pages/mainmenu/tracking/alltrackings/AllTrackings";
import TrackingCandidates from "./components/pages/mainmenu/tracking/candidates/Candidates";
import TrackingClients from "./components/pages/mainmenu/tracking/clients/Clients";
import TrackingJobs from "./components/pages/mainmenu/tracking/jobs/Jobs";
import TrackingApps from "./components/pages/mainmenu/tracking/apps/Apps";
import TrackingAds from "./components/pages/mainmenu/tracking/ads/Ads";
import TrackingIt from "./components/pages/mainmenu/tracking/it/It";
import TrackingAccounting from "./components/pages/mainmenu/tracking/accounting/Accounting";

// End

//Start Main Get Started Page
import MainGetStartedSummary from "./components/pages/mainmenu/get-started/summary/Summary";
import GetStartedPrepsrstion from "./components/pages/mainmenu/get-started/preparation/Preparation";
import GetStartedMoring from "./components/pages/mainmenu/get-started/morning/Morning";
import GetStartedMeetings from "./components/pages/mainmenu/get-started/meetings/Meetings";
import GetStartedWorkPlan from "./components/pages/mainmenu/get-started/workplan/WorkPlan";
import GetStartedLunch from "./components/pages/mainmenu/get-started/lunch/Lunch";
import GetStartedAfterNoon from "./components/pages/mainmenu/get-started/afternoon/AfterNoon";
import GetStartedPipelines from "./components/pages/mainmenu/get-started/pipelines/Pipelines";
import GetStartedClosing from "./components/pages/mainmenu/get-started/closing/Closing";

//End Main Get Started Page
//Start Main FAQ Page
// import FaqDkGlobal from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqDkGlobal';
// import FaqKpiCsf from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqKpiCsf';
// import FaqClients from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqClients';
// import FaqDemands from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqDemands';
// import FaqContracts from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqContracts';
// import FaqPayments from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqPayments';
// import FaqJobTraining from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqJobTraining';
// import FaqTechnicalBugs from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqTechnicalBugs';
// import FaqSupport from './components/pages/mainmenu/faq/faqdkglobalcrm/FaqSupport';
//End Main FAQ Page
//Start DKGCloud Pages
import DKGCloudSummary from "./components/pages/dkgcloud/summary/Summary";
import DKGCloudFiles from "./components/pages/dkgcloud/dkgfiles/DkgFiles";
//End DKGCloud Pages
//Start Accounting Page
import AccountingSummary from "./components/pages/accounting/summary";
import AccountingAlerts from "./components/pages/accounting/alerts/Alerts";
import AccountingClients from "./components/pages/accounting/clients/Clients";
import AccountingInvoices from "./components/pages/accounting/invoices/Invoices";
import AccountingIncomes from "./components/pages/accounting/incomes/Incomes";
import AccountingExpense from "./components/pages/accounting/expense/Expense";
import AccountingVatFile from "./components/pages/accounting/vatfile/VatFile";
import AccountingBankState from "./components/pages/accounting/bankstate/BankState";
import AccountingInformation from "./components/pages/accounting/information/Information";
import AccountingSettings from "./components/pages/accounting/settings/Settings";
import AccountingTraining from "./components/pages/accounting/training/Training";
//End Accounting Page

//Start Knowleadgebase Page

import KnowleadgeSummary from "./components/pages/knowleadgebase/summary/index";
import KnowleadgeDkGlobal from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqDkGlobal";
import KnowleadgeSamplePages from "./components/pages/knowleadgebase/kpidkglobal/DkGobalSample";
import KnowleadgeKpiSamplePages from "./components/pages/knowleadgebase/kpi/KpiSample";
import KnowleadgeKpi from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqKpi";
import KnowleadgeCsf from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqCsf";
import KnowleadgeCsfSamplePages from "./components/pages/knowleadgebase/kpicsf/CsfSample";
// import KnowleadgeEmployee from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqEmployee";
import KnowleadgeEmployeeSamplePages from "./components/pages/knowleadgebase/kpiemployee/EmployeeSample";
import KnowleadgeProcess from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqProcess";
import KnowleadgeProcessSamplePages from "./components/pages/knowleadgebase/kpiprocess/ProcessSample";
import KnowleadgeClients from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqClients";
import KnowleadgeClientsSamplePages from "./components/pages/knowleadgebase/kpiclients/ClientsSample";
import KnowleadgeDemands from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqDemands";
import KnowleadgeDemandssSamplePages from "./components/pages/knowleadgebase/kpidemands/DemandsSample";
import KnowleadgeCandidates from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqCandidates";
import KnowleadgeCandidatessSamplePages from "./components/pages/knowleadgebase/kpicandidates/CandidatesSample";
import KnowleadgeTechnicalBugs from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqTechnicalBugs";
import KnowleadgeTechBugsSamplePages from "./components/pages/knowleadgebase/kpitechbugs/TechBugsSample";
import KnowleadgeSupport from "./components/pages/knowleadgebase/faqdkglobalcrm/FaqSupport";
import KnowleadgeSupportSamplePages from "./components/pages/knowleadgebase/kpisupport/SupportSample";
import VisainfoOverview from "./components/pages/knowleadgebase/visainfo/VisainfoOverview";
import VisainfoConcerns from "./components/pages/knowleadgebase/visainfo/VisainfoConcern";
import VisainfoPermission from "./components/pages/knowleadgebase/visainfo/VisainfoPermissions";
import VisainfoAllVisaType from "./components/pages/knowleadgebase/visainfo/VisainfoAllVisaType";
import VisainfoStudent from "./components/pages/knowleadgebase/visainfo/VisainfoStudent";

//End Knowleadgebase Page

// Start Resource Page
import ResSummary from "./components/pages/resource/summary/ResourcesSummary";
import MLResource from "./components/pages/resource/mlresource/MLResource";
import ITResource from "./components/pages/resource/itresource/ITResource";
import ResourceTargets from "./components/pages/resource/targets";
import ResourcePartners from "./components/pages/resource/partners";
import ResourceResearch from "./components/pages/resource/research";
import LoginsResource from "./components/pages/resource/logins/Logins";
import BudgetResource from "./components/pages/resource/budget/Budget";
import BudgetPerformance from "./components/pages/resource/budget/BudgetPerformance";
import CostbenfitResource from "./components/pages/resource/costbenfit/CostBenefits";

// Resource Training Start

import ResOverview from "./components/pages/resource/restraining/overview/ResOverview";
import ResDashboard from "./components/pages/resource/restraining/dashboard/ResDashboard";
import ResWorkPlan from "./components/pages/resource/restraining/workplan/ResWorkPlan";
import ResNotifications from "./components/pages/resource/restraining/notifications/ResNotifications";
import ResCandidate from "./components/pages/resource/restraining/candidate/ResCandidate";
import ResJobs from "./components/pages/resource/restraining/jobs/ResJobs";
import ResCompany from "./components/pages/resource/restraining/company/ResCompany";
import ResKpi from "./components/pages/resource/restraining/kpi/ResKpi";
import ResDkgCloud from "./components/pages/resource/restraining/dkgcloud/ResDkgCloud";
import ResDatabase from "./components/pages/resource/restraining/database/ResDatabase";
import ResDkGlobal from "./components/pages/resource/restraining/dkglobal/ResDkGlobal";

// End Resource Training

//End Resource Page

// Training Pages Start
import TrainingSummary from "./components/pages/training/summary/Summary";
import TrainingOnboarding from "./components/pages/training/onboarding/Onboarding";
import TrainingAboutDkg from "./components/pages/training/onboarding/AboutDkg";
import TrainingDkgTeam from "./components/pages/training/onboarding/DkgTeam";
import TrainingTimeTable from "./components/pages/training/onboarding/TimeTable";
import TrainingTechnical from "./components/pages/training/onboarding/Technical";
import TrainingAccount from "./components/pages/training/onboarding/Account";
import TrainingKpiCsf from "./components/pages/training/onboarding/KpiCsf";
import TrainingIntroduction from "./components/pages/training/onboarding/Introduction";
import TrainingProgress from "./components/pages/training/onboarding/TrainingProgress";
import TrainingConducts from "./components/pages/training/onboarding/Conducts";
import TrainingContracts from "./components/pages/training/onboarding/Contracts";
import OnboardingTraining from "./components/pages/training/onboarding/Training";
import TrainingManagement from "./components/pages/training/management/Management";
import ManagementTeamLeader from "./components/pages/training/management/ManagementTeamLeader";
import ManagementRecManager from "./components/pages/training/management/ManagementRecManager";
import ManagementMarketingManager from "./components/pages/training/management/ManagementMarketingManager";
import ManagementHrManager from "./components/pages/training/management/ManagementHrManager";
import ManagementItManager from "./components/pages/training/management/ManagementItManager";
import ManagementResourceManager from "./components/pages/training/management/ManagementResourceManager";
import ManagementFinanceManager from "./components/pages/training/management/ManagementFinanceManager";
import ManagementOperationManager from "./components/pages/training/management/ManagementOperationManager";
import ManagementDirector from "./components/pages/training/management/ManagementDirector";
import CompletionOverview from "./components/pages/training/completion/Completion";
import TrainingSchedule from "./components/pages/training/schedule/Schedule";
import DayOneFullTime from "./components/pages/training/schedule/DayOneFullTime";
import DayTwoFullTime from "./components/pages/training/schedule/DayTwoFullTime";
import DayThreeFullTime from "./components/pages/training/schedule/DayThreeFullTime";
import DayFourFullTime from "./components/pages/training/schedule/DayFourFullTime";
import DayFiveFullTime from "./components/pages/training/schedule/DayFiveFullTime";
import DaySixPartTime from "./components/pages/training/schedule/DaySixPartTime";
import DaySevenPartTime from "./components/pages/training/schedule/DaySevenPartTime";
import DayEightPartTime from "./components/pages/training/schedule/DayEightPartTime";
import DayNinePartTime from "./components/pages/training/schedule/DayNinePartTime";
import DayTenPartTime from "./components/pages/training/schedule/DayTenPartTime";
import FinalAccessment from "./components/pages/training/schedule/FinalAccessment";
import TrainingStatus from "./components/pages/training/schedule/TrainingStatus";
import TrainingTools from "./components/pages/training/tools/Tools";
import TrainingMasterbox from "./components/pages/training/masterbox/Masterbox";
import CommonTraining from "./components/pages/training/commontraining/CommonTraining";
import CommonOverview from "./components/pages/training/commontraining/CommonOverview";
import CommonEstablished from "./components/pages/training/commontraining/commonoverview/CommonEstablished";
import CommonBusiness from "./components/pages/training/commontraining/commonoverview/CommonBusiness";
import CommonIndustry from "./components/pages/training/commontraining/commonoverview/CommonIndustry";
import CommonServices from "./components/pages/training/commontraining/commonoverview/CommonServices";
import CommonClient from "./components/pages/training/commontraining/commonoverview/CommonClient";
import CommonSuppliers from "./components/pages/training/commontraining/commonoverview/CommonSuppliers";
import CommonCompletion from "./components/pages/training/commontraining/commonoverview/CommonCompletion";
import CommonEmployees from "./components/pages/training/commontraining/commonoverview/CommonEmployees";
import CommonFuturePlans from "./components/pages/training/commontraining/commonoverview/CommonFuturePlans";
import CommonSocialMedia from "./components/pages/training/commontraining/commonoverview/CommonSocialMedia";
import CommonContactDetails from "./components/pages/training/commontraining/commonoverview/CommonContactDetails";
import CommonStructure from "./components/pages/training/commontraining/CommonStructure";
import CommonProcess from "./components/pages/training/commontraining/teamroles/TeamRoles";
import CommonEditTeamRoles from "./components/pages/training/commontraining/teamroles/EditTeamRoles";
import CommonTrainings from "./components/pages/training/commontraining/jobdetails/JobDetails";
import CommonPipelines from "./components/pages/training/commontraining/pipelines/PipelinesManagement";
import EditCommonPipelinesManagement from "./components/pages/training/commontraining/pipelines/EditPipelines";
import CommonTools from "./components/pages/training/commontraining/CommonTools";
import CommonMasterbox from "./components/pages/training/commontraining/CommonMasterbox";
import CommonClients from "./components/pages/training/commontraining/CommonClients";
import CommonDkgGuidelines from "./components/pages/training/commontraining/CommonDkgGuidelines";
import CommonDkgPipelines from "./components/pages/training/commontraining/CommonDkgPipelines";
import DkgPipelinesNewCv from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesNewCv";
import DkgPipelinesScreening from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesScreening";
import DkgPipelinesReminders from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesReminders";
import DkgPipelinesRecruiters from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesRecruiters";
import DkgPipelinesAccountM from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesAccountM";
import DkgPipelinesJobOffers from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesJobOffers";
import DkgPipelinesEscalated from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesEscalated";
import DkgPipelinesOnHold from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesOnHold";
import DkgPipelinesClosed from "./components/pages/training/commontraining/dkgpipelines/DkgPipelinesClosed";
import CommonWorkPlan from "./components/pages/training/commontraining/CommonWorkPlan";
import CommonMyTask from "./components/pages/training/commontraining/CommonMyTask";
import CommonResource from "./components/pages/training/commontraining/CommonResource";
import CommonSales from "./components/pages/training/commontraining/CommonSales";
import CommonPayments from "./components/pages/training/commontraining/CommonPayments";
import CommonJobs from "./components/pages/training/commontraining/CommonJobs";
import CommonCandidates from "./components/pages/training/commontraining/CommonCandidates";
import CommonOutlook from "./components/pages/training/commontraining/CommonOutlook";
import CommonEmails from "./components/pages/training/commontraining/CommonEmails";
import CommonCalls from "./components/pages/training/commontraining/CommonCalls";
import CommonSms from "./components/pages/training/commontraining/CommonSms";
import CommonChats from "./components/pages/training/commontraining/CommonChats";
import AssessmentsOverview from "./components/pages/training/assessments/Assessments";
import AssessmentsAssessmentOne from "./components/pages/training/assessments/AssessmentOne";
import AssessmentsAssessmentTwo from "./components/pages/training/assessments/AssessmentTwo";
import AssessmentPreparation from "./components/pages/training/assessments/AssessmentPreparation";
import AssessmentDecision from "./components/pages/training/assessments/AssessmentDecision";
import AssessmentFaq from "./components/pages/training/assessments/AssessmentFaq";
import Admins from "./components/pages/training/admins/Admins";
import AdminWorkProcess from "./components/pages/training/admins/AdminWorkProcess";
import AdminJobDetailsStructure from "./components/pages/training/admins/jobdetails/AdminJobDetailsStructure";
import AdminJobDetailsTeamInfo from "./components/pages/training/admins/jobdetails/AdminJobDetailsTeamInfo";
import AdminJobDetailsJobSkills from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobSkills";
import AdminJobDetailsJobTask from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobTask";
import AdminJobDetailsJobQuality from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobQuality";
import AdminJobDetailsJobVolume from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobVolume";
import AdminJobDetailsJobQueries from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobQueries";
import AdminJobDetailsJobTraining from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobTraining";
import AdminJobDetailsJobDelegation from "./components/pages/training/admins/jobdetails/AdminJobDetailsJobDelegation";
import AdminPreparation from "./components/pages/training/admins/AdminPreparation";
import AdminPreparationToolsLogin from "./components/pages/training/admins/preparation/AdminPreparationToolsLogin";
import AdminPreparationTeamGreeting from "./components/pages/training/admins/preparation/AdminPreparationTeamGreeting";
import AdminPreparationDkgCrmTabs from "./components/pages/training/admins/preparation/AdminPreparationDkgCrmTabs";
import AdminPreparationReviewMailbox from "./components/pages/training/admins/preparation/AdminPreparationReviewMailbox";
import AdminPreparationReviewLiveJobs from "./components/pages/training/admins/preparation/AdminPreparationReviewLiveJobs";
import AdminPreparationReviewPipelines from "./components/pages/training/admins/preparation/AdminPreparationReviewPipelines";
import AdminPreparationReviewWorkplan from "./components/pages/training/admins/preparation/AdminPreparationReviewWorkplan";
import AdminAction from "./components/pages/training/admins/AdminAction";
import AdminActionSequence from "./components/pages/training/admins/action/AdminActionSequence";
import AdminActionSetPriority from "./components/pages/training/admins/action/AdminActionSetPriority";
import AdminActionPriority from "./components/pages/training/admins/action/AdminActionPriority";
import AdminActionCreateNewTask from "./components/pages/training/admins/action/AdminActionCreateNewTask";
import AdminActionFollowWorkplan from "./components/pages/training/admins/action/AdminActionFollowWorkplan";
import AdminReport from "./components/pages/training/admins/AdminReport";
import AdminsPerformanceCriteria from "./components/pages/training/admins/performance/AdminsPerformanceCriteria";
import AdminsPerformanceReviews from "./components/pages/training/admins/performance/AdminsPerformanceReviews";
import AdminsPerformanceReporting from "./components/pages/training/admins/performance/AdminsPerformanceReporting";
import AdminKnowledgeBase from "./components/pages/training/admins/AdminKnowledgeBase";
import AdminsKnowledgeSearchInfo from "./components/pages/training/admins/knowledgebase/AdminsKnowledgeSearchInfo";
import AdminsKnowledgeTaskInfo from "./components/pages/training/admins/knowledgebase/AdminsKnowledgeTaskInfo";
import AdminsKnowledgeToolsInfo from "./components/pages/training/admins/knowledgebase/AdminsKnowledgeToolsInfo";
import AdminsKnowledgeCandidates from "./components/pages/training/admins/knowledgebase/AdminsKnowledgeCandidates";
import AdminsKnowledgeClients from "./components/pages/training/admins/knowledgebase/AdminsKnowledgeClients";
import AdminsKnowledgeJobs from "./components/pages/training/admins/knowledgebase/AdminsKnowledgeJobs";
import AdminSendBulkEmail from "./components/pages/training/admins/AdminSendBulkEmail";
import AdminsSendBeSituations from "./components/pages/training/admins/sendbulkemails/AdminsSendBeSituations";
import AdminsSendBeTaskRules from "./components/pages/training/admins/sendbulkemails/AdminsSendBeTaskRules";
import AdminsSendBeHowToDo from "./components/pages/training/admins/sendbulkemails/AdminsSendBeHowToDo";
import AdminsSendBeCreateList from "./components/pages/training/admins/sendbulkemails/AdminsSendBeCreateList";
import AdminsSendBeCreateTemplate from "./components/pages/training/admins/sendbulkemails/AdminsSendBeCreateTemplate";
import AdminsSendBeReviewTemplate from "./components/pages/training/admins/sendbulkemails/AdminsSendBeReviewTemplate";
import AdminsSendBeSendBulkEmails from "./components/pages/training/admins/sendbulkemails/AdminsSendBeSendBulkEmails";
import AdminsSendBeTrackResponse from "./components/pages/training/admins/sendbulkemails/AdminsSendBeTrackResponse";
import AdminSearchDatabase from "./components/pages/training/admins/AdminSearchDatabase";
import AdminsSearchDaSituations from "./components/pages/training/admins/searchdatabase/AdminsSearchDaSituations";
import AdminsSearchDaTaskRules from "./components/pages/training/admins/searchdatabase/AdminsSearchDaTaskRules";
import AdminsSearchDaHowToDo from "./components/pages/training/admins/searchdatabase/AdminsSearchDaHowToDo";
import AdminsSearchDaWorkPlan from "./components/pages/training/admins/searchdatabase/AdminsSearchDaWorkPlan";
import AdminsSearchDaResource from "./components/pages/training/admins/searchdatabase/AdminsSearchDaResource";
import AdminsSearchDaHowToSearch from "./components/pages/training/admins/searchdatabase/AdminsSearchDaHowToSearch";
import AdminsSearchDaCreateProject from "./components/pages/training/admins/searchdatabase/AdminsSearchDaCreateProject";
import AdminsSearchDaSendMiniSpecs from "./components/pages/training/admins/searchdatabase/AdminsSearchDaSendMiniSpecs";
import AdminAssignPriority from "./components/pages/training/admins/AdminAssignPriority";
import AssignPrioritySituations from "./components/pages/training/admins/assignpriority/AssignPrioritySituations";
import AssignPriorityIndicators from "./components/pages/training/admins/assignpriority/AssignPriorityIndicators";
import AssignPriorityTodayBased from "./components/pages/training/admins/assignpriority/AssignPriorityTodayBased";
import AssignPriorityLiveJobBased from "./components/pages/training/admins/assignpriority/AssignPriorityLiveJobBased";
import AssignPriorityLanguageBased from "./components/pages/training/admins/assignpriority/AssignPriorityLanguageBased";
import AdminPriorityScreening from "./components/pages/training/admins/AdminPriorityScreening";
import AdminsPriorityScPreparation from "./components/pages/training/admins/priorityscreening/AdminsPriorityScPreparation";
import AdminsPriorityScActionTools from "./components/pages/training/admins/priorityscreening/AdminsPriorityScActionTools";
import AdminsPriorityScPhoneCall from "./components/pages/training/admins/priorityscreening/AdminsPriorityScPhoneCall";
import AdminsPriorityScSendEmail from "./components/pages/training/admins/priorityscreening/AdminsPriorityScSendEmail";
import AdminsPriorityScSendSms from "./components/pages/training/admins/priorityscreening/AdminsPriorityScSendSms";
import AdminCloseDecision from "./components/pages/training/admins/AdminCloseDecision";
import AdminsCaseDeSituations from "./components/pages/training/admins/casedecision/AdminsCaseDeSituations";
import AdminsCaseDeTaskRules from "./components/pages/training/admins/casedecision/AdminsCaseDeTaskRules";
import AdminsCaseDeHowToDo from "./components/pages/training/admins/casedecision/AdminsCaseDeHowToDo";
import AdminsCaseDeDiscussion from "./components/pages/training/admins/casedecision/AdminsCaseDeDiscussion";
import AdminsCaseDeEscalation from "./components/pages/training/admins/casedecision/AdminsCaseDeEscalation";
import AdminsCaseDeComments from "./components/pages/training/admins/casedecision/AdminsCaseDeComments";
import AdminsCaseDeUpdatePipeline from "./components/pages/training/admins/casedecision/AdminsCaseDeUpdatePipeline";
import AdminsCaseDeDkgDatabase from "./components/pages/training/admins/casedecision/AdminsCaseDeDkgDatabase";
import AdminReviewNewCv from "./components/pages/training/admins/AdminReviewNewCv";
import AdminUpdateProfiles from "./components/pages/training/admins/AdminUpdateProfiles";
import AdminsReviewContacts from "./components/pages/training/admins/reviewprofiles/AdminsReviewContacts";
import AdminsReviewLocation from "./components/pages/training/admins/reviewprofiles/AdminsReviewLocation";
import AdminsReviewLanguages from "./components/pages/training/admins/reviewprofiles/AdminsReviewLanguages";
import AdminsReviewJobTitles from "./components/pages/training/admins/reviewprofiles/AdminsReviewJobTitles";
import AdminsReviewJobExperience from "./components/pages/training/admins/reviewprofiles/AdminsReviewJobExperience";
import AdminsReviewQualifications from "./components/pages/training/admins/reviewprofiles/AdminsReviewQualifications";
import AdminsReviewTechnicalSkills from "./components/pages/training/admins/reviewprofiles/AdminsReviewTechnicalSkills";
import AdminsReviewCareerSeniority from "./components/pages/training/admins/reviewprofiles/AdminsReviewCareerSeniority";
import AdminsReviewLiveJobSuitability from "./components/pages/training/admins/reviewprofiles/AdminsReviewLiveJobSuitability";
import AdminMatchWithJobs from "./components/pages/training/admins/AdminMatchWithJobs";
import AdminsUpdateStatus from "./components/pages/training/admins/updateprofiles/AdminsUpdateStatus";
import AdminsUpdateContacts from "./components/pages/training/admins/updateprofiles/AdminsUpdateContacts";
import AdminsUpdateCategory from "./components/pages/training/admins/updateprofiles/AdminsUpdateCategory";
import AdminsUpdateCvSource from "./components/pages/training/admins/updateprofiles/AdminsUpdateCvSource";
import AdminsUpdateLocations from "./components/pages/training/admins/updateprofiles/AdminsUpdateLocations";
import AdminsUpdateNationality from "./components/pages/training/admins/updateprofiles/AdminsUpdateNationality";
import AdminsUpdateLanguages from "./components/pages/training/admins/updateprofiles/AdminsUpdateLanguages";
import AdminsUpdateAssignJob from "./components/pages/training/admins/updateprofiles/AdminsUpdateAssignJob";
import AdminsUpdateCaseComment from "./components/pages/training/admins/updateprofiles/AdminsUpdateCaseComment";
import AdminRearchDatabase from "./components/pages/training/admins/AdminRearchDatabase";
import AdminMiniJobSpecs from "./components/pages/training/admins/AdminMiniJobSpecs";
import AdminsMiniJobSpecCreate from "./components/pages/training/admins/minijobspec/AdminsMiniJobSpecCreate";
import AdminsMiniJobSpecOutlook from "./components/pages/training/admins/minijobspec/AdminsMiniJobSpecOutlook";
import AdminsMiniJobSpecSaveSpec from "./components/pages/training/admins/minijobspec/AdminsMiniJobSpecSaveSpec";
import AdminsMiniJobSpecEmailSpec from "./components/pages/training/admins/minijobspec/AdminsMiniJobSpecEmailSpec";
import AdminsMiniJobSpecSendSms from "./components/pages/training/admins/minijobspec/AdminsMiniJobSpecSendSms";
import AdminSendReminders from "./components/pages/training/admins/AdminSendReminders";
import AdminsSendReSituations from "./components/pages/training/admins/sendreminders/AdminsSendReSituations";
import AdminsSendReTaskRules from "./components/pages/training/admins/sendreminders/AdminsSendReTaskRules";
import AdminsSendReHowToDo from "./components/pages/training/admins/sendreminders/AdminsSendReHowToDo";
import AdminsSendReFirstReminder from "./components/pages/training/admins/sendreminders/AdminsSendReFirstReminder";
import AdminsSendReSecondReminder from "./components/pages/training/admins/sendreminders/AdminsSendReSecondReminder";
import AdminsSendReThirdReminder from "./components/pages/training/admins/sendreminders/AdminsSendReThirdReminder";
import AdminsSendReFinalReminder from "./components/pages/training/admins/sendreminders/AdminsSendReFinalReminder";
import AdminsSendReCaseDiscussion from "./components/pages/training/admins/sendreminders/AdminsSendReCaseDiscussion";
import AdminNotReachable from "./components/pages/training/admins/AdminNotReachable";
import AdminsNotReSituations from "./components/pages/training/admins/notreachables/AdminsNotReSituations";
import AdminsNotReTaskRules from "./components/pages/training/admins/notreachables/AdminsNotReTaskRules";
import AdminsNotReHowToDo from "./components/pages/training/admins/notreachables/AdminsNotReHowToDo";
import AdminsNotRePreparation from "./components/pages/training/admins/notreachables/AdminsNotRePreparation";
import AdminsNotReActionTools from "./components/pages/training/admins/notreachables/AdminsNotReActionTools";
import AdminsNotRePhoneCall from "./components/pages/training/admins/notreachables/AdminsNotRePhoneCall";
import AdminsNotReSendEmail from "./components/pages/training/admins/notreachables/AdminsNotReSendEmail";
import AdminsNotReSendSms from "./components/pages/training/admins/notreachables/AdminsNotReSendSms";

import TrainingResource from "./components/pages/training/resource/TrainingResource";
import TrainingWorkProcess from "./components/pages/training/resource/WorkProcess";
import JobDetailStructure from "./components/pages/training/resource/jobdetails/JobDetailStructure";
import JobDetailTeamInfo from "./components/pages/training/resource/jobdetails/JobDetailTeamInfo";
import JobDetailJobSkills from "./components/pages/training/resource/jobdetails/JobDetailJobSkills";
import JobDetailJobTask from "./components/pages/training/resource/jobdetails/JobDetailJobTask";
import JobDetailJobQuality from "./components/pages/training/resource/jobdetails/JobDetailJobQuality";
import JobDetailJobVolume from "./components/pages/training/resource/jobdetails/JobDetailJobVolume";
import JobDetailJobQueries from "./components/pages/training/resource/jobdetails/JobDetailJobQueries";
import JobDetailJobTraining from "./components/pages/training/resource/jobdetails/JobDetailJobTraining";
import JobDetailJobDelegation from "./components/pages/training/resource/jobdetails/JobDetailJobDelegation";
import ResourcePreparation from "./components/pages/training/resource/ResourcePreparation";
import PreparationToolsLogin from "./components/pages/training/resource/preparation/PreparationToolsLogin";
import PreparationTeamGreeting from "./components/pages/training/resource/preparation/PreparationTeamGreeting";
import PreparationDkgCrmTabs from "./components/pages/training/resource/preparation/PreparationDkgCrmTabs";
import PreparationReviewMailbox from "./components/pages/training/resource/preparation/PreparationReviewMailbox";
import PreparationReviewLiveJobs from "./components/pages/training/resource/preparation/PreparationReviewLiveJobs";
import PreparationReviewPipelines from "./components/pages/training/resource/preparation/PreparationReviewPipelines";
import PreparationReviewWorkplan from "./components/pages/training/resource/preparation/PreparationReviewWorkplan";
import ResourceAction from "./components/pages/training/resource/ResourceAction";
import ActionSequence from "./components/pages/training/resource/action/ActionSequence";
import ActionSetPriority from "./components/pages/training/resource/action/ActionSetPriority";
import ActionPriority from "./components/pages/training/resource/action/ActionPriority";
import ActionCreateNewTask from "./components/pages/training/resource/action/ActionCreateNewTask";
import ActionFollowWorkPlan from "./components/pages/training/resource/action/ActionFollowWorkPlan";
import ResourceReport from "./components/pages/training/resource/ResourceReport";
import PerformanceCriteria from "./components/pages/training/resource/performance/PerformanceCriteria";
import PerformanceReviews from "./components/pages/training/resource/performance/PerformanceReviews";
import PerformanceReporting from "./components/pages/training/resource/performance/PerformanceReporting";
import ResourceKnowledgeBase from "./components/pages/training/resource/ResourceKnowledgeBase";
import KnowledgeSearchInfo from "./components/pages/training/resource/knowledgebase/KnowledgeSearchInfo";
import KnowledgeTaskInfo from "./components/pages/training/resource/knowledgebase/KnowledgeTaskInfo";
import KnowledgeToolsInfo from "./components/pages/training/resource/knowledgebase/KnowledgeToolsInfo";
import KnowledgeCandidates from "./components/pages/training/resource/knowledgebase/KnowledgeCandidates";
import KnowledgeClients from "./components/pages/training/resource/knowledgebase/KnowledgeClients";
import KnowledgeJobs from "./components/pages/training/resource/knowledgebase/KnowledgeJobs";
import ResourceManageAds from "./components/pages/training/resource/ResourceManageAds";
import ResourceAppsManagement from "./components/pages/training/resource/ResourceAppsManagement";
import ResourceManageJobs from "./components/pages/training/resource/ResourceManageJobs";
import ResourceCreateSpecs from "./components/pages/training/resource/ResourceCreateSpecs";
import ResourceSocialMedia from "./components/pages/training/resource/ResourceSocialMedia";
import ResourceDkgDatabase from "./components/pages/training/resource/ResourceDkgDatabase";
import ResourcePipelinesKpi from "./components/pages/training/resource/ResourcePipelinesKpi";
import ResourceAdsBudget from "./components/pages/training/resource/ResourceAdsBudget";
import ResourceAdCampaign from "./components/pages/training/resource/ResourceAdCampaign";
import ResourceAdsTracker from "./components/pages/training/resource/ResourceAdsTracker";
import Recruiters from "./components/pages/training/recruiters/Recruiters";
import RecruitersWorkProcess from "./components/pages/training/recruiters/RecruitersWorkProcess";
import RecruitersJobDetailsStructure from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsStructure";
import RecruitersJobDetailsTeamInfo from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsTeamInfo";
import RecruitersJobDetailsJobSkills from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobSkills";
import RecruitersJobDetailsJobTask from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobTask";
import RecruitersJobDetailsJobQuality from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobQuality";
import RecruitersJobDetailsJobVolume from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobVolume";
import RecruitersJobDetailsJobQueries from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobQueries";
import RecruitersJobDetailsJobTraining from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobTraining";
import RecruitersJobDetailsJobDelegation from "./components/pages/training/recruiters/jobdetails/RecruitersJobDetailsJobDelegation";
import RecruitersPreparation from "./components/pages/training/recruiters/RecruitersPreparation";
import RecruitersPreparationToolsLogin from "./components/pages/training/recruiters/preparation/RecruitersPreparationToolsLogin";
import RecruitersPreparationTeamGreeting from "./components/pages/training/recruiters/preparation/RecruitersPreparationTeamGreeting";
import RecruitersPreparationDkgCrmTabs from "./components/pages/training/recruiters/preparation/RecruitersPreparationDkgCrmTabs";
import RecruitersPreparationReviewMailbox from "./components/pages/training/recruiters/preparation/RecruitersPreparationReviewMailbox";
import RecruitersPreparationReviewLiveJobs from "./components/pages/training/recruiters/preparation/RecruitersPreparationReviewLiveJobs";
import RecruitersPreparationReviewPipelines from "./components/pages/training/recruiters/preparation/RecruitersPreparationReviewPipelines";
import RecruitersPreparationReviewWorkplan from "./components/pages/training/recruiters/preparation/RecruitersPreparationReviewWorkplan";
import RecruitersAction from "./components/pages/training/recruiters/RecruitersAction";
import RecruitersActionSequence from "./components/pages/training/recruiters/action/RecruitersActionSequence";
import RecruitersActionSetPriority from "./components/pages/training/recruiters/action/RecruitersActionSetPriority";
import RecruitersActionPriority from "./components/pages/training/recruiters/action/RecruitersActionPriority";
import RecruitersActionCreateNewTask from "./components/pages/training/recruiters/action/RecruitersActionCreateNewTask";
import RecruitersActionFollowWorkplan from "./components/pages/training/recruiters/action/RecruitersActionFollowWorkplan";
import RecruitersReport from "./components/pages/training/recruiters/RecruitersReport";
import RecruitersPerformanceCriteria from "./components/pages/training/recruiters/performance/RecruitersPerformanceCriteria";
import RecruitersPerformanceReviews from "./components/pages/training/recruiters/performance/RecruitersPerformanceReviews";
import RecruitersPerformanceReporting from "./components/pages/training/recruiters/performance/RecruitersPerformanceReporting";
import RecruitersKnowledgeBase from "./components/pages/training/recruiters/RecruitersKnowledgeBase";
import RecruitersKnowledgeSearchInfo from "./components/pages/training/recruiters/knowledgebase/RecruitersKnowledgeSearchInfo";
import RecruitersKnowledgeTaskInfo from "./components/pages/training/recruiters/knowledgebase/RecruitersKnowledgeTaskInfo";
import RecruitersKnowledgeToolsInfo from "./components/pages/training/recruiters/knowledgebase/RecruitersKnowledgeToolsInfo";
import RecruitersKnowledgeCandidates from "./components/pages/training/recruiters/knowledgebase/RecruitersKnowledgeCandidates";
import RecruitersKnowledgeClients from "./components/pages/training/recruiters/knowledgebase/RecruitersKnowledgeClients";
import RecruitersKnowledgeJobs from "./components/pages/training/recruiters/knowledgebase/RecruitersKnowledgeJobs";
import RecruitersInterviewCall from "./components/pages/training/recruiters/RecruitersInterviewCall";
import RecruitersFullJobSpec from "./components/pages/training/recruiters/RecruitersFullJobSpec";
import RecruitersCaseQueries from "./components/pages/training/recruiters/RecruitersCaseQueries";
import RecruitersLiveNewQuery from "./components/pages/training/recruiters/RecruitersLiveNewQuery";
import RecruitersUpdateProfile from "./components/pages/training/recruiters/RecruitersUpdateProfile";
import RecruitersCreateJobSpec from "./components/pages/training/recruiters/RecruitersCreateJobSpec";
import RecruitersCaseDecision from "./components/pages/training/recruiters/RecruitersCaseDecision";
import RecruitersClientsPortals from "./components/pages/training/recruiters/RecruitersClientsPortals";
import RecruitersMakeSendout from "./components/pages/training/recruiters/RecruitersMakeSendout";
import RecruitersNotReachable from "./components/pages/training/recruiters/RecruitersNotReachable";

import Managers from "./components/pages/training/managers/Managers";
import ManagersWorkProcess from "./components/pages/training/managers/ManagersWorkProcess";
import ManagersJobDetailsStructure from "./components/pages/training/managers/jobdetails/ManagersJobDetailsStructure";
import ManagersJobDetailsTeamInfo from "./components/pages/training/managers/jobdetails/ManagersJobDetailsTeamInfo";
import ManagersJobDetailsJobSkills from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobSkills";
import ManagersJobDetailsJobTask from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobTask";
import ManagersJobDetailsJobQuality from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobQuality";
import ManagersJobDetailsJobVolume from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobVolume";
import ManagersJobDetailsJobQueries from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobQueries";
import ManagersJobDetailsJobTraining from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobTraining";
import ManagersJobDetailsJobDelegation from "./components/pages/training/managers/jobdetails/ManagersJobDetailsJobDelegation";
import ManagersPreparation from "./components/pages/training/managers/ManagersPreparation";
import ManagersPreparationToolsLogin from "./components/pages/training/managers/preparation/ManagersPreparationToolsLogin";
import ManagersPreparationTeamGreeting from "./components/pages/training/managers/preparation/ManagersPreparationTeamGreeting";
import ManagersPreparationDkgCrmTabs from "./components/pages/training/managers/preparation/ManagersPreparationDkgCrmTabs";
import ManagersPreparationReviewMailbox from "./components/pages/training/managers/preparation/ManagersPreparationReviewMailbox";
import ManagersPreparationReviewLiveJobs from "./components/pages/training/managers/preparation/ManagersPreparationReviewLiveJobs";
import ManagersPreparationReviewPipelines from "./components/pages/training/managers/preparation/ManagersPreparationReviewPipelines";
import ManagersPreparationReviewWorkplan from "./components/pages/training/managers/preparation/ManagersPreparationReviewWorkplan";
import ManagersAction from "./components/pages/training/managers/ManagersAction";
import ManagersActionSequence from "./components/pages/training/managers/action/ManagersActionSequence";
import ManagersActionSetPriority from "./components/pages/training/managers/action/ManagersActionSetPriority";
import ManagersActionPriority from "./components/pages/training/managers/action/ManagersActionPriority";
import ManagersActionCreateNewTask from "./components/pages/training/managers/action/ManagersActionCreateNewTask";
import ManagersActionFollowWorkplan from "./components/pages/training/managers/action/ManagersActionFollowWorkplan";
import ManagersReport from "./components/pages/training/managers/ManagersReport";
import ManagersPerformanceCriteria from "./components/pages/training/managers/performance/ManagersPerformanceCriteria";
import ManagersPerformanceReviews from "./components/pages/training/managers/performance/ManagersPerformanceReviews";
import ManagersPerformanceReporting from "./components/pages/training/managers/performance/ManagersPerformanceReporting";
import ManagersKnowledgeBase from "./components/pages/training/managers/ManagersKnowledgeBase";
import ManagersKnowledgeSearchInfo from "./components/pages/training/managers/knowledgebase/ManagersKnowledgeSearchInfo";
import ManagersKnowledgeTaskInfo from "./components/pages/training/managers/knowledgebase/ManagersKnowledgeTaskInfo";
import ManagersKnowledgeToolsInfo from "./components/pages/training/managers/knowledgebase/ManagersKnowledgeToolsInfo";
import ManagersKnowledgeCandidates from "./components/pages/training/managers/knowledgebase/ManagersKnowledgeCandidates";
import ManagersKnowledgeClients from "./components/pages/training/managers/knowledgebase/ManagersKnowledgeClients";
import ManagersKnowledgeJobs from "./components/pages/training/managers/knowledgebase/ManagersKnowledgeJobs";
import ManagersSendInterviewPrep from "./components/pages/training/managers/ManagersSendInterviewPrep";
import ManagersSheduleInterviews from "./components/pages/training/managers/ManagersSheduleInterviews";
import ManagersReviewSendout from "./components/pages/training/managers/ManagersReviewSendout";
import ManagersSubmitSendout from "./components/pages/training/managers/ManagersSubmitSendout";
import ManagersAskJobDemand from "./components/pages/training/managers/ManagersAskJobDemand";
import ManagersCandidatesFeedback from "./components/pages/training/managers/ManagersCandidatesFeedback";
import ManagersPrepareInterviews from "./components/pages/training/managers/ManagersPrepareInterviews";
import ManagersUpdateCandidates from "./components/pages/training/managers/ManagersUpdateCandidates";
import ManagersClientsProjections from "./components/pages/training/managers/ManagersClientsProjections";
import ManagersJobOfferDocuments from "./components/pages/training/managers/ManagersJobOfferDocuments";
// Training Pages End
//  Onboarding Pages Start
import OnboardingSummary from "./components/pages/onboarding/summary";
// Onboarding Pages End
// IT Team Start
import TeamProjects from "./components/pages/itteam/projects/index";
import AddNewFeedback from "./components/pages/itteam/addfeedback/AddNewFeedback";
import ItTeamModule from "./components/pages/itteam/ItTeamModule";
import TeamSummary from "./components/pages/itteam/details/TeamSummary";
import TeamStories from "./components/pages/itteam/stories/Stories";
import TeamBacklog from "./components/pages/itteam/backlog/Backlog";
import TeamWIP from "./components/pages/itteam/wip/WorkProgress";
import TeamDelivered from "./components/pages/itteam/deleverd/StoriesDelivered";
import TeamAjay from "./components/pages/itteam/TeamAjay";
import TeamAmit from "./components/pages/itteam/TeamAmit";
import TeamDesigner from "./components/pages/itteam/TeamDesigner";
import TeamDeveloper from "./components/pages/itteam/TeamDeveloper";
import TeamMarketer from "./components/pages/itteam/TeamMarketer";

import GuidelinesDashboard from "./components/pages/itteam/guidelines/dashboard/GuidelinesDashboard";

// End
//START PROJECTRIONS PAGE
import ProjectionsSummary from "./components/pages/projections/summary";
import ProjectionsIncomeTax from "./components/pages/projections/incometax/IncomeTax";
//END PROJECTIONS PAGE
// START MARKETING PAGE
import MarketingSummary from "./components/pages/marketing/budget/summary";
//END MARKETING PAGE
// START HR PAGE
import HrDashboard from "./components/pages/hr/dashboard";
import HRHolidays from "./components/pages/hr/holidays/Holidays";
import HrEmpDkgTeam from "./components/pages/hr/employee/dkgTeam/Index";
//END HR PAGE
// START PAYMENTS PAGE
import PaymentsSummary from "./components/pages/payments/summary";

// Payemnts Invoices Start

import PayJobBoard from "./components/pages/payments/payinvoices/jobboard/PayJobBoard";
import PayEmployee from "./components/pages/payments/payinvoices/payemployee/PayEmployee";
import PaySoftware from "./components/pages/payments/payinvoices/paysoftware/PaySoftware";
import PayLicense from "./components/pages/payments/payinvoices/paylicense/PayLicense";
import PayLegal from "./components/pages/payments/payinvoices/paylegal/PayLegal";

// end

// Start Payments Training

import PayOverview from "./components/pages/payments/paytraining/overview/PayOverview";
import PayDashboard from "./components/pages/payments/paytraining/dashboard/PayDashboard";
import PayWorkPlan from "./components/pages/payments/paytraining/workplan/PayWorkPlan";
import PayNotifications from "./components/pages/payments/paytraining/notifications/PayNotifications";
import PayCandidate from "./components/pages/payments/paytraining/candidate/PayCandidate";
import PayJobs from "./components/pages/payments/paytraining/jobs/PayJobs";
import PayCompany from "./components/pages/payments/paytraining/company/PayCompany";
import PayKpi from "./components/pages/payments/paytraining/kpi/PayKpi";
import PayDkgCloud from "./components/pages/payments/paytraining/dkgcloud/PayDkgCloud";
import PayDatabase from "./components/pages/payments/paytraining/database/PayDatabase";
import PayDkGlobal from "./components/pages/payments/paytraining/dkglobal/PayDkGlobal";

// End Payments Training

//END PAYMENTS PAGE
// START JOBSPECS  PAGE
import ProjectsDashboard from "./components/pages/moduleprojects/summary/ProjectSummary";
// Deepak Project pages
import ModuleProjectsDeepakSummary from "./components/pages/moduleprojects/deepak/DeepakSummary";
import ModuleProjectsDeepakLive from "./components/pages/moduleprojects/deepak/DeepakProjectLive";
import ModuleProjectsDeepakClosed from "./components/pages/moduleprojects/deepak/DeepakProjectClosed";
import ModuleProjectsDeepakSales from "./components/pages/moduleprojects/deepak/DeepakProjectSales";
import ModuleProjectsDeepakKpi from "./components/pages/moduleprojects/deepak/DeepakProjectKpi";
import ModuleProjectsDeepakTraining from "./components/pages/moduleprojects/deepak/DeepakProjectTraining";
import ModuleProjectsDeepakProfile from "./components/pages/moduleprojects/deepak/DeepakProjectProfile";

import DeepakProjectOne from "./components/pages/moduleprojects/deepak/projectpages/DeepakProjectOne";
import DeepakProjectTwo from "./components/pages/moduleprojects/deepak/projectpages/DeepakProjectTwo";
import DeepakProjectThree from "./components/pages/moduleprojects/deepak/projectpages/DeepakProjectThree";
import DeepakProjectFour from "./components/pages/moduleprojects/deepak/projectpages/DeepakProjectFour";
import DeepakProjectFive from "./components/pages/moduleprojects/deepak/projectpages/DeepakProjectFive";
import DeepakProjectSix from "./components/pages/moduleprojects/deepak/projectpages/DeepakProjectSix";

// End

// Luiza Pages Start

// START JOBSPECS  PAGE
import JobSpecDashboard from "./components/pages/jobspec/summary/ProjectSummary";
// import JobSpecActivespec from "./components/pages/recruitment/jobspec/activespec";
// import JobSpecInactivespec from "./components/pages/recruitment/jobspec/inactivespec";
import JobSpecLivespec from "./components/pages/recruitment/jobspec/livespec";

// Creat Spec Pages
// import JobSpecAddNew from "./components/pages/jobspec/addnew/AddNew";

import ModuleProjectsLuizaSummary from "./components/pages/moduleprojects/luiza/LuizaSummary";
import ModuleProjectsLuizaLive from "./components/pages/moduleprojects/luiza/LuizaProjectLive";
import ModuleProjectsLuizaClosed from "./components/pages/moduleprojects/luiza/LuizaProjectClosed";
import ModuleProjectsLuizaSales from "./components/pages/moduleprojects/luiza/LuizaProjectSales";
import ModuleProjectsLuizaKpi from "./components/pages/moduleprojects/luiza/LuizaProjectKpi";
import ModuleProjectsLuizaTraining from "./components/pages/moduleprojects/luiza/LuizaProjectTraining";
import ModuleProjectsLuizaProfile from "./components/pages/moduleprojects/luiza/LuizaProjectProfile";

import LuizaProjectOne from "./components/pages/moduleprojects/luiza/projectpages/LuizaProjectOne";
import LuizaProjectTwo from "./components/pages/moduleprojects/luiza/projectpages/LuizaProjectTwo";

// End

// Mariana Pages Start

import ModuleProjectsMarianaSummary from "./components/pages/moduleprojects/mariana/MarianaSummary";
import ModuleProjectsMarianaLive from "./components/pages/moduleprojects/mariana/MarianaProjectLive";
import ModuleProjectsMarianaClosed from "./components/pages/moduleprojects/mariana/MarianaProjectClosed";
import ModuleProjectsMarianaSales from "./components/pages/moduleprojects/mariana/MarianaProjectSales";
import ModuleProjectsMarianaKpi from "./components/pages/moduleprojects/mariana/MarianaProjectKpi";
import ModuleProjectsMarianaTraining from "./components/pages/moduleprojects/mariana/MarianaProjectTraining";
import ModuleProjectsMarianaProfile from "./components/pages/moduleprojects/mariana/MarianaProjectProfile";

import MarianaProjectOne from "./components/pages/moduleprojects/mariana/projectpages/MarianProjectOne";
import MarianaProjectTwo from "./components/pages/moduleprojects/mariana/projectpages/MarianProjectTwo";
import MarianaProjectThree from "./components/pages/moduleprojects/mariana/projectpages/MarianProjectThree";
import MarianaProjectFour from "./components/pages/moduleprojects/mariana/projectpages/MarianProjectFour";

// End

import ModuleProjectsMarianaTwoProjectOne from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectOne";
import ModuleProjectsMarianaTwoProjectTwo from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectTwo";
import ModuleProjectsMarianaTwoProjectThree from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectThree";
import ModuleProjectsMarianaTwoProjectFour from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectFour";
import ModuleProjectsMarianaTwoProjectFive from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectFive";
import ModuleProjectsMarianaTwoProjectSix from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectSix";
import ModuleProjectsMarianaTwoProjectSeven from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectSeven";
import ModuleProjectsMarianaTwoProjectEight from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectEight";
import ModuleProjectsMarianaTwoProjectNine from "./components/pages/moduleprojects/marianatwo/MarianaTwoProjectNine";

// Filip Pages Start
import ModuleProjectsFilipSummary from "./components/pages/moduleprojects/filip/FilipSummary";
import ModuleProjectsFilipLive from "./components/pages/moduleprojects/filip/FilipProjectLive";
import ModuleProjectsFilipClosed from "./components/pages/moduleprojects/filip/FilipProjectClosed";
import ModuleProjectsFilipSales from "./components/pages/moduleprojects/filip/FilipProjectSales";
import ModuleProjectsFilipKpi from "./components/pages/moduleprojects/filip/FilipProjectKpi";
import ModuleProjectsFilipTraining from "./components/pages/moduleprojects/filip/FilipProjectTraining";
import ModuleProjectsFilipProfile from "./components/pages/moduleprojects/filip/FilipProjectProfile";

import FilipProjectOne from "./components/pages/moduleprojects/filip/projectpages/FilipProjectOne";
import FilipProjectTwo from "./components/pages/moduleprojects/filip/projectpages/FilipProjectTwo";

// End

// Elis Pages Start
import ModuleProjectsElisSummary from "./components/pages/moduleprojects/elis/ElisSummary";
import ModuleProjectsElisLive from "./components/pages/moduleprojects/elis/ElisProjectLive";
import ModuleProjectsElisClosed from "./components/pages/moduleprojects/elis/ElisProjectClosed";
import ModuleProjectsElisSales from "./components/pages/moduleprojects/elis/ElisProjectSales";
import ModuleProjectsElisKpi from "./components/pages/moduleprojects/elis/ElisProjectKpi";
import ModuleProjectsElisTraining from "./components/pages/moduleprojects/elis/ElisProjectTraining";
import ModuleProjectsElisProfile from "./components/pages/moduleprojects/elis/ElisProjectProfile";

import ElisProjectOne from "./components/pages/moduleprojects/elis/projectpages/ElisProjectOne";
import ElisProjectTwo from "./components/pages/moduleprojects/elis/projectpages/ElisProjectTwo";
import ElisProjectThree from "./components/pages/moduleprojects/elis/projectpages/ElisProjectThree";
import ElisProjectFour from "./components/pages/moduleprojects/elis/projectpages/ElisProjectFour";
import ElisProjectFive from "./components/pages/moduleprojects/elis/projectpages/ElisProjectFive";

// End

import ModuleProjectsTehreempSummary from "./components/pages/moduleprojects/tehreem/TehreemSummary";
import TehreemProjectOne from "./components/pages/moduleprojects/tehreem/projectpages/TehreemProjectOne";
import TehreemProjectTwo from "./components/pages/moduleprojects/tehreem/projectpages/TehreemProjectTwo";
import TehreemProjectThree from "./components/pages/moduleprojects/tehreem/projectpages/TehreemProjectThree";
import TehreemProjectFour from "./components/pages/moduleprojects/tehreem/projectpages/TehreemProjectFour";
import TehreemProjectFive from "./components/pages/moduleprojects/tehreem/projectpages/TehreemProjectFive";
import ModuleProjectsTehreemLive from "./components/pages/moduleprojects/tehreem/TehreemProjectLive";
import ModuleProjectsTehreemClosed from "./components/pages/moduleprojects/tehreem/TehreemProjectClosed";
import ModuleProjectsTehreempSales from "./components/pages/moduleprojects/tehreem/TehreemProjectSales";
import ModuleProjectsTehreempKpi from "./components/pages/moduleprojects/tehreem/TehreemProjectKpi";
import ModuleProjectsTehreemTraining from "./components/pages/moduleprojects/tehreem/TehreemProjectTraining";
import ModuleProjectsTehreemProfile from "./components/pages/moduleprojects/tehreem/TehreemProjectProfile";

import ModuleProjectsOnur from "./components/pages/moduleprojects/onur";
//END JOBSPECS PAGE
// START KPI'S PAGE
import MainKPIDashboard from "./components/pages/kpi/dashboard";
// END KPI'S PAGE
// START DATABASE PAGE
import MainDBSuMM from "./components/pages/database/summary";
//END DATABASE
// START FILES PAGE
import MyFilesAlerts from "./components/pages/myfiles/alerts";
import MyFilesDirector from "./components/pages/myfiles/director";
import MyFilesLicenses from "./components/pages/myfiles/licenses";
import MyFilesMyacc from "./components/pages/myfiles/bank-account";
import MyFilesAccount from "./components/pages/myfiles/accounts";
import MyFilesReturns from "./components/pages/myfiles/b1-returns";
import MyFilesTaxOffice from "./components/pages/myfiles/tax-office";
import MyFilesCertificates from "./components/pages/myfiles/certificates";
import MyFilesMembnership from "./components/pages/myfiles/membership";
import MyFilesAgreements from "./components/pages/myfiles/agreements";
import MyFilesDashboard from "./components/pages/myfiles/summary";
// START MODULES FOR DATABASE TABLE
import DBTableSuMM from "./components/pages/databasetable/dashboard";
//import DBDataTable from './components/pages/databasetable/datatable/DataTable';
import SearchDBDataTable from "./components/pages/fulldatabase/searchdb";
import DataBDataTable from "./components/pages/databasetable/pipeline/DataTable/Datatable";
import FullDBDataTable from "./components/pages/databasetable/fulldatabase/datatable/DataTable";
import FullDBVistorslog from "./components/pages/databasetable/fulldatabase/vistorlogs/JobseekerAccessLogs";
import DatetableTwentyOne from "./components/pages/databasetable/pipeline/DataTable";
import DatetableTwentyTwo from "./components/pages/databasetable/pipeline/DataTable/DatatableTwentyTwo";
import DatetableTwenty from "./components/pages/databasetable/pipeline/DataTable/DatatableTwenty";
import DatatwentyNineteen from "./components/pages/databasetable/pipeline/DataTable/DatatwentyNineteen";
import DatatwentyEighteen from "./components/pages/databasetable/pipeline/DataTable/DatatwentyEighteen";
import DatatwentySeventeen from "./components/pages/databasetable/pipeline/DataTable/DatatwentySeventeen";
import DatatwentySixteen from "./components/pages/databasetable/pipeline/DataTable/DatatwentySixteen";
import DBArchivedTable from "./components/pages/databasetable/pipeline/Archived";
import DBBlockedTable from "./components/pages/databasetable/pipeline/Blocked";
//END MODULES FOR DATABASE TABLE
import FulldbSuMM from "./components/pages/fulldatabase/summary";
// START MODULES FOR FULL DATABASE

// END MODULES FOR FULL DATABASE

// Training Inner module

import TrainingRecModule from "./components/pages/trainingmodule/TrainingRecModule";

// End

// Start DK Global Admin Training  Module

import DkAdminTraningDashboard from "./components/pages/dkglobaladmin/training/summary/Summary";
import DkGlobalSummary from "./components/pages/dkglobal/summary/DkGlobalSummary";
import TrainingSchduleOverView from "./components/pages/dkglobaladmin/training/overview/TrainingOverView";
import DkAdminTrainingSchedule from "./components/pages/dkglobaladmin/training/schedule/Schedule";
import DkAdminDayOneFullTime from "./components/pages/dkglobaladmin/training/schedule/DayOneFullTime";
import DkAdminDayTwoFullTime from "./components/pages/dkglobaladmin/training/schedule/DayTwoFullTime";
import DkAdminDayThreeFullTime from "./components/pages/dkglobaladmin/training/schedule/DayThreeFullTime";
import DkAdminDayFourFullTime from "./components/pages/dkglobaladmin/training/schedule/DayFourFullTime";
import DkAdminDayFiveFullTime from "./components/pages/dkglobaladmin/training/schedule/DayFiveFullTime";
import DkAdminDaySixPartTime from "./components/pages/dkglobaladmin/training/schedule/DaySixPartTime";
import DkAdminDaySevenPartTime from "./components/pages/dkglobaladmin/training/schedule/DaySevenPartTime";
import DkAdminDayEightPartTime from "./components/pages/dkglobaladmin/training/schedule/DayEightPartTime";
import DkAdminDayNinePartTime from "./components/pages/dkglobaladmin/training/schedule/DayNinePartTime";
import DkAdminDayTenPartTime from "./components/pages/dkglobaladmin/training/schedule/DayTenPartTime";
import DkAdminFinalAccessment from "./components/pages/dkglobaladmin/training/schedule/FinalAccessment";
import DkAdminTrainingStatus from "./components/pages/dkglobaladmin/training/schedule/TrainingStatus";

import SheduleModelTwo from "./components/pages/dkglobaladmin/training/schedule/trainingshedule/modeltwo/SheduleModelTwo";
import SheduleModelThree from "./components/pages/dkglobaladmin/training/schedule/trainingshedule/modelthree/SheduleModelThree";
import SheduleStatus from "./components/pages/dkglobaladmin/training/schedule/trainingshedule/status/SheduleStatus";

import DkAdminCompletionOverview from "./components/pages/dkglobaladmin/training/completion/Completion";

import DkAdminTrainingOnboarding from "./components/pages/dkglobaladmin/training/onboarding/Onboarding";
import DkAdminTrainingAboutDkg from "./components/pages/dkglobaladmin/training/onboarding/AboutDkg";
import DkAdminTrainingDkgTeam from "./components/pages/dkglobaladmin/training/onboarding/DkgTeam";
import DkAdminTrainingTimeTable from "./components/pages/dkglobaladmin/training/onboarding/TimeTable";
import DkAdminTrainingTechnical from "./components/pages/dkglobaladmin/training/onboarding/Technical";
import DkAdminTrainingAccount from "./components/pages/dkglobaladmin/training/onboarding/Account";
import DkAdminTrainingKpiCsf from "./components/pages/dkglobaladmin/training/onboarding/KpiCsf";
import DkAdminTrainingIntroduction from "./components/pages/dkglobaladmin/training/onboarding/Introduction";
import DkAdminTrainingProgress from "./components/pages/dkglobaladmin/training/onboarding/TrainingProgress";
import DkAdminTrainingConducts from "./components/pages/dkglobaladmin/training/onboarding/Conducts";
import DkAdminTrainingContracts from "./components/pages/dkglobaladmin/training/onboarding/Contracts";
import DkAdminOnboardingTraining from "./components/pages/dkglobaladmin/training/onboarding/Training";

import DkAdminCommonTraining from "./components/pages/dkglobaladmin/training/commontraining/CommonTraining";
import DkAdminCommonOverview from "./components/pages/dkglobaladmin/training/commontraining/CommonOverview";
import DkAdminCommonEstablished from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonEstablished";
import DkAdminCommonBusiness from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonBusiness";
import DkAdminCommonIndustry from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonIndustry";
import DkAdminCommonServices from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonServices";
import DkAdminCommonClient from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonClient";
import DkAdminCommonSuppliers from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonSuppliers";
import DkAdminCommonCompletion from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonCompletion";
import DkAdminCommonEmployees from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonEmployees";
import DkAdminCommonFuturePlans from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonFuturePlans";
import DkAdminCommonSocialMedia from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonSocialMedia";
import DkAdminCommonContactDetails from "./components/pages/dkglobaladmin/training/commontraining/commonoverview/CommonContactDetails";
import DkAdminCommonStructure from "./components/pages/dkglobaladmin/training/commontraining/CommonStructure";
import DkAdminCommonProcess from "./components/pages/dkglobaladmin/training/commontraining/teamroles/TeamRoles";
import DkAdminCommonEditTeamRoles from "./components/pages/dkglobaladmin/training/commontraining/teamroles/EditTeamRoles";
import DkAdminCommonTrainings from "./components/pages/dkglobaladmin/training/commontraining/jobdetails/JobDetails";
import DkAdminCommonPipelines from "./components/pages/dkglobaladmin/training/commontraining/pipelines/PipelinesManagement";
import DkAdminEditCommonPipelinesManagement from "./components/pages/dkglobaladmin/training/commontraining/pipelines/EditPipelines";
import DkAdminCommonTools from "./components/pages/dkglobaladmin/training/commontraining/CommonTools";
import DkAdminCommonMasterbox from "./components/pages/dkglobaladmin/training/commontraining/CommonMasterbox";
import DkAdminCommonClients from "./components/pages/dkglobaladmin/training/commontraining/CommonClients";
import DkAdminCommonDkgGuidelines from "./components/pages/dkglobaladmin/training/commontraining/CommonDkgGuidelines";
import DkAdminCommonDkgPipelines from "./components/pages/dkglobaladmin/training/commontraining/CommonDkgPipelines";
import DkAdminDkgPipelinesNewCv from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesNewCv";
import DkAdminDkgPipelinesScreening from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesScreening";
import DkAdminDkgPipelinesReminders from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesReminders";
import DkAdminDkgPipelinesRecruiters from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesRecruiters";
import DkAdminDkgPipelinesAccountM from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesAccountM";
import DkAdminDkgPipelinesJobOffers from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesJobOffers";
import DkAdminDkgPipelinesEscalated from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesEscalated";
import DkAdminDkgPipelinesOnHold from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesOnHold";
import DkAdminDkgPipelinesClosed from "./components/pages/dkglobaladmin/training/commontraining/dkgpipelines/DkgPipelinesClosed";
import DkAdminCommonWorkPlan from "./components/pages/dkglobaladmin/training/commontraining/CommonWorkPlan";
import DkAdminCommonMyTask from "./components/pages/dkglobaladmin/training/commontraining/CommonMyTask";
import DkAdminCommonResource from "./components/pages/dkglobaladmin/training/commontraining/CommonResource";
import DkAdminCommonSales from "./components/pages/dkglobaladmin/training/commontraining/CommonSales";
import DkAdminCommonPayments from "./components/pages/dkglobaladmin/training/commontraining/CommonPayments";
import DkAdminCommonJobs from "./components/pages/dkglobaladmin/training/commontraining/CommonJobs";
import DkAdminCommonCandidates from "./components/pages/dkglobaladmin/training/commontraining/CommonCandidates";
import DkAdminCommonOutlook from "./components/pages/dkglobaladmin/training/commontraining/CommonOutlook";
import DkAdminCommonEmails from "./components/pages/dkglobaladmin/training/commontraining/CommonEmails";
import DkAdminCommonCalls from "./components/pages/dkglobaladmin/training/commontraining/CommonCalls";
import DkAdminCommonSms from "./components/pages/dkglobaladmin/training/commontraining/CommonSms";
import DkAdminCommonChats from "./components/pages/dkglobaladmin/training/commontraining/CommonChats";

import DkAdminTrainingResource from "./components/pages/dkglobaladmin/training/resource/TrainingResource";
import DkAdminTrainingWorkProcess from "./components/pages/dkglobaladmin/training/resource/WorkProcess";
import DkAdminJobDetailStructure from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailStructure";
import DkAdminJobDetailTeamInfo from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailTeamInfo";
import DkAdminJobDetailJobSkills from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobSkills";
import DkAdminJobDetailJobTask from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobTask";
import DkAdminJobDetailJobQuality from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobQuality";
import DkAdminJobDetailJobVolume from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobVolume";
import DkAdminJobDetailJobQueries from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobQueries";
import DkAdminJobDetailJobTraining from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobTraining";
import DkAdminJobDetailJobDelegation from "./components/pages/dkglobaladmin/training/resource/jobdetails/JobDetailJobDelegation";
import DkAdminResourcePreparation from "./components/pages/dkglobaladmin/training/resource/ResourcePreparation";
import DkAdminPreparationToolsLogin from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationToolsLogin";
import DkAdminPreparationTeamGreeting from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationTeamGreeting";
import DkAdminPreparationDkgCrmTabs from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationDkgCrmTabs";
import DkAdminPreparationReviewMailbox from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationReviewMailbox";
import DkAdminPreparationReviewLiveJobs from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationReviewLiveJobs";
import DkAdminPreparationReviewPipelines from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationReviewPipelines";
import DkAdminPreparationReviewWorkplan from "./components/pages/dkglobaladmin/training/resource/preparation/PreparationReviewWorkplan";
import DkAdminResourceAction from "./components/pages/dkglobaladmin/training/resource/ResourceAction";
import DkAdminActionSequence from "./components/pages/dkglobaladmin/training/resource/action/ActionSequence";
import DkAdminActionSetPriority from "./components/pages/dkglobaladmin/training/resource/action/ActionSetPriority";
import DkAdminActionPriority from "./components/pages/dkglobaladmin/training/resource/action/ActionPriority";
import DkAdminActionCreateNewTask from "./components/pages/dkglobaladmin/training/resource/action/ActionCreateNewTask";
import DkAdminActionFollowWorkPlan from "./components/pages/dkglobaladmin/training/resource/action/ActionFollowWorkPlan";
import DkAdminResourceReport from "./components/pages/dkglobaladmin/training/resource/ResourceReport";
import DkAdminPerformanceCriteria from "./components/pages/dkglobaladmin/training/resource/performance/PerformanceCriteria";
import DkAdminPerformanceReviews from "./components/pages/dkglobaladmin/training/resource/performance/PerformanceReviews";
import DkAdminPerformanceReporting from "./components/pages/dkglobaladmin/training/resource/performance/PerformanceReporting";
import DkAdminResourceKnowledgeBase from "./components/pages/dkglobaladmin/training/resource/ResourceKnowledgeBase";
import DkAdminKnowledgeSearchInfo from "./components/pages/dkglobaladmin/training/resource/knowledgebase/KnowledgeSearchInfo";
import DkAdminKnowledgeTaskInfo from "./components/pages/dkglobaladmin/training/resource/knowledgebase/KnowledgeTaskInfo";
import DkAdminKnowledgeToolsInfo from "./components/pages/dkglobaladmin/training/resource/knowledgebase/KnowledgeToolsInfo";
import DkAdminKnowledgeCandidates from "./components/pages/dkglobaladmin/training/resource/knowledgebase/KnowledgeCandidates";
import DkAdminKnowledgeClients from "./components/pages/dkglobaladmin/training/resource/knowledgebase/KnowledgeClients";
import DkAdminKnowledgeJobs from "./components/pages/dkglobaladmin/training/resource/knowledgebase/KnowledgeJobs";
import DkAdminResourceManageAds from "./components/pages/dkglobaladmin/training/resource/ResourceManageAds";
import DkAdminResourceAppsManagement from "./components/pages/dkglobaladmin/training/resource/ResourceAppsManagement";
import DkAdminResourceManageJobs from "./components/pages/dkglobaladmin/training/resource/ResourceManageJobs";
import DkAdminResourceCreateSpecs from "./components/pages/dkglobaladmin/training/resource/ResourceCreateSpecs";
import DkAdminResourceSocialMedia from "./components/pages/dkglobaladmin/training/resource/ResourceSocialMedia";
import DkAdminResourceDkgDatabase from "./components/pages/dkglobaladmin/training/resource/ResourceDkgDatabase";
import DkAdminResourcePipelinesKpi from "./components/pages/dkglobaladmin/training/resource/ResourcePipelinesKpi";
import DkAdminResourceAdsBudget from "./components/pages/dkglobaladmin/training/resource/ResourceAdsBudget";
import DkAdminResourceAdCampaign from "./components/pages/dkglobaladmin/training/resource/ResourceAdCampaign";
import DkAdminResourceAdsTracker from "./components/pages/dkglobaladmin/training/resource/ResourceAdsTracker";

import DkAdminAdmins from "./components/pages/dkglobaladmin/training/admins/Admins";
import DkAdminAdminWorkProcess from "./components/pages/dkglobaladmin/training/admins/AdminWorkProcess";
import DkAdminAdminJobDetailsStructure from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsStructure";
import DkAdminAdminJobDetailsTeamInfo from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsTeamInfo";
import DkAdminAdminJobDetailsJobSkills from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobSkills";
import DkAdminAdminJobDetailsJobTask from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobTask";
import DkAdminAdminJobDetailsJobQuality from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobQuality";
import DkAdminAdminJobDetailsJobVolume from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobVolume";
import DkAdminAdminJobDetailsJobQueries from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobQueries";
import DkAdminAdminJobDetailsJobTraining from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobTraining";
import DkAdminAdminJobDetailsJobDelegation from "./components/pages/dkglobaladmin/training/admins/jobdetails/AdminJobDetailsJobDelegation";
import DkAdminAdminPreparation from "./components/pages/dkglobaladmin/training/admins/AdminPreparation";
import DkAdminAdminPreparationToolsLogin from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationToolsLogin";
import DkAdminAdminPreparationTeamGreeting from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationTeamGreeting";
import DkAdminAdminPreparationDkgCrmTabs from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationDkgCrmTabs";
import DkAdminAdminPreparationReviewMailbox from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationReviewMailbox";
import DkAdminAdminPreparationReviewLiveJobs from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationReviewLiveJobs";
import DkAdminAdminPreparationReviewPipelines from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationReviewPipelines";
import DkAdminAdminPreparationReviewWorkplan from "./components/pages/dkglobaladmin/training/admins/preparation/AdminPreparationReviewWorkplan";
import DkAdminAdminAction from "./components/pages/dkglobaladmin/training/admins/AdminAction";
import DkAdminAdminActionSequence from "./components/pages/dkglobaladmin/training/admins/action/AdminActionSequence";
import DkAdminAdminActionSetPriority from "./components/pages/dkglobaladmin/training/admins/action/AdminActionSetPriority";
import DkAdminAdminActionPriority from "./components/pages/dkglobaladmin/training/admins/action/AdminActionPriority";
import DkAdminAdminActionCreateNewTask from "./components/pages/dkglobaladmin/training/admins/action/AdminActionCreateNewTask";
import DkAdminAdminActionFollowWorkplan from "./components/pages/dkglobaladmin/training/admins/action/AdminActionFollowWorkplan";
import DkAdminAdminReport from "./components/pages/dkglobaladmin/training/admins/AdminReport";
import DkAdminAdminsPerformanceCriteria from "./components/pages/dkglobaladmin/training/admins/performance/AdminsPerformanceCriteria";
import DkAdminAdminsPerformanceReviews from "./components/pages/dkglobaladmin/training/admins/performance/AdminsPerformanceReviews";
import DkAdminAdminsPerformanceReporting from "./components/pages/dkglobaladmin/training/admins/performance/AdminsPerformanceReporting";
import DkAdminAdminKnowledgeBase from "./components/pages/dkglobaladmin/training/admins/AdminKnowledgeBase";
import DkAdminAdminsKnowledgeSearchInfo from "./components/pages/dkglobaladmin/training/admins/knowledgebase/AdminsKnowledgeSearchInfo";
import DkAdminAdminsKnowledgeTaskInfo from "./components/pages/dkglobaladmin/training/admins/knowledgebase/AdminsKnowledgeTaskInfo";
import DkAdminAdminsKnowledgeToolsInfo from "./components/pages/dkglobaladmin/training/admins/knowledgebase/AdminsKnowledgeToolsInfo";
import DkAdminAdminsKnowledgeCandidates from "./components/pages/dkglobaladmin/training/admins/knowledgebase/AdminsKnowledgeCandidates";
import DkAdminAdminsKnowledgeClients from "./components/pages/dkglobaladmin/training/admins/knowledgebase/AdminsKnowledgeClients";
import DkAdminAdminsKnowledgeJobs from "./components/pages/dkglobaladmin/training/admins/knowledgebase/AdminsKnowledgeJobs";
import DkAdminAdminSendBulkEmail from "./components/pages/dkglobaladmin/training/admins/AdminSendBulkEmail";
import DkAdminAdminsSendBeSituations from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeSituations";
import DkAdminAdminsSendBeTaskRules from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeTaskRules";
import DkAdminAdminsSendBeHowToDo from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeHowToDo";
import DkAdminAdminsSendBeCreateList from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeCreateList";
import DkAdminAdminsSendBeCreateTemplate from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeCreateTemplate";
import DkAdminAdminsSendBeReviewTemplate from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeReviewTemplate";
import DkAdminAdminsSendBeSendBulkEmails from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeSendBulkEmails";
import DkAdminAdminsSendBeTrackResponse from "./components/pages/dkglobaladmin/training/admins/sendbulkemails/AdminsSendBeTrackResponse";
import DkAdminAdminSearchDatabase from "./components/pages/dkglobaladmin/training/admins/AdminSearchDatabase";
import DkAdminAdminsSearchDaSituations from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaSituations";
import DkAdminAdminsSearchDaTaskRules from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaTaskRules";
import DkAdminAdminsSearchDaHowToDo from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaHowToDo";
import DkAdminAdminsSearchDaWorkPlan from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaWorkPlan";
import DkAdminAdminsSearchDaResource from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaResource";
import DkAdminAdminsSearchDaHowToSearch from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaHowToSearch";
import DkAdminAdminsSearchDaCreateProject from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaCreateProject";
import DkAdminAdminsSearchDaSendMiniSpecs from "./components/pages/dkglobaladmin/training/admins/searchdatabase/AdminsSearchDaSendMiniSpecs";
import DkAdminAdminAssignPriority from "./components/pages/dkglobaladmin/training/admins/AdminAssignPriority";
import DkAdminAssignPrioritySituations from "./components/pages/dkglobaladmin/training/admins/assignpriority/AssignPrioritySituations";
import DkAdminAssignPriorityIndicators from "./components/pages/dkglobaladmin/training/admins/assignpriority/AssignPriorityIndicators";
import DkAdminAssignPriorityTodayBased from "./components/pages/dkglobaladmin/training/admins/assignpriority/AssignPriorityTodayBased";
import DkAdminAssignPriorityLiveJobBased from "./components/pages/dkglobaladmin/training/admins/assignpriority/AssignPriorityLiveJobBased";
import DkAdminAssignPriorityLanguageBased from "./components/pages/dkglobaladmin/training/admins/assignpriority/AssignPriorityLanguageBased";
import DkAdminAdminPriorityScreening from "./components/pages/dkglobaladmin/training/admins/AdminPriorityScreening";
import DkAdminAdminsPriorityScPreparation from "./components/pages/dkglobaladmin/training/admins/priorityscreening/AdminsPriorityScPreparation";
import DkAdminAdminsPriorityScActionTools from "./components/pages/dkglobaladmin/training/admins/priorityscreening/AdminsPriorityScActionTools";
import DkAdminAdminsPriorityScPhoneCall from "./components/pages/dkglobaladmin/training/admins/priorityscreening/AdminsPriorityScPhoneCall";
import DkAdminAdminsPriorityScSendEmail from "./components/pages/dkglobaladmin/training/admins/priorityscreening/AdminsPriorityScSendEmail";
import DkAdminAdminsPriorityScSendSms from "./components/pages/dkglobaladmin/training/admins/priorityscreening/AdminsPriorityScSendSms";
import DkAdminAdminCloseDecision from "./components/pages/dkglobaladmin/training/admins/AdminCloseDecision";
import DkAdminAdminsCaseDeSituations from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeSituations";
import DkAdminAdminsCaseDeTaskRules from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeTaskRules";
import DkAdminAdminsCaseDeHowToDo from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeHowToDo";
import DkAdminAdminsCaseDeDiscussion from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeDiscussion";
import DkAdminAdminsCaseDeEscalation from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeEscalation";
import DkAdminAdminsCaseDeComments from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeComments";
import DkAdminAdminsCaseDeUpdatePipeline from "./components/pages/dkglobaladmin/training/admins/casedecision/AdminsCaseDeUpdatePipeline";
import DkAdminAdminReviewNewCv from "./components/pages/dkglobaladmin/training/admins/AdminReviewNewCv";
import DkAdminAdminUpdateProfiles from "./components/pages/dkglobaladmin/training/admins/AdminUpdateProfiles";
import DkAdminAdminsReviewContacts from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewContacts";
import DkAdminAdminsReviewLocation from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewLocation";
import DkAdminAdminsReviewLanguages from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewLanguages";
import DkAdminAdminsReviewJobTitles from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewJobTitles";
import DkAdminAdminsReviewJobExperience from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewJobExperience";
import DkAdminAdminsReviewQualifications from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewQualifications";
import DkAdminAdminsReviewTechnicalSkills from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewTechnicalSkills";
import DkAdminAdminsReviewCareerSeniority from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewCareerSeniority";
import DkAdminAdminsReviewLiveJobSuitability from "./components/pages/dkglobaladmin/training/admins/reviewprofiles/AdminsReviewLiveJobSuitability";
import DkAdminAdminMatchWithJobs from "./components/pages/dkglobaladmin/training/admins/AdminMatchWithJobs";
import DkAdminAdminsUpdateStatus from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateStatus";
import DkAdminAdminsUpdateContacts from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateContacts";
import DkAdminAdminsUpdateCategory from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateCategory";
import DkAdminAdminsUpdateCvSource from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateCvSource";
import DkAdminAdminsUpdateLocations from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateLocations";
import DkAdminAdminsUpdateNationality from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateNationality";
import DkAdminAdminsUpdateLanguages from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateLanguages";
import DkAdminAdminsUpdateAssignJob from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateAssignJob";
import DkAdminAdminsUpdateCaseComment from "./components/pages/dkglobaladmin/training/admins/updateprofiles/AdminsUpdateCaseComment";
import DkAdminAdminRearchDatabase from "./components/pages/dkglobaladmin/training/admins/AdminRearchDatabase";
import DkAdminAdminMiniJobSpecs from "./components/pages/dkglobaladmin/training/admins/AdminMiniJobSpecs";
import DkAdminAdminsMiniJobSpecCreate from "./components/pages/dkglobaladmin/training/admins/minijobspec/AdminsMiniJobSpecCreate";
import DkAdminAdminsMiniJobSpecOutlook from "./components/pages/dkglobaladmin/training/admins/minijobspec/AdminsMiniJobSpecOutlook";
import DkAdminAdminsMiniJobSpecSaveSpec from "./components/pages/dkglobaladmin/training/admins/minijobspec/AdminsMiniJobSpecSaveSpec";
import DkAdminAdminsMiniJobSpecEmailSpec from "./components/pages/dkglobaladmin/training/admins/minijobspec/AdminsMiniJobSpecEmailSpec";
import DkAdminAdminsMiniJobSpecSendSms from "./components/pages/dkglobaladmin/training/admins/minijobspec/AdminsMiniJobSpecSendSms";
import DkAdminAdminSendReminders from "./components/pages/dkglobaladmin/training/admins/AdminSendReminders";
import DkAdminAdminsSendReSituations from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReSituations";
import DkAdminAdminsSendReTaskRules from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReTaskRules";
import DkAdminAdminsSendReHowToDo from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReHowToDo";
import DkAdminAdminsSendReFirstReminder from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReFirstReminder";
import DkAdminAdminsSendReSecondReminder from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReSecondReminder";
import DkAdminAdminsSendReThirdReminder from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReThirdReminder";
import DkAdminAdminsSendReFinalReminder from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReFinalReminder";
import DkAdminAdminsSendReCaseDiscussion from "./components/pages/dkglobaladmin/training/admins/sendreminders/AdminsSendReCaseDiscussion";
import DkAdminAdminNotReachable from "./components/pages/dkglobaladmin/training/admins/AdminNotReachable";
import DkAdminAdminsNotReSituations from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotReSituations";
import DkAdminAdminsNotReTaskRules from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotReTaskRules";
import DkAdminAdminsNotReHowToDo from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotReHowToDo";
import DkAdminAdminsNotRePreparation from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotRePreparation";
import DkAdminAdminsNotReActionTools from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotReActionTools";
import DkAdminAdminsNotRePhoneCall from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotRePhoneCall";
import DkAdminAdminsNotReSendEmail from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotReSendEmail";
import DkAdminAdminsNotReSendSms from "./components/pages/dkglobaladmin/training/admins/notreachables/AdminsNotReSendSms";

import DkAdminRecruiters from "./components/pages/dkglobaladmin/training/recruiters/Recruiters";
import DkAdminRecruitersWorkProcess from "./components/pages/dkglobaladmin/training/recruiters/RecruitersWorkProcess";
import DkAdminRecruitersJobDetailsStructure from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsStructure";
import DkAdminRecruitersJobDetailsTeamInfo from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsTeamInfo";
import DkAdminRecruitersJobDetailsJobSkills from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobSkills";
import DkAdminRecruitersJobDetailsJobTask from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobTask";
import DkAdminRecruitersJobDetailsJobQuality from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobQuality";
import DkAdminRecruitersJobDetailsJobVolume from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobVolume";
import DkAdminRecruitersJobDetailsJobQueries from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobQueries";
import DkAdminRecruitersJobDetailsJobTraining from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobTraining";
import DkAdminRecruitersJobDetailsJobDelegation from "./components/pages/dkglobaladmin/training/recruiters/jobdetails/RecruitersJobDetailsJobDelegation";
import DkAdminRecruitersPreparation from "./components/pages/dkglobaladmin/training/recruiters/RecruitersPreparation";
import DkAdminRecruitersPreparationToolsLogin from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationToolsLogin";
import DkAdminRecruitersPreparationTeamGreeting from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationTeamGreeting";
import DkAdminRecruitersPreparationDkgCrmTabs from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationDkgCrmTabs";
import DkAdminRecruitersPreparationReviewMailbox from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationReviewMailbox";
import DkAdminRecruitersPreparationReviewLiveJobs from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationReviewLiveJobs";
import DkAdminRecruitersPreparationReviewPipelines from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationReviewPipelines";
import DkAdminRecruitersPreparationReviewWorkplan from "./components/pages/dkglobaladmin/training/recruiters/preparation/RecruitersPreparationReviewWorkplan";
import DkAdminRecruitersAction from "./components/pages/dkglobaladmin/training/recruiters/RecruitersAction";
import DkAdminRecruitersActionSequence from "./components/pages/dkglobaladmin/training/recruiters/action/RecruitersActionSequence";
import DkAdminRecruitersActionSetPriority from "./components/pages/dkglobaladmin/training/recruiters/action/RecruitersActionSetPriority";
import DkAdminRecruitersActionPriority from "./components/pages/dkglobaladmin/training/recruiters/action/RecruitersActionPriority";
import DkAdminRecruitersActionCreateNewTask from "./components/pages/dkglobaladmin/training/recruiters/action/RecruitersActionCreateNewTask";
import DkAdminRecruitersActionFollowWorkplan from "./components/pages/dkglobaladmin/training/recruiters/action/RecruitersActionFollowWorkplan";
import DkAdminRecruitersReport from "./components/pages/dkglobaladmin/training/recruiters/RecruitersReport";
import DkAdminRecruitersPerformanceCriteria from "./components/pages/dkglobaladmin/training/recruiters/performance/RecruitersPerformanceCriteria";
import DkAdminRecruitersPerformanceReviews from "./components/pages/dkglobaladmin/training/recruiters/performance/RecruitersPerformanceReviews";
import DkAdminRecruitersPerformanceReporting from "./components/pages/dkglobaladmin/training/recruiters/performance/RecruitersPerformanceReporting";
import DkAdminRecruitersKnowledgeBase from "./components/pages/dkglobaladmin/training/recruiters/RecruitersKnowledgeBase";
import DkAdminRecruitersKnowledgeSearchInfo from "./components/pages/dkglobaladmin/training/recruiters/knowledgebase/RecruitersKnowledgeSearchInfo";
import DkAdminRecruitersKnowledgeTaskInfo from "./components/pages/dkglobaladmin/training/recruiters/knowledgebase/RecruitersKnowledgeTaskInfo";
import DkAdminRecruitersKnowledgeToolsInfo from "./components/pages/dkglobaladmin/training/recruiters/knowledgebase/RecruitersKnowledgeToolsInfo";
import DkAdminRecruitersKnowledgeCandidates from "./components/pages/dkglobaladmin/training/recruiters/knowledgebase/RecruitersKnowledgeCandidates";
import DkAdminRecruitersKnowledgeClients from "./components/pages/dkglobaladmin/training/recruiters/knowledgebase/RecruitersKnowledgeClients";
import DkAdminRecruitersKnowledgeJobs from "./components/pages/dkglobaladmin/training/recruiters/knowledgebase/RecruitersKnowledgeJobs";
import DkAdminRecruitersInterviewCall from "./components/pages/dkglobaladmin/training/recruiters/RecruitersInterviewCall";
import DkAdminRecruitersFullJobSpec from "./components/pages/dkglobaladmin/training/recruiters/RecruitersFullJobSpec";
import DkAdminRecruitersCaseQueries from "./components/pages/dkglobaladmin/training/recruiters/RecruitersCaseQueries";
import DkAdminRecruitersLiveNewQuery from "./components/pages/dkglobaladmin/training/recruiters/RecruitersLiveNewQuery";
import DkAdminRecruitersUpdateProfile from "./components/pages/dkglobaladmin/training/recruiters/RecruitersUpdateProfile";
import DkAdminRecruitersCreateJobSpec from "./components/pages/dkglobaladmin/training/recruiters/RecruitersCreateJobSpec";
import DkAdminRecruitersCaseDecision from "./components/pages/dkglobaladmin/training/recruiters/RecruitersCaseDecision";
import DkAdminRecruitersClientsPortals from "./components/pages/dkglobaladmin/training/recruiters/RecruitersClientsPortals";
import DkAdminRecruitersMakeSendout from "./components/pages/dkglobaladmin/training/recruiters/RecruitersMakeSendout";
import DkAdminRecruitersNotReachable from "./components/pages/dkglobaladmin/training/recruiters/RecruitersNotReachable";

// Common Training Start

import DashboardCommon from "./components/pages/commontraining/dashboard/DashboardCommon";
// import TraningCommonImage from './components/pages/commontraining/image';
import TraningCommonBusiness from "./components/pages/trainingdepartments/dkglobaltraining/recruitmenttraining/history/RecHistoryTraining";

// End

import DkAdminManagers from "./components/pages/dkglobaladmin/training/managers/Managers";
import DkAdminManagersWorkProcess from "./components/pages/dkglobaladmin/training/managers/ManagersWorkProcess";
import DkAdminManagersJobDetailsStructure from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsStructure";
import DkAdminManagersJobDetailsTeamInfo from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsTeamInfo";
import DkAdminManagersJobDetailsJobSkills from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobSkills";
import DkAdminManagersJobDetailsJobTask from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobTask";
import DkAdminManagersJobDetailsJobQuality from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobQuality";
import DkAdminManagersJobDetailsJobVolume from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobVolume";
import DkAdminManagersJobDetailsJobQueries from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobQueries";
import DkAdminManagersJobDetailsJobTraining from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobTraining";
import DkAdminManagersJobDetailsJobDelegation from "./components/pages/dkglobaladmin/training/managers/jobdetails/ManagersJobDetailsJobDelegation";
import DkAdminManagersPreparation from "./components/pages/dkglobaladmin/training/managers/ManagersPreparation";
import DkAdminManagersPreparationToolsLogin from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationToolsLogin";
import DkAdminManagersPreparationTeamGreeting from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationTeamGreeting";
import DkAdminManagersPreparationDkgCrmTabs from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationDkgCrmTabs";
import DkAdminManagersPreparationReviewMailbox from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationReviewMailbox";
import DkAdminManagersPreparationReviewLiveJobs from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationReviewLiveJobs";
import DkAdminManagersPreparationReviewPipelines from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationReviewPipelines";
import DkAdminManagersPreparationReviewWorkplan from "./components/pages/dkglobaladmin/training/managers/preparation/ManagersPreparationReviewWorkplan";
import DkAdminManagersAction from "./components/pages/dkglobaladmin/training/managers/ManagersAction";
import DkAdminManagersActionSequence from "./components/pages/dkglobaladmin/training/managers/action/ManagersActionSequence";
import DkAdminManagersActionSetPriority from "./components/pages/dkglobaladmin/training/managers/action/ManagersActionSetPriority";
import DkAdminManagersActionPriority from "./components/pages/dkglobaladmin/training/managers/action/ManagersActionPriority";
import DkAdminManagersActionCreateNewTask from "./components/pages/dkglobaladmin/training/managers/action/ManagersActionCreateNewTask";
import DkAdminManagersActionFollowWorkplan from "./components/pages/dkglobaladmin/training/managers/action/ManagersActionFollowWorkplan";
import DkAdminManagersReport from "./components/pages/dkglobaladmin/training/managers/ManagersReport";
import DkAdminManagersPerformanceCriteria from "./components/pages/dkglobaladmin/training/managers/performance/ManagersPerformanceCriteria";
import DkAdminManagersPerformanceReviews from "./components/pages/dkglobaladmin/training/managers/performance/ManagersPerformanceReviews";
import DkAdminManagersPerformanceReporting from "./components/pages/dkglobaladmin/training/managers/performance/ManagersPerformanceReporting";
import DkAdminManagersKnowledgeBase from "./components/pages/dkglobaladmin/training/managers/ManagersKnowledgeBase";
import DkAdminManagersKnowledgeSearchInfo from "./components/pages/dkglobaladmin/training/managers/knowledgebase/ManagersKnowledgeSearchInfo";
import DkAdminManagersKnowledgeTaskInfo from "./components/pages/dkglobaladmin/training/managers/knowledgebase/ManagersKnowledgeTaskInfo";
import DkAdminManagersKnowledgeToolsInfo from "./components/pages/dkglobaladmin/training/managers/knowledgebase/ManagersKnowledgeToolsInfo";
import DkAdminManagersKnowledgeCandidates from "./components/pages/dkglobaladmin/training/managers/knowledgebase/ManagersKnowledgeCandidates";
import DkAdminManagersKnowledgeClients from "./components/pages/dkglobaladmin/training/managers/knowledgebase/ManagersKnowledgeClients";
import DkAdminManagersKnowledgeJobs from "./components/pages/dkglobaladmin/training/managers/knowledgebase/ManagersKnowledgeJobs";
import DkAdminManagersSendInterviewPrep from "./components/pages/dkglobaladmin/training/managers/ManagersSendInterviewPrep";
import DkAdminManagersSheduleInterviews from "./components/pages/dkglobaladmin/training/managers/ManagersSheduleInterviews";
import DkAdminManagersReviewSendout from "./components/pages/dkglobaladmin/training/managers/ManagersReviewSendout";
import DkAdminManagersSubmitSendout from "./components/pages/dkglobaladmin/training/managers/ManagersSubmitSendout";
import DkAdminManagersAskJobDemand from "./components/pages/dkglobaladmin/training/managers/ManagersAskJobDemand";
import DkAdminManagersCandidatesFeedback from "./components/pages/dkglobaladmin/training/managers/ManagersCandidatesFeedback";
import DkAdminManagersPrepareInterviews from "./components/pages/dkglobaladmin/training/managers/ManagersPrepareInterviews";
import DkAdminManagersUpdateCandidates from "./components/pages/dkglobaladmin/training/managers/ManagersUpdateCandidates";
import DkAdminManagersClientsProjections from "./components/pages/dkglobaladmin/training/managers/ManagersClientsProjections";
import DkAdminManagersJobOfferDocuments from "./components/pages/dkglobaladmin/training/managers/ManagersJobOfferDocuments";
import DkAdminAccountManagers from "./components/pages/dkglobaladmin/training/managers/AccountManagers";

import DkAdminTrainingManagement from "./components/pages/dkglobaladmin/training/management/Management";
import DkAdminManagementTeamLeader from "./components/pages/dkglobaladmin/training/management/ManagementTeamLeader";
import DkAdminManagementRecManager from "./components/pages/dkglobaladmin/training/management/ManagementRecManager";
import DkAdminManagementMarketingManager from "./components/pages/dkglobaladmin/training/management/ManagementMarketingManager";
import DkAdminManagementHrManager from "./components/pages/dkglobaladmin/training/management/ManagementHrManager";
import DkAdminManagementItManager from "./components/pages/dkglobaladmin/training/management/ManagementItManager";
import DkAdminManagementResourceManager from "./components/pages/dkglobaladmin/training/management/ManagementResourceManager";
import DkAdminManagementFinanceManager from "./components/pages/dkglobaladmin/training/management/ManagementFinanceManager";
import DkAdminManagementOperationManager from "./components/pages/dkglobaladmin/training/management/ManagementOperationManager";
import DkAdminManagementDirector from "./components/pages/dkglobaladmin/training/management/ManagementDirector";

import DkAdminAssessmentsOverview from "./components/pages/dkglobaladmin/training/assessments/Assessments";
import DkAdminAssessmentsAssessmentOne from "./components/pages/dkglobaladmin/training/assessments/AssessmentOne";
import DkAdminAssessmentsAssessmentTwo from "./components/pages/dkglobaladmin/training/assessments/AssessmentTwo";
import DkAdminAssessmentPreparation from "./components/pages/dkglobaladmin/training/assessments/AssessmentPreparation";
import DkAdminAssessmentDecision from "./components/pages/dkglobaladmin/training/assessments/AssessmentDecision";
import DkAdminAssessmentFaq from "./components/pages/dkglobaladmin/training/assessments/AssessmentFaq";
import ProjectManagerTraining from "./components/pages/dkglobaladmin/training/projectmanager/ProjectManagerTraining";

// End DK Global Training Module

// Finance Payments Start

import PaymentsDashboard from "./components/pages/dkglobalfinance/financepayments/dashboard/PaymentsDashboard";

// /End

// Training Module Start

import TrainingModule from "./components/pages/trainingmodule/TrainingModule";

import TrainingSuppliers from "./components/pages/dkglobaladmin/training/commontraining/suppliers/TrainingSuppliers";
import TrainingClients from "./components/pages/dkglobaladmin/training/commontraining/clients/TrainingClients";
import TrainingProducts from "./components/pages/dkglobaladmin/training/commontraining/products/TrainingProducts";
import TrainingCustomers from "./components/pages/dkglobaladmin/training/commontraining/customers/TrainingCustomers";
import TrainingWorkTools from "./components/pages/dkglobaladmin/training/commontraining/worktools/TrainingWorkTools";
import TrainingModuleConducts from "./components/pages/dkglobaladmin/training/commontraining/conducts/TrainingConducts";
import TrainingFaq from "./components/pages/dkglobaladmin/training/commontraining/trainingfaq/TrainingFaq";
import BusinessSummary from "./components/pages/dkglobaladmin/training/commontraining/businesstraining/BusinessSummary";
// import RecMarketShare from "./components/pages/trainingdepartments/dkglobaltraining/recruitmenttraining/marketshare/RecMarketShare";
// import RecDefination from "./components/pages/trainingdepartments/dkglobaltraining/recruitmenttraining/defination/RecDefination";
// import RecIndustries from "./components/pages/trainingdepartments/dkglobaltraining/recruitmenttraining/industries/RecIndustries";
// import RecModels from "./components/pages/trainingdepartments/dkglobaltraining/recruitmenttraining/models/RecModels";
// import RecPricing from "./components/pages/trainingdepartments/dkglobaltraining/recruitmenttraining/pricing/RecPricing";

// End

// Resource Departments Start

import ResourceDashboard from "./components/pages/resourcedepartments/dashboard/ResourceDashboard";

// End

// Training Recruitment Module Pages

import RecAccountManager from "./components/pages/trainingdepartments/recruitmentmodule/accountmanager/RecAccountManager";
import RecRecruitmentManager from "./components/pages/trainingdepartments/recruitmentmodule/recruitmentmanager/RecRecruitmentManager";
import RecRecruitmentConsultant from "./components/pages/trainingdepartments/recruitmentmodule/recruitmentconsultant/RecRecruitmentConsultant";
import RecConsultantsBusiness from "./components/pages/trainingdepartments/recruitmentconsultants/business/RecConsultantsBusiness";
import RecBusinessSampleTwo from "./components/pages/trainingdepartments/recruitmentconsultants/business/RecBusinessSampleTwo";
import RecBusinessSampleThree from "./components/pages/trainingdepartments/recruitmentconsultants/business/RecBusinessSampleThree";
import RecBusinessSampleFour from "./components/pages/trainingdepartments/recruitmentconsultants/business/RecBusinessSampleFour";
import RecConsultantsRecDep from "./components/pages/trainingdepartments/recruitmentconsultants/recruitmentdepartment/RecConsultantsRecDep";
import RecRecDepSampleTwo from "./components/pages/trainingdepartments/recruitmentconsultants/recruitmentdepartment/RecRecDepSampleTwo";
import RecRecDepSampleThree from "./components/pages/trainingdepartments/recruitmentconsultants/recruitmentdepartment/RecRecDepSampleThree";
import RecRecDepSampleFour from "./components/pages/trainingdepartments/recruitmentconsultants/recruitmentdepartment/RecRecDepSampleFour";
import RecJobProfile from "./components/pages/trainingdepartments/recruitmentconsultants/jobprofile/RecJobProfile";
import RecProcess from "./components/pages/trainingdepartments/recruitmentconsultants/process/RecProcess";
import RecTools from "./components/pages/trainingdepartments/recruitmentconsultants/tools/RecTools";
import RecMailbox from "./components/pages/trainingdepartments/recruitmentconsultants/mailbox/RecMailbox";
import RecKpi from "./components/pages/trainingdepartments/recruitmentconsultants/kpi/RecKpi";
import RecCsf from "./components/pages/trainingdepartments/recruitmentconsultants/csf/RecCsf";
import RecFaq from "./components/pages/trainingdepartments/recruitmentconsultants/faq/RecFaq";
import RecDatabaseAdmin from "./components/pages/trainingdepartments/recruitmentmodule/databaseadmin/RecDatabaseAdmin";
import RecAdsPostAdmin from "./components/pages/trainingdepartments/recruitmentmodule/adspostadmin/RecAdsPostAdmin";

// End
// Start Recuiter Account Module
import RcaccountDashboard from "./components/pages/rc-account/dashboard";
// End Recuiter Account Module
// Start My Account Module
import MyaccountDashboard from "./components/pages/myaccount/dashboard";
import MyaccountMailbox from "./components/pages/myaccount/mailbox/MyaccMailbox";
import MyaccountProfile from "./components/pages/myaccount/myprofile/MyProfile";
import MyaccResetLogin from "./components/pages/myaccount/resetlogin/ChangeLogin";
import MyaccDeleteAcc from "./components/pages/myaccount/setting/delete-acc/DeleteAccount";
import MyacctNotifiation from "./components/pages/myaccount/setting/notification/AccountNotifiation";
import MyaccContact from "./components/pages/myaccount/setting/contacts/MyaccContact";
import MyaccFeedback from "./components/pages/myaccount/setting/feedback/MyaccFeedback";
import MyaccCookies from "./components/pages/myaccount/setting/cookies/MyaccCookies";
import MyaccTestTable from "./components/pages/myaccount/table/TestTable";
import MyaccPrivacy from "./components/pages/myaccount/privacy/MyaccPrivacy";
import MyaccTermsofUse from "./components/pages/myaccount/setting/terms/TermsofUse";
import MyaccMyfiles from "./components/pages/myaccount/setting/myfile/Myfiles";
import MyaccGdpr from "./components/pages/myaccount/privacy/GdprPrivacy";
// import MyaccServicesSummary from "./components/pages/myaccount/setting/overview/ServicesOverview";
import MyaccServicesOverview from "./components/pages/myaccount/setting/overview/ServicesOverview";
import MyaccServicesConsults from "./components/pages/myaccount/services/consult/Consults";

import MyaccServicespricing from "./components/pages/myaccount/services/pricing/Paidserviceprice";
import MyaccServicestestiomonial from "./components/pages/myaccount/services/testiomonial";
import MyaccServicesfeedbacks from "./components/pages/myaccount/services/feedbacks";
import MyaccServicesfaq from "./components/pages/myaccount/services/faq/ServiceFaq";

import CommingsoonPage from "./components/pages/myaccount/commingsoon/CommingPage";
import MyaccBenfitService from "./components/pages/myaccount/services/benfits/BenfitService";

import MyaccCandidates from "./components/pages/myaccount/facilities/Candidates";
import MyaccCallSms from "./components/pages/myaccount/facilities/CallSms";
import MyaccCRMLinesense from "./components/pages/myaccount/facilities/CRMLinesense";
import MyaccMSoffice from "./components/pages/myaccount/facilities/MSoffice";
import MyaccMSMailbox from "./components/pages/myaccount/facilities/MSMailbox";
import MyaccJobProfile from "./components/pages/myaccount/job-profile/JobProfile";
import MyaccPayment from "./components/pages/myaccount/payments/Payment";
import MyaccInvoice from "./components/pages/myaccount/invoices/Invoice";
import MyaccContract from "./components/pages/myaccount/contracts/Contract";
import MyaccFAQ from "./components/pages/myaccount/faq/Faq";
import MyaccKpi from "./components/pages/myaccount/kpi/MyaccKpi";
import MyaccInterview from "./components/pages/myaccount/interviews/MyaccInterview";
import MyaccJoboffer from "./components/pages/myaccount/joboffer/MyaccJoboffer";
import MyaccReferrals from "./components/pages/myaccount/referral/Referrals";

import MyaccMyresume from "./components/pages/myaccount/myresume/MyaccMyresume";
import MyaccLeaves from "./components/pages/myaccount/leaves/MyaccLeaves";
import MyaccProfileSuMM from "./components/pages/myaccount/myprofile/MyProfile";
import MyaccProfileAccInfo from "./components/pages/myaccount/myprofile/acc-info/AccountInfo";
import MyaccProfileContactInfo from "./components/pages/myaccount/myprofile/contact-info/ContactInfo";

import MyaccProfileContact from "./components/pages/myaccount/myprofile/contact/MyaccContact";
import MyaccResume from "./components/pages/myaccount/myprofile/resume/MyaccResume";
import MyaccCoverLetter from "./components/pages/myaccount/myprofile/cover/CoverLetter";
import MyaccUploadCv from "./components/pages/myaccount/myprofile/uploadcv/UploadCv";
import MyaccDownloadCv from "./components/pages/myaccount/myprofile/downloadcv/DownloadCv";

import MyaccMyProfile from "./components/pages/myaccount/myprofile/profile/MyProfile";
import MyaccPaidservover from "./components/pages/myaccount/paidservice/overview/Paidserveoverview";

import MyaccDocuments from "./components/pages/myaccount/myprofile/documents/Document";
import MyaccMyReferrals from "./components/pages/myaccount/myprofile/my-referrals/MyReferrals";

// import MyaccMyresume from './components/pages/myaccount/holidays/Holidays';
//End My Account Module
// Start Main Maangement Module
import MainManagmentDashboard from "./components/pages/management/dashboard/ModuleManagementDashboard";
import WorkplanRulesdkglobal from "./components/pages/mainmenu/workplan/moods/rules/WorkplanDkglobal";
import WorkplanRulesTasks from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesTasks";
import WorkplanRulesClients from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesClients";
import WorkplanRulesCandidates from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesCandidates";
import WorkplanRulesPipeline from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesPipelines";
import WorkplanRulesJobs from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesJobs";
import WorkplanRulesRecruitment from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesRecruitment";
import WorkplanRulesAds from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesAds";
import WorkplanRulesDkgCrm from "./components/pages/mainmenu/workplan/moods/rules/WorkplanRulesDkgCrm";

//End Main Management Module
// Start Main Checklist Module
// End Main Checklist Module
// Start Main Job Profile Module
import DkglobalJobprofile from "./components/pages/mainmenu/jobprofile/DkglobalJobprofile";
// import DkglobalChecklist from './components/pages/mainmenu/checklist/DkglobalChecklist';
import DkglobalChecklist from "./components/pages/mainmenu/checklist/DkglobalChecklist";

// End Main Job Profile Module
// Main Department Faq Start

import MainDepartmentFaq from "./components/pages/maindepartmentfaq/MainDepartmentFaq";
import RecruitmentFaq from "./components/pages/maindepartmentfaq/RecruitmentFaq";
import AccountingFaq from "./components/pages/maindepartmentfaq/AccountingFaq";
import ResourceFaq from "./components/pages/maindepartmentfaq/ResourceFaq";
import MarketingFaq from "./components/pages/maindepartmentfaq/MarketingFaq";
import LegalFaq from "./components/pages/maindepartmentfaq/LegalFaq";
import ManagementFaq from "./components/pages/maindepartmentfaq/ManagementFaq";
import NewBusinessFaq from "./components/pages/maindepartmentfaq/NewBusinessFaq";
import MainTrainingFaq from "./components/pages/maindepartmentfaq/TrainingFaq";
import ItTeamFaq from "./components/pages/maindepartmentfaq/ItTeamFaq";
import HrDepartmentFaq from "./components/pages/dkglobaladmin/adminhr/hrfaq/HrDepartmentFaq";

// End

// Training Common Start

import CommonTrainingDkgCrm from "./components/pages/commontraining/DkgCrm";
import CommonTrainingEmails from "./components/pages/commontraining/CommonEmails";
import CommonTrainingCalls from "./components/pages/commontraining/CommonCalls";
import CommonTrainingTasks from "./components/pages/commontraining/CommonTasks";
import CommonTrainingWorkplan from "./components/pages/commontraining/CommonWorkplan";
import CommonTrainingPipeline from "./components/pages/training/commontraining/process/DkgProcess";
import CommonTrainingKpi from "./components/pages/commontraining/CommonKpi";
import CommonTrainingTargets from "./components/pages/commontraining/CommonTargets";

//

//END FILES PAGE
// START RECRUITMENT TIPS MODULE
import RectipsSummary from "./components/pages/recruitment-tips/summary/Summary";
import RecDepartmentFaq from "./components/pages/recruitment-tips/recuirtmentfaq/RecDepartmentFaq";
import RecClientsInfoFaq from "./components/pages/recruitment-tips/recuirtmentfaq/RecClientsInfoFaq";
import RecProjectsFaq from "./components/pages/recruitment-tips/recuirtmentfaq/RecProjectsFaq";
import ReJobDemandFaq from "./components/pages/recruitment-tips/recuirtmentfaq/ReJobDemandFaq";
import ReManageAdsFaq from "./components/pages/recruitment-tips/recuirtmentfaq/ReManageAdsFaq";
import ReDatabaseFaq from "./components/pages/recruitment-tips/recuirtmentfaq/ReDatabaseFaq";
import ReProjectionsFaq from "./components/pages/recruitment-tips/recuirtmentfaq/ReProjectionsFaq";
import ReRecruitmentTipsFaq from "./components/pages/recruitment-tips/recuirtmentfaq/ReRecruitmentTipsFaq";
import RcaccountRoute from "./components/hocs/RcaccountRoute";
//END  RECRUITMENT TIPS MODULE

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <LoginRoute exact path="/login" component={Login} /> */}
        <LoginRoute exact path="/recruters/login" component={recrutersLogin} />
        <LoginRoute exact path="/candidate/login" component={candidatesLogin} />
        <LoginRoute path="/" component={CommonLoginPage} exact />

        {/* Start Module For Mainmenu Workplan */}
        <PrivateRoute
          path="/dashboard/manage-ment/reports"
          component={BusinessMangementReoprts}
        />
        <MainPrivateRoute
          path="/dashboard/get-started"
          component={WorkplanMoods}
        />
        <MainPrivateRoute
          path="/dashboard/principles"
          component={MainPrincipales}
        />
        <MainPrivateRoute
          path="/dashboard/check-in"
          component={WorkplanCheckIn}
        />
        <MainPrivateRoute
          path="/dashboard/workflow"
          component={WorkplanMainWorkFlow}
        />
        <MainPrivateRoute
          path="/dashboard/reports"
          component={MainDkgReoprts}
        />
        <MainPrivateRoute
          path="/dashboard/rules/dk-global"
          component={WorkplanRulesdkglobal}
        />
        <MainPrivateRoute
          path="/dashboard/rules/tasks"
          component={WorkplanRulesTasks}
        />
        <MainPrivateRoute
          path="/dashboard/rules/clients"
          component={WorkplanRulesClients}
        />
        <MainPrivateRoute
          path="/dashboard/rules/candidates"
          component={WorkplanRulesCandidates}
        />
        <MainPrivateRoute
          path="/dashboard/rules/pipeline"
          component={WorkplanRulesPipeline}
        />
        <MainPrivateRoute
          path="/dashboard/rules/jobs"
          component={WorkplanRulesJobs}
        />
        <MainPrivateRoute
          path="/dashboard/rules/recruitment"
          component={WorkplanRulesRecruitment}
        />
        <MainPrivateRoute
          path="/dashboard/rules/ads"
          component={WorkplanRulesAds}
        />
        <MainPrivateRoute
          path="/dashboard/rules/dkg-crm"
          component={WorkplanRulesDkgCrm}
        />
        {/* <MainPrivateRoute path="/dashboard/rules" component={WorkplanRules} /> */}
        <MainPrivateRoute
          path="/dashboard/requests/summary"
          component={WorkplanRequest}
        />
        <MainPrivateRoute
          path="/dashboard/requests/recruiter"
          component={WorkplanReqRequiters}
        />
        <MainPrivateRoute
          path="/dashboard/requests/rec-admin"
          component={WorkplanReqRecAdmin}
        />
        <MainPrivateRoute
          path="/dashboard/requests/account-manager"
          component={WorkplanReqAccManager}
        />
        <MainPrivateRoute
          path="/dashboard/requests/dkg-admin"
          component={WorkplanReqdkgAdmin}
        />
        <MainPrivateRoute
          path="/dashboard/work-models/overview"
          component={RcSummary}
        />
        <MainPrivateRoute
          path="/dashboard/my-profile"
          component={WorkplanMyProfile}
        />
        <MainPrivateRoute
          path="/dashboard/work-load"
          component={MainWorkload}
        />
        <MainPrivateRoute path="/dashboard/case-studies" component={MainMoods} />
        <MainPrivateRoute
          path="/dashboard/manage-ment"
          component={MainManagmentDashboard}
        />
        <MainPrivateRoute
          path="/dashboard/clients"
          component={NewBusinessModule}
        />
        <MainPrivateRoute
          path="/dashboard/resource"
          component={AdminDashboard}
        />
        {/* End Module For Mainmenu Workplan  */}
        {/* Start Dk Global Admin Supplier pages */}
        {/* Start Module For Mainmenu Checklist */}
        {/* End Module For Mainmenu Checklist */}
        {/* Start Module For Job Profile Pages */}
        <MainPrivateRoute
          path="/dashboard/job-profile/dk-global"
          component={DkglobalJobprofile}
        />
        <MainPrivateRoute
          path="/dashboard/checklist/dk-global"
          component={DkglobalChecklist}
        />
        {/* End Module For Job Profile Pages */}
        <MainPrivateRoute
          path="/dashboard/suppliers"
          component={AdminSupplierDashboard}
        />
        <MainPrivateRoute
          path="/dashboard/accounting"
          component={FinanceDashboard}
        />
        <PrivateRoute path="/dashboard/hr/leaves" component={HrnewLeaves} />
        <MainPrivateRoute path="/dashboard/hr" component={AdminHrDashboard} />
        <MainPrivateRoute
          path="/dashboard/training"
          component={TrainingModule}
        />
        <MainPrivateRoute path="/dashboard/it-teams" component={ItTeamModule} />

        {/* End */}
        {/* Start Module For Mainmenu Job Spec  */}
        {/* <MainPrivateRoute path="/spec/summary" component={MainSpecSummary} /> */}
        {/* <MainPrivateRoute path="/spec/live-spec" component={MainLiveSpec} /> */}
        {/* <MainPrivateRoute path="/spec/:id" component={MainSavedSpec} /> */}

        {/* End Module For Mainmenu Job Spec */}
        {/* Start Module For Mainmenu Int Spec  */}
        {/* <MainPrivateRoute path="/int-spec/summary" component={MainSpecSummary} /> */}
        {/* <MainPrivateRoute path="/spec/live-spec" component={MainLiveSpec} /> */}

        {/* End Module For Mainmenu Int Spec */}
        {/* Start Module For Training Request */}
        <MainPrivateRoute
          path="/dashboard/training-req/summary"
          component={TrainingReq}
        />
        {/* End Module For Training Request */}

        <MainPrivateRoute
          path="/dashboard/check-in"
          component={DailyTracking}
        />
        {/* <MainPrivateRoute path="/dashboard/job-profile" component={JobProfile} /> */}

        <MainPrivateRoute
          path="/dashboard/recruitment"
          component={AllModules}
        />
        <MainPrivateRoute path="/dashboard" component={Visitsite} />

        {/* Start RC Wallet Start */}
        <PrivateRoute
          path="/dkg-global/hierarchy"
          component={DkGlobalhierarchy}
        />
        <PrivateRoute
          path="/dkg-global/work-model/summary"
          component={GetstartedModelOverview}
        />
        <PrivateRoute
          path="/dkg-global/work-model/model-1"
          component={WorkplanModelOne}
        />
        <PrivateRoute
          path="/dkg-global/work-model/model-2"
          component={WorkplanModelTwo}
        />
        <PrivateRoute
          path="/dkg-global/work-model/model-3"
          component={WorkplanModelThree}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/overview"
          component={DKGlobalCompany}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/established"
          component={DKGlobalEstablished}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/business"
          component={DKGlobalBuisness}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/industry"
          component={DKGlobalIndustry}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/services"
          component={DKGlobalServices}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/clients"
          component={DKGlobalClients}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/suppliers"
          component={DKGlobalSuppliers}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/completion"
          component={DKGlobalCompletion}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/employees"
          component={DKGlobalEmployees}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/future-plans"
          component={DKGlobalFuturePlans}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/social-media"
          component={DKGlobalSocialMedia}
        />
        <PrivateRoute
          path="/dkg-global/dkglobal/contact-details"
          component={DKGlobalContactDetails}
        />
        <PrivateRoute
          path="/dkg-global/departments/summary"
          component={DKGlobalDepartmentSummary}
        />
        <PrivateRoute
          path="/dkg-global/departments/recruitment"
          component={DepartmentRecruitment}
        />
        <PrivateRoute
          path="/dkg-global/departments/advertisement"
          component={DepartmentAdvertisement}
        />
        <PrivateRoute
          path="/dkg-global/departments/accounting-finance"
          component={DepartmentAccountingFinance}
        />
        <PrivateRoute
          path="/dkg-global/departments/business-development"
          component={DepartmentBusinessDevelopment}
        />
        <PrivateRoute
          path="/dkg-global/departments/suppliers"
          component={DepartmentSuppliers}
        />
        <PrivateRoute
          path="/dkg-global/departments/hr"
          component={DepartmentHr}
        />
        <PrivateRoute
          path="/dkg-global/departments/management"
          component={DepartmentManagement}
        />
        <PrivateRoute
          path="/dkg-global/departments/training"
          component={DepartmentTraining}
        />
        <PrivateRoute
          path="/dkg-global/departments/legal"
          component={DepartmentLegal}
        />
        <PrivateRoute
          path="/dkg-global/departments/it-team"
          component={DepartmentItTeam}
        />
        <PrivateRoute path="/dkg-global/dkg-team" component={DkGlobalDkgTeam} />
        <PrivateRoute path="/dkg-global/clients" component={DkGlobalDkgTeam} />
        <PrivateRoute
          path="/dkg-global/structure"
          component={DkGlobalStructure}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/overview"
          component={DkGlobalJobprofiles}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/established"
          component={JobProfilesEstablished}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/business"
          component={JobProfilesBusiness}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/industry"
          component={JobProfilesIndustry}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/services"
          component={JobProfilesServices}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/clients"
          component={JobProfilesClients}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/suppliers"
          component={JobProfilesSuppliers}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/completion"
          component={JobProfilesCompletion}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/employees"
          component={JobProfilesEmployees}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/future-plans"
          component={JobProfilesFuturePlan}
        />
        <PrivateRoute
          path="/dkg-global/job-profiles/social-media"
          component={JobProfilesSocialMedia}
        />
        <MainPrivateRoute
          path="/paytasks/information"
          component={RcInformation}
        />

        {/* End */}

        <MainPrivateRoute
          path="/kpis/sales-kpi/case-opened"
          component={SalesKpiCaseOpened}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/job-spec"
          component={SalesKpiJobSpec}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/sendout"
          component={SalesKpiJobSpec}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/refused-offer"
          component={SalesKpiRefuseOffer}
        />
        <MainPrivateRoute path="/kpis/sales-kpi/credit" component={CreditKpi} />
        <MainPrivateRoute
          path="/kpis/sales-kpi/placements"
          component={PlacementsSalesKpi}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/annual-sale"
          component={AnnualSalesKpi}
        />
        {/* <MainPrivateRoute path="/kpis/sales-kpi/team-sale" component={SalesKpi} /> */}
        <MainPrivateRoute
          path="/kpis/tasks/refused"
          component={KpiSalesRefused}
        />
        <MainPrivateRoute
          path="/kpis/tasks/joined"
          component={KpiSalesJoined}
        />
        <MainPrivateRoute
          path="/kpis/tasks/credit"
          component={KpiSalesCredit}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/dashboard"
          component={SalesKpiDashboard}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/monthly"
          component={SalesKpiMonthly}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/stats-details"
          component={SalesKpiStatsDetails}
        />
        <MainPrivateRoute
          path="/kpis/sales-kpi/case-details"
          component={SalesCaseDetails}
        />

        {/* Start Main Module Rules Pages */}

        <MainPrivateRoute path="/rules/dk-global" component={ModuleDkGlobal} />
        <MainPrivateRoute path="/rules/tasks" component={ModuleTasks} />
        <MainPrivateRoute path="/rules/clients" component={ModuleClients} />
        <MainPrivateRoute
          path="/rules/candidates"
          component={ModuleCandidates}
        />
        <MainPrivateRoute path="/rules/pipeline" component={ModulePipeline} />
        <MainPrivateRoute path="/rules/jobs" component={ModuleJobs} />
        <MainPrivateRoute
          path="/rules/recruitment"
          component={ModuleRecruitment}
        />
        <MainPrivateRoute path="/rules/ads" component={ModuleAds} />
        <MainPrivateRoute path="/rules/partners" component={ModulePartners} />

        {/* End */}

        {/* Start DK Global Finance Pages */}

        {/* Start Dk Global Finance Accounting pages */}
        <PrivateRoute
          path="/dkglobalfinance/accounting/dashboard"
          component={AccountingDashboard}
        />
        <PrivateRoute
          path="/dkglobalfinance/accounting/income/summary"
          component={ExpIncomeSummary}
        />
        <PrivateRoute
          path="/dkglobalfinance/accounting/income/2023"
          component={ExpIncome2023}
        />
        <PrivateRoute
          path="/dkglobalfinance/accounting/income/2022"
          component={ExpIncomeTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/accounting/expense/summary"
          component={LegalRegistration}
        />
        <PrivateRoute
          path="/dkglobalfinance/accounting/profit-loss/summary"
          component={PLSummary}
        />
        {/* End */}

        {/* Start Dk Global Finance Payroll pages */}

        <PrivateRoute
          path="/dkglobalfinance/payroll/dashboard"
          component={FinancePayrollDashboard}
        />

        {/* End */}
        {/* Start DK Global Finance Clients Invoices Page */}
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/dashboard"
          component={ClientsinovoicesSummary}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2025"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2024"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2023"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2022"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2021"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2020"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2019"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2018"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2017"
          component={ClientsinvoicesTwentytwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/clients-invoices/2016"
          component={ClientsinvoicesTwentytwo}
        />
        {/* End  DK Global Finance Clients Invoices Page */}
        {/* Start Dk Global Finance Vat Files pages */}

        {/* <PrivateRoute path="/dkglobalfinance/vatfiles/summary" component={FinanceVatFilesSummary} /> */}
        {/* <PrivateRoute path="/dkglobalfinance/taxreturn/corporatetax/2022" component={TaxReturnCorporateTax} /> */}

        {/* End */}
        {/* Start Dk Global Finance Tax Returns  pages */}
        <PrivateRoute
          path="/dkglobalfinance/tax-returns/summary"
          component={FinanceVatFilesSummary}
        />
        <PrivateRoute
          path="/dkglobalfinance/tax-returns/corporatetax/2022"
          component={TaxReturnCorporateTax}
        />
        {/* End Page For Tax Returns  */}
        {/* Start Dk Global Finance Banking pages */}

        <PrivateRoute
          path="/dkglobalfinance/banking/dashboard"
          component={FinanceBankingSummary}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/overview"
          component={BankingStatements}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2022"
          component={StatementTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2021"
          component={StatementTwentyOne}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2020"
          component={StatementTwentyTwenty}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2019"
          component={StatementTwentyNinteen}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2018"
          component={StatementTwentyEighteen}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2017"
          component={StatementTwentySeventeen}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/statements/2016"
          component={StatementTwentySixteen}
        />

        <PrivateRoute
          path="/dkglobalfinance/banking/2023/"
          component={BankingTwentyThree}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2024/"
          component={BankingTwentyFour}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2025/"
          component={BankingTwentyFive}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2022/"
          component={BankingTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2021/"
          component={BankingTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2020/"
          component={BankingTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2019/"
          component={BankingTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2018/"
          component={BankingTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2017/"
          component={BankingTwentyTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/banking/2016/"
          component={BankingTwentyTwo}
        />

        {/* End */}

        {/* Start Dk Global Finance Cashflow pages */}

        <PrivateRoute
          path="/dkglobalfinance/cashflow/dashboard"
          component={FinanceCashFlowSummary}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2025"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2024"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2023"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2022"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2021"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2020"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2019"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2018"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2017"
          component={CashFlowComponent}
        />
        <PrivateRoute
          path="/dkglobalfinance/cashflow/2016"
          component={CashFlowComponent}
        />

        {/* End */}

        {/* Start Dk Global Finance Business Overview pages */}

        <PrivateRoute
          path="/accounting/businessoverview/summary"
          component={FinanceBusinessOverviewDashboard}
        />
        <PrivateRoute
          path="/accounting/businessoverview/dashboard"
          component={BusinessOverviewSummary}
        />
        <PrivateRoute
          path="/business-overview/dashboard"
          component={BusinessOverviewSummary}
        />
        <PrivateRoute
          path="/accounting/businessoverview/2022/summary"
          component={BusinessTwentytwosuMM}
        />
        <PrivateRoute
          path="/business-overview/2022/summary"
          component={BusinessTwentytwosuMM}
        />
        <PrivateRoute
          path="/accounting/businessoverview/interviews/2023"
          component={YearTwentyTwo}
        />
        <PrivateRoute
          path="/accounting/businessoverview/job-offers/2023"
          component={businessBookJobOffer}
        />
        <PrivateRoute
          path="/accounting/businessoverview/joined/2023"
          component={businessBookJoined}
        />
        <PrivateRoute
          path="/accounting/businessoverview/final-fee/2023"
          component={businessFinalFee}
        />
        <PrivateRoute
          path="/accounting/businessoverview/qualified/2023"
          component={businessQualified}
        />

        <PrivateRoute
          path="/business-overview/interviews/2023"
          component={YearTwentyTwo}
        />
        <PrivateRoute
          path="/business-overview/job-offers/2023"
          component={businessBookJobOffer}
        />
        <PrivateRoute
          path="/business-overview/joined/2023"
          component={businessBookJoined}
        />
        <PrivateRoute
          path="/business-overview/final-fee/2023"
          component={businessFinalFee}
        />
        <PrivateRoute
          path="/business-overview/qualified/2023"
          component={businessQualified}
        />
        <PrivateRoute
          path="/business-overview/newcase/2023"
          component={BusinessNewCase}
        />
        <PrivateRoute
          path="/business-overview/selected/2023"
          component={BusinessSelected}
        />
        <PrivateRoute
          path="/business-overview/sendouts/2023"
          component={BusinessSebdouts}
        />
        <PrivateRoute
          path="/accounting/businessoverview/interviews/2022"
          component={YearTwentyTwo}
        />
        <PrivateRoute
          path="/accounting/businessoverview/job-offers/2022"
          component={businessBookJobOffer}
        />
        <PrivateRoute
          path="/accounting/businessoverview/joined/2022"
          component={businessBookJoined}
        />
        <PrivateRoute
          path="/accounting/businessoverview/final-fee/2022"
          component={businessFinalFee}
        />
        <PrivateRoute
          path="/accounting/businessoverview/qualified/2022"
          component={businessQualified}
        />

        <PrivateRoute
          path="/business-overview/interviews/2022"
          component={YearTwentyTwo}
        />
        <PrivateRoute
          path="/business-overview/job-offers/2022"
          component={businessBookJobOffer}
        />
        <PrivateRoute
          path="/business-overview/joined/2022"
          component={businessBookJoined}
        />
        <PrivateRoute
          path="/business-overview/final-fee/2022"
          component={businessFinalFee}
        />
        <PrivateRoute
          path="/business-overview/qualified/2022"
          component={businessQualified}
        />

        {/* <PrivateRoute path="/acccounting/business-book/2023" component={YearTwentyThree} /> */}

        <PrivateRoute
          path="/accounting/businessoverview/2023/job-offers"
          component={businessBookJobOffer}
        />
        {/* End */}
        {/* Start Dk Global Finance Business Income pages  */}
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/dashboard"
          component={FinanceIncomeDashboard}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/summary"
          component={BibbyFinancialSummary}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/statement"
          component={BibbyFinancialStatement}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/open-inv"
          component={BibbyFinancialOpenInv}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/submitted"
          component={BibbyFinancialSubmited}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/bibby-fee"
          component={BibbyFinancialBibbyFee}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/debtors"
          component={BibbyFinancialDebetors}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/contract"
          component={BibbyFinancialContract}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/overview"
          component={BibbyFinancialTermsStepOne}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-01"
          component={BibbyFinancialTerms}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-2"
          component={BibbyFinancialTermsStepTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-3"
          component={BibbyFinancialTermsStepThree}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-4"
          component={BibbyFinancialTermsStepFour}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-5"
          component={BibbyFinancialTermsStepFive}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-6"
          component={BibbyFinancialTermsStepSix}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-7"
          component={BibbyFinancialTermsStepSeven}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-8"
          component={BibbyFinancialTermsStepEight}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-9"
          component={BibbyFinancialTermsStepNine}
        />
        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/terms/step-10"
          component={BibbyFinancialTermsStepTen}
        />

        <PrivateRoute
          path="/dkglobalfinance/bibby-financial/faq"
          component={BibbyFinancialFAQ}
        />
        {/* End */}

        {/* Start Dk Global Finance Business Income pages  */}
        <PrivateRoute
          path="/accounting/payments/summary"
          component={PayTasksSummary}
        />
        <PrivateRoute
          path="/accounting/payments/archived"
          component={paymentsArchived}
        />
        <PrivateRoute
          path="/accounting/payments/to-pay"
          component={TasksPayments}
        />
        <PrivateRoute
          path="/accounting/payments/paid"
          component={TasksPaidPayments}
        />
        <PrivateRoute
          path="/accounting/payments/achieved"
          component={BibbyFinancialOpenInv}
        />
        <PrivateRoute
          path="/accounting/payments/submitted"
          component={BibbyFinancialSubmited}
        />
        <PrivateRoute
          path="/accounting/payments/bibby-fee"
          component={BibbyFinancialBibbyFee}
        />
        <PrivateRoute
          path="/accounting/payments/debtors"
          component={BibbyFinancialDebetors}
        />
        <PrivateRoute
          path="/accounting/payments/contract"
          component={BibbyFinancialContract}
        />
        <PrivateRoute
          path="/accounting/payments/terms/overview"
          component={BibbyFinancialTermsStepOne}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-01"
          component={BibbyFinancialTerms}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-2"
          component={BibbyFinancialTermsStepTwo}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-3"
          component={BibbyFinancialTermsStepThree}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-4"
          component={BibbyFinancialTermsStepFour}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-5"
          component={BibbyFinancialTermsStepFive}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-6"
          component={BibbyFinancialTermsStepSix}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-7"
          component={BibbyFinancialTermsStepSeven}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-8"
          component={BibbyFinancialTermsStepEight}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-9"
          component={BibbyFinancialTermsStepNine}
        />
        <PrivateRoute
          path="/accounting/payments/terms/step-10"
          component={BibbyFinancialTermsStepTen}
        />

        <PrivateRoute
          path="/accounting/payments/faq"
          component={BibbyFinancialFAQ}
        />
        {/* End */}

        <PrivateRoute
          path="/dkglobalfinance/invoices/dashboard"
          component={FinanceExpenseDashboard}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/saved"
          component={FinanceExpenseSavedInvoices}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/overview"
          component={FinanceProcessOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-01"
          component={FinanceProcessStepOne}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-2"
          component={FinanceProcessStepTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-3"
          component={FinanceProcessStepThree}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-4"
          component={FinanceProcessStepFour}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-5"
          component={FinanceProcessStepFive}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-6"
          component={FinanceProcessStepSix}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-7"
          component={FinanceProcessStepSeven}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-8"
          component={FinanceProcessStepEight}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-9"
          component={FinanceProcessStepNine}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/process/step-10"
          component={FinanceProcessStepTen}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/overview"
          component={FinanceClientsOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-01"
          component={FinanceClientsStepOne}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-2"
          component={FinanceClientsStepTwo}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-3"
          component={FinanceClientsStepThree}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-4"
          component={FinanceClientsStepFour}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-5"
          component={FinanceClientsStepFive}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-6"
          component={FinanceClientsStepSix}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-7"
          component={FinanceClientsStepSeven}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-8"
          component={FinanceClientsStepEight}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-9"
          component={FinanceClientsStepNine}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/clients/step-10"
          component={FinanceClientsStepTen}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/job-board/overview"
          component={FinanceJobBoardOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/job-board/step-1"
          component={FinanceJobBoardStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/it-services/overview"
          component={FinanceItServicesOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/it-services/step-1"
          component={FinanceItServicesStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/software/overview"
          component={FinanceSoftwareOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/software/step-1"
          component={FinanceSoftwareStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/call-sms/overview"
          component={FinanceCallSmsOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/call-sms/step-1"
          component={FinanceCallSmsStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/accountant/overview"
          component={FinanceAccountantOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/accountant/step-1"
          component={FinanceAccountantStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/dk-staff/overview"
          component={FinanceDkStaffOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/dk-staff/step-1"
          component={FinanceDkStaffStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/dkglobal/overview"
          component={FinanceDkGlobalOverview}
        />
        <PrivateRoute
          path="/dkglobalfinance/invoices/dkglobal/step-1"
          component={FinanceDkGlobalStepOne}
        />

        <PrivateRoute
          path="/dkglobalfinance/invoices/alerts/my-tasks"
          component={FinanceAlerts}
        />

        {/* End */}

        {/* End */}

        {/* Start Dk Global Finance Business Profit & Loss pages */}

        <PrivateRoute
          path="/dkglobalfinance/profitloss/dashboard"
          component={FinanceProfitLossDashboard}
        />

        {/* End */}

        {/* Start Dk Global Finance Business Contracts pages */}

        <PrivateRoute
          path="/dkglobalfinance/acc-contracts/summary"
          component={FinanceContractsDashboard}
        />
        <PrivateRoute
          path="/dkglobalfinance/acc-contracts/dkgstaff/2022"
          component={ContractsDkgStaff}
        />

        {/* End */}

        {/* Start Dk Global Finance Business Knowleadge Base pages */}

        <PrivateRoute
          path="/dkglobalfinance/knowledgebase/dashboard"
          component={FinanceAccountingFaq}
        />

        {/* End */}

        {/* Start Dk Global Finance Business Legal pages */}

        <PrivateRoute
          path="/dkglobalfinance/legal/dashboard"
          component={FinanceLegalDashboard}
        />
        <PrivateRoute
          path="/dkglobalfinance/legal/registration/2022"
          component={ExpenseSummary}
        />

        {/* End */}

        {/* End DK Global Finance Module */}

        {/* Start DK Global Admin Module */}

        {/* Start Dk Global Admin Resource pages */}

        <PrivateRoute
          path="/dkglobaladmin/resource/summary"
          component={AdminResourceSummary}
        />

        {/* End */}
        {/* Start Module For Marketing Budget   */}
        {/* <PrivateRoute path="/budget/summary" component={MarketBudgetSummary}  /> */}
        <PrivateRoute path="/budget/summary" component={BudgetResource} />
        <PrivateRoute
          path="/budget/performance"
          component={BudgetPerformance}
        />
        <PrivateRoute
          path="/live-ads/summary"
          component={MarketLiveAdsSummary}
        />
        <PrivateRoute
          path="/saved-ads/summary"
          component={MarketSavedAdsSummary}
        />
        <PrivateRoute path="/saved-ads/ads-plan" component={AdvertiseAdsPlan} />
        <PrivateRoute
          path="/saved-ads/policies"
          component={AdvertiseSavedAds}
        />
        <PrivateRoute
          path="/sample-ads/summary"
          component={MarketSampleAdsSummary}
        />
        <PrivateRoute
          path="/ads-tracker/summary"
          component={MarketAdsTrackertSummary}
        />
        <PrivateRoute
          path="/banners/summary"
          component={MarketbannersSummary}
        />
        {/* <PrivateRoute path="/campagins/ads-summary" component={MarketCampaignsSummary} /> */}
        <PrivateRoute
          path="/job-boards/summary"
          component={MarketJobboardsSummary}
        />
        <PrivateRoute
          path="/knowleadge-base/summary"
          component={MarketknowledgeSummary}
        />
        {/* End Module For Marketing Budget */}
        {/* Start Dk Global Admin Personal Relations pages */}

        <PrivateRoute
          path="/dkglobaladmin/pr/dashboard"
          component={AdminPrDashboard}
        />

        {/* End */}

        {/* Start Dk Global Admin Marketing pages */}

        <PrivateRoute
          path="/dkglobaladmin/marketing/dashboard"
          component={AdminMarketingDashboard}
        />

        {/* End */}

        {/* Start Dk Global Admin Clients pages */}

        <PrivateRoute
          path="/dkglobaladmin/clients/dashboard"
          component={AdminClientsDashboard}
        />

        {/* End */}

        {/* Start Dk Global Admin Business Develop pages  */}

        {/* <PrivateRoute path="/dkglobaladmin/bd/dashboard" component={AdminBDDashboard} /> */}
        <PrivateRoute
          path="/newbusinesss/newleads/summary"
          component={NewBusinessNLeads}
        />
        {/* End */}

        {/* Legal Module Start */}

        <MainPrivateRoute
          path="/legalmodule/legal-module"
          component={LegalModule}
        />
        <PrivateRoute
          path="/legalmodule/debt/dashboard"
          component={LegalDebtDashboard}
        />
        <PrivateRoute
          path="/legalmodule/legal-director/dashboard"
          component={LegalDirectorDashboard}
        />

        {/* End */}

        {/* Start Dk Global Admin HR pages */}

        <PrivateRoute
          path="/dkglobaladmin/onboarding/dashboard"
          component={Hronboardingdashboard}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/joboffer/overview"
          component={HrJobOffer}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/contracts/overview"
          component={HrContracts}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/documents/overview"
          component={HrDocuments}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/refrences/overview"
          component={HrRefrences}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/work-station/overview"
          component={HrWorkStations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/work-station/overview"
          component={HrWorkStations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/tech-check/overview"
          component={HrTechCheck}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/accounts/overview"
          component={HrAccounts}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/instructions/overview"
          component={HrInstructions}
        />
        {/* End */}

        {/* Start Dk Global Admin My Files pages */}

        <PrivateRoute
          path="/dkglobaladmin/myfiles/dashboard"
          component={AdminMyFilesDashboard}
        />

        {/* End */}

        {/* Start Dk Global Admin Management pages */}

        <PrivateRoute
          path="/dkglobaladmin/adminmanagement/dashboard"
          component={AdminManagementDashboard}
        />

        {/* End */}

        {/* Start Dk Global Admin Knowledge Base pages */}

        <PrivateRoute
          path="/dkglobaladmin/admin-knowledgebase/dashboard"
          component={AdminKnowledgeBaseDashboard}
        />

        {/* End */}

        {/* End DK Global Module */}

        {/* <MainPrivateRoute path="/workplan/1" component={AllModules} /> */}
        {/* Start Module For MailBox */}
        <MainPrivateRoute
          path="/mailbox/report"
          component={JobsMailboxReport}
        />
        <MainPrivateRoute
          path="/mailbox/validation"
          component={JobsMailboxValidation}
        />
        <MainPrivateRoute
          path="/mailbox/mailshot"
          component={JobsMailboxMailshot}
        />
        <MainPrivateRoute
          path="/mailbox/templates"
          component={JobsMailboxTemplate}
        />
        {/*<MainPrivateRoute path="/mailbox/sent" component={MainMailBoxSent} />*/}
        {/*<MainPrivateRoute path="/mailbox/spam" component={MainMailBoxSpam} />*/}
        {/*<MainPrivateRoute path="/mailbox/draft" component={MainMailBoxDraft} />*/}
        {/*<MainPrivateRoute path="/mailbox/trash" component={MainMailBoxTrash} />*/}
        {/*<MainPrivateRoute path="/mailbox/archive" component={MainMailBoxArchive} />*/}
        <MainPrivateRoute
          path="/mailbox/mail/:routeType"
          component={MainMailBox}
        />
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        {/* user_type: job, services, support, privacy 
            routeType: inbox, send,archive, trash
        */}
        <MainPrivateRoute
          path="/mailbox/system-user/:user/:routeType"
          component={SystemUserMailBox}
        />

        <MainPrivateRoute
          path="/cand-mailbox/mail/inbox-new"
          component={CandidateMailbox}
        />

        {/* Start Module For Payments Task */}
        <MainPrivateRoute
          path="/paytasks/summary"
          component={PaymentTasksSummary}
        />
        <MainPrivateRoute
          path="/paytasks/to-pay"
          component={PaymentsTasksTopay}
        />
        <MainPrivateRoute path="/paytasks/paid" component={PaymentsTasksPaid} />
        <MainPrivateRoute
          path="/paytasks/archived"
          component={PaymentsTasksArchived}
        />
        {/* End Module For Payments Task */}
        {/* End Module For Mailbox */}
        {/* Start Module For Tasks */}
        <MainPrivateRoute path="/tasks/summary" component={TasksSummary} />
        <MainPrivateRoute path="/tasks/to-do" component={TasksToDo} />
        <MainPrivateRoute
          path="/tasks/in-progress"
          component={TasksInprogress}
        />
        <MainPrivateRoute path="/tasks/completed" component={TasksCompleted} />
        <MainPrivateRoute path="/tasks/archived" component={TasksArchived} />
        <MainPrivateRoute path="/paytasks/payment" component={TasksPayments} />
        <MainPrivateRoute path="/paytasks/paid" component={TasksPaidPayments} />
        <MainPrivateRoute
          path="/paytasks/summary"
          component={PayTasksSummary}
        />

        {/* End Module For Tasks  */}

        {/* Start Module For Pipelines */}
        <MainPrivateRoute
          path="/pipelines/summary"
          component={CandidateSummary}
        />
        <MainPrivateRoute
          path="/pipelines/all"
          component={CandidatePipelinesTableAll}
        />
        <MainPrivateRoute
          path="/pipelines/new-cv"
          component={CandidatePipelinesTableNewCvs}
        />
        <MainPrivateRoute
          path="/pipelines/job-spec"
          component={CandidatePipelinesTableJobSpec}
        />
        <MainPrivateRoute
          path="/pipelines/calling"
          component={CandidatePipelinesTableCalling}
        />
        <MainPrivateRoute
          path="/pipelines/interested"
          component={CandidatePipelinesTableIntrested}
        />
        <MainPrivateRoute
          path="/pipelines/sendouts"
          component={CandidatePipelinesTableSendouts}
        />
        <MainPrivateRoute
          path="/pipelines/job-offer"
          component={CandidatePipelinesTableJobOffer}
        />
        <MainPrivateRoute
          path="/pipelines/on-hold"
          component={CandidatePipelinesTableOnHold}
        />
        <MainPrivateRoute
          path="/pipelines/rec-admin"
          component={CandidatePipelinesTableRecAdmin}
        />
        <MainPrivateRoute
          path="/pipelines/closed"
          component={CandidatePipelinesTableClosed}
        />
        <MainPrivateRoute
          path="/pipelines/db-ready"
          component={CandidatePipelinesTableDBReady}
        />
        {/* End Module For Pipelines */}
        {/* Start Module For Job Apps */}
        <MainPrivateRoute path="/job-apps/summary" component={JobappSummary} />
        <MainPrivateRoute
          path="/job-apps/:id"
          component={JobappSavedPdfTable}
        />
        {/* End Module For Job Apps */}
        {/* Module Sendouts Start */}
        <MainPrivateRoute path="/reports/summary" component={SendoutsSummary} />
        <MainPrivateRoute
          path="/kpis/reports/reports-job-offer"
          component={ReportJobOffer}
        />
        <MainPrivateRoute
          path="/kpis/reports/reports-refused"
          component={ReportRefused}
        />
        <MainPrivateRoute
          path="/kpis/reports/reports-joined"
          component={ReportJoined}
        />
        <MainPrivateRoute
          path="/kpis/reports/reports-credit"
          component={ReportCredit}
        />
        {/* Module Sendouts End */}
        {/*Start Module For Recuiters KPI's  */}
        <MainPrivateRoute
          path="/kpis/kpi-recruiters/summary"
          component={KpireqSummary}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/clients"
          component={RecuitersClients}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/summary"
          component={RecuitersSummary}
        />
        <MainPrivateRoute path="/kpis/operation-m" component={OperationM} />
        <MainPrivateRoute path="/kpis/accounts-m" component={AccountsM} />
        <MainPrivateRoute path="/kpis/advertise-m" component={AdvertiseM} />
        <MainPrivateRoute path="/kpis/accounting-m" component={AccountingM} />
        <MainPrivateRoute path="/kpis/dkg-admins" component={DkgAdmins} />
        <MainPrivateRoute path="/kpis/it-teams" component={ItTeams} />
        <MainPrivateRoute
          path="/kpis/recruiters/sendouts"
          component={RecuitersendoutsKpi}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/reports-assigned"
          component={ReportNewCase}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/reports-qualified"
          component={ReportQualified}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/reports-job-specs"
          component={ReportJobSpecs}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/job-offers"
          component={RecuitersJoboffersKpi}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/refused"
          component={RecuitersRefeusedKpi}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/joined"
          component={RecuitersJoineddKpi}
        />
        <MainPrivateRoute
          path="/kpis/recruiters/credit"
          component={RecuitersCreditdKpi}
        />
        {/* End Module For Recuiters KPI's */}
        {/* Start Module For Targets KPI's  */}
        <MainPrivateRoute path="/kpis/summary" component={MainTragetSummary} />
        <MainPrivateRoute path="/kpis/clients-kpi" component={ClientKpi} />
        <MainPrivateRoute path="/kpis/clients-sales-kpi" component={SalestargetKpi} />
        <MainPrivateRoute path="/kpis/recuiters-sale" component={SalesKpi} />
        <MainPrivateRoute path="/kpis/sendouts" component={SalesSummary} />
        <MainPrivateRoute path="/kpis/rc-sendouts" component={RCSendouts} />
        <MainPrivateRoute path="/kpis/performance" component={RcPerformance} />
        <MainPrivateRoute path="/kpis/sendout" component={RcSendouts} />
        <MainPrivateRoute path="/kpis/placements" component={RcPlacements} />
        <MainPrivateRoute
          path="/kpis/recruiter-new"
          component={KpiRecruiters}
        />
        {/* End Module For Targets Kpi's */}
        {/* Start Module For Workplan */}
        <MainPrivateRoute path="/workplan" component={WorkplanModule} />
        {/* End Module For Workplan */}
        {/* Start  For Day Closing */}
        <MainPrivateRoute path="/closure/moods" component={DayClsoingMoods} />
        <MainPrivateRoute
          path="/closure/moods-2"
          component={DayClsoingMoods2}
        />
        <MainPrivateRoute
          path="/closure/moods-3"
          component={DayClsoingMoods3}
        />
        <MainPrivateRoute
          path="/closure/moods-4"
          component={DayClsoingMoods4}
        />
        <MainPrivateRoute
          path="/closure/moods-5"
          component={DayClsoingMoods5}
        />
        {/* End Module For Day Closing */}

        {/* Start Module For Notifications */}
        <MainPrivateRoute
          path="/notifications/summary"
          component={MainNotificationSummary}
        />

        <MainPrivateRoute
          path="/notifications/today/my-tasks"
          component={TodayMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/today/new-case"
          component={TodayNewCase}
        />
        <MainPrivateRoute
          path="/notifications/today/comments"
          component={TodayComments}
        />
        <MainPrivateRoute
          path="/notifications/today/new-jobs"
          component={TodayNewJobs}
        />
        <MainPrivateRoute
          path="/notifications/today/live-jobs"
          component={TodayLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/today/ads-post"
          component={TodayAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/today/resources"
          component={TodayResources}
        />
        <MainPrivateRoute
          path="/notifications/today/payments"
          component={TodayPayments}
        />
        <MainPrivateRoute
          path="/notifications/today/sales-kpi"
          component={TodaySalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/today/clients"
          component={TodayClients}
        />
        <MainPrivateRoute
          path="/notifications/today/dk-global"
          component={TodayDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/onedayago/my-tasks"
          component={OneDayAgoMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/new-case"
          component={OneDayAgoNewCase}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/comments"
          component={OneDayAgoComments}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/new-jobs"
          component={OneDayAgoNewJobs}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/live-jobs"
          component={OneDayAgoLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/Ads-post"
          component={OneDayAgoAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/resources"
          component={OneDayAgoResources}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/payments"
          component={OneDayAgoPayments}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/sales-kpi"
          component={OneDayAgoSalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/clients"
          component={OneDayAgoClients}
        />
        <MainPrivateRoute
          path="/notifications/onedayago/dk-global"
          component={OneDayAgoDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/twodayago/my-tasks"
          component={TwoDayAgoMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/new-case"
          component={TwoDayAgoNewCase}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/comments"
          component={TwoDayAgoComments}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/new-jobs"
          component={TwoDayAgoNewjobs}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/live-jobs"
          component={TwoDayAgoLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/ads-post"
          component={TwoDayAgoAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/resources"
          component={TwoDayAgoResources}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/payments"
          component={TwoDayAgoPayments}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/sales-kpi"
          component={TwoDayAgoSalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/clients"
          component={TwoDayAgoClients}
        />
        <MainPrivateRoute
          path="/notifications/twodayago/dk-global"
          component={TwoDayAgoDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/threedayago/my-tasks"
          component={ThreeDayAgoMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/new-case"
          component={ThreeDayAgoNewCase}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/comments"
          component={ThreeDayAgoComments}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/new-jobs"
          component={ThreeDayAgoNewJobs}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/live-jobs"
          component={ThreeDayAgoLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/ads-post"
          component={ThreeDayAgoAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/resources"
          component={ThreeDayAgoResources}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/payments"
          component={ThreeDayAgoPayments}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/sales-kpi"
          component={ThreeDayAgoSalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/clients"
          component={ThreeDayAgoClients}
        />
        <MainPrivateRoute
          path="/notifications/threedayago/dk-global"
          component={ThreeDayAgoDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/fourdayago/my-tasks"
          component={FourDayAgoMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/new-case"
          component={FourDayAgoNewCase}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/comments"
          component={FourDayAgoComments}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/new-jobs"
          component={FourDayAgoNewJobs}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/live-jobs"
          component={FourDayAgoLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/ads-post"
          component={FourDayAgoAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/resources"
          component={FourDayAgoResources}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/payments"
          component={FourDayAgoPayments}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/sales-kpi"
          component={FourDayAgoSalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/clients"
          component={FourDayAgoClients}
        />
        <MainPrivateRoute
          path="/notifications/fourdayago/dk-global"
          component={FourDayAgoDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/fivedayago/my-tasks"
          component={FiveDayAgoMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/new-case"
          component={FiveDayAgoNewCase}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/comments"
          component={FiveDayAgoComments}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/new-jobs"
          component={FiveDayAgoNewJobs}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/live-jobs"
          component={FiveDayAgoLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/ads-post"
          component={FiveDayAgoAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/resources"
          component={FiveDayAgoResources}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/payments"
          component={FiveDayAgoPayments}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/sales-kpi"
          component={FiveDayAgoSalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/clients"
          component={FiveDayAgoClients}
        />
        <MainPrivateRoute
          path="/notifications/fivedayago/dk-global"
          component={FiveDayAgoDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/archived/my-tasks"
          component={ArchivedMyTasks}
        />
        <MainPrivateRoute
          path="/notifications/archived/new-case"
          component={ArchivedNewCase}
        />
        <MainPrivateRoute
          path="/notifications/archived/comments"
          component={ArchivedComments}
        />
        <MainPrivateRoute
          path="/notifications/archived/new-jobs"
          component={ArchivedNewJobs}
        />
        <MainPrivateRoute
          path="/notifications/archived/live-jobs"
          component={ArchivedLiveJobs}
        />
        <MainPrivateRoute
          path="/notifications/archived/ads-post"
          component={ArchivedAdsPost}
        />
        <MainPrivateRoute
          path="/notifications/archived/resources"
          component={ArchivedResources}
        />
        <MainPrivateRoute
          path="/notifications/archived/payments"
          component={ArchivedPayments}
        />
        <MainPrivateRoute
          path="/notifications/archived/sales-kpi"
          component={ArchivedSalesKpi}
        />
        <MainPrivateRoute
          path="/notifications/archived/clients"
          component={ArchivedClients}
        />
        <MainPrivateRoute
          path="/notifications/archived/dk-global"
          component={ArchivedDkGlobal}
        />

        <MainPrivateRoute
          path="/notifications/add-new"
          component={MainNotificationAddNew}
        />
        {/* End Module For Notifications */}

        {/* Resource Departments Start */}

        <PrivateRoute
          path="/resource/resource-hr/dashboard"
          component={ResourceDashboard}
        />

        {/* End */}

        {/* Start Module For DK Global */}

        <PrivateRoute
          path="/dkg-global/company/teams/summary"
          component={DkGlobalTeamSummary}
        />
        <PrivateRoute
          path="/dkg-global/company/teams/team-1"
          component={DkGlobalTeamOne}
        />
        <PrivateRoute
          path="/dkg-global/company/teams/team-2"
          component={DkGlobalTeamTwo}
        />
        <PrivateRoute
          path="/dkg-global/company/teams/team-3"
          component={DkGlobalReminder}
        />
        <PrivateRoute
          path="/dkg-global/company/teams/team-4"
          component={DkGlobalTeamThree}
        />
        <PrivateRoute
          path="/dkg-global/company/teams/team-5"
          component={DkGlobalTeamFour}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/dkclients/overview"
          component={DkGlobalClients}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/suppliers/overview"
          component={DkGlobalSuppliers}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/candidates/overview"
          component={DkGlobalCandidates}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/dkg-crm/overview"
          component={DkGlobalDkgCrm}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/teams/edit-teams"
          component={DkGlobalEditProcess}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/teams"
          component={DkGlobalProcess}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/process/edit-process"
          component={EditDkGlobalPipelines}
        />
        <PrivateRoute
          path="/commontraining/dkglobal/pipelines"
          component={DkGlobalPipelines}
        />
        {/* End */}

        <PrivateRoute
          path="/my-mailbox/summary"
          component={AnnouncementSummary}
        />

        <PrivateRoute
          path="/training/get-started/overview"
          component={MainModuleTrainingGetStarted}
        />
        <PrivateRoute
          path="/training/get-started/stpe-1"
          component={MainModuleTrainingGetStartedtepOne}
        />
        <PrivateRoute
          path="/training/get-started/stpe-2"
          component={MainModuleTrainingGetStartedtepTwo}
        />
        <PrivateRoute
          path="/training/get-started/stpe-3"
          component={MainModuleTrainingGetStartedtepThree}
        />
        <PrivateRoute
          path="/training/get-started/stpe-4"
          component={MainModuleTrainingGetStartedtepFour}
        />
        <PrivateRoute
          path="/training/get-started/stpe-5"
          component={MainModuleTrainingGetStartedtepFive}
        />
        <PrivateRoute
          path="/training/get-started/stpe-6"
          component={MainModuleTrainingGetStartedtepSix}
        />
        <PrivateRoute
          path="/training/get-started/stpe-7"
          component={MainModuleTrainingGetStartedtepSeven}
        />
        <PrivateRoute
          path="/training/get-started/stpe-8"
          component={MainModuleTrainingGetStartedtepEight}
        />

        <PrivateRoute
          path="/training/work-plan/sample-1"
          component={MainModuleTrainingWorkplanSampleOne}
        />
        <PrivateRoute
          path="/training/work-plan/sample-2"
          component={MainModuleTrainingWorkplanSampleTwo}
        />
        <PrivateRoute
          path="/training/work-plan/sample-3"
          component={MainModuleTrainingWorkplanSampleThree}
        />

        <PrivateRoute
          path="/training/live-jobs/sample-1"
          component={MainModuleTrainingLiveJobsOne}
        />
        <PrivateRoute
          path="/training/live-jobs/sample-2"
          component={MainModuleTrainingLiveJobsTwo}
        />
        <PrivateRoute
          path="/training/live-jobs/sample-3"
          component={MainModuleTrainingLiveJobsThree}
        />

        <PrivateRoute
          path="/training/job-specs/sample-1"
          component={MainModuleTrainingJobSpecsOne}
        />
        <PrivateRoute
          path="/training/job-specs/sample-2"
          component={MainModuleTrainingJobSpecsTwo}
        />
        <PrivateRoute
          path="/training/job-specs/sample-3"
          component={MainModuleTrainingJobSpecsThree}
        />

        <PrivateRoute
          path="/training/resume/sample-1"
          component={MainModuleTrainingResumeOne}
        />
        <PrivateRoute
          path="/training/resume/sample-2"
          component={MainModuleTrainingResumeTwo}
        />
        <PrivateRoute
          path="/training/resume/sample-3"
          component={MainModuleTrainingResumeThree}
        />

        <PrivateRoute
          path="/training/pipelines/sample-1"
          component={MainModuleTrainingPipelinesOne}
        />
        <PrivateRoute
          path="/training/pipelines/sample-2"
          component={MainModuleTrainingPipelinesTwo}
        />
        <PrivateRoute
          path="/training/pipelines/sample-3"
          component={MainModuleTrainingPipelinesThree}
        />

        <PrivateRoute
          path="/training/sendouts/sample-1"
          component={MainModuleTrainingSendOutsOne}
        />
        <PrivateRoute
          path="/training/sendouts/sample-2"
          component={MainModuleTrainingSendOutsTwo}
        />
        <PrivateRoute
          path="/training/sendouts/sample-3"
          component={MainModuleTrainingSendOutsThree}
        />

        <PrivateRoute
          path="/training/post-ads/sample-1"
          component={MainModuleTrainingPostAdsOne}
        />
        <PrivateRoute
          path="/training/post-ads/sample-2"
          component={MainModuleTrainingPostAdsTwo}
        />
        <PrivateRoute
          path="/training/post-ads/sample-3"
          component={MainModuleTrainingPostAdsThree}
        />

        <PrivateRoute
          path="/training/make-calls/sample-1"
          component={MainModuleTrainingMakeCallsOne}
        />
        <PrivateRoute
          path="/training/make-calls/sample-2"
          component={MainModuleTrainingMakeCallsTwo}
        />
        <PrivateRoute
          path="/training/make-calls/sample-3"
          component={MainModuleTrainingMakeCallsThree}
        />

        <PrivateRoute
          path="/training/work-plan/overview"
          component={MainModuleTrainingWorkPlan}
        />
        <PrivateRoute
          path="/training/live-jobs/overview"
          component={MainModuleTrainingLiveJobs}
        />
        <PrivateRoute
          path="/training/job-specs/overview"
          component={MainModuleTrainingJobSpecs}
        />
        <PrivateRoute
          path="/training/resume/overview"
          component={MainModuleTrainingResume}
        />
        <PrivateRoute
          path="/training/pipelines/overview"
          component={MainModuleTrainingPipelines}
        />
        <PrivateRoute
          path="/training/sendouts/overview"
          component={MainModuleTrainingSendOuts}
        />
        <PrivateRoute
          path="/training/post-ads/overview"
          component={MainModuleTrainingPostAds}
        />
        <PrivateRoute
          path="/training/make-calls/overview"
          component={MainModuleTrainingMakeCalls}
        />
        <MainPrivateRoute path="/summary" component={MainSummary} />
        <MainPrivateRoute path="/moods" component={MainMyMoods} />

        {/* Main Module Tracking Pages */}

        <MainPrivateRoute
          path="/tracking/summary"
          component={TrackingSummary}
        />
        <MainPrivateRoute
          path="/tracking/all-trackings"
          component={TrackingAllTracking}
        />
        <MainPrivateRoute
          path="/tracking/candidates"
          component={TrackingCandidates}
        />
        <MainPrivateRoute
          path="/tracking/clients"
          component={TrackingClients}
        />
        <MainPrivateRoute path="/tracking/jobs" component={TrackingJobs} />
        <MainPrivateRoute path="/tracking/apps" component={TrackingApps} />
        <MainPrivateRoute path="/tracking/ads" component={TrackingAds} />
        <MainPrivateRoute path="/tracking/it" component={TrackingIt} />
        <MainPrivateRoute
          path="/tracking/accounting"
          component={TrackingAccounting}
        />

        {/* End */}

        {/* Start Module For Get Started in Mainmenu */}
        <MainPrivateRoute path="/tracking" component={MainGetStartedSummary} />
        <MainPrivateRoute
          path="/get-started/preparation"
          component={GetStartedPrepsrstion}
        />
        <MainPrivateRoute
          path="/get-started/morning"
          component={GetStartedMoring}
        />
        <MainPrivateRoute
          path="/get-started/meetings"
          component={GetStartedMeetings}
        />
        <MainPrivateRoute
          path="/get-started/workplan"
          component={GetStartedWorkPlan}
        />
        <MainPrivateRoute
          path="/get-started/lunch"
          component={GetStartedLunch}
        />
        <MainPrivateRoute
          path="/get-started/afternoon"
          component={GetStartedAfterNoon}
        />
        <MainPrivateRoute
          path="/get-started/pipelines"
          component={GetStartedPipelines}
        />
        <MainPrivateRoute
          path="/get-started/closing"
          component={GetStartedClosing}
        />
        {/* End Module For Get Started in Mainmenu  */}
        {/* Start Module For Faq in Mainmenu */}
        {/* <MainPrivateRoute path="/faq/dk-global" component={FaqDkGlobal} />
        <MainPrivateRoute path="/faq/kpi-csf" component={FaqKpiCsf} />
        <MainPrivateRoute path="/faq/clients" component={FaqClients} />
        <MainPrivateRoute path="/faq/demands" component={FaqDemands} />
        <MainPrivateRoute path="/faq/contracts" component={FaqContracts} />
        <MainPrivateRoute path="/faq/payments" component={FaqPayments} />
        <MainPrivateRoute path="/faq/job-training" component={FaqJobTraining} />
        <MainPrivateRoute path="/faq/technical-bugs" component={FaqTechnicalBugs} />
        <MainPrivateRoute path="/faq/support" component={FaqSupport} /> */}
        {/* End Modlule For Faq in Mainmenu */}
        {/* End Module For DK Global */}

        <PrivateRoute
          path="/recruitment/dashboard/dkgteam"
          component={DkgTeam}
        />

        <PrivateRoute
          path="/recruitment/dashboard/savings"
          component={Savings}
        />
        <PrivateRoute
          path="/recruitment/dashboard/team-and-roles/edit-team-and-roles"
          component={EditTeamRoles}
        />
        <PrivateRoute
          path="/recruitment/dashboard/team-and-roles"
          component={TeamRoles}
        />
        <PrivateRoute
          path="/recruitment/dashboard/job-details"
          component={JobDetails}
        />
        <PrivateRoute
          path="/recruitment/dashboard/pipelines-management/edit-pipelines-management"
          component={EditPipelinesManagement}
        />
        <PrivateRoute
          path="/recruitment/dashboard/pipelines-management"
          component={PipelinesManagement}
        />
        {/* <PrivateRoute path="/recruitment/dashboard/holidays" component={Holidays} /> */}
        <PrivateRoute
          path="/recruitment/dashboard/live-projects"
          component={LiveProjects}
        />

        {/* Start Module For Notification */}
        <PrivateRoute
          path="/recruitment/notification/summary"
          component={NotiSummary}
        />
        <PrivateRoute
          path="/recruitment/notification/live-jobs"
          component={NotiLiveJobs}
        />
        <PrivateRoute
          path="/recruitment/notification/candidates"
          component={NotiCandidates}
        />
        {/* End Module For Notification */}
        {/* Start Module For Compaines */}
        <MainPrivateRoute path="/clients/summary" component={CompanySummary} />
        <MainPrivateRoute
          path="/clients/clients-list"
          component={CompanyListView}
        />
        <MainPrivateRoute path="/clients/compaines" component={ClientCompany} />
        <MainPrivateRoute path="/clients/projects" component={ClientTemplate} />
        {/* <MainPrivateRoute path="/clients/projects" component={DkgClientProject} /> */}

        <MainPrivateRoute
          path="/clients/role-types"
          component={CandidateQuery}
        />
        <MainPrivateRoute
          path="/clients/demands"
          component={MainClientinfoDemands}
        />
        <MainPrivateRoute
          path="/clients/companies"
          component={DkgClientCompaines}
        />
        <MainPrivateRoute
          path="/clients/salaries"
          component={MainDkgSalaries}
        />
        <MainPrivateRoute
          path="/clients/profiling/wh-athens"
          component={MainModuleCompanyWhAthens}
        />
        <MainPrivateRoute
          path="/clients/benefits"
          component={MainModuleCompanyBenfits}
        />
        <MainPrivateRoute
          path="/clients/language"
          component={MainModuleDkgLanguage}
        />
        <MainPrivateRoute
          path="/clients/portals"
          component={MainModuleDkgPortal}
        />
        <MainPrivateRoute
          path="/clients/feedbacks"
          component={MainModuleDkgFeedbacks}
        />
        <MainPrivateRoute
          path="/clients/interviews"
          component={MainModuleCompanyInterview}
        />
        <MainPrivateRoute
          path="/clients/sendouts"
          component={ClientinfoSendouts}
        />
        <MainPrivateRoute
          path="/clients/detail-view"
          component={CompanyDetailView}
        />
        <MainPrivateRoute
          path="/clients/relocation"
          component={CompanyRelocation}
        />

        {/* <PrivateRoute path="/recruitment/company/summary" component={CompanySummary} />
        <PrivateRoute path="/recruitment/clients-list/list-view" component={CompanyListView} />
        <PrivateRoute path="/recruitment/clients-list/demands" component={MainClientinfoDemands} />
        <PrivateRoute path="/recruitment/clients-list/requirements" component={MainDkgRequirements} />
        <PrivateRoute path="/recruitment/clients-list/salaries" component={MainDkgSalaries} />
        <PrivateRoute path="/client-info/profiling/wh-athens" component={MainModuleCompanyWhAthens} />
        <PrivateRoute path="/recruitment/clients-list/benefits" component={MainModuleCompanyBenfits} />
        <PrivateRoute path="/recruitment/clients-list/portals" component={MainModuleDkgPortal} />
        <PrivateRoute path="/recruitment/clients-list/feedbacks" component={MainModuleDkgFeedbacks} />
        <PrivateRoute path="/recruitment/clients-list/interviews" component={MainModuleCompanyInterview} />
        <PrivateRoute path="/recruitment/clients-list/sendouts" component={ClientinfoSendouts} />
        <PrivateRoute path="/recruitment/clients-list/detail-view" component={CompanyDetailView} /> */}
        <PrivateRoute
          path="/recruitment/clients-list/add-new"
          component={CompanyAddNew}
        />
        <PrivateRoute
          path="/recruitment/company/policies"
          component={CompanyPolicies}
        />
        <PrivateRoute
          path="/recruitment/company/salaries"
          component={CompanySalaries}
        />
        <PrivateRoute
          path="/recruitment/company/income-tax"
          component={CompanyIncometax}
        />
        <PrivateRoute
          path="/recruitment/company/profiling"
          component={CompanyProfiling}
        />
        <PrivateRoute
          path="/recruitment/company/profiling/wh-athens"
          component={CompanyWhAthens}
        />
        <PrivateRoute
          path="/recruitment/company/benefits"
          component={CompanyBenfits}
        />
        {/* <PrivateRoute path="/recruitment/company/relocation" component={CompanyRelocation} /> */}
        <PrivateRoute
          path="/recruitment/company/interviews"
          component={CompanyInterview}
        />
        <PrivateRoute
          path="/recruitment/clients-list/client-details"
          component={CompanyClentsDetails}
        />
        {/* End Module For Compaines */}
        {/* Start Module For Clients Info */}
        <PrivateRoute
          path="/recruitment/client-info/summary"
          component={RecClientinfoSuMM}
        />
        {/* End Module For Clients Info */}

        {/* Start Module For Logins */}
        <PrivateRoute path="/logins/summary" component={LoginsSummary} />
        <PrivateRoute
          path="/logins/resouces-login"
          component={LoginsResource}
        />
        <PrivateRoute path="/logins/office-365" component={Loginsoffice365} />
        <PrivateRoute path="/logins/dkg-crm" component={LoginsDKGCrm} />
        <PrivateRoute path="/logins/job-portals" component={JobPortals} />
        <PrivateRoute
          path="/logins/clients-portal"
          component={LoginsClientsportal}
        />
        <PrivateRoute
          path="/logins/social-media"
          component={LoginsSocailMedia}
        />
        <PrivateRoute path="/logins/email-acc" component={LoginsEmailAcc} />
        <PrivateRoute path="/logins/software" component={LoginsSoftware} />
        <PrivateRoute path="/logins/domains" component={LoginsDomain} />
        <PrivateRoute
          path="/logins/communications"
          component={LoginsCommunications}
        />
        <PrivateRoute path="/logins/others" component={LoginsOthers} />
        {/* End Module For Logins */}
        {/* Start Module For Visainfo */}
        <PrivateRoute
          path="/visa-info/summary"
          component={MainOfferSpecSummary}
        />
        <PrivateRoute
          path="/visa-info/live-spec"
          component={RecuitmentLiveSpec}
        />
        <PrivateRoute
          path="/visa-info/create-spec"
          component={MainOfferAddSpec}
        />
        <PrivateRoute
          path="/visa-info/spec/:id/:id"
          component={MainOfferSavedSpecTable}
        />
        <PrivateRoute path="/visa-info/:id" component={MainOfferSavedSpec} />

        {/* End Module For Visainfo */}
        {/* Start Module For Sales Tips */}
        <PrivateRoute path="/sales-tips/summary" component={SalestipsSummary} />
        {/* End Module For Sales Tips */}
        {/* Start Module For Recruitment Rules */}
        <PrivateRoute
          path="/recruitment-rules/summary"
          component={RecrulesSummary}
        />
        {/* End Module For Recruitment Rules */}
        {/* Start Module For Dkglobal in Datatatable */}
        <PrivateRoute
          path="/db-table/dkglobal/deepak"
          component={DTDKGlobalDeepak}
        />
        {/* End Module For Dkglobal in Datatatable  */}
        <PrivateRoute path="/recruitment/demand" component={RecDemand} />
        {/* Start Module for Jobpsec in Main Menu */}
        <MainPrivateRoute
          path="/job-specs/summary"
          component={MainJobspecSumm}
        />
        {/* End Module for Jobspec in Main Menu */}
        {/* Start Module For Jobs */}
        <MainPrivateRoute path="/jobs/summary" component={JobsSummary} />
        <MainPrivateRoute path="/jobs/live-jobs" component={LiveJobs} />
        <MainPrivateRoute
          path="/jobs/table-live-jobs"
          component={TableLiveJobs}
        />
        <MainPrivateRoute
          path="/jobs/table-inactive-jobs-one"
          component={InactiveJobsFirst}
        />
        <MainPrivateRoute
          path="/jobs/inactive-jobs"
          component={inactiveLiveJobsThree}
        />
        <MainPrivateRoute path="/jobs/closed-one" component={ClosedJobsOne} />
        <MainPrivateRoute path="/jobs/live-jobs-3" component={LiveJobsThree} />
        <MainPrivateRoute path="/jobs/closed-jobs" component={ClosedJobs} />
        <MainPrivateRoute path="/jobs/clj-jobs" component={ClosedclJobs} />
        <MainPrivateRoute path="/jobs/admin/category" component={JobsAdmin} />
        <MainPrivateRoute
          path="/jobs/admin/company"
          component={JobsAdminCompany}
        />
        <MainPrivateRoute path="/jobs/add-new" component={JobsAddNew} />
        <MainPrivateRoute path="/jobs/qualify" component={Qualify} />
        <MainPrivateRoute path="/jobs/dkg-cloud" component={DKGCloud} />
        <MainPrivateRoute path="/jobs/job-board" component={JobBoard} />
        <MainPrivateRoute path="/jobs/demand/summary" component={JobsDemand} />
        <MainPrivateRoute path="/jobs/work-plan" component={JobsWorkplan} />

        <PrivateRoute
          path="/ads-tracker/daily-tracker"
          component={DailyTracker}
        />
        <PrivateRoute
          path="/ads-tracker/weekly-tracker"
          component={WeeklyAppsTracker}
        />
        <PrivateRoute
          path="/ads-tracker/monthly-tracker"
          component={MonthAppsTracker}
        />
        <PrivateRoute
          path="/ads-tracker/yearly-tracker"
          component={YearlyTracker}
        />
        {/* <PrivateRoute path="/recruitment/ads/apps-tracker/daily-tracker" component={DailyTracker} />
        <PrivateRoute path="/ads/apps-tracker/weekly-tracker" component={WeeklyAppsTracker} />
        <PrivateRoute path="/ads/apps-tracker/monthly-tracker" component={MonthAppsTracker} />
        <PrivateRoute path="/ads/apps-tracker/yearly-tracker" component={YearlyTracker} /> */}

        <PrivateRoute path="/recruitment/jobs" component={Jobs} />
        {/* End Module For Jobs */}

        <PrivateRoute
          path="/recruitment/candidate/add-new"
          component={CandidateAddNew}
        />
        <PrivateRoute
          path="/recruitment/candidate/workflow"
          component={WorkplanWorkFlow}
        />
        <PrivateRoute
          path="/recruitment/candidate/workflow-edit"
          component={WorkplanWorkFlowEdit}
        />
        <PrivateRoute
          path="/recruitment/candidate/duplicate"
          component={CandidateDuplicate}
        />
        <PrivateRoute
          path="/recruitment/candidate/backend-values"
          component={CandBackedValues}
        />
        <PrivateRoute
          path="/recruitment/candidate/backend-values-test"
          component={CandBackedValuesTest}
        />
        {/* End Module For Candidate */}

        {/* Start Module For Database */}
        <MainPrivateRoute
          path="/database/datatable"
          component={CandidateDataTable}
        />
        <MainPrivateRoute path="/database/archived" component={DBArchive} />
        <MainPrivateRoute path="/database/summary" component={CandDBSummary} />
        <MainPrivateRoute
          path="/database/search-cvs"
          component={CandDBSearchCvs}
        />
        <MainPrivateRoute
          path="/database/blocked"
          component={RecDatabaseBlocked}
        />
        <MainPrivateRoute
          path="/database/duplicate"
          component={RecruitmentDB}
        />
        <MainPrivateRoute path="/database/deepak" component={RecruitmentDBDK} />
        <MainPrivateRoute
          path="/database/mariana"
          component={RecruitmentMaria}
        />
        <MainPrivateRoute path="/database/filip" component={RecruitmentFilip} />
        <MainPrivateRoute path="/database/elis" component={RecruitmentElis} />
        <MainPrivateRoute
          path="/database/tehreem"
          component={RecruitmentTehreem}
        />
        <MainPrivateRoute
          path="/database/new-hire"
          component={RecruitmentNewHire}
        />
        <MainPrivateRoute
          path="/database/new-hire-2"
          component={RecruitmentNewHireTwo}
        />
        <MainPrivateRoute
          path="/database/dk-global"
          component={RecruitmentDkGlobal}
        />
        {/* End Module For Database */}
        {/* Start Module For KPI */}
        <MainPrivateRoute path="/kpis/kpi-summary" component={KpiSummary} />
        <MainPrivateRoute
          path="/kpis/candidates/dashboard"
          component={KpiDashboard}
        />
        <MainPrivateRoute
          path="/kpis/candidates/job-sepcs"
          component={KpiJobSpecs}
        />
        <MainPrivateRoute
          path="/kpis/candidates/new-case"
          component={KpiNewCase}
        />
        <MainPrivateRoute
          path="/kpis/candidates/sendouts"
          component={KpiSendouts}
        />
        <MainPrivateRoute
          path="/kpis/candidates/interviews"
          component={KpiInterviews}
        />
        <MainPrivateRoute
          path="/kpis/candidates/job-offers"
          component={KpiJobOffer}
        />
        <MainPrivateRoute
          path="/kpis/candidates/joined"
          component={KpiJoined}
        />
        <MainPrivateRoute
          path="/kpis/candidates/refused"
          component={KpiRefused}
        />
        <MainPrivateRoute
          path="/kpis/candidates/invoiced"
          component={KpiInvoiced}
        />
        <MainPrivateRoute
          path="/kpis/candidates/credits"
          component={KpiCredits}
        />

        <MainPrivateRoute path="/kpis/case/week-1" component={KpiCaseWeekOne} />
        <MainPrivateRoute path="/kpis/case/week-2" component={KpiCaseWeekTwo} />
        <MainPrivateRoute
          path="/kpis/case/week-3"
          component={KpiCaseWeekThree}
        />
        <MainPrivateRoute
          path="/kpis/case/week-4"
          component={KpiCaseWeekFour}
        />

        {/* <MainPrivateRoute path="/kpis/clients/backup" component={ClientKpiBackup} />  */}
        <MainPrivateRoute
          path="/kpis/tasks/assigned"
          component={ReportsAssigned}
        />
        <MainPrivateRoute path="/kpis/jobs/summary" component={KpiJobs} />
        <MainPrivateRoute
          path="/kpis/tasks/qualified"
          component={ReportsQualifed}
        />
        <MainPrivateRoute
          path="/kpis/jobspecs/summary"
          component={ModuleKpiJobSepcs}
        />
        <MainPrivateRoute
          path="/kpis/jobspecs/week-1"
          component={JobSpecsWeekOne}
        />
        <MainPrivateRoute
          path="/kpis/jobspecs/week-2"
          component={JobSpecsWeekTwo}
        />
        <MainPrivateRoute
          path="/kpis/jobspecs/week-3"
          component={JobSpecsWeekThree}
        />
        <MainPrivateRoute
          path="/kpis/jobspecs/week-4"
          component={JobSpecsWeekFour}
        />
        <MainPrivateRoute path="/kpis/sales" component={KpiSales} />
        {/* <MainPrivateRoute path="/kpis/sendouts/summary" component={SalesSummary} /> */}
        <MainPrivateRoute
          path="/kpis/sendouts/week-1"
          component={SalesSendoutsWeekOne}
        />
        <MainPrivateRoute
          path="/kpis/sendouts/week-2"
          component={SalesSendoutsWeekTwo}
        />
        <MainPrivateRoute
          path="/kpis/sendouts/week-3"
          component={SalesSendoutsWeekThree}
        />
        <MainPrivateRoute
          path="/kpis/sendouts/week-4"
          component={SalesSendoutsWeekFour}
        />
        <MainPrivateRoute
          path="/kpis/sendouts/stats-report"
          component={KpiSalesSendouts}
        />
        <MainPrivateRoute
          path="/kpis/sendouts/stats-details"
          component={StatsDetails}
        />
        <MainPrivateRoute
          path="/kpis/sendouts/case-details"
          component={CaseDetails}
        />

        <MainPrivateRoute
          path="/kpis/tasks/summary"
          component={TasksStatsSummary}
        />
        <MainPrivateRoute
          path="/kpis/tasks/qualified"
          component={KpiQualified}
        />
        <MainPrivateRoute
          path="/kpis/tasks/job-specs"
          component={ModuleKpiJobSepcs}
        />
        <MainPrivateRoute
          path="/kpis/tasks/stats-report"
          component={TasksStatsReport}
        />
        <MainPrivateRoute
          path="/kpis/tasks/stats-details"
          component={TasksStatsDetails}
        />
        <MainPrivateRoute
          path="/kpis/tasks/case-details"
          component={TasksCaseDetails}
        />

        {/* End Module For KPI */}
        {/* Start Module For Mypipeline */}
        <MainPrivateRoute
          path="/my-pipeline/summary"
          component={RecMypipelinesSumm}
        />
        <MainPrivateRoute
          path="/my-pipeline/new-case"
          component={RecMypipelinesNewCase}
        />
        <MainPrivateRoute
          path="/my-pipeline/job-spec"
          component={RecMypipelinesJobSpec}
        />
        <MainPrivateRoute
          path="/my-pipeline/calling"
          component={RecMypipelinesCalling}
        />
        <MainPrivateRoute
          path="/my-pipeline/intrested"
          component={RecMypipelinesIntrested}
        />
        <MainPrivateRoute
          path="/my-pipeline/sendouts"
          component={RecMypipelinesSendouts}
        />
        <MainPrivateRoute
          path="/my-pipeline/job-offer"
          component={RecMypipelinesjobffer}
        />
        <MainPrivateRoute
          path="/my-pipeline/not-reachable"
          component={RecMypipelinesNotReachable}
        />
        <MainPrivateRoute
          path="/my-pipeline/rec-admin"
          component={RecMypipelinesRecAdmin}
        />
        <MainPrivateRoute
          path="/my-pipeline/closed"
          component={RecMypipelinesClosed}
        />
        <MainPrivateRoute
          path="/my-pipeline/db-ready"
          component={RecMypipelinesDBReady}
        />
        {/* End Module For Mypipeline */}
        {/* Start Client Info */}
        <MainPrivateRoute
          path="/client-info/summary"
          component={MainClientinfoSuMM}
        />
        <MainPrivateRoute
          path="/client-info/policies"
          component={MainModuleCompanyPolicies}
        />
        <MainPrivateRoute
          path="/client-info/profiling"
          component={MainModuleCompanyProfiling}
        />

        {/* End */}
        {/* Start Module For Ads */}
        <PrivateRoute path="/recruitment/ads/summary" component={AdsSummary} />
        <PrivateRoute
          path="/recruitment/ads/templates"
          component={AdsTemplates}
        />
        <PrivateRoute
          path="/recruitment/ads/resources/summary"
          component={AdsResources}
        />
        <PrivateRoute
          path="/recruitment/ads/titles/summary"
          component={AdsTitles}
        />
        <PrivateRoute
          path="/recruitment/ads/saved-ads/summary"
          component={AdsSavedAds}
        />
        <PrivateRoute
          path="/recruitment/ads/ads-credits"
          component={AdsCredits}
        />
        <PrivateRoute path="/ads-plan/today-tracker" component={todaysAds} />
        <PrivateRoute path="/live-ads/live-tracker" component={liveAds} />
        <PrivateRoute path="/campaigns/live" component={Campaigns} />
        <PrivateRoute path="/campaigns/closed" component={CampaignsClosed} />
        <PrivateRoute
          path="/clients/income-tax"
          component={MainModuleCompanyIncometax}
        />

        {/* Start Ads Training */}

        {/* Common Training  */}
        <PrivateRoute
          path="/training/common-training/dashboard"
          component={DashboardCommon}
        />
        {/* <PrivateRoute path="/training/common-training/image" component={TraningCommonImage} /> */}
        <PrivateRoute
          path="/training/common-training/image"
          component={TraningCommonBusiness}
        />

        {/* End */}

        <PrivateRoute path="/ads/training/overview" component={AdsOverview} />
        <PrivateRoute path="/ads/training/dashboard" component={AdsDashboard} />
        <PrivateRoute path="/ads/training/work-plan" component={AdsWorkPlan} />
        <PrivateRoute
          path="/ads/training/training-notifications"
          component={AdsNotifications}
        />
        <PrivateRoute path="/ads/training/candidate" component={AdsCandidate} />
        <PrivateRoute path="/ads/training/training-jobs" component={AdsJobs} />
        <PrivateRoute path="/ads/training/company" component={AdsCompany} />
        <PrivateRoute path="/ads/training/kpi" component={AdsKpi} />
        <PrivateRoute path="/ads/training/dkg-cloud" component={AdsDkgCloud} />
        <PrivateRoute
          path="/ads/training/training-database"
          component={AdsDatabase}
        />
        <PrivateRoute
          path="/ads/training/training-dkglobal"
          component={AdsDkGlobal}
        />

        {/* End Ads Training */}

        {/* End Module For Ads */}

        {/* Recruitment Training Start */}

        <PrivateRoute
          path="/recruitment/rectraining/overview"
          component={RecOverview}
        />
        <PrivateRoute
          path="/recruitment/rectraining/dashboard"
          component={RecDashboard}
        />
        <PrivateRoute
          path="/recruitment/rectraining/work-plan"
          component={ReWorkPlan}
        />
        <PrivateRoute
          path="/recruitment/rectraining/training-notifications"
          component={ReNotifications}
        />
        <PrivateRoute
          path="/recruitment/rectraining/candidate"
          component={ReCandidate}
        />
        <PrivateRoute
          path="/recruitment/rectraining/training-jobs"
          component={ReJobs}
        />
        <PrivateRoute
          path="/recruitment/rectraining/company"
          component={ReCompany}
        />
        <PrivateRoute path="/recruitment/rectraining/kpi" component={ReKpi} />
        <PrivateRoute
          path="/recruitment/rectraining/dkg-cloud"
          component={ReDkgCloud}
        />
        <PrivateRoute
          path="/recruitment/rectraining/training-database"
          component={ReDatabase}
        />
        <PrivateRoute
          path="/recruitment/rectraining/training-dkglobal"
          component={ReDkGlobal}
        />
        <PrivateRoute
          path="/recruitment/rectraining/dkg-teams"
          component={ReDkGlobalDkgTeams}
        />
        <PrivateRoute
          path="/recruitment/rectraining/structure"
          component={ReDkGlobalStructure}
        />
        <PrivateRoute
          path="/recruitment/rectraining/process"
          component={ReDkGlobalProcess}
        />
        <PrivateRoute
          path="/recruitment/rectraining/pipelines"
          component={ReDkGlobalPipelines}
        />

        {/* Recruitment Training End */}

        {/* Start Module For Settings */}
        <PrivateRoute
          path="/recruitment/settings/summary"
          component={SettingsSummary}
        />
        <PrivateRoute
          path="/recruitment/settings/dkg-faqs"
          component={SettingsDkgFaq}
        />
        <PrivateRoute path="/recruitment/settings/faqs" component={DkgFaq} />
        {/* End Module For Settings */}

        {/* Start Module For DKG Cloud */}
        <PrivateRoute path="/dkg-cloud/summary" component={DKGCloudSummary} />
        <PrivateRoute path="/dkg-cloud/dk-global" component={DKGCloudFiles} />
        {/* End Module For DKG Cloud */}
        {/*Start Module For Accounting  */}
        <PrivateRoute
          path="/accounting/summary"
          component={AccountingSummary}
        />
        <PrivateRoute path="/accounting/alerts" component={AccountingAlerts} />
        <PrivateRoute
          path="/accounting/clients"
          component={AccountingClients}
        />
        <PrivateRoute
          path="/accounting/invoices"
          component={AccountingInvoices}
        />
        <PrivateRoute
          path="/accounting/incomes"
          component={AccountingIncomes}
        />
        <PrivateRoute
          path="/accounting/expense"
          component={AccountingExpense}
        />
        <PrivateRoute
          path="/accounting/vat-file"
          component={AccountingVatFile}
        />
        <PrivateRoute
          path="/accounting/bank-state"
          component={AccountingBankState}
        />
        <PrivateRoute
          path="/accounting/information"
          component={AccountingInformation}
        />
        <PrivateRoute
          path="/accounting/settings"
          component={AccountingSettings}
        />
        <PrivateRoute
          path="/accounting/training"
          component={AccountingTraining}
        />
        {/* End Module For Accounting */}

        {/* Start Module For Knowleadegebase */}

        <PrivateRoute
          path="/knowleadge/summary"
          component={KnowleadgeSummary}
        />
        <PrivateRoute
          path="/knowleadge/dk-global/overview"
          component={KnowleadgeDkGlobal}
        />
        <PrivateRoute
          path="/knowleadge/dk-global/Sample-1"
          component={KnowleadgeSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/dk-global/Sample-2"
          component={KnowleadgeSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/dk-global/Sample-3"
          component={KnowleadgeSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/kpi/overview"
          component={KnowleadgeKpi}
        />
        <PrivateRoute
          path="/knowleadge/kpi/sample-1"
          component={KnowleadgeKpiSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/kpi/sample-2"
          component={KnowleadgeKpiSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/kpi/sample-3"
          component={KnowleadgeKpiSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/csf/overview"
          component={KnowleadgeCsf}
        />
        <PrivateRoute
          path="/knowleadge/csf/sample-1"
          component={KnowleadgeCsfSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/csf/sample-2"
          component={KnowleadgeCsfSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/csf/sample-3"
          component={KnowleadgeCsfSamplePages}
        />
        {/* <PrivateRoute path="/knowleadge/visa-info/overview" component={KnowleadgeEmployee} /> */}
        <PrivateRoute
          path="/knowleadge/visa-info/sample-1"
          component={KnowleadgeEmployeeSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/visa-info/sample-2"
          component={KnowleadgeEmployeeSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/visa-info/sample-3"
          component={KnowleadgeEmployeeSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/process/overview"
          component={KnowleadgeProcess}
        />
        <PrivateRoute
          path="/knowleadge/process/sample-1"
          component={KnowleadgeProcessSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/process/sample-2"
          component={KnowleadgeProcessSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/process/sample-3"
          component={KnowleadgeProcessSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/clients/overview"
          component={KnowleadgeClients}
        />
        <PrivateRoute
          path="/knowleadge/clients/sample-1"
          component={KnowleadgeClientsSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/clients/sample-2"
          component={KnowleadgeClientsSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/clients/sample-3"
          component={KnowleadgeClientsSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/demands/overview"
          component={KnowleadgeDemands}
        />
        <PrivateRoute
          path="/knowleadge/demands/sample-1"
          component={KnowleadgeDemandssSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/demands/sample-2"
          component={KnowleadgeDemandssSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/demands/sample-3"
          component={KnowleadgeDemandssSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/candidates/overview"
          component={KnowleadgeCandidates}
        />
        <PrivateRoute
          path="/knowleadge/candidates/sample-1"
          component={KnowleadgeCandidatessSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/candidates/sample-2"
          component={KnowleadgeCandidatessSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/candidates/sample-3"
          component={KnowleadgeCandidatessSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/tech-bugs/overview"
          component={KnowleadgeTechnicalBugs}
        />
        <PrivateRoute
          path="/knowleadge/tech-bugs/sample-1"
          component={KnowleadgeTechBugsSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/tech-bugs/sample-2"
          component={KnowleadgeTechBugsSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/tech-bugs/sample-3"
          component={KnowleadgeTechBugsSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/support/overview"
          component={KnowleadgeSupport}
        />
        <PrivateRoute
          path="/knowleadge/support/sample-1"
          component={KnowleadgeSupportSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/support/sample-2"
          component={KnowleadgeSupportSamplePages}
        />
        <PrivateRoute
          path="/knowleadge/support/sample-3"
          component={KnowleadgeSupportSamplePages}
        />
        <PrivateRoute
          path="/knowleadge-base/visa-info/overview"
          component={VisainfoOverview}
        />
        <PrivateRoute
          path="/knowleadge-base/visa-info/concerns"
          component={VisainfoConcerns}
        />
        <PrivateRoute
          path="/knowleadge-base/visa-info/permissions"
          component={VisainfoPermission}
        />
        <PrivateRoute
          path="/knowleadge-base/visa-info/all-visa-type"
          component={VisainfoAllVisaType}
        />
        <PrivateRoute
          path="/knowleadge-base/visa-info/student-visa"
          component={VisainfoStudent}
        />

        {/* End Module For Knowleadgebase */}

        {/* Start Module for Resource */}
        <PrivateRoute path="/resources/summary" component={ResSummary} />
        <PrivateRoute path="/resources/ml-resource" component={MLResource} />
        <PrivateRoute path="/resources/it-resource" component={ITResource} />
        <PrivateRoute path="/resources/targets" component={ResourceTargets} />
        <PrivateRoute path="/resources/partners" component={ResourcePartners} />
        <PrivateRoute path="/resources/research" component={ResourceResearch} />
        {/* <PrivateRoute path="/resources/logins" component={LoginsResource} /> */}
        {/* <PrivateRoute path="/resources/budget" component={BudgetResource} /> */}
        <PrivateRoute
          path="/resources/cost-and-benefit"
          component={CostbenfitResource}
        />
        {/* Resource Training STart */}

        <PrivateRoute
          path="/resources/training/overview"
          component={ResOverview}
        />
        <PrivateRoute
          path="/resources/training/dashboard"
          component={ResDashboard}
        />
        <PrivateRoute
          path="/resources/training/work-plan"
          component={ResWorkPlan}
        />
        <PrivateRoute
          path="/resources/training/training-notifications"
          component={ResNotifications}
        />
        <PrivateRoute
          path="/resources/training/candidate"
          component={ResCandidate}
        />
        <PrivateRoute
          path="/resources/training/training-jobs"
          component={ResJobs}
        />
        <PrivateRoute
          path="/resources/training/company"
          component={ResCompany}
        />
        <PrivateRoute path="/resources/training/kpi" component={ResKpi} />
        <PrivateRoute
          path="/resources/training/dkg-cloud"
          component={ResDkgCloud}
        />
        <PrivateRoute
          path="/resources/training/training-database"
          component={ResDatabase}
        />
        <PrivateRoute
          path="/resources/training/training-dkglobal"
          component={ResDkGlobal}
        />

        {/* Resource Training End */}

        {/* End Module for Resource */}

        {/* Training Pages Start */}
        <PrivateRoute path="/training/summary" component={TrainingSummary} />
        <PrivateRoute
          path="/training/onboarding/about-dkg"
          component={TrainingAboutDkg}
        />
        <PrivateRoute
          path="/training/onboarding/welcome"
          component={TrainingOnboarding}
        />
        <PrivateRoute
          path="/training/onboarding/dkg-team"
          component={TrainingDkgTeam}
        />
        <PrivateRoute
          path="/training/onboarding/timetable"
          component={TrainingTimeTable}
        />
        <PrivateRoute
          path="/training/onboarding/technical"
          component={TrainingTechnical}
        />
        <PrivateRoute
          path="/training/onboarding/account"
          component={TrainingAccount}
        />
        <PrivateRoute
          path="/training/onboarding/kpi-csf"
          component={TrainingKpiCsf}
        />
        <PrivateRoute
          path="/training/onboarding/onboarding-training-progress"
          component={TrainingProgress}
        />
        <PrivateRoute
          path="/training/onboarding/introduction"
          component={TrainingIntroduction}
        />
        <PrivateRoute
          path="/training/onboarding/conducts"
          component={TrainingConducts}
        />
        <PrivateRoute
          path="/training/onboarding/contracts"
          component={TrainingContracts}
        />
        <PrivateRoute
          path="/training/onboarding/training"
          component={OnboardingTraining}
        />
        <PrivateRoute
          path="/training/management/teamleader/welcome"
          component={ManagementTeamLeader}
        />
        <PrivateRoute
          path="/training/management/recruitment-manager"
          component={ManagementRecManager}
        />
        <PrivateRoute
          path="/training/management/marketing-manager"
          component={ManagementMarketingManager}
        />
        <PrivateRoute
          path="/training/management/hr-manager"
          component={ManagementHrManager}
        />
        <PrivateRoute
          path="/training/management/it-manager"
          component={ManagementItManager}
        />
        <PrivateRoute
          path="/training/management/resource-manager"
          component={ManagementResourceManager}
        />
        <PrivateRoute
          path="/training/management/finance-manager"
          component={ManagementFinanceManager}
        />
        <PrivateRoute
          path="/training/management/operation-manager"
          component={ManagementOperationManager}
        />
        <PrivateRoute
          path="/training/management/director"
          component={ManagementDirector}
        />
        <PrivateRoute
          path="/training/completion/overview"
          component={CompletionOverview}
        />
        <PrivateRoute
          path="/training/management"
          component={TrainingManagement}
        />
        <PrivateRoute
          path="/training/schedule/overview"
          component={TrainingSchedule}
        />
        <PrivateRoute
          path="/training/schedule/day-one"
          component={DayOneFullTime}
        />
        <PrivateRoute
          path="/training/schedule/day-two"
          component={DayTwoFullTime}
        />
        <PrivateRoute
          path="/training/schedule/day-three"
          component={DayThreeFullTime}
        />
        <PrivateRoute
          path="/training/schedule/day-four"
          component={DayFourFullTime}
        />
        <PrivateRoute
          path="/training/schedule/day-five"
          component={DayFiveFullTime}
        />
        <PrivateRoute
          path="/training/schedule/day-six"
          component={DaySixPartTime}
        />
        <PrivateRoute
          path="/training/schedule/day-seven"
          component={DaySevenPartTime}
        />
        <PrivateRoute
          path="/training/schedule/day-eight"
          component={DayEightPartTime}
        />
        <PrivateRoute
          path="/training/schedule/day-nine"
          component={DayNinePartTime}
        />
        <PrivateRoute
          path="/training/schedule/day-ten"
          component={DayTenPartTime}
        />
        <PrivateRoute
          path="/training/schedule/final-accessment"
          component={FinalAccessment}
        />
        <PrivateRoute
          path="/training/schedule/training-status"
          component={TrainingStatus}
        />
        <PrivateRoute path="/training/tools" component={TrainingTools} />
        <PrivateRoute
          path="/training/masterbox"
          component={TrainingMasterbox}
        />
        <PrivateRoute
          path="/training/commontraining/common-training"
          component={CommonTraining}
        />
        <PrivateRoute
          path="/training/commontraining/overview"
          component={CommonOverview}
        />
        <PrivateRoute
          path="/training/commontraining/established"
          component={CommonEstablished}
        />
        <PrivateRoute
          path="/training/commontraining/business"
          component={CommonBusiness}
        />
        <PrivateRoute
          path="/training/commontraining/industry"
          component={CommonIndustry}
        />
        <PrivateRoute
          path="/training/commontraining/services"
          component={CommonServices}
        />
        <PrivateRoute
          path="/training/commontraining/clients"
          component={CommonClient}
        />
        <PrivateRoute
          path="/training/commontraining/suppliers"
          component={CommonSuppliers}
        />
        <PrivateRoute
          path="/training/commontraining/completion"
          component={CommonCompletion}
        />
        <PrivateRoute
          path="/training/commontraining/employees"
          component={CommonEmployees}
        />
        <PrivateRoute
          path="/training/commontraining/future-plans"
          component={CommonFuturePlans}
        />
        <PrivateRoute
          path="/training/commontraining/social-media"
          component={CommonSocialMedia}
        />
        <PrivateRoute
          path="/training/commontraining/contact-details"
          component={CommonContactDetails}
        />
        <PrivateRoute
          path="/training/commontraining/structure"
          component={CommonStructure}
        />
        <PrivateRoute
          path="/training/commontraining/process"
          component={CommonProcess}
        />
        <PrivateRoute
          path="/training/commontraining/edit-process"
          component={CommonEditTeamRoles}
        />
        <PrivateRoute
          path="/training/commontraining/trainings"
          component={CommonTrainings}
        />
        <PrivateRoute
          path="/training/commontraining/pipelines"
          component={CommonPipelines}
        />
        <PrivateRoute
          path="/training/commontraining/edit-pipelines"
          component={EditCommonPipelinesManagement}
        />
        <PrivateRoute
          path="/training/commontraining/tools"
          component={CommonTools}
        />
        <PrivateRoute
          path="/training/commontraining/masterbox"
          component={CommonMasterbox}
        />
        <PrivateRoute
          path="/training/commontraining/common-clients"
          component={CommonClients}
        />
        <PrivateRoute
          path="/training/commontraining/dkg-guidelines"
          component={CommonDkgGuidelines}
        />
        <PrivateRoute
          path="/training/commontraining/dkg-pipelines"
          component={CommonDkgPipelines}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/new-cv"
          component={DkgPipelinesNewCv}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/screening"
          component={DkgPipelinesScreening}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/reminders"
          component={DkgPipelinesReminders}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/recruiters"
          component={DkgPipelinesRecruiters}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/account-m"
          component={DkgPipelinesAccountM}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/job-offers"
          component={DkgPipelinesJobOffers}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/escalated"
          component={DkgPipelinesEscalated}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/on-hold"
          component={DkgPipelinesOnHold}
        />
        <PrivateRoute
          path="/training/commontraining/dkgpipelines/closed"
          component={DkgPipelinesClosed}
        />
        <PrivateRoute
          path="/training/commontraining/work-plan"
          component={CommonWorkPlan}
        />
        <PrivateRoute
          path="/training/commontraining/my-task"
          component={CommonMyTask}
        />
        <PrivateRoute
          path="/training/commontraining/resource"
          component={CommonResource}
        />
        <PrivateRoute
          path="/training/commontraining/sales"
          component={CommonSales}
        />
        <PrivateRoute
          path="/training/commontraining/payments"
          component={CommonPayments}
        />
        <PrivateRoute
          path="/training/commontraining/jobs"
          component={CommonJobs}
        />
        <PrivateRoute
          path="/training/commontraining/candidates"
          component={CommonCandidates}
        />
        <PrivateRoute
          path="/training/commontraining/outlook"
          component={CommonOutlook}
        />
        <PrivateRoute
          path="/training/commontraining/emails"
          component={CommonEmails}
        />
        <PrivateRoute
          path="/training/commontraining/calls"
          component={CommonCalls}
        />
        <PrivateRoute
          path="/training/commontraining/sms"
          component={CommonSms}
        />
        <PrivateRoute
          path="/training/commontraining/chats"
          component={CommonChats}
        />
        <PrivateRoute
          path="/training/assessments/overview"
          component={AssessmentsOverview}
        />
        <PrivateRoute
          path="/training/assessments/assessment-1"
          component={AssessmentsAssessmentOne}
        />
        <PrivateRoute
          path="/training/assessments/assessment-2"
          component={AssessmentsAssessmentTwo}
        />
        <PrivateRoute
          path="/training/assessments/preparation"
          component={AssessmentPreparation}
        />
        <PrivateRoute
          path="/training/assessments/decision"
          component={AssessmentDecision}
        />
        <PrivateRoute
          path="/training/assessments/faq"
          component={AssessmentFaq}
        />

        <PrivateRoute
          path="/training/admins/job-details"
          component={AdminWorkProcess}
        />
        <PrivateRoute
          path="/training/admins/structure"
          component={AdminJobDetailsStructure}
        />
        <PrivateRoute
          path="/training/admins/team-info"
          component={AdminJobDetailsTeamInfo}
        />
        <PrivateRoute
          path="/training/admins/job-skills"
          component={AdminJobDetailsJobSkills}
        />
        <PrivateRoute
          path="/training/admins/job-task"
          component={AdminJobDetailsJobTask}
        />
        <PrivateRoute
          path="/training/admins/job-quality"
          component={AdminJobDetailsJobQuality}
        />
        <PrivateRoute
          path="/training/admins/job-volume"
          component={AdminJobDetailsJobVolume}
        />
        <PrivateRoute
          path="/training/admins/job-queries"
          component={AdminJobDetailsJobQueries}
        />
        <PrivateRoute
          path="/training/admins/job-training"
          component={AdminJobDetailsJobTraining}
        />
        <PrivateRoute
          path="/training/admins/job-delegation"
          component={AdminJobDetailsJobDelegation}
        />
        <PrivateRoute
          path="/training/admins/preparation"
          component={AdminPreparation}
        />
        <PrivateRoute
          path="/training/admins/tools-login"
          component={AdminPreparationToolsLogin}
        />
        <PrivateRoute
          path="/training/admins/team-greeting"
          component={AdminPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/training/admins/dkg-crm-tabs"
          component={AdminPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/training/admins/review-mailbox"
          component={AdminPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/training/admins/review-live-jobs"
          component={AdminPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/training/admins/review-pipelines"
          component={AdminPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/training/admins/review-workplan"
          component={AdminPreparationReviewWorkplan}
        />
        <PrivateRoute path="/training/admins/action" component={AdminAction} />
        <PrivateRoute
          path="/training/admins/sequence"
          component={AdminActionSequence}
        />
        <PrivateRoute
          path="/training/admins/set-priority"
          component={AdminActionSetPriority}
        />
        <PrivateRoute
          path="/training/admins/admins-action-priority"
          component={AdminActionPriority}
        />
        <PrivateRoute
          path="/training/admins/create-new-task"
          component={AdminActionCreateNewTask}
        />
        <PrivateRoute
          path="/training/admins/follow-workplan"
          component={AdminActionFollowWorkplan}
        />
        <PrivateRoute
          path="/training/admins/performance"
          component={AdminReport}
        />
        <PrivateRoute
          path="/training/admins/criteria"
          component={AdminsPerformanceCriteria}
        />
        <PrivateRoute
          path="/training/admins/reviews"
          component={AdminsPerformanceReviews}
        />
        <PrivateRoute
          path="/training/admins/reporting"
          component={AdminsPerformanceReporting}
        />
        <PrivateRoute
          path="/training/admins/knowledge-base"
          component={AdminKnowledgeBase}
        />
        <PrivateRoute
          path="/training/admins/search-info"
          component={AdminsKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/training/admins/task-info"
          component={AdminsKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/training/admins/tools-info"
          component={AdminsKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/training/admins/candidates"
          component={AdminsKnowledgeCandidates}
        />
        <PrivateRoute
          path="/training/admins/clients"
          component={AdminsKnowledgeClients}
        />
        <PrivateRoute
          path="/training/admins/jobs"
          component={AdminsKnowledgeJobs}
        />
        <PrivateRoute
          path="/training/admins/send-bulk-email"
          component={AdminSendBulkEmail}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/situations"
          component={AdminsSendBeSituations}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/task-rules"
          component={AdminsSendBeTaskRules}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/how-to-do"
          component={AdminsSendBeHowToDo}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/create-list"
          component={AdminsSendBeCreateList}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/create-template"
          component={AdminsSendBeCreateTemplate}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/review-template"
          component={AdminsSendBeReviewTemplate}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/send-bulk-emails"
          component={AdminsSendBeSendBulkEmails}
        />
        <PrivateRoute
          path="/training/admins/sendbulkemail/track-responses"
          component={AdminsSendBeTrackResponse}
        />
        <PrivateRoute
          path="/training/admins/search-database"
          component={AdminSearchDatabase}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/situations"
          component={AdminsSearchDaSituations}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/task-rules"
          component={AdminsSearchDaTaskRules}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/how-to-do"
          component={AdminsSearchDaHowToDo}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/work-plan"
          component={AdminsSearchDaWorkPlan}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/resource"
          component={AdminsSearchDaResource}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/how-to-search"
          component={AdminsSearchDaHowToSearch}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/create-project"
          component={AdminsSearchDaCreateProject}
        />
        <PrivateRoute
          path="/training/admins/searchdatabase/send-mini-specs"
          component={AdminsSearchDaSendMiniSpecs}
        />
        <PrivateRoute
          path="/training/admins/assign-priority"
          component={AdminAssignPriority}
        />
        <PrivateRoute
          path="/training/admins/assignpriority/indicators"
          component={AssignPriorityIndicators}
        />
        <PrivateRoute
          path="/training/admins/assignpriority/situations"
          component={AssignPrioritySituations}
        />
        <PrivateRoute
          path="/training/admins/assignpriority/today-based"
          component={AssignPriorityTodayBased}
        />
        <PrivateRoute
          path="/training/admins/assignpriority/live-job-based"
          component={AssignPriorityLiveJobBased}
        />
        <PrivateRoute
          path="/training/admins/assignpriority/language-based"
          component={AssignPriorityLanguageBased}
        />
        <PrivateRoute
          path="/training/admins/priority-screening"
          component={AdminPriorityScreening}
        />
        <PrivateRoute
          path="/training/admins/priorityscreening/preparation"
          component={AdminsPriorityScPreparation}
        />
        <PrivateRoute
          path="/training/admins/priorityscreening/action-tools"
          component={AdminsPriorityScActionTools}
        />
        <PrivateRoute
          path="/training/admins/priorityscreening/phone-call"
          component={AdminsPriorityScPhoneCall}
        />
        <PrivateRoute
          path="/training/admins/priorityscreening/send-email"
          component={AdminsPriorityScSendEmail}
        />
        <PrivateRoute
          path="/training/admins/priorityscreening/send-sms"
          component={AdminsPriorityScSendSms}
        />
        <PrivateRoute
          path="/training/admins/close-decision"
          component={AdminCloseDecision}
        />
        <PrivateRoute
          path="/training/admins/casedecision/situations"
          component={AdminsCaseDeSituations}
        />
        <PrivateRoute
          path="/training/admins/casedecision/task-rules"
          component={AdminsCaseDeTaskRules}
        />
        <PrivateRoute
          path="/training/admins/casedecision/how-to-do"
          component={AdminsCaseDeHowToDo}
        />
        <PrivateRoute
          path="/training/admins/casedecision/discussion"
          component={AdminsCaseDeDiscussion}
        />
        <PrivateRoute
          path="/training/admins/casedecision/escalation"
          component={AdminsCaseDeEscalation}
        />
        <PrivateRoute
          path="/training/admins/casedecision/comments"
          component={AdminsCaseDeComments}
        />
        <PrivateRoute
          path="/training/admins/casedecision/update-pipeline"
          component={AdminsCaseDeUpdatePipeline}
        />
        <PrivateRoute
          path="/training/admins/casedecision/dkg-database"
          component={AdminsCaseDeDkgDatabase}
        />
        <PrivateRoute
          path="/training/admins/review-new-cv"
          component={AdminReviewNewCv}
        />
        <PrivateRoute
          path="/training/admins/review-profiles"
          component={AdminUpdateProfiles}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/contacts"
          component={AdminsReviewContacts}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/location"
          component={AdminsReviewLocation}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/languages"
          component={AdminsReviewLanguages}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/job-titles"
          component={AdminsReviewJobTitles}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/job-experience"
          component={AdminsReviewJobExperience}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/qualifications"
          component={AdminsReviewQualifications}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/technical-skills"
          component={AdminsReviewTechnicalSkills}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/career-seniority"
          component={AdminsReviewCareerSeniority}
        />
        <PrivateRoute
          path="/training/admins/reviewprofiles/live-job-suitability"
          component={AdminsReviewLiveJobSuitability}
        />
        <PrivateRoute
          path="/training/admins/update-profiles"
          component={AdminMatchWithJobs}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/status"
          component={AdminsUpdateStatus}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/contacts"
          component={AdminsUpdateContacts}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/category"
          component={AdminsUpdateCategory}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/cv-source"
          component={AdminsUpdateCvSource}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/locations"
          component={AdminsUpdateLocations}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/nationality"
          component={AdminsUpdateNationality}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/languages"
          component={AdminsUpdateLanguages}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/assign-job"
          component={AdminsUpdateAssignJob}
        />
        <PrivateRoute
          path="/training/admins/updateprofiles/case-comment"
          component={AdminsUpdateCaseComment}
        />
        <PrivateRoute
          path="/training/admins/search-database"
          component={AdminRearchDatabase}
        />
        <PrivateRoute
          path="/training/admins/mini-job-specs"
          component={AdminMiniJobSpecs}
        />
        <PrivateRoute
          path="/training/admins/minijobspecs/create"
          component={AdminsMiniJobSpecCreate}
        />
        <PrivateRoute
          path="/training/admins/minijobspecs/outlook"
          component={AdminsMiniJobSpecOutlook}
        />
        <PrivateRoute
          path="/training/admins/minijobspecs/save-spec"
          component={AdminsMiniJobSpecSaveSpec}
        />
        <PrivateRoute
          path="/training/admins/minijobspecs/email-spec"
          component={AdminsMiniJobSpecEmailSpec}
        />
        <PrivateRoute
          path="/training/admins/minijobspecs/send-sms"
          component={AdminsMiniJobSpecSendSms}
        />
        <PrivateRoute
          path="/training/admins/send-reminders"
          component={AdminSendReminders}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/situations"
          component={AdminsSendReSituations}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/task-rules"
          component={AdminsSendReTaskRules}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/how-to-do"
          component={AdminsSendReHowToDo}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/1st-reminder"
          component={AdminsSendReFirstReminder}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/2nd-reminder"
          component={AdminsSendReSecondReminder}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/3rd-reminder"
          component={AdminsSendReThirdReminder}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/final-reminder"
          component={AdminsSendReFinalReminder}
        />
        <PrivateRoute
          path="/training/admins/sendreminders/case-discussion"
          component={AdminsSendReCaseDiscussion}
        />
        <PrivateRoute
          path="/training/admins/not-reachables"
          component={AdminNotReachable}
        />
        <PrivateRoute
          path="/training/admins/notreachables/situations"
          component={AdminsNotReSituations}
        />
        <PrivateRoute
          path="/training/admins/notreachables/task-rules"
          component={AdminsNotReTaskRules}
        />
        <PrivateRoute
          path="/training/admins/notreachables/how-to-do"
          component={AdminsNotReHowToDo}
        />
        <PrivateRoute
          path="/training/admins/notreachables/preparation"
          component={AdminsNotRePreparation}
        />
        <PrivateRoute
          path="/training/admins/notreachables/action-tools"
          component={AdminsNotReActionTools}
        />
        <PrivateRoute
          path="/training/admins/notreachables/phone-call"
          component={AdminsNotRePhoneCall}
        />
        <PrivateRoute
          path="/training/admins/notreachables/send-email"
          component={AdminsNotReSendEmail}
        />
        <PrivateRoute
          path="/training/admins/notreachables/send-sms"
          component={AdminsNotReSendSms}
        />
        <PrivateRoute path="/training/admins" component={Admins} />
        <PrivateRoute
          path="/training/training-resource/job-details"
          component={TrainingWorkProcess}
        />
        <PrivateRoute
          path="/training/training-resource/structure"
          component={JobDetailStructure}
        />
        <PrivateRoute
          path="/training/training-resource/team-info"
          component={JobDetailTeamInfo}
        />
        <PrivateRoute
          path="/training/training-resource/job-skills"
          component={JobDetailJobSkills}
        />
        <PrivateRoute
          path="/training/training-resource/job-task"
          component={JobDetailJobTask}
        />
        <PrivateRoute
          path="/training/training-resource/job-quality"
          component={JobDetailJobQuality}
        />
        <PrivateRoute
          path="/training/training-resource/job-volume"
          component={JobDetailJobVolume}
        />
        <PrivateRoute
          path="/training/training-resource/job-queries"
          component={JobDetailJobQueries}
        />
        <PrivateRoute
          path="/training/training-resource/job-training"
          component={JobDetailJobTraining}
        />
        <PrivateRoute
          path="/training/training-resource/job-delegation"
          component={JobDetailJobDelegation}
        />
        <PrivateRoute
          path="/training/training-resource/preparation"
          component={ResourcePreparation}
        />
        <PrivateRoute
          path="/training/training-resource/tools-login"
          component={PreparationToolsLogin}
        />
        <PrivateRoute
          path="/training/training-resource/team-greeting"
          component={PreparationTeamGreeting}
        />
        <PrivateRoute
          path="/training/training-resource/dkg-crm-tabs"
          component={PreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/training/training-resource/review-mailbox"
          component={PreparationReviewMailbox}
        />
        <PrivateRoute
          path="/training/training-resource/review-live-jobs"
          component={PreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/training/training-resource/review-pipelines"
          component={PreparationReviewPipelines}
        />
        <PrivateRoute
          path="/training/training-resource/review-workplan"
          component={PreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/training/training-resource/action"
          component={ResourceAction}
        />
        <PrivateRoute
          path="/training/training-resource/sequence"
          component={ActionSequence}
        />
        <PrivateRoute
          path="/training/training-resource/set-priority"
          component={ActionSetPriority}
        />
        <PrivateRoute
          path="/training/training-resource/action-priority"
          component={ActionPriority}
        />
        <PrivateRoute
          path="/training/training-resource/create-new-task"
          component={ActionCreateNewTask}
        />
        <PrivateRoute
          path="/training/training-resource/follow-workplan"
          component={ActionFollowWorkPlan}
        />
        <PrivateRoute
          path="/training/training-resource/performance"
          component={ResourceReport}
        />
        <PrivateRoute
          path="/training/training-resource/criteria"
          component={PerformanceCriteria}
        />
        <PrivateRoute
          path="/training/training-resource/reviews"
          component={PerformanceReviews}
        />
        <PrivateRoute
          path="/training/training-resource/reporting"
          component={PerformanceReporting}
        />
        <PrivateRoute
          path="/training/training-resource/knowledge-base"
          component={ResourceKnowledgeBase}
        />
        <PrivateRoute
          path="/training/training-resource/search-info"
          component={KnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/training/training-resource/task-info"
          component={KnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/training/training-resource/tools-info"
          component={KnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/training/training-resource/candidates"
          component={KnowledgeCandidates}
        />
        <PrivateRoute
          path="/training/training-resource/clients"
          component={KnowledgeClients}
        />
        <PrivateRoute
          path="/training/training-resource/jobs"
          component={KnowledgeJobs}
        />
        <PrivateRoute
          path="/training/training-resource/manage-ads"
          component={ResourceManageAds}
        />
        <PrivateRoute
          path="/training/training-resource/apps-management"
          component={ResourceAppsManagement}
        />
        <PrivateRoute
          path="/training/training-resource/manage-jobs"
          component={ResourceManageJobs}
        />
        <PrivateRoute
          path="/training/training-resource/create-specs"
          component={ResourceCreateSpecs}
        />
        <PrivateRoute
          path="/training/training-resource/social-media"
          component={ResourceSocialMedia}
        />
        <PrivateRoute
          path="/training/training-resource/dkg-database"
          component={ResourceDkgDatabase}
        />
        <PrivateRoute
          path="/training/training-resource/pipelines-kpi"
          component={ResourcePipelinesKpi}
        />
        <PrivateRoute
          path="/training/training-resource/ads-budget"
          component={ResourceAdsBudget}
        />
        <PrivateRoute
          path="/training/training-resource/ad-campaign"
          component={ResourceAdCampaign}
        />
        <PrivateRoute
          path="/training/training-resource/ads-tracker"
          component={ResourceAdsTracker}
        />
        <PrivateRoute
          path="/training/training-resource"
          component={TrainingResource}
        />
        <PrivateRoute
          path="/training/recruiters/job-details"
          component={RecruitersWorkProcess}
        />
        <PrivateRoute
          path="/training/recruiters/structure"
          component={RecruitersJobDetailsStructure}
        />
        <PrivateRoute
          path="/training/recruiters/team-info"
          component={RecruitersJobDetailsTeamInfo}
        />
        <PrivateRoute
          path="/training/recruiters/job-skills"
          component={RecruitersJobDetailsJobSkills}
        />
        <PrivateRoute
          path="/training/recruiters/job-task"
          component={RecruitersJobDetailsJobTask}
        />
        <PrivateRoute
          path="/training/recruiters/job-quality"
          component={RecruitersJobDetailsJobQuality}
        />
        <PrivateRoute
          path="/training/recruiters/job-volume"
          component={RecruitersJobDetailsJobVolume}
        />
        <PrivateRoute
          path="/training/recruiters/job-queries"
          component={RecruitersJobDetailsJobQueries}
        />
        <PrivateRoute
          path="/training/recruiters/job-training"
          component={RecruitersJobDetailsJobTraining}
        />
        <PrivateRoute
          path="/training/recruiters/job-delegation"
          component={RecruitersJobDetailsJobDelegation}
        />
        <PrivateRoute
          path="/training/recruiters/preparation"
          component={RecruitersPreparation}
        />
        <PrivateRoute
          path="/training/recruiters/tools-login"
          component={RecruitersPreparationToolsLogin}
        />
        <PrivateRoute
          path="/training/recruiters/team-greeting"
          component={RecruitersPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/training/recruiters/dkg-crm-tabs"
          component={RecruitersPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/training/recruiters/review-mailbox"
          component={RecruitersPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/training/recruiters/review-live-jobs"
          component={RecruitersPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/training/recruiters/review-pipelines"
          component={RecruitersPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/training/recruiters/review-workplan"
          component={RecruitersPreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/training/recruiters/action"
          component={RecruitersAction}
        />
        <PrivateRoute
          path="/training/recruiters/sequence"
          component={RecruitersActionSequence}
        />
        <PrivateRoute
          path="/training/recruiters/set-priority"
          component={RecruitersActionSetPriority}
        />
        <PrivateRoute
          path="/training/recruiters/recruiters-action-priority"
          component={RecruitersActionPriority}
        />
        <PrivateRoute
          path="/training/recruiters/create-new-task"
          component={RecruitersActionCreateNewTask}
        />
        <PrivateRoute
          path="/training/recruiters/follow-workplan"
          component={RecruitersActionFollowWorkplan}
        />
        <PrivateRoute
          path="/training/recruiters/performance"
          component={RecruitersReport}
        />
        <PrivateRoute
          path="/training/recruiters/criteria"
          component={RecruitersPerformanceCriteria}
        />
        <PrivateRoute
          path="/training/recruiters/reviews"
          component={RecruitersPerformanceReviews}
        />
        <PrivateRoute
          path="/training/recruiters/reporting"
          component={RecruitersPerformanceReporting}
        />
        <PrivateRoute
          path="/training/recruiters/knowledge-base"
          component={RecruitersKnowledgeBase}
        />
        <PrivateRoute
          path="/training/recruiters/search-info"
          component={RecruitersKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/training/recruiters/tasks-info"
          component={RecruitersKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/training/recruiters/tools-info"
          component={RecruitersKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/training/recruiters/candidates"
          component={RecruitersKnowledgeCandidates}
        />
        <PrivateRoute
          path="/training/recruiters/clients"
          component={RecruitersKnowledgeClients}
        />
        <PrivateRoute
          path="/training/recruiters/jobs"
          component={RecruitersKnowledgeJobs}
        />
        <PrivateRoute
          path="/training/recruiters/interview-call"
          component={RecruitersInterviewCall}
        />
        <PrivateRoute
          path="/training/recruiters/full-job-spec"
          component={RecruitersFullJobSpec}
        />
        <PrivateRoute
          path="/training/recruiters/case-queries"
          component={RecruitersCaseQueries}
        />
        <PrivateRoute
          path="/training/recruiters/live-new-query"
          component={RecruitersLiveNewQuery}
        />
        <PrivateRoute
          path="/training/recruiters/update-profile"
          component={RecruitersUpdateProfile}
        />
        <PrivateRoute
          path="/training/recruiters/create-job-spec"
          component={RecruitersCreateJobSpec}
        />
        <PrivateRoute
          path="/training/recruiters/case-decision"
          component={RecruitersCaseDecision}
        />
        <PrivateRoute
          path="/training/recruiters/clients-portals"
          component={RecruitersClientsPortals}
        />
        <PrivateRoute
          path="/training/recruiters/make-sendout"
          component={RecruitersMakeSendout}
        />
        <PrivateRoute
          path="/training/recruiters/not-reachable"
          component={RecruitersNotReachable}
        />
        <PrivateRoute path="/training/recruiters" component={Recruiters} />

        <PrivateRoute
          path="/training/managers/job-details"
          component={ManagersWorkProcess}
        />
        <PrivateRoute
          path="/training/managers/structure"
          component={ManagersJobDetailsStructure}
        />
        <PrivateRoute
          path="/training/managers/team-info"
          component={ManagersJobDetailsTeamInfo}
        />
        <PrivateRoute
          path="/training/managers/job-skills"
          component={ManagersJobDetailsJobSkills}
        />
        <PrivateRoute
          path="/training/managers/job-task"
          component={ManagersJobDetailsJobTask}
        />
        <PrivateRoute
          path="/training/managers/job-quality"
          component={ManagersJobDetailsJobQuality}
        />
        <PrivateRoute
          path="/training/managers/job-volume"
          component={ManagersJobDetailsJobVolume}
        />
        <PrivateRoute
          path="/training/managers/job-queries"
          component={ManagersJobDetailsJobQueries}
        />
        <PrivateRoute
          path="/training/managers/job-training"
          component={ManagersJobDetailsJobTraining}
        />
        <PrivateRoute
          path="/training/managers/job-delegation"
          component={ManagersJobDetailsJobDelegation}
        />
        <PrivateRoute
          path="/training/managers/preparation"
          component={ManagersPreparation}
        />
        <PrivateRoute
          path="/training/managers/tools-login"
          component={ManagersPreparationToolsLogin}
        />
        <PrivateRoute
          path="/training/managers/team-greeting"
          component={ManagersPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/training/managers/dkg-crm-tabs"
          component={ManagersPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/training/managers/review-mailbox"
          component={ManagersPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/training/managers/review-live-jobs"
          component={ManagersPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/training/managers/review-pipelines"
          component={ManagersPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/training/managers/review-workplan"
          component={ManagersPreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/training/managers/action"
          component={ManagersAction}
        />
        <PrivateRoute
          path="/training/managers/sequence"
          component={ManagersActionSequence}
        />
        <PrivateRoute
          path="/training/managers/set-priority"
          component={ManagersActionSetPriority}
        />
        <PrivateRoute
          path="/training/managers/managers-action-priority"
          component={ManagersActionPriority}
        />
        <PrivateRoute
          path="/training/managers/create-new-task"
          component={ManagersActionCreateNewTask}
        />
        <PrivateRoute
          path="/training/managers/follow-workplan"
          component={ManagersActionFollowWorkplan}
        />
        <PrivateRoute
          path="/training/managers/performance"
          component={ManagersReport}
        />
        <PrivateRoute
          path="/training/managers/criteria"
          component={ManagersPerformanceCriteria}
        />
        <PrivateRoute
          path="/training/managers/reviews"
          component={ManagersPerformanceReviews}
        />
        <PrivateRoute
          path="/training/managers/reporting"
          component={ManagersPerformanceReporting}
        />
        <PrivateRoute
          path="/training/managers/knowledge-base"
          component={ManagersKnowledgeBase}
        />
        <PrivateRoute
          path="/training/managers/search-info"
          component={ManagersKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/training/managers/tasks-info"
          component={ManagersKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/training/managers/tools-info"
          component={ManagersKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/training/managers/candidates"
          component={ManagersKnowledgeCandidates}
        />
        <PrivateRoute
          path="/training/managers/clients"
          component={ManagersKnowledgeClients}
        />
        <PrivateRoute
          path="/training/managers/jobs"
          component={ManagersKnowledgeJobs}
        />
        <PrivateRoute
          path="/training/managers/send-interview-prep"
          component={ManagersSendInterviewPrep}
        />
        <PrivateRoute
          path="/training/managers/shedule-interviews"
          component={ManagersSheduleInterviews}
        />
        <PrivateRoute
          path="/training/managers/review-sendout"
          component={ManagersReviewSendout}
        />
        <PrivateRoute
          path="/training/managers/submit-sendout"
          component={ManagersSubmitSendout}
        />
        <PrivateRoute
          path="/training/managers/ask-job-demand"
          component={ManagersAskJobDemand}
        />
        <PrivateRoute
          path="/training/managers/candidates-feedback"
          component={ManagersCandidatesFeedback}
        />
        <PrivateRoute
          path="/training/managers/prepare-interviews"
          component={ManagersPrepareInterviews}
        />
        <PrivateRoute
          path="/training/managers/update-candidates"
          component={ManagersUpdateCandidates}
        />
        <PrivateRoute
          path="/training/managers/clients-projection"
          component={ManagersClientsProjections}
        />
        <PrivateRoute
          path="/training/managers/job-offer-document"
          component={ManagersJobOfferDocuments}
        />
        <PrivateRoute path="/training/managers" component={Managers} />
        {/* Start On Boarding Module  */}
        <PrivateRoute
          path="/onboarding/summary"
          component={OnboardingSummary}
        />
        {/* End On Boarding Module */}
        {/* IT Team */}
        <PrivateRoute
          path="/dkglobaladmin/it-teams/details"
          component={TeamSummary}
        />
        <PrivateRoute
          path="/dkglobaladmin/it-teams/add-new-feedback"
          component={AddNewFeedback}
        />
        <PrivateRoute
          path="/dkglobaladmin/it-teams/stories"
          component={TeamStories}
        />
        <PrivateRoute
          path="/dkglobaladmin/it-teams/backlogs"
          component={TeamBacklog}
        />
        <PrivateRoute path="/dkglobaladmin/it-teams/wip" component={TeamWIP} />
        <PrivateRoute
          path="/dkglobaladmin/it-teams/delivered"
          component={TeamDelivered}
        />
        <PrivateRoute
          path="/dkglobaladmin/it-teams/develop-bugs"
          component={TeamDeveloper}
        />
        <PrivateRoute
          path="/dkglobaladmin/it-teams/design-bugs"
          component={TeamDesigner}
        />

        <PrivateRoute
          path="/dkglobaladmin/it-teams/all-projects"
          component={TeamProjects}
        />

        <PrivateRoute
          path="/itteams/guidelines/dashboard"
          component={GuidelinesDashboard}
        />
        <PrivateRoute
          path="/itteams/guidelines/training"
          component={TeamMarketer}
        />
        <PrivateRoute
          path="/itteams/developertask/developer-tasks"
          component={TeamAjay}
        />
        <PrivateRoute
          path="/itteams/designertasks/designer-tasks"
          component={TeamAmit}
        />
        {/* End */}
        {/* Start Projections Page */}
        <PrivateRoute
          path="/projections/summary"
          component={ProjectionsSummary}
        />
        <PrivateRoute
          path="/projections/income-tax"
          component={ProjectionsIncomeTax}
        />
        {/* End Projections Page */}
        {/* Start Marketing Page */}
        <PrivateRoute path="/marketing/summary" component={MarketingSummary} />
        {/* End Marketing Page */}
        {/* Start Payments page */}
        <PrivateRoute path="/payments/summary" component={PaymentsSummary} />
        <PrivateRoute
          path="/payments/invoices/job-board"
          component={PayJobBoard}
        />
        <PrivateRoute
          path="/payments/invoices/employee"
          component={PayEmployee}
        />
        <PrivateRoute
          path="/payments/invoices/software"
          component={PaySoftware}
        />
        <PrivateRoute
          path="/payments/invoices/license"
          component={PayLicense}
        />
        <PrivateRoute path="/payments/invoices/legal" component={PayLegal} />
        <PrivateRoute
          path="/payments/training/overview"
          component={PayOverview}
        />
        <PrivateRoute
          path="/payments/training/dashboard"
          component={PayDashboard}
        />
        <PrivateRoute
          path="/payments/training/work-plan"
          component={PayWorkPlan}
        />
        <PrivateRoute
          path="/payments/training/training-notifications"
          component={PayNotifications}
        />
        <PrivateRoute
          path="/payments/training/candidate"
          component={PayCandidate}
        />
        <PrivateRoute
          path="/payments/training/training-jobs"
          component={PayJobs}
        />
        <PrivateRoute
          path="/payments/training/company"
          component={PayCompany}
        />
        <PrivateRoute path="/payments/training/kpi" component={PayKpi} />
        <PrivateRoute
          path="/payments/training/dkg-cloud"
          component={PayDkgCloud}
        />
        <PrivateRoute
          path="/payments/training/training-database"
          component={PayDatabase}
        />
        <PrivateRoute
          path="/payments/training/training-dkglobal"
          component={PayDkGlobal}
        />

        {/* End Payments Summary */}

        {/* Start HR Page */}
        <PrivateRoute path="/hr/dashboard" component={HrDashboard} />
        <PrivateRoute path="/hr/holidays" component={HRHolidays} />
        <PrivateRoute path="/hr/employee/dkg-team" component={HrEmpDkgTeam} />

        {/* End HR Page */}
        {/* Start Projects Page */}
        <PrivateRoute
          path="/projects/dashboard"
          component={ProjectsDashboard}
        />
        {/* Deepak Project Pages */}
        <PrivateRoute
          path="/projects/deepak/summary"
          component={ModuleProjectsDeepakSummary}
        />
        <PrivateRoute
          path="/projects/deepak/live/project-1"
          component={DeepakProjectOne}
        />
        <PrivateRoute
          path="/projects/deepak/live/project-2"
          component={DeepakProjectTwo}
        />
        <PrivateRoute
          path="/projects/deepak/live/project-3"
          component={DeepakProjectThree}
        />
        <PrivateRoute
          path="/projects/deepak/live/project-4"
          component={DeepakProjectFour}
        />
        <PrivateRoute
          path="/projects/deepak/live/project-5"
          component={DeepakProjectFive}
        />
        <PrivateRoute
          path="/projects/deepak/live/project-6"
          component={DeepakProjectSix}
        />
        <PrivateRoute
          path="/projects/deepak/live/summary"
          component={ModuleProjectsDeepakLive}
        />
        <PrivateRoute
          path="/projects/deepak/closed"
          component={ModuleProjectsDeepakClosed}
        />
        <PrivateRoute
          path="/projects/deepak/sales"
          component={ModuleProjectsDeepakSales}
        />
        <PrivateRoute
          path="/projects/deepak/kpi"
          component={ModuleProjectsDeepakKpi}
        />
        <PrivateRoute
          path="/projects/deepak/training"
          component={ModuleProjectsDeepakTraining}
        />
        <PrivateRoute
          path="/projects/deepak/profile"
          component={ModuleProjectsDeepakProfile}
        />

        {/* End */}

        {/* Training Module Start */}

        {/* Start Job Spec Page */}
        <PrivateRoute path="/job-spec/dashboard" component={JobSpecDashboard} />
        <PrivateRoute path="/job-spec/live-spec" component={JobSpecLivespec} />
        {/* <PrivateRoute path="/job-spec/create-spec" component={JobSpecInactivespec} /> */}
        <PrivateRoute path="/job-spec/create-spec" component={MainAddSpec} />
        <PrivateRoute path="/job-spec/apply-job" component={ApplyJob} />

        <PrivateRoute
          path="/job-spec/spec/:id/:id"
          component={MainSavedSpecTable}
        />

        <PrivateRoute path="/job-spec/:id" component={MainSavedSpec} />

        {/* Create Spec Pages */}
        {/* End */}

        {/* End */}

        {/* Luiza Project Pages */}
        <PrivateRoute
          path="/projects/luiza/summary"
          component={ModuleProjectsLuizaSummary}
        />
        <PrivateRoute
          path="/projects/luiza/live/project-1"
          component={LuizaProjectOne}
        />
        <PrivateRoute
          path="/projects/luiza/live/project-2"
          component={LuizaProjectTwo}
        />
        <PrivateRoute
          path="/projects/luiza/live/summary"
          component={ModuleProjectsLuizaLive}
        />
        <PrivateRoute
          path="/projects/luiza/closed"
          component={ModuleProjectsLuizaClosed}
        />
        <PrivateRoute
          path="/projects/luiza/sales"
          component={ModuleProjectsLuizaSales}
        />
        <PrivateRoute
          path="/projects/luiza/kpi"
          component={ModuleProjectsLuizaKpi}
        />
        <PrivateRoute
          path="/projects/luiza/training"
          component={ModuleProjectsLuizaTraining}
        />
        <PrivateRoute
          path="/projects/luiza/profile"
          component={ModuleProjectsLuizaProfile}
        />
        {/* End */}

        <PrivateRoute
          path="/projects/mariana/summary"
          component={ModuleProjectsMarianaSummary}
        />
        <PrivateRoute
          path="/projects/mariana/live/project-1"
          component={MarianaProjectOne}
        />
        <PrivateRoute
          path="/projects/mariana/live/project-2"
          component={MarianaProjectTwo}
        />
        <PrivateRoute
          path="/projects/mariana/live/project-3"
          component={MarianaProjectThree}
        />
        <PrivateRoute
          path="/projects/mariana/live/project-4"
          component={MarianaProjectFour}
        />
        <PrivateRoute
          path="/projects/mariana/live/summary"
          component={ModuleProjectsMarianaLive}
        />
        <PrivateRoute
          path="/projects/mariana/closed"
          component={ModuleProjectsMarianaClosed}
        />
        <PrivateRoute
          path="/projects/mariana/sales"
          component={ModuleProjectsMarianaSales}
        />
        <PrivateRoute
          path="/projects/mariana/kpi"
          component={ModuleProjectsMarianaKpi}
        />
        <PrivateRoute
          path="/projects/mariana/training"
          component={ModuleProjectsMarianaTraining}
        />
        <PrivateRoute
          path="/projects/mariana/profile"
          component={ModuleProjectsMarianaProfile}
        />

        <PrivateRoute
          path="/projects/marianatwo/project_1"
          component={ModuleProjectsMarianaTwoProjectOne}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_2"
          component={ModuleProjectsMarianaTwoProjectTwo}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_3"
          component={ModuleProjectsMarianaTwoProjectThree}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_4"
          component={ModuleProjectsMarianaTwoProjectFour}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_5"
          component={ModuleProjectsMarianaTwoProjectFive}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_6"
          component={ModuleProjectsMarianaTwoProjectSix}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_7"
          component={ModuleProjectsMarianaTwoProjectSeven}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_8"
          component={ModuleProjectsMarianaTwoProjectEight}
        />
        <PrivateRoute
          path="/projects/marianatwo/project_9"
          component={ModuleProjectsMarianaTwoProjectNine}
        />

        <PrivateRoute
          path="/projects/filip/summary"
          component={ModuleProjectsFilipSummary}
        />
        <PrivateRoute
          path="/projects/filip/live/summary"
          component={ModuleProjectsFilipLive}
        />
        <PrivateRoute
          path="/projects/filip/live/project-1"
          component={FilipProjectOne}
        />
        <PrivateRoute
          path="/projects/filip/live/project-2"
          component={FilipProjectTwo}
        />
        <PrivateRoute
          path="/projects/filip/closed/summary"
          component={ModuleProjectsFilipClosed}
        />
        <PrivateRoute
          path="/projects/filip/sales/summary"
          component={ModuleProjectsFilipSales}
        />
        <PrivateRoute
          path="/projects/filip/kpi/summary"
          component={ModuleProjectsFilipKpi}
        />
        <PrivateRoute
          path="/projects/filip/training/summary"
          component={ModuleProjectsFilipTraining}
        />
        <PrivateRoute
          path="/projects/filip/profile/summary"
          component={ModuleProjectsFilipProfile}
        />

        <PrivateRoute
          path="/projects/elis/summary"
          component={ModuleProjectsElisSummary}
        />
        <PrivateRoute
          path="/projects/elis/live/project-1"
          component={ElisProjectOne}
        />
        <PrivateRoute
          path="/projects/elis/live/project-2"
          component={ElisProjectTwo}
        />
        <PrivateRoute
          path="/projects/elis/live/project-3"
          component={ElisProjectThree}
        />
        <PrivateRoute
          path="/projects/elis/live/project-4"
          component={ElisProjectFour}
        />
        <PrivateRoute
          path="/projects/elis/live/project-5"
          component={ElisProjectFive}
        />
        <PrivateRoute
          path="/projects/elis/live/summary"
          component={ModuleProjectsElisLive}
        />
        <PrivateRoute
          path="/projects/elis/closed/summary"
          component={ModuleProjectsElisClosed}
        />
        <PrivateRoute
          path="/projects/elis/sales/summary"
          component={ModuleProjectsElisSales}
        />
        <PrivateRoute
          path="/projects/elis/kpi/summary"
          component={ModuleProjectsElisKpi}
        />
        <PrivateRoute
          path="/projects/elis/training/summary"
          component={ModuleProjectsElisTraining}
        />
        <PrivateRoute
          path="/projects/elis/profile/summary"
          component={ModuleProjectsElisProfile}
        />

        <PrivateRoute
          path="/projects/tehreem/summary"
          component={ModuleProjectsTehreempSummary}
        />
        <PrivateRoute
          path="/projects/tehreem/live/project-1"
          component={TehreemProjectOne}
        />
        <PrivateRoute
          path="/projects/tehreem/live/project-2"
          component={TehreemProjectTwo}
        />
        <PrivateRoute
          path="/projects/tehreem/live/project-3"
          component={TehreemProjectThree}
        />
        <PrivateRoute
          path="/projects/tehreem/live/project-4"
          component={TehreemProjectFour}
        />
        <PrivateRoute
          path="/projects/tehreem/live/project-5"
          component={TehreemProjectFive}
        />
        <PrivateRoute
          path="/projects/tehreem/live/summary"
          component={ModuleProjectsTehreemLive}
        />
        <PrivateRoute
          path="/projects/tehreem/closed/summary"
          component={ModuleProjectsTehreemClosed}
        />
        <PrivateRoute
          path="/projects/tehreem/sales/summary"
          component={ModuleProjectsTehreempSales}
        />
        <PrivateRoute
          path="/projects/tehreem/kpi/summary"
          component={ModuleProjectsTehreempKpi}
        />
        <PrivateRoute
          path="/projects/tehreem/training/summary"
          component={ModuleProjectsTehreemTraining}
        />
        <PrivateRoute
          path="/projects/tehreem/profile/summary"
          component={ModuleProjectsTehreemProfile}
        />

        <PrivateRoute path="/projects/onur" component={ModuleProjectsOnur} />
        {/* End Projects Page */}
        {/* Start KPI's Page */}
        <PrivateRoute path="/kpi/dashboard" component={MainKPIDashboard} />
        {/* End KPI's Page */}
        {/* Main Department FAQ */}
        <PrivateRoute
          path="/maindepartment/main-faq/dashboard"
          component={MainDepartmentFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/recruitment-faq"
          component={RecruitmentFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/accounting-faq"
          component={AccountingFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/resource-faq"
          component={ResourceFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/marketing-faq"
          component={MarketingFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/legal-faq"
          component={LegalFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/management-faq"
          component={ManagementFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/newbusiness-faq"
          component={NewBusinessFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/training-faq"
          component={MainTrainingFaq}
        />
        <PrivateRoute
          path="/maindepartment/main-faq/itteam-faq"
          component={ItTeamFaq}
        />
        <PrivateRoute
          path="/resource/resource-hr/faq"
          component={HrDepartmentFaq}
        />

        {/* End */}

        {/* Finance Payments Start */}
        <PrivateRoute
          path="/dkglobalfinance/finance-payments/dashboard"
          component={PaymentsDashboard}
        />
        {/* End */}

        {/* Start Main DB Page */}
        <PrivateRoute path="/database/summary" component={MainDBSuMM} />
        {/* End Main DB Page */}
        {/* Start MyFiles Page */}
        <PrivateRoute path="/my-files/dashboard" component={MyFilesDashboard} />
        <PrivateRoute
          path="/my-files/alerts/alerts1"
          component={MyFilesAlerts}
        />
        <PrivateRoute
          path="/my-files/director/director1"
          component={MyFilesDirector}
        />
        <PrivateRoute
          path="/my-files/licenses/license1"
          component={MyFilesLicenses}
        />
        <PrivateRoute
          path="/my-files/bank-account/bank-account1"
          component={MyFilesMyacc}
        />
        <PrivateRoute
          path="/my-files/accounts/account1"
          component={MyFilesAccount}
        />
        <PrivateRoute
          path="/my-files/b1-returns/b1-return1"
          component={MyFilesReturns}
        />
        <PrivateRoute
          path="/my-files/tax-office/tax-office1"
          component={MyFilesTaxOffice}
        />
        <PrivateRoute
          path="/my-files/certificates/certificate1"
          component={MyFilesCertificates}
        />
        <PrivateRoute
          path="/my-files/membership/membership1"
          component={MyFilesMembnership}
        />
        <PrivateRoute
          path="/my-files/agreements/agreement1"
          component={MyFilesAgreements}
        />
        {/* End MyFiles Page */}
        {/* Start Modules For Database Table  */}
        <PrivateRoute path="/db-table/summary" component={DBTableSuMM} />
        <PrivateRoute
          path="/db-table/search-db"
          component={SearchDBDataTable}
        />
        <PrivateRoute
          path="/db-table/data-twenty-one"
          component={DatetableTwentyOne}
        />
        <PrivateRoute
          path="/db-table/data-twenty-two"
          component={DatetableTwentyTwo}
        />
        <PrivateRoute
          path="/db-table/data-twenty-twenty"
          component={DatetableTwenty}
        />
        <PrivateRoute
          path="/db-table/data-twenty-nineteen"
          component={DatatwentyNineteen}
        />
        <PrivateRoute
          path="/db-table/data-twenty-eighteen"
          component={DatatwentyEighteen}
        />
        <PrivateRoute
          path="/db-table/data-twenty-seventeen"
          component={DatatwentySeventeen}
        />
        <PrivateRoute
          path="/db-table/data-twenty-sixteen"
          component={DatatwentySixteen}
        />
        <PrivateRoute path="/db-table/blocked" component={DBBlockedTable} />
        {/* <PrivateRoute path="/db-table/search-cv" component={DBSearchcv} /> */}
        <PrivateRoute path="/db-table/mailshots" component={MailShoots} />
        <PrivateRoute
          path="/db-table/projects/talent-pool"
          component={ProjectTalentPool}
        />
        <PrivateRoute
          path="/db-table/projects/selected"
          component={SelectedProjects}
        />
        <PrivateRoute
          path="/db-table/projects/saved-projects"
          component={CandSavedProjects}
        />
        {/* End Modules For Database Table */}
        {/* Start Module For Full Database  */}
        <PrivateRoute path="/full-database/summary" component={FulldbSuMM} />
        <PrivateRoute path="/full-database/search-cv" component={DBSearchcv} />
        <PrivateRoute
          path="/full-database/data-table"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-five"
          component={FullDBDataTable}
        />
        <PrivateRoute
          path="/full-database/archived"
          component={DBArchivedTable}
        />
        <PrivateRoute
          path="/full-database/visitors-logs"
          component={FullDBVistorslog}
        />

        {/* <PrivateRoute
          path="/full-database/twenty-four"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-three"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-two"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-one"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-table"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-nineteen"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-eighteen"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-seventeen"
          component={DataBDataTable}
        />
        <PrivateRoute
          path="/full-database/twenty-sixteen"
          component={DataBDataTable}
        /> */}
        {/* End Module For Full Database */}
        {/* <Route exact path="/dkglobaladmin/visit-website" component={VisitWebsite} /> */}
        {/* <Route exact path="/visit-sites" component={Visitsite} /> */}
        {/* Start My Account Module  */}
        <MyaccountRoute
          path="/js-account/mailbox"
          component={MyaccountMailbox}
        />
        <MyaccountRoute
          path="/js-account/dashboard"
          component={MyaccountDashboard}
        />
        <MyaccountRoute
          path="/js-account/profile"
          component={MyaccountProfile}
        />
        <MyaccountRoute
          path="/js-account/test-table"
          component={MyaccTestTable}
        />
        <MyaccountRoute
          path="/js-account/settings/privacy"
          component={MyaccPrivacy}
        />
        <MyaccountRoute
          path="/js-account/settings/terms-of-use"
          component={MyaccTermsofUse}
        />
        <MyaccountRoute
          path="/js-account/settings/my-files"
          component={MyaccMyfiles}
        />
        <MyaccountRoute
          path="/js-account/settings/gdpr-explained"
          component={MyaccGdpr}
        />
        {/* <MyaccountRoute path="/js-account/emp-model/candidates" component={Myaccfacilities} /> */}
        <MyaccountRoute
          path="/js-account/settings/change-logins"
          component={MyaccResetLogin}
        />
        <MyaccountRoute
          path="/js-account/settings/delete-account"
          component={MyaccDeleteAcc}
        />
        <MyaccountRoute
          path="/js-account/settings/notifications"
          component={MyacctNotifiation}
        />
        <MyaccountRoute
          path="/js-account/settings/contact-us"
          component={MyaccContact}
        />
        <MyaccountRoute
          path="/js-account/settings/feedback"
          component={MyaccFeedback}
        />
        <MyaccountRoute
          path="/js-account/settings/cookies"
          component={MyaccCookies}
        />
        <MyaccountRoute path="/js-account/settings/faq" component={MyaccFAQ} />
        <MyaccountRoute
          path="/js-account/dkg-services/pricing"
          component={MyaccServicespricing}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/testimonial"
          component={MyaccServicestestiomonial}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/feedbacks"
          component={MyaccServicesfeedbacks}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/faq-s"
          component={MyaccServicesfaq}
        />

        <MyaccountRoute
          path="/js-account/dkg-services/create-cv"
          component={MyaccServicesOverview}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/consultants"
          component={MyaccServicesConsults}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/search-job"
          component={MyaccBenfitService}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/pass-interview"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/assessment"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/communication"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/using-internet"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/work-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/study-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/work-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/study-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/plan-carrer"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/js-account/emp-model/crm-license"
          component={MyaccCRMLinesense}
        />
        <MyaccountRoute
          path="/js-account/emp-model/calls-sms"
          component={MyaccCallSms}
        />
        <MyaccountRoute
          path="/js-account/emp-model/ms-office"
          component={MyaccMSoffice}
        />
        <MyaccountRoute
          path="/js-account/emp-model/ms-mailbox"
          component={MyaccMSMailbox}
        />
        <MyaccountRoute
          path="/js-account/job-profile"
          component={MyaccJobProfile}
        />
        <MyaccountRoute path="/js-account/payments" component={MyaccPayment} />
        <MyaccountRoute path="/js-account/invoices" component={MyaccInvoice} />
        <MyaccountRoute
          path="/js-account/contracts"
          component={MyaccContract}
        />
        <MyaccountRoute path="/js-account/kpi" component={MyaccKpi} />
        <MyaccountRoute
          path="/js-account/interviews"
          component={MyaccInterview}
        />
        <MyaccountRoute
          path="/js-account/job-offers"
          component={MyaccJoboffer}
        />
        <MyaccountRoute path="/js-account/offers" component={MyaccReferrals} />
        <MyaccountRoute path="/js-account/leaves" component={MyaccMyresume} />
        <MyaccountRoute
          path="/js-account/my-profile/summary"
          component={MyaccProfileSuMM}
        />
        <MyaccountRoute
          path="/js-account/my-profile/account-info"
          component={MyaccProfileAccInfo}
        />
        <MyaccountRoute
          path="/js-account/my-profile/contact-info"
          component={MyaccProfileContactInfo}
        />
        <MyaccountRoute
          path="/js-account/my-profile/contact"
          component={MyaccProfileContact}
        />
        <MyaccountRoute
          path="/js-account/dkg-services/summary"
          component={MyaccPaidservover}
        />
        <MyaccountRoute
          path="/js-account/my-profile/profile"
          component={MyaccMyProfile}
        />
        <MyaccountRoute
          path="/js-account/my-profile/resume"
          component={MyaccResume}
        />
        <MyaccountRoute
          path="/js-account/my-profile/cover-letter"
          component={MyaccCoverLetter}
        />
        <MyaccountRoute
          path="/js-account/my-profile/upload-cv"
          component={MyaccUploadCv}
        />
        <MyaccountRoute
          path="/js-account/my-profile/download-cv"
          component={MyaccDownloadCv}
        />
        <MyaccountRoute
          path="/js-account/my-profile/my-documents"
          component={MyaccDocuments}
        />
        <MyaccountRoute
          path="/js-account/my-profile/my-referrals"
          component={MyaccMyReferrals}
        />
        {/* <PrivateRoute path="/recruitment/dashboard/holidays" component={Holidays} /> */}
        {/* End My Account Module */}
        {/* Start Recuiter Account Module */}
        <RcaccountRoute
          path="/rc-account/dashboard"
          component={RcaccountDashboard}
        />
        {/* End Recuiter Account Module */}
        {/* Training Inner Module Start */}

        <MainPrivateRoute
          path="/dashboard/training/recruitment-training"
          component={TrainingRecModule}
        />
        {/* End */}

        {/* Training Recruitment Module Pages */}

        <PrivateRoute
          path="/training/recruitmentmodule/account-manager/dashboard"
          component={RecAccountManager}
        />
        <PrivateRoute
          path="/training/recruitmentmodule/recruitment-manager/dashboard"
          component={RecRecruitmentManager}
        />
        <PrivateRoute
          path="/training/recruitmentmodule/recruitment-consultant/dashboard"
          component={RecRecruitmentConsultant}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/business/sample"
          component={RecConsultantsBusiness}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/business/sample-2"
          component={RecBusinessSampleTwo}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/business/sample-3"
          component={RecBusinessSampleThree}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/business/sample-4"
          component={RecBusinessSampleFour}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/rec-dep/sample"
          component={RecConsultantsRecDep}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/rec-dep/sample-2"
          component={RecRecDepSampleTwo}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/rec-dep/sample-3"
          component={RecRecDepSampleThree}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/rec-dep/sample-4"
          component={RecRecDepSampleFour}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/job-profile/sample"
          component={RecJobProfile}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/process/sample"
          component={RecProcess}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/tools/sample"
          component={RecTools}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/mailbox/sample"
          component={RecMailbox}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/kpi/sample"
          component={RecKpi}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/csf/sample"
          component={RecCsf}
        />
        <PrivateRoute
          path="/trainingmodule/recruitment-consultants/faq/sample"
          component={RecFaq}
        />
        <PrivateRoute
          path="/training/recruitmentmodule/database-admin/dashboard"
          component={RecDatabaseAdmin}
        />
        <PrivateRoute
          path="/training/recruitmentmodule/ads-post-admin/dashboard"
          component={RecAdsPostAdmin}
        />

        {/* End */}

        {/* Main Management  Page Start */}
        {/* Main Management Page End */}
        {/* Training Pages Start  */}
        {/* <PrivateRoute exact path="/dkglobaladmin/it-teams/details" component={VisitWebsite} /> */}
        <PrivateRoute
          path="/dkglobaladmin/admintraining/dashboard"
          component={DkAdminTraningDashboard}
        />
        <PrivateRoute
          path="/dkglobaladmin/statusadmintraining/statusdashboard"
          component={DkAdminTraningDashboard}
        />
        <PrivateRoute
          path="/dkglobaladmin/managementadmintraining/managementdashboard"
          component={DkAdminTraningDashboard}
        />
        <PrivateRoute
          path="/dkglobaladmin/onboardingadmintraining/onboardingdashboard"
          component={DkAdminTraningDashboard}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/overview"
          component={TrainingSchduleOverView}
        />

        {/* Model 1 Start */}
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/overview"
          component={DkAdminTrainingSchedule}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-one"
          component={DkAdminDayOneFullTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-two"
          component={DkAdminDayTwoFullTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-three"
          component={DkAdminDayThreeFullTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-four"
          component={DkAdminDayFourFullTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-five"
          component={DkAdminDayFiveFullTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-six"
          component={DkAdminDaySixPartTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-seven"
          component={DkAdminDaySevenPartTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-eight"
          component={DkAdminDayEightPartTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-nine"
          component={DkAdminDayNinePartTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/day-ten"
          component={DkAdminDayTenPartTime}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/final-accessment"
          component={DkAdminFinalAccessment}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/schedule/training-status"
          component={DkAdminTrainingStatus}
        />
        {/* End */}

        {/* Training Common Start */}

        <PrivateRoute
          path="/training/common-training/dkg-crm/overview"
          component={CommonTrainingDkgCrm}
        />
        <PrivateRoute
          path="/training/common-training/emails/overview"
          component={CommonTrainingEmails}
        />
        <PrivateRoute
          path="/training/common-training/calls/overview"
          component={CommonTrainingCalls}
        />
        <PrivateRoute
          path="/training/common-training/tasks/overview"
          component={CommonTrainingTasks}
        />
        <PrivateRoute
          path="/training/common-training/workplan/overview"
          component={CommonTrainingWorkplan}
        />
        <PrivateRoute
          path="/training/common-training/pipeline/overview"
          component={CommonTrainingPipeline}
        />
        <PrivateRoute
          path="/training/common-training/kpi/overview"
          component={CommonTrainingKpi}
        />
        <PrivateRoute
          path="/training/common-training/targets/overview"
          component={CommonTrainingTargets}
        />

        {/* End */}

        {/* Model 2 Start */}

        <PrivateRoute
          path="/dkglobaladmin/admintraining/model-2/overview"
          component={SheduleModelTwo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/model-3/overview"
          component={SheduleModelThree}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/status"
          component={SheduleStatus}
        />

        {/* End */}

        <PrivateRoute
          path="/dkglobaladmin/admintraining/completion/overview"
          component={DkAdminCompletionOverview}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/about-dkg"
          component={DkAdminTrainingAboutDkg}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/welcome"
          component={DkAdminTrainingOnboarding}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/dkg-team"
          component={DkAdminTrainingDkgTeam}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/timetable"
          component={DkAdminTrainingTimeTable}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/technical"
          component={DkAdminTrainingTechnical}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/account"
          component={DkAdminTrainingAccount}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/kpi-csf"
          component={DkAdminTrainingKpiCsf}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/onboarding-training-progress"
          component={DkAdminTrainingProgress}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/introduction"
          component={DkAdminTrainingIntroduction}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/conducts"
          component={DkAdminTrainingConducts}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/contracts"
          component={DkAdminTrainingContracts}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/onboarding/training"
          component={DkAdminOnboardingTraining}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/common-training"
          component={DkAdminCommonTraining}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/overview"
          component={DkAdminCommonOverview}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/established"
          component={DkAdminCommonEstablished}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/business"
          component={DkAdminCommonBusiness}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/industry"
          component={DkAdminCommonIndustry}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/services"
          component={DkAdminCommonServices}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/clients"
          component={DkAdminCommonClient}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/suppliers"
          component={DkAdminCommonSuppliers}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/completion"
          component={DkAdminCommonCompletion}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/employees"
          component={DkAdminCommonEmployees}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/future-plans"
          component={DkAdminCommonFuturePlans}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/social-media"
          component={DkAdminCommonSocialMedia}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/contact-details"
          component={DkAdminCommonContactDetails}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/structure"
          component={DkAdminCommonStructure}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/process"
          component={DkAdminCommonProcess}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/edit-process"
          component={DkAdminCommonEditTeamRoles}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/trainings"
          component={DkAdminCommonTrainings}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/pipelines"
          component={DkAdminCommonPipelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/edit-pipelines"
          component={DkAdminEditCommonPipelinesManagement}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/tools"
          component={DkAdminCommonTools}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/masterbox"
          component={DkAdminCommonMasterbox}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/common-clients"
          component={DkAdminCommonClients}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkg-guidelines"
          component={DkAdminCommonDkgGuidelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkg-pipelines"
          component={DkAdminCommonDkgPipelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/new-cv"
          component={DkAdminDkgPipelinesNewCv}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/screening"
          component={DkAdminDkgPipelinesScreening}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/reminders"
          component={DkAdminDkgPipelinesReminders}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/recruiters"
          component={DkAdminDkgPipelinesRecruiters}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/account-m"
          component={DkAdminDkgPipelinesAccountM}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/job-offers"
          component={DkAdminDkgPipelinesJobOffers}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/escalated"
          component={DkAdminDkgPipelinesEscalated}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/on-hold"
          component={DkAdminDkgPipelinesOnHold}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/dkgpipelines/closed"
          component={DkAdminDkgPipelinesClosed}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/work-plan"
          component={DkAdminCommonWorkPlan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/my-task"
          component={DkAdminCommonMyTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/resource"
          component={DkAdminCommonResource}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/sales"
          component={DkAdminCommonSales}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/payments"
          component={DkAdminCommonPayments}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/jobs"
          component={DkAdminCommonJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/candidates"
          component={DkAdminCommonCandidates}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/outlook"
          component={DkAdminCommonOutlook}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/emails"
          component={DkAdminCommonEmails}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/calls"
          component={DkAdminCommonCalls}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/sms"
          component={DkAdminCommonSms}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/commontraining/chats"
          component={DkAdminCommonChats}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-details"
          component={DkAdminTrainingWorkProcess}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/structure"
          component={DkAdminJobDetailStructure}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/team-info"
          component={DkAdminJobDetailTeamInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-skills"
          component={DkAdminJobDetailJobSkills}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-task"
          component={DkAdminJobDetailJobTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-quality"
          component={DkAdminJobDetailJobQuality}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-volume"
          component={DkAdminJobDetailJobVolume}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-queries"
          component={DkAdminJobDetailJobQueries}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-training"
          component={DkAdminJobDetailJobTraining}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/job-delegation"
          component={DkAdminJobDetailJobDelegation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/preparation"
          component={DkAdminResourcePreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/tools-login"
          component={DkAdminPreparationToolsLogin}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/team-greeting"
          component={DkAdminPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/dkg-crm-tabs"
          component={DkAdminPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/review-mailbox"
          component={DkAdminPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/review-live-jobs"
          component={DkAdminPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/review-pipelines"
          component={DkAdminPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/review-workplan"
          component={DkAdminPreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/action"
          component={DkAdminResourceAction}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/sequence"
          component={DkAdminActionSequence}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/set-priority"
          component={DkAdminActionSetPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/action-priority"
          component={DkAdminActionPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/create-new-task"
          component={DkAdminActionCreateNewTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/follow-workplan"
          component={DkAdminActionFollowWorkPlan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/performance"
          component={DkAdminResourceReport}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/criteria"
          component={DkAdminPerformanceCriteria}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/reviews"
          component={DkAdminPerformanceReviews}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/reporting"
          component={DkAdminPerformanceReporting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/knowledge-base"
          component={DkAdminResourceKnowledgeBase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/search-info"
          component={DkAdminKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/task-info"
          component={DkAdminKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/tools-info"
          component={DkAdminKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/candidates"
          component={DkAdminKnowledgeCandidates}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/clients"
          component={DkAdminKnowledgeClients}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/jobs"
          component={DkAdminKnowledgeJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/manage-ads"
          component={DkAdminResourceManageAds}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/apps-management"
          component={DkAdminResourceAppsManagement}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/manage-jobs"
          component={DkAdminResourceManageJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/create-specs"
          component={DkAdminResourceCreateSpecs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/social-media"
          component={DkAdminResourceSocialMedia}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/dkg-database"
          component={DkAdminResourceDkgDatabase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/pipelines-kpi"
          component={DkAdminResourcePipelinesKpi}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/ads-budget"
          component={DkAdminResourceAdsBudget}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/ad-campaign"
          component={DkAdminResourceAdCampaign}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource/ads-tracker"
          component={DkAdminResourceAdsTracker}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/training-resource"
          component={DkAdminTrainingResource}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-details"
          component={DkAdminAdminWorkProcess}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/structure"
          component={DkAdminAdminJobDetailsStructure}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/team-info"
          component={DkAdminAdminJobDetailsTeamInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-skills"
          component={DkAdminAdminJobDetailsJobSkills}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-task"
          component={DkAdminAdminJobDetailsJobTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-quality"
          component={DkAdminAdminJobDetailsJobQuality}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-volume"
          component={DkAdminAdminJobDetailsJobVolume}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-queries"
          component={DkAdminAdminJobDetailsJobQueries}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-training"
          component={DkAdminAdminJobDetailsJobTraining}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/job-delegation"
          component={DkAdminAdminJobDetailsJobDelegation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/preparation"
          component={DkAdminAdminPreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/tools-login"
          component={DkAdminAdminPreparationToolsLogin}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/team-greeting"
          component={DkAdminAdminPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/dkg-crm-tabs"
          component={DkAdminAdminPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/review-mailbox"
          component={DkAdminAdminPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/review-live-jobs"
          component={DkAdminAdminPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/review-pipelines"
          component={DkAdminAdminPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/review-workplan"
          component={DkAdminAdminPreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/action"
          component={DkAdminAdminAction}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sequence"
          component={DkAdminAdminActionSequence}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/set-priority"
          component={DkAdminAdminActionSetPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/admins-action-priority"
          component={DkAdminAdminActionPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/create-new-task"
          component={DkAdminAdminActionCreateNewTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/follow-workplan"
          component={DkAdminAdminActionFollowWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/performance"
          component={DkAdminAdminReport}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/criteria"
          component={DkAdminAdminsPerformanceCriteria}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviews"
          component={DkAdminAdminsPerformanceReviews}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reporting"
          component={DkAdminAdminsPerformanceReporting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/knowledge-base"
          component={DkAdminAdminKnowledgeBase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/search-info"
          component={DkAdminAdminsKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/task-info"
          component={DkAdminAdminsKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/tools-info"
          component={DkAdminAdminsKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/candidates"
          component={DkAdminAdminsKnowledgeCandidates}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/clients"
          component={DkAdminAdminsKnowledgeClients}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/jobs"
          component={DkAdminAdminsKnowledgeJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/send-bulk-email"
          component={DkAdminAdminSendBulkEmail}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/situations"
          component={DkAdminAdminsSendBeSituations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/task-rules"
          component={DkAdminAdminsSendBeTaskRules}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/how-to-do"
          component={DkAdminAdminsSendBeHowToDo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/create-list"
          component={DkAdminAdminsSendBeCreateList}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/create-template"
          component={DkAdminAdminsSendBeCreateTemplate}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/review-template"
          component={DkAdminAdminsSendBeReviewTemplate}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/send-bulk-emails"
          component={DkAdminAdminsSendBeSendBulkEmails}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendbulkemail/track-responses"
          component={DkAdminAdminsSendBeTrackResponse}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/search-database"
          component={DkAdminAdminSearchDatabase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/situations"
          component={DkAdminAdminsSearchDaSituations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/task-rules"
          component={DkAdminAdminsSearchDaTaskRules}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/how-to-do"
          component={DkAdminAdminsSearchDaHowToDo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/work-plan"
          component={DkAdminAdminsSearchDaWorkPlan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/resource"
          component={DkAdminAdminsSearchDaResource}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/how-to-search"
          component={DkAdminAdminsSearchDaHowToSearch}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/create-project"
          component={DkAdminAdminsSearchDaCreateProject}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/searchdatabase/send-mini-specs"
          component={DkAdminAdminsSearchDaSendMiniSpecs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/assign-priority"
          component={DkAdminAdminAssignPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/assignpriority/indicators"
          component={DkAdminAssignPriorityIndicators}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/assignpriority/situations"
          component={DkAdminAssignPrioritySituations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/assignpriority/today-based"
          component={DkAdminAssignPriorityTodayBased}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/assignpriority/live-job-based"
          component={DkAdminAssignPriorityLiveJobBased}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/assignpriority/language-based"
          component={DkAdminAssignPriorityLanguageBased}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/priority-screening"
          component={DkAdminAdminPriorityScreening}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/priorityscreening/preparation"
          component={DkAdminAdminsPriorityScPreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/priorityscreening/action-tools"
          component={DkAdminAdminsPriorityScActionTools}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/priorityscreening/phone-call"
          component={DkAdminAdminsPriorityScPhoneCall}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/priorityscreening/send-email"
          component={DkAdminAdminsPriorityScSendEmail}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/priorityscreening/send-sms"
          component={DkAdminAdminsPriorityScSendSms}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/close-decision"
          component={DkAdminAdminCloseDecision}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/situations"
          component={DkAdminAdminsCaseDeSituations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/task-rules"
          component={DkAdminAdminsCaseDeTaskRules}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/how-to-do"
          component={DkAdminAdminsCaseDeHowToDo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/discussion"
          component={DkAdminAdminsCaseDeDiscussion}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/escalation"
          component={DkAdminAdminsCaseDeEscalation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/comments"
          component={DkAdminAdminsCaseDeComments}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/casedecision/update-pipeline"
          component={DkAdminAdminsCaseDeUpdatePipeline}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/review-new-cv"
          component={DkAdminAdminReviewNewCv}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/review-profiles"
          component={DkAdminAdminUpdateProfiles}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/contacts"
          component={DkAdminAdminsReviewContacts}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/location"
          component={DkAdminAdminsReviewLocation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/languages"
          component={DkAdminAdminsReviewLanguages}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/job-titles"
          component={DkAdminAdminsReviewJobTitles}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/job-experience"
          component={DkAdminAdminsReviewJobExperience}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/qualifications"
          component={DkAdminAdminsReviewQualifications}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/technical-skills"
          component={DkAdminAdminsReviewTechnicalSkills}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/career-seniority"
          component={DkAdminAdminsReviewCareerSeniority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/reviewprofiles/live-job-suitability"
          component={DkAdminAdminsReviewLiveJobSuitability}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/update-profiles"
          component={DkAdminAdminMatchWithJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/status"
          component={DkAdminAdminsUpdateStatus}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/contacts"
          component={DkAdminAdminsUpdateContacts}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/category"
          component={DkAdminAdminsUpdateCategory}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/cv-source"
          component={DkAdminAdminsUpdateCvSource}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/locations"
          component={DkAdminAdminsUpdateLocations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/nationality"
          component={DkAdminAdminsUpdateNationality}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/languages"
          component={DkAdminAdminsUpdateLanguages}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/assign-job"
          component={DkAdminAdminsUpdateAssignJob}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/updateprofiles/case-comment"
          component={DkAdminAdminsUpdateCaseComment}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/search-database"
          component={DkAdminAdminRearchDatabase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/mini-job-specs"
          component={DkAdminAdminMiniJobSpecs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/minijobspecs/create"
          component={DkAdminAdminsMiniJobSpecCreate}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/minijobspecs/outlook"
          component={DkAdminAdminsMiniJobSpecOutlook}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/minijobspecs/save-spec"
          component={DkAdminAdminsMiniJobSpecSaveSpec}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/minijobspecs/email-spec"
          component={DkAdminAdminsMiniJobSpecEmailSpec}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/minijobspecs/send-sms"
          component={DkAdminAdminsMiniJobSpecSendSms}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/send-reminders"
          component={DkAdminAdminSendReminders}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/situations"
          component={DkAdminAdminsSendReSituations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/task-rules"
          component={DkAdminAdminsSendReTaskRules}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/how-to-do"
          component={DkAdminAdminsSendReHowToDo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/1st-reminder"
          component={DkAdminAdminsSendReFirstReminder}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/2nd-reminder"
          component={DkAdminAdminsSendReSecondReminder}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/3rd-reminder"
          component={DkAdminAdminsSendReThirdReminder}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/final-reminder"
          component={DkAdminAdminsSendReFinalReminder}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/sendreminders/case-discussion"
          component={DkAdminAdminsSendReCaseDiscussion}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/not-reachables"
          component={DkAdminAdminNotReachable}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/situations"
          component={DkAdminAdminsNotReSituations}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/task-rules"
          component={DkAdminAdminsNotReTaskRules}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/how-to-do"
          component={DkAdminAdminsNotReHowToDo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/preparation"
          component={DkAdminAdminsNotRePreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/action-tools"
          component={DkAdminAdminsNotReActionTools}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/phone-call"
          component={DkAdminAdminsNotRePhoneCall}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/send-email"
          component={DkAdminAdminsNotReSendEmail}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins/notreachables/send-sms"
          component={DkAdminAdminsNotReSendSms}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/admins"
          component={DkAdminAdmins}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-details"
          component={DkAdminRecruitersWorkProcess}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/structure"
          component={DkAdminRecruitersJobDetailsStructure}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/team-info"
          component={DkAdminRecruitersJobDetailsTeamInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-skills"
          component={DkAdminRecruitersJobDetailsJobSkills}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-task"
          component={DkAdminRecruitersJobDetailsJobTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-quality"
          component={DkAdminRecruitersJobDetailsJobQuality}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-volume"
          component={DkAdminRecruitersJobDetailsJobVolume}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-queries"
          component={DkAdminRecruitersJobDetailsJobQueries}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-training"
          component={DkAdminRecruitersJobDetailsJobTraining}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/job-delegation"
          component={DkAdminRecruitersJobDetailsJobDelegation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/preparation"
          component={DkAdminRecruitersPreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/tools-login"
          component={DkAdminRecruitersPreparationToolsLogin}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/team-greeting"
          component={DkAdminRecruitersPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/dkg-crm-tabs"
          component={DkAdminRecruitersPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/review-mailbox"
          component={DkAdminRecruitersPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/review-live-jobs"
          component={DkAdminRecruitersPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/review-pipelines"
          component={DkAdminRecruitersPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/review-workplan"
          component={DkAdminRecruitersPreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/action"
          component={DkAdminRecruitersAction}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/sequence"
          component={DkAdminRecruitersActionSequence}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/set-priority"
          component={DkAdminRecruitersActionSetPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/recruiters-action-priority"
          component={DkAdminRecruitersActionPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/create-new-task"
          component={DkAdminRecruitersActionCreateNewTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/follow-workplan"
          component={DkAdminRecruitersActionFollowWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/performance"
          component={DkAdminRecruitersReport}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/criteria"
          component={DkAdminRecruitersPerformanceCriteria}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/reviews"
          component={DkAdminRecruitersPerformanceReviews}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/reporting"
          component={DkAdminRecruitersPerformanceReporting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/knowledge-base"
          component={DkAdminRecruitersKnowledgeBase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/search-info"
          component={DkAdminRecruitersKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/tasks-info"
          component={DkAdminRecruitersKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/tools-info"
          component={DkAdminRecruitersKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/candidates"
          component={DkAdminRecruitersKnowledgeCandidates}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/clients"
          component={DkAdminRecruitersKnowledgeClients}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/jobs"
          component={DkAdminRecruitersKnowledgeJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/interview-call"
          component={DkAdminRecruitersInterviewCall}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/full-job-spec"
          component={DkAdminRecruitersFullJobSpec}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/case-queries"
          component={DkAdminRecruitersCaseQueries}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/live-new-query"
          component={DkAdminRecruitersLiveNewQuery}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/update-profile"
          component={DkAdminRecruitersUpdateProfile}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/create-job-spec"
          component={DkAdminRecruitersCreateJobSpec}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/case-decision"
          component={DkAdminRecruitersCaseDecision}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/clients-portals"
          component={DkAdminRecruitersClientsPortals}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/make-sendout"
          component={DkAdminRecruitersMakeSendout}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters/not-reachable"
          component={DkAdminRecruitersNotReachable}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/recruiters"
          component={DkAdminRecruiters}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-details"
          component={DkAdminManagersWorkProcess}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/structure"
          component={DkAdminManagersJobDetailsStructure}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/team-info"
          component={DkAdminManagersJobDetailsTeamInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-skills"
          component={DkAdminManagersJobDetailsJobSkills}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-task"
          component={DkAdminManagersJobDetailsJobTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-quality"
          component={DkAdminManagersJobDetailsJobQuality}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-volume"
          component={DkAdminManagersJobDetailsJobVolume}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-queries"
          component={DkAdminManagersJobDetailsJobQueries}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-training"
          component={DkAdminManagersJobDetailsJobTraining}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-delegation"
          component={DkAdminManagersJobDetailsJobDelegation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/preparation"
          component={DkAdminManagersPreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/tools-login"
          component={DkAdminManagersPreparationToolsLogin}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/team-greeting"
          component={DkAdminManagersPreparationTeamGreeting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/dkg-crm-tabs"
          component={DkAdminManagersPreparationDkgCrmTabs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/review-mailbox"
          component={DkAdminManagersPreparationReviewMailbox}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/review-live-jobs"
          component={DkAdminManagersPreparationReviewLiveJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/review-pipelines"
          component={DkAdminManagersPreparationReviewPipelines}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/review-workplan"
          component={DkAdminManagersPreparationReviewWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/action"
          component={DkAdminManagersAction}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/sequence"
          component={DkAdminManagersActionSequence}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/set-priority"
          component={DkAdminManagersActionSetPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/managers-action-priority"
          component={DkAdminManagersActionPriority}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/create-new-task"
          component={DkAdminManagersActionCreateNewTask}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/follow-workplan"
          component={DkAdminManagersActionFollowWorkplan}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/performance"
          component={DkAdminManagersReport}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/criteria"
          component={DkAdminManagersPerformanceCriteria}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/reviews"
          component={DkAdminManagersPerformanceReviews}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/reporting"
          component={DkAdminManagersPerformanceReporting}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/knowledge-base"
          component={DkAdminManagersKnowledgeBase}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/search-info"
          component={DkAdminManagersKnowledgeSearchInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/tasks-info"
          component={DkAdminManagersKnowledgeTaskInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/tools-info"
          component={DkAdminManagersKnowledgeToolsInfo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/candidates"
          component={DkAdminManagersKnowledgeCandidates}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/clients"
          component={DkAdminManagersKnowledgeClients}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/jobs"
          component={DkAdminManagersKnowledgeJobs}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/send-interview-prep"
          component={DkAdminManagersSendInterviewPrep}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/shedule-interviews"
          component={DkAdminManagersSheduleInterviews}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/review-sendout"
          component={DkAdminManagersReviewSendout}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/submit-sendout"
          component={DkAdminManagersSubmitSendout}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/ask-job-demand"
          component={DkAdminManagersAskJobDemand}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/candidates-feedback"
          component={DkAdminManagersCandidatesFeedback}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/prepare-interviews"
          component={DkAdminManagersPrepareInterviews}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/update-candidates"
          component={DkAdminManagersUpdateCandidates}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/clients-projection"
          component={DkAdminManagersClientsProjections}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers/job-offer-document"
          component={DkAdminManagersJobOfferDocuments}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/account-manager"
          component={DkAdminAccountManagers}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/managers"
          component={DkAdminManagers}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/teamleader/welcome"
          component={DkAdminManagementTeamLeader}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/recruitment-manager"
          component={DkAdminManagementRecManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/marketing-manager"
          component={DkAdminManagementMarketingManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/hr-manager"
          component={DkAdminManagementHrManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/it-manager"
          component={DkAdminManagementItManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/resource-manager"
          component={DkAdminManagementResourceManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/finance-manager"
          component={DkAdminManagementFinanceManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/operation-manager"
          component={DkAdminManagementOperationManager}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management/director"
          component={DkAdminManagementDirector}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/management"
          component={DkAdminTrainingManagement}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/assessments/overview"
          component={DkAdminAssessmentsOverview}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/assessments/assessment-1"
          component={DkAdminAssessmentsAssessmentOne}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/assessments/assessment-2"
          component={DkAdminAssessmentsAssessmentTwo}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/assessments/preparation"
          component={DkAdminAssessmentPreparation}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/assessments/decision"
          component={DkAdminAssessmentDecision}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/assessments/faq"
          component={DkAdminAssessmentFaq}
        />
        <PrivateRoute
          path="/dkglobaladmin/admintraining/project-manager"
          component={ProjectManagerTraining}
        />

        {/* end */}

        {/* Training Module Page */}
        <PrivateRoute
          path="/trainingmodule/suppliers-training/sample-1"
          component={TrainingSuppliers}
        />
        <PrivateRoute
          path="/trainingmodule/clients-training/sample-1"
          component={TrainingClients}
        />
        <PrivateRoute
          path="/trainingmodule/products-training/sample-1"
          component={TrainingProducts}
        />
        <PrivateRoute
          path="/trainingmodule/customers-training/sample-1"
          component={TrainingCustomers}
        />
        <PrivateRoute
          path="/trainingmodule/worktools-training/sample-1"
          component={TrainingWorkTools}
        />
        <PrivateRoute
          path="/trainingmodule/conducts-training/sample-1"
          component={TrainingModuleConducts}
        />
        <PrivateRoute
          path="/trainingmodule/training-faq/dashboard"
          component={TrainingFaq}
        />
        <PrivateRoute
          path="/dkg-global/business/summary"
          component={BusinessSummary}
        />
        {/* <PrivateRoute path="/dkg-global/recruitment/history" component={RecHistoryTraining} />
        <PrivateRoute path="/dkg-global/recruitment/market-share/overview" component={RecMarketShare} />
        <PrivateRoute path="/dkg-global/recruitment/defination/overview" component={RecDefination} />
        <PrivateRoute path="/dkg-global/recruitment/industries/overview" component={RecIndustries} />
        <PrivateRoute path="/dkg-global/recruitment/models/overview" component={RecModels} />
        <PrivateRoute path="/dkg-global/recruitment/pricing/overview" component={RecPricing} /> */}

        {/* End */}
        {/* Start Recuiterment Tips Module */}
        <PrivateRoute
          path="/recruitment-tips/summary"
          component={MainInterviewSpecSummary}
        />
        <PrivateRoute
          path="/recruitment-tips/live-spec"
          component={MainInterviewLiveSpec}
        />
        <PrivateRoute
          path="/recruitment-tips/create-spec"
          component={MainInterviewAddSpec}
        />
        <PrivateRoute
          path="/recruitment-tips/spec/:id/:id"
          component={MainInterviewSavedSpecTable}
        />
        <PrivateRoute
          path="/recruitment-tips/:id"
          component={MainInterviewSavedSpec}
        />

        {/* <PrivateRoute path="/recruitment-tips/inactive-spec" component={MainSpecSummary} /> */}

        <PrivateRoute
          path="/recruitment/faq/dashboard"
          component={RecDepartmentFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/clientsinfo-faq"
          component={RecClientsInfoFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/projects-faq"
          component={RecProjectsFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/jobdemand-faq"
          component={ReJobDemandFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/manageads-faq"
          component={ReManageAdsFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/database-faq"
          component={ReDatabaseFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/projections-faq"
          component={ReProjectionsFaq}
        />
        <PrivateRoute
          path="/recruitment/faq/recruitmenttips-faq"
          component={ReRecruitmentTipsFaq}
        />
        {/* End Recuiterment Tips Module */}
        {/* Start DK Global */}
        <PrivateRoute
          path="/departs/dkg-global/dashboard"
          component={DkGlobalSummary}
        />
        {/* End DK Global */}
      </Switch>
    </div>
  );
}

export default App;
