import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
//import { Link } from "react-router-dom";
import "./tableViewJob.scss";
import "../../../../assets/scss/extradiv.scss";
import {
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import "./jobboardtable.scss";
import { FaEdit } from "react-icons/fa";
import UserList from "../../../ui/userList/userListByRole";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationList,
  updateApplication,
  clearState,
  deleteApplication,
} from "../../../../slice/jobSpec/jobsSlice";
import { Modal } from 'react-bootstrap';
import TableStatus from "./dropdown/status/tableStatus";
import JobTableStatus from "../../../ui/dropdownList/JobAppsStatus";
import Checkbox from "./utils/checkbox";
import JobStatus from "./tableFilter/jobstatus";
import PdfIcon from '../../../../assets/images/green-pdf.png'
import RedPdfIcon from '../../../../assets/images/red-pdf.png'
import YellowPdfIcon from '../../../../assets/images/yellow-pdf.png'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AMFilter from "./tableFilter/users/am";
import RightModalFilter from "./RightModalFilter";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
import $ from "jquery";
import TableLoader from "../../../ui/tableLoader";
import moment from "moment-timezone";

const TableSuspence = lazy(() =>
  import("../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({
  columns,
  data,
  getInstanceCallback,
  amFilterId,
  companyId,
  languageId,
  projectId,
  roleId,
  statusId,
  dateId,
  jobStatusId,
  loading,
  createdAtId,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    setFilter,
    pageOptions,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getInstanceCallback,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 400,
        hiddenColumns: [
          "createdAt",
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  const [filterType, setFilterType] = useState("");


  useEffect(() => {
    if (amFilterId === "0") {
      setTimeout(() => {
        setFilter("rc", "");
      }, 200);
    } else {
      setFilterType("rc");
      doFilter(amFilterId);
    }
  }, [amFilterId]);

  useEffect(() => {
    if (companyId === "0") {
      setTimeout(() => {
        setFilter("company", "");
      }, 200);
    } else {
      setFilterType("company");
      doFilter(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (createdAtId === "0") {
      setTimeout(() => {
        setFilter("createdAt", "");
      }, 200);
    } else {
      setFilterType("createdAt");
      doFilter(createdAtId);
    }
  }, [createdAtId]);

  useEffect(() => {
    if (languageId === "0") {
      setTimeout(() => {
        setFilter("lang", "");
      }, 200);
    } else {
      setFilterType("lang");
      doFilter(languageId);
    }
  }, [languageId]);

  useEffect(() => {
    if (projectId === "0") {
      setTimeout(() => {
        setFilter("project", "");
      }, 200);
    } else {
      setFilterType("project");
      doFilter(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (roleId === "0") {
      setTimeout(() => {
        setFilter("role", "");
      }, 200);
    } else {
      setFilterType("role");
      doFilter(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (statusId === "0") {
      setTimeout(() => {
        setFilter("status", "");
      }, 200);
    } else {
      setFilterType("status");
      doFilter(statusId);
    }
  }, [statusId]);

  useEffect(() => {
    if (dateId === "0") {
      setTimeout(() => {
        setFilter("date", "");
      }, 200);
    } else {
      setFilterType("date");
      doFilter(dateId);
    }
  }, [dateId]);

  useEffect(() => {
    if (jobStatusId === "0") {
      setTimeout(() => {
        setFilter("status", "");
      }, 200);
    } else {
      setFilterType("status");
      doFilter(jobStatusId);
    }
  }, [jobStatusId]);

  const doFilter = useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );
  return (
    <React.Fragment>
      <table
        className="table dkpipeline-newCVTable dkg-livejobs-table-234"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="24" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="24" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>

      <div className="col-sm-12">
        <div className="dk-resPaginationCon pagination">
          <div className="dk-resPagination">
            <div className="dk-resLeftPag">
              <div
                className="dk-texCounter"
                style={{ color: "#fff !imporant" }}
              >
                Showing {pageIndex + 1} to {pageOptions.length} (of{" "}
                {rows.length} entries)
              </div>
            </div>
            {/* <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search...`}
        onFocus={(e) => (e.target.placeholder = "")}
        onBlur={(e) => (e.target.placeholder = "Search...")}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={clearSearch}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="btn btn-secondary" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const SavedPdfTable = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isUpdate, message, applicationListData } =
    useSelector((state) => state.jobSpec);
  const pathname = window.location.pathname;
  const segment4 = pathname.split("/")[2];

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfFile, setPdfFile] = useState('');

  const [columnKey, setColumnKey] = useState("");
  const [columnValue, setColumnValue] = useState("0");
  const [isUpdatePop, setIsUpdatePop] = useState(false);
  /******comment popup start****** */
  const [rowId, setRowId] = useState("0");

  const handleClose = () => setShow(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "Application Form List | Recruitment | DK Global CRM";
  }, []);

  const handleClose1 = () => setShow1(false);

  useEffect(() => {
    const fetchList = async () => {
      try {
        setData([]);
        let applicationStatus = "";
        if (segment4 == "new-case") {
          applicationStatus = "New Case";
        }
        if (segment4 == "in-progress") {
          applicationStatus = "In Progress";
        }
        if (segment4 == "closed") {
          applicationStatus = "Closed";
        }
        await dispatch(applicationList({ status: applicationStatus }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, [segment4]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setData(applicationListData);
      }, 200);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      //setData(applicationListData);
      let applicationStatus = "";
      if (segment4 == "new-case") {
        applicationStatus = "New Case";
      }
      if (segment4 == "in-progress") {
        applicationStatus = "In Progress";
      }
      if (segment4 == "closed") {
        applicationStatus = "Closed";
      }
      dispatch(applicationList({ status: applicationStatus }));
    }
  }, [isUpdate]);


  const [isRightFilter, setIsRightFilter] = useState(false);

  const showrightFilter = () => {
    setIsRightFilter(true);
    setShow1(true);
  };

  const [totalJobsNumber, setTotalJobsNumber] = useState([]);

  const handleClosePdfModal = () => {
    setPdfModal(false);
  }


  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "No.",
        accessor: "job_id",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div className="pipleFilterCols">
                <span
                  className="dk-chevron-rightIcon"
                >
                  {Number(cell.row.id) + 1}
                </span>

                <span
                  className="dk-chevron-rightIcon hover-blue"
                  style={{ marginRight: "2px" }}
                >
                  {cell.row.original.job_id}
                </span>
                <span className="dk-checkinputIcon">
                  <Checkbox
                    id={cell.row.original.id}
                    checkActiveExtraDiv={checkActiveExtraDiv}
                    checkExtraDivBtnTxt={checkExtraDivBtnTxt}
                  />
                </span>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "year",
        Header: "Year",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (moment(moment(cell.row.original.createdAt)).format("YYYY"));
        }
      },
      {
        id: "createdAt",
        Header: "Created",
        accessor: (row) => moment(moment(row.createdAt)).format("DD MMM, YY"),
      },
      {
        id: "month",
        Header: "Month",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (moment(moment(cell.row.original.createdAt)).format("MMMM"));
        }
      },
      {
        id: "date",
        Header: "Day",
        accessor: "date",
        Cell: ({ cell }) => {
          return (moment(moment(cell.row.original.createdAt)).format("DD"));
        }
      },
      {
        id: "rc",
        Header: "RC",
        accessor: "recAM.uid",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon applicationRec">
                <Dropdown id={`rec_am${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        style={{ height: "25px", width: "25px" }}
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.recAM.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.recAM.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.recAM.firstName +
                          " " +
                          cell.row.original.recAM.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="rc"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "doc_id",
        Header: "Application",
        accessor: "doc_id",
        Cell: ({ cell }) => {
          const handleShow = async () => {
            setPdfModal(true);
            setPdfFile(cell.row.original.doc_id);
          }
          let pdf = "";
          if(cell.row.original.status == "New Case"){
            pdf = PdfIcon;
          }
          if(cell.row.original.status == "In Progress"){
            pdf = YellowPdfIcon;
          }
          if(cell.row.original.status == "Closed"){
            pdf = RedPdfIcon;
          }
          return (<a onClick={handleShow} href="#" style={{ border: 0,background:"none" }} className="circle-pdf"><img src={pdf} style={{ maxWidth: "40px", padding: "5px" }} alt='' /></a>);
        }
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value) => {
            updateMyData(id, columnId, value);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`caseStatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <TableStatus value={cell.row.original.status} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <JobTableStatus
                      id={cell.row.original.id}
                      columnId="status"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "category",
        Header: "Category",
        accessor: "category",
      },
      {
        id: "company",
        Header: "Company",
        accessor: "company",
      },
      {
        id: "lang",
        Header: "Language",
        accessor: "lang",
      },
      {
        id: "project",
        Header: "Project",
        accessor: "project",
      },
      {
        id: "role",
        Header: "Role Type",
        accessor: "role",
      },

    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      setInstanceData(instance);
    }
  };

  const updateMyData = (id, columnId, value) => {
    dispatch(updateApplication({ id, key: columnId, value }));
  };

  const [showTotalJob, setShowTotalJob] = useState("0");

  useEffect(() => {
    //const totalRow = totalJobsNumber.length;
    const totalJob = totalJobsNumber.reduce((total, row) => (total = total + Number(row.original.job_jp)), 0);
    setShowTotalJob(totalJob);
  }, [totalJobsNumber]);

  const [amFilterId, setAmFilterId] = useState("0");
  const [amFilterImg, setAmFilterImg] = useState("0");
  const [statusId, setStatusId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [createdAtId, setCreatedAtId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [projectId, setProjectId] = useState("0");
  const [roleId, setRoleId] = useState("0");
  const [dateId, setDateId] = useState("0");
  const [jobSpecId, setjobSpecId] = useState("0");
  const [jobStatusId, setjobStatusId] = useState("0");
  const [jobprofileId, setJobprofileId] = useState("0");
  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  const [countCheck, setCountCheck] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const checkActiveExtraDiv = (isShowExitra, checkedBoxCount) => {
    if (isShowExitra) {
      setIsActive(true);
      setCountCheck(checkedBoxCount);
    } else {
      setIsActive(false);
      setCountCheck(checkedBoxCount);
    }
  };
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const checkExtraDivBtnTxt = (btnTxt) => {
    setCheckBtnTxt(btnTxt);
  };

  const selectUserFilter = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "rc":
        setAmFilterId(e.target.dataset.id);
        setAmFilterImg(e.target.dataset.img);
        break;

      default:
        break;
    }
  };

  const clearUserFilter = (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "rc":
        setAmFilterId("0");
        setAmFilterImg("0");
        break;

      default:
        break;
    }
  };

  const [filterTags, setFilterTags] = useState([]);
  const [jobSpecFilter, setJobSpecFilter] = useState("Language");
  const [jobStatusFilter, setjobStatusFilter] = useState("Status");
  const [jobValuesFilter, setjobValuesFilter] = useState("Values");

  const selectOtherFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;
    switch (type) {

      case "status":
        i = filterTags.findIndex(function (x) {
          return x.type === "status";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setjobStatusId(value);
        setjobStatusFilter(value);
        break;

      case "company":
        i = filterTags.findIndex(function (x) {
          return x.type === "company";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCompanyId(value);
        break;

      case "language":
        i = filterTags.findIndex(function (x) {
          return x.type === "language";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLanguageId(value);
        setJobSpecFilter(value);
        break;

      case "project":
        i = filterTags.findIndex(function (x) {
          return x.type === "project";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProjectId(value);
        break;

      case "role":
        i = filterTags.findIndex(function (x) {
          return x.type === "role";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setRoleId(value);
        break;

        case "createdAt":
          i = filterTags.findIndex(function (x) {
            return x.type === "createdAt";
          });
          if (i === -1) {
            filterTags.push({ type, value });
          } else {
            filterTags.splice(i, 1);
            filterTags.push({ type, value });
          }
          setCreatedAtId(value);
          break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    setFilterTags(filterTags.filter((item) => item.type !== type));
    switch (type) {
      case "status":
        setjobStatusId("0");
        setjobStatusFilter("Status");
        break;

      case "company":
        setCompanyId("0");
        break;

      case "language":
        setLanguageId("0");
        setJobSpecFilter("Language");
        break;

      case "project":
        setProjectId("0");
        break;

      case "role":
        setRoleId("0");
        break;

      case "status":
        setStatusId("0");
        break;

        case "createdAt":
        setCreatedAtId("0");
        break;

      default:
        break;
    }
  };

  const [isClearAll, setIsClearAll] = useState(false);
  
  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  const deleteSpec = () => {
    setOpen(true);
  };

  const handleClose2 = async (e) => {
  setOpen(false);
}

  const [dataMoveTo, SetDataMoveTo] = useState('')

  const moveRecords = async (e) => {
      SetDataMoveTo(e.target.dataset.moveto)
      setOpen(false);

      var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
      if (checkedBoxCount == 0) {
      } else {
          var canIds = [];
          $.each($(".chkCandidate:checked"), function () {
              canIds.push($(this).val());
          });
          await dispatch(deleteApplication({ ids: canIds, status: dataMoveTo }))
          $('.chkCandidate').prop('checked', false);
          setIsActive(false);
      }
  }

  return (
    <React.Fragment>

{isRightFilter ? (
        <RightModalFilter
          list={list}
          selectUserFilter={selectUserFilter}
          selectOtherFilter={selectOtherFilter}
          show={show1}
          onHide={handleClose1}
        />
      ) : null}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            background: "#dddddd",
            color: "#000",
            textAlign: "center",
          }}
          id="alert-dialog-title"
        >
          Job Applications
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete these job Application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Cancel</Button>
          <Button
            variant="contained"
            autoFocus
            color="error"
            data-moveto="Archive"
            onClick={moveRecords}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div className="container-fluid dk-cliBoardViewPageCont">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="dk-qulaifyTopHead">
              <div className="dk-qulaifyLeftTopHead">
                <div className="dk-SrchInputBox mr-2">
                  <div className="input-group">
                    {instanceData !== "0" ? (
                      <GlobalFilter
                        preGlobalFilteredRows={
                          instanceData.preGlobalFilteredRows
                        }
                        globalFilter={instanceData.state.globalFilter}
                        setGlobalFilter={instanceData.setGlobalFilter}
                        isClearAll={isClearAll}
                        restClearAllFilter={restClearAllFilter}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                  <AMFilter
                      list={list}
                      id={amFilterId}
                      img={amFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {amFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="rc"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div>
              
                <div className="mr-2 dk-reclivejobsBox">
                </div>
              </div>
              <div className="dk-qulaifyTopHeadTitle">
                <div className="dk-filterTagsCon">
                  <h3 className="title" style={{ fontSize: "22px" }}>
                      {filterTags.length > 0 ? (
                    filterTags.length > 0 &&
                    filterTags.map((item, index) => (
                      <ButtonGroup
                        className="dk-filterbtnGrp mr-2"
                        key={`tagButton${index}`}
                      >
                         <button className="dk-filterbtntext btn btn-primary">
                          {item.value}
                        </button>
                        <button
                          className="dk-filterbtnicon btn btn-primary"
                          data-type={item.type}
                          onClick={removeTag}
                        >
                          <i
                            className="fas fa-times"
                            data-type={item.type}
                            onClick={removeTag}
                          ></i>
                        </button>
                      </ButtonGroup>
                    ))
                  ) : (
                    <h3 className="title" style={{ fontSize: "22px" }}>
                      Job Applications - {applicationListData.length}
                    </h3>
                  )}
                  </h3>
                </div>
              </div>
              <div className="dk-qulaifyRightTopHead">
                <button className="dk-cricleBtn mr-2" onClick={showrightFilter}>
                  <i className="fa fa-filter"></i>
                </button>
                <div className="dk-qulaifyRightFilter">
                 <button className="btn btn-sm" style={{fontSize:"12px",boxShadow:"none",border:"1px solid #ccc"}}>
                Add New App
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid dk-jobAppsTableMain page-margin-top dkg-table-view-mainCon dkg-pipelines-main-tableCon">
        <div className="row">
          <div className="col-md-12">
            <div className="jobAppsTable table-responsive">
              <Table
                data={data}
                columns={columns}
                getInstanceCallback={reactTableInstance}
                amFilterId={amFilterId}
                companyId={companyId}
                languageId={languageId}
                projectId={projectId}
                roleId={roleId}
                statusId={statusId}
                dateId={dateId}
                jobSpecId={jobSpecId}
                jobStatusId={jobStatusId}
                loading={isLoading}
                jobprofileId={jobprofileId}
                createdAtId={createdAtId}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`batch-actions-menu-wrapper ${isActive ? "activePopup" : ""
          }`}
        style={{ width: "217px" }}
      >
        <div className="num-of-actions_wrapper">
          <div className="num-of-actions">{countCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
        </div>
        {/* <div className="batch-actions-item archived-checkboxbg" style={{ backgroundColor: "#26597d", color: "#fff" }}>
          <span >
            <FaEdit />
          </span>
          Edit
        </div> */}
        <div
          className="batch-actions-item archived-checkboxbg"
          data-moveto="Archive"
          onClick={deleteSpec}
          style={{ backgroundColor: "#6a0707", color: "#fff" }}
        >
          <span data-moveto="Archive">
            <i className="fa fa-trash" data-moveto="Archive"></i>
          </span>
          Delete
        </div>
        <div
          className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center"
          onClick={handleToggle}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>

      <Modal show={pdfModal} onHide={handleClosePdfModal} className="dk-modalCvRight" dialogClassName="dk-pipedocs-previewModal"
        aria-labelledby="example-custom-modal-styling-title"
        centered animation={false}>
        <Modal.Header closeButton className='dkg-candprof-cvdownload-header'>
          <Modal.Title>
            <a style={{position:"relative",marginTop:"7px"}} href={process.env.REACT_APP_INTERNAL_BASE_URL + "/application_pdf/" + pdfFile} download target={"_blank"} className='dkg-cvdownload-title'>Application Form</a>
          </Modal.Title>
        </Modal.Header>
          <Modal.Body className='dk-pipedocs-preview-modalbody'>
            <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL + "/application_pdf/" + pdfFile} title='Application' frameborder="0"></iframe>
          </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SavedPdfTable;
