import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./cashflowtwentytwo.scss";
//import { Tab, Nav, Dropdown } from 'react-bootstrap'
import {
  FaExchangeAlt,
  FaPlus,
  FaArrowsAlt,
  FaExpandArrowsAlt,
} from "react-icons/fa";
import UpdateNumerModal from "../modal/UpdateNumer";
import UpdateExpendature from "../modal/UpdateExpendature";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../slice/utils/message/messageSlice";
import {
  listTable,
  cashoutListTable,
  addValue,
  clearState,
  bulkDeleteState,
  addExpendatureValue,
} from "../../../../../slice/accounting/cashflow";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import TableLoader from "../../../../ui/tableLoader";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import $ from "jquery";
import DeleteModal from "../../../../ui/Modal/deletemodal/DeleteModal";
//import Expendature from "./expendature";
import CashInReorderModal from "../modal/CashInReorder";
import CashOutSubHeading from "../modal/CashOutSubHeading";
import CashoutHeadingReorder from "../modal/CashoutHeadingReorder";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tblDdlActive: {
    backgroundColor: "#31a886 !important",
    color: "#ffffff !important",
    minWidth: 90,
  },
  tblDdlInActive: {
    backgroundColor: "#d8345f !important",
    color: "#ffffff !important",
    minWidth: 90,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "green",
  },
}));

const CashFlowTwentyTwo = () => {
  const dispatch = useDispatch();
  const {
    cashflowList,
    cashoutHeadingList,
    isLoading,
    isUpdate,
    isReorder,
    isCashOutReorder,
    isInsert,
    isError,
    isDelete,
    errorMessage,
  } = useSelector((state) => state.cashflowdata);
  const classes = useStyles();
  const [listcashflow, setListcashflow] = useState([]);
  const [listCashOutHeading, setListCashOutHeading] = useState([]);
  const [openingBalance, setOpeningBalance] = useState([]);
  const [subheading, setSubHeading] = useState([]);

  const lastSegment = window.location.href.split("/").pop(); //for decode use atob("zyz")

  const [year, setYear] = useState(atob(lastSegment));

  const retrieveData = async () => {
    await dispatch(listTable({ year: atob(lastSegment) }));
    await dispatch(cashoutListTable({ year: atob(lastSegment) }));
  };
  useEffect(retrieveData, [lastSegment]);

  useEffect(() => {
    if (!isLoading && cashflowList) {
      setListcashflow(cashflowList);
    }
    if (!isLoading && cashoutHeadingList) {
      setListCashOutHeading(cashoutHeadingList.filter((a) => a.pid == 0));
      setOpeningBalance(cashoutHeadingList.filter((a) => a.pid == -1));
      setSubHeading(
        cashoutHeadingList?.filter((a) => a.pid != 0 && a.pid != -1)
      );
    }

    if (isInsert) {
      setListcashflow(cashflowList);
      dispatch(clearState());
    }
    if (isUpdate) {
      setListcashflow(cashflowList);
      dispatch(clearState());
    }
    if (isDelete) {
      dispatch(showSuccess({ msg: "Delete Successfully." }));
      dispatch(clearState());
      setListcashflow(cashflowList);
    }
    if (isReorder) {
      dispatch(clearState());
      setListcashflow(cashflowList);
      retrieveData();
    }
    if (isCashOutReorder) {
      dispatch(clearState());
      setListCashOutHeading(cashoutHeadingList.filter((a) => a.pid == 0));
      retrieveData();
    }
  }, [
    isLoading,
    isInsert,
    isUpdate,
    isDelete,
    isReorder,
    isCashOutReorder,
    lastSegment,
  ]);

  const [isShowUpdate, setShowUpdate] = useState(false);
  const showUpdatePopup = () => {
    setShowUpdate(true);
  };

  const SearchUpdate = () => {
    setShowUpdate(false);
  };
  const hideUpdateValuePop = () => {
    setIsUpdatePop(false);
    setIsUpdateExpendaturePop(false);
  };
  const addIncomeCashIn = () => {
    dispatch(addValue({ year: atob(lastSegment), tableName: "income_tbl" }));
  };
  const addExpendatureHeading = () => {
    dispatch(addExpendatureValue({ year: atob(lastSegment), pid: 0 }));
  };
  const addExpendatureRow = (id) => {
    dispatch(addExpendatureValue({ year: atob(lastSegment), pid: id }));
  };

  const [rowId, setRowId] = useState("0");
  const [columnKey, setColumnKey] = useState("");
  const [columnValue, setColumnValue] = useState("0");
  const [janC, setJanC] = useState("0");
  const [isUpdatePop, setIsUpdatePop] = useState(false);
  const [isUpdateExpendaturePop, setIsUpdateExpendaturePop] = useState(false);

  const updateExpendature = (id, key, value) => {
    setRowId(id);
    setColumnKey(key);
    setColumnValue(value);
    setIsUpdateExpendaturePop(true);
  };

  const openUpdateValuePop = (id, key, value) => {
    setRowId(id);
    setColumnKey(key);
    setColumnValue(value);
    setIsUpdatePop(true);
  };

  const [bulkLoad_e, setBulkLoad_e] = useState(false);
  const [isActive_e, setIsActive_e] = useState(false);
  const [countCheck_e, setCountCheck_e] = useState(0);
  const [checkBtnTxt_e, setCheckBtnTxt_e] = useState("Check All");
  const [isDeleteBulkPop_e, setIsDeleteBulkPop_e] = useState(false);

  const [bulkLoad, setBulkLoad] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [countCheck, setCountCheck] = useState(0);
  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");

  const [isDeletePop, setIsDeletePop] = useState(false);
  const [isDeleteBulkPop, setIsDeleteBulkPop] = useState(false);

  const handleToggle_e = () => {
    $(".expendature").prop("checked", false);
    setIsActive_e(false);
  };
  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const expendatureCheckbox = (e, id) => {
    var checkedBoxCount = $('input[name="expendature[]"]:checked').length;
    if (checkedBoxCount > 0) {
      setCountCheck_e(checkedBoxCount);
      setIsActive_e(true);
    } else {
      setCountCheck_e(0);
      setIsActive_e(false);
    }
    var checkedBoxCount = $('input[name="expendature[]"]:checked').length;
    var totalCheckboxes = $('input[name="expendature[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt_e("Check All");
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt_e("Uncheck All");
    }
  };
  const toggleCheckbox = (e, id) => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    if (checkedBoxCount > 0) {
      setCountCheck(checkedBoxCount);
      setIsActive(true);
    } else {
      setCountCheck(0);
      setIsActive(false);
    }
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt("Check All");
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt("Uncheck All");
    }
  };

  const toggleCheckAll_e = () => {
    var checkedBoxCount = $('input[name="expendature[]"]:checked').length;
    var totalCheckboxes = $('input[name="expendature[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt_e("Uncheck All");
      setCountCheck_e(totalCheckboxes);
      $(".expendature").prop("checked", true);
      // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt_e("Check All");
      setCountCheck_e(0);
      $(".expendature").prop("checked", false);
      setIsActive_e(false);
    }
  };
  const toggleCheckAll = () => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt("Uncheck All");
      setCountCheck(totalCheckboxes);
      $(".chkCandidate").prop("checked", true);
      // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt("Check All");
      setCountCheck(0);
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
    }
  };

  const deleteSelected_e = () => {
    setIsDeleteBulkPop_e(true);
  };
  const closeDeleteBulk_e = () => {
    setIsDeleteBulkPop_e(false);
  };
  const deleteSelected = () => {
    setIsDeleteBulkPop(true);
  };
  const closeDeleteBulk = () => {
    setIsDeleteBulkPop(false);
  };
  const deleteItemBulk = async () => {
    setBulkLoad(true);
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
    await fetch(
      process.env.REACT_APP_BASE_URL + "/accounting/cashflow/bulkdelete",
      {
        method: "POST",
        body: JSON.stringify({ ids: rowId }),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_WEB_API_KEY,
          platform: "web",
          Authorization: "Bearer" + " " + token.access_token,
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        dispatch(showSuccess({ msg: "Delete successfully" }));
        async function fetchNewData() {
          try {
            setRowId(0);
            setIsUpdatePop(false);
            await dispatch(clearState());
            await dispatch(listTable({ year: atob(lastSegment) }));
            await dispatch(bulkDeleteState());
          } catch (err) {
            console.log(err);
          }
        }
        fetchNewData();
        setBulkLoad(false);
        closeDeleteBulk(false);
        setIsActive(false);
      }
    });
  };

  const deleteItemBulk_e = async () => {
    setBulkLoad_e(true);
    let deleteUrl;
    if (isExpense == "delete") {
      deleteUrl = "/accounting/cashflow/expendature/bulkdelete";
    } else {
      deleteUrl = "/accounting/cashflow/expendature/subheading/delete";
    }
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
    await fetch(process.env.REACT_APP_BASE_URL + deleteUrl, {
      method: "POST",
      body: JSON.stringify({ ids: rowId }),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
        platform: "web",
        Authorization: "Bearer" + " " + token.access_token,
      },
    }).then((response) => {
      if (response.status === 200) {
        dispatch(showSuccess({ msg: "Delete successfully" }));
        async function fetchNewData() {
          try {
            setRowId(0);
            setExpense("");
            setIsUpdateExpendaturePop(false);
            await dispatch(clearState());
            await dispatch(cashoutListTable({ year: atob(lastSegment) }));
            await dispatch(bulkDeleteState());
          } catch (err) {
            console.log(err);
          }
        }
        fetchNewData();
        setBulkLoad_e(false);
        closeDeleteBulk_e(false);
        setIsActive_e(false);
      }
    });
  };

  const hideDeleteModal = () => {
    setIsDeletePop(false);
  };
  const hideDeleteModal_e = () => {
    setIsDeletePop(false);
  };
  const [isCashIn, setCashIn] = useState(false);
  const [isCashOut, setCashOut] = useState(false);
  const [isExpense, setExpense] = useState("");
  const [isSubHeadingCashOut, setSubHeadingCashOut] = useState(false);
  const [isSubHeadingId, isSetSubHeadingId] = useState(0);
  const showCashInModal = () => {
    setCashIn(true);
  };
  const hideCashInModal = () => {
    setCashIn(false);
  };
  const showSubHeadingCashoutReorder = (id) => {
    isSetSubHeadingId(id);
    setSubHeadingCashOut(true);
  };
  const showCashOutModal = () => {
    setCashOut(true);
  };
  const hideCashOutModal = () => {
    setCashOut(false);
  };
  const hideSubHeadingCashOut = () => {
    isSetSubHeadingId(0);
    setSubHeadingCashOut(false);
  };

  async function getDetails(e, data) {
    if (data.type == "delete") {
      setRowId(data.id);
      setIsDeleteBulkPop(true);
    }
  }
  async function getDetails_heading(e, data) {
    if (data.type == "delete") {
      setRowId(data.id);
      setExpense("delete");
      setIsDeleteBulkPop_e(true);
    }
    if (data.type == "subheading") {
      setRowId(data.id);
      setExpense(subheading);
      setIsDeleteBulkPop_e(true);
    }
  }

  // console.log(openingBalance)

  return (
    <>
      {isUpdatePop ? (
        <UpdateNumerModal
          onHide={hideUpdateValuePop}
          rowId={rowId}
          columnKey={columnKey}
          value={columnValue}
        />
      ) : null}
      {isUpdateExpendaturePop ? (
        <UpdateExpendature
          onHide={hideUpdateValuePop}
          rowId={rowId}
          columnKey={columnKey}
          value={columnValue}
        />
      ) : null}
      {isDeleteBulkPop ? (
        <DeleteModal
          id={rowId}
          onHide={hideDeleteModal}
          deleteItem={deleteItemBulk}
          handleClose={closeDeleteBulk}
        />
      ) : null}
      {isDeleteBulkPop_e ? (
        <DeleteModal
          id={rowId}
          onHide={hideDeleteModal_e}
          deleteItem={deleteItemBulk_e}
          handleClose={closeDeleteBulk_e}
        />
      ) : null}
      {isCashIn ? (
        <CashInReorderModal
          hideCashInModal={hideCashInModal}
          dataList={listcashflow}
        />
      ) : null}
      {isCashOut ? (
        <CashoutHeadingReorder
          hideCashOutModal={hideCashOutModal}
          dataList={listCashOutHeading}
        />
      ) : null}
      {isSubHeadingCashOut ? (
        <CashOutSubHeading
          hideCashOutModal={hideSubHeadingCashOut}
          subHeadingId={isSubHeadingId}
          dataList={subheading}
        />
      ) : null}
      <div className="container-fluid dkg-finance-cashflowtwentytwoMain">
        <div className="row">
          <div className="col-md-12 dkg-cashflowtwentytwo-pageCon">
            <div className="dkg-cashflowtwentytwo-TableCon">
              <table className="dkg-cashflowtwentytwo-Table  dkg-cashfloe-cashin-table78 table table-bordered">
                <thead>
                  <tr>
                    {/* <th></th> */}
                    {/* <th>
                                            <span className="dkg-exchangeIcon">
                                                <FaExchangeAlt />
                                            </span>
                                        </th> */}
                    <th style={{ backgroundColor: "#fff" }}></th>
                    <th style={{ backgroundColor: "#fff" }}>Jan </th>
                    <th style={{ backgroundColor: "#fff" }}>Feb </th>
                    <th style={{ backgroundColor: "#fff" }}>Mar </th>
                    <th style={{ backgroundColor: "#fff" }}>Apr </th>
                    <th style={{ backgroundColor: "#fff" }}>May </th>
                    <th style={{ backgroundColor: "#fff" }}>Jun </th>
                    <th style={{ backgroundColor: "#fff" }}>Jul </th>
                    <th style={{ backgroundColor: "#fff" }}>Aug </th>
                    <th style={{ backgroundColor: "#fff" }}>Sep </th>
                    <th style={{ backgroundColor: "#fff" }}>Oct </th>
                    <th style={{ backgroundColor: "#fff" }}>Nov </th>
                    <th style={{ backgroundColor: "#fff" }}>Dec </th>
                    <th style={{ backgroundColor: "#fff" }}></th>
                  </tr>
                </thead>
                <tbody className="dkg-table-workflow-tbody1 dkg-cashflow-cashin-tbody-78">
                  <tr>
                    {/* <td>

                                        </td> */}
                    <td
                      colSpan={13}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "#3a9469",
                      }}
                    >
                      INCOME - CASH IN
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <Link
                          to="#"
                          className="add-new-payment-section mr-2"
                          onClick={addIncomeCashIn}
                        >
                          <FaPlus />
                        </Link>
                        <Link
                          to="#"
                          className="add-new-payment-section"
                          onClick={showCashInModal}
                        >
                          <FaArrowsAlt />
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
                {!isLoading ? (
                  <tbody className="dkg-table-workflow-tbody2">
                    {listcashflow.map((item, index) => (
                      <tr key={index + 1}>
                        {/* <td>
                                                            <div className="dk-smsTableCheckbox">

                                                                {index + 1}
                                                                <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={item.id} onChange={(e) => toggleCheckbox(e, item.id)} />
                                                            </div>
                                                        </td> */}

                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "name", item.name)
                          }
                        >
                          <div className="dkg-subheadingg">
                            <ContextMenuTrigger
                              className="dk-todo-dropdowncon"
                              id={`delete_records${item.id}`}
                            >
                              {item.name}
                            </ContextMenuTrigger>
                            <ContextMenu
                              className="todorightDropmenu"
                              id={`delete_records${item.id}`}
                            >
                              <MenuItem
                                data={{ id: item.id, type: "delete" }}
                                onClick={getDetails}
                              >
                                <i className="far fa-trash-alt"></i> Delete
                              </MenuItem>
                            </ContextMenu>
                          </div>
                        </td>

                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "jan", item.jan)
                          }
                        >
                          {item.jan == ""
                            ? item.jan
                            : Number(item.jan).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "feb", item.feb)
                          }
                        >
                          {item.feb == ""
                            ? item.feb
                            : Number(item.feb).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "march", item.march)
                          }
                        >
                          {item.march == ""
                            ? item.march
                            : Number(item.march).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "april", item.april)
                          }
                        >
                          {item.april == ""
                            ? item.april
                            : Number(item.april).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "may", item.may)
                          }
                        >
                          {item.may == ""
                            ? item.may
                            : Number(item.may).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "june", item.june)
                          }
                        >
                          {item.june == ""
                            ? item.june
                            : Number(item.june).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "july", item.july)
                          }
                        >
                          {item.july == ""
                            ? item.july
                            : Number(item.july).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "aug", item.aug)
                          }
                        >
                          {item.aug == ""
                            ? item.aug
                            : Number(item.aug).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "sep", item.sep)
                          }
                        >
                          {item.sep == ""
                            ? item.sep
                            : Number(item.sep).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "oct", item.oct)
                          }
                        >
                          {item.oct == ""
                            ? item.oct
                            : Number(item.oct).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "nov", item.nov)
                          }
                        >
                          {item.nov == ""
                            ? item.nov
                            : Number(item.nov).toFixed(2)}
                        </td>
                        <td
                          className="cursor-p"
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() =>
                            openUpdateValuePop(item.id, "dec", item.dec)
                          }
                        >
                          {item.dec == ""
                            ? item.dec
                            : Number(item.dec).toFixed(2)}
                        </td>

                        <td style={{ fontWeight: "bold", fontSize: "12px" }}>
                          {(
                            Number(item.jan ? item.jan : 0) +
                            Number(item.feb ? item.feb : 0) +
                            Number(item.march ? item.march : 0) +
                            Number(item.april ? item.april : 0) +
                            Number(item.may ? item.may : 0) +
                            Number(item.june ? item.june : 0) +
                            Number(item.july ? item.july : 0) +
                            Number(item.aug ? item.aug : 0) +
                            Number(item.sep ? item.sep : 0) +
                            Number(item.oct ? item.oct : 0) +
                            Number(item.nov ? item.nov : 0) +
                            Number(item.dec ? item.dec : 0)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      {/* <th></th> */}
                      <th>TOTAL INCOME</th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.jan)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.feb)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.march)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.april)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.may)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.june)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.july)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.aug)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.sep)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.oct)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.nov)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {listcashflow
                          .reduce((a, v) => (a = a + Number(v.dec)), 0)
                          .toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                      <th>
                        {(
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.nov)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.dec)),
                            0
                          )
                        ).toFixed(2)}
                        <span className="ml-1">€</span>
                      </th>
                    </tr>
                  </tbody>
                ) : (
                  <TableLoader colSpan="14" />
                )}

                <tbody className="dkg-expenditure-workflow-tbody4">
                  <tr>
                    {/* <td>
                                        </td> */}
                    <td
                      colSpan={13}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "",
                        backgroundColor: "#fff",
                        color: "#d91010",
                      }}
                    >
                      EXPENSE - CASH OUT
                    </td>
                    <td style={{ backgroundColor: "#fff" }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <Link
                          to="#"
                          className="add-new-payment-section mr-2"
                          onClick={addExpendatureHeading}
                        >
                          <FaPlus />
                        </Link>
                        <Link
                          to="#"
                          className="add-new-payment-section"
                          onClick={showCashOutModal}
                        >
                          <FaArrowsAlt />
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
                {!isLoading ? (
                  <>
                    <tbody className="dkg-expenditure-cashout-addrow-tbody">
                      {listCashOutHeading.map((item, index) => (
                        <>
                          {
                            <>
                              <tr
                                key={index + 1}
                                className="dkg-expandature-tbody-row-ui-header-678"
                              >
                                {/* <td>
                                                                        </td> */}

                                <td
                                  className="cursor-p"
                                  onClick={() =>
                                    updateExpendature(
                                      item.id,
                                      "heading",
                                      item.heading
                                    )
                                  }
                                  colSpan={13}
                                  style={{
                                    textTransform: "uppercase",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className="dkg-subheadingg">
                                    <ContextMenuTrigger
                                      className="dk-todo-dropdowncon"
                                      id={`delete_heading${item.id}`}
                                    >
                                      {item.heading}
                                    </ContextMenuTrigger>
                                    <ContextMenu
                                      className="todorightDropmenu"
                                      id={`delete_heading${item.id}`}
                                    >
                                      <MenuItem
                                        data={{ id: item.id, type: "delete" }}
                                        onClick={getDetails_heading}
                                      >
                                        <i className="far fa-trash-alt"></i>{" "}
                                        Delete
                                      </MenuItem>
                                    </ContextMenu>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <Link
                                      to="#"
                                      className="mr-2 add-new-payment-section"
                                      onClick={() => addExpendatureRow(item.id)}
                                    >
                                      <FaPlus />
                                    </Link>
                                    <Link
                                      to="#"
                                      className="add-new-payment-section"
                                      onClick={() =>
                                        showSubHeadingCashoutReorder(item.id)
                                      }
                                    >
                                      <FaArrowsAlt />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              {cashoutHeadingList
                                ?.filter((a) => a.pid == item.id && a.pid != -1)
                                .map((dataItem, dataIndex) => (
                                  <tr
                                    key={dataIndex + 1}
                                    className="dkg-expandature-tbody-row-ui-678"
                                  >
                                    {/* <td>
                                                                                    <div className="dk-smsTableCheckbox">
                                                                                        {dataIndex + 1}
                                                                                        <input type="checkbox" className="expendature" name="expendature[]" value={dataItem.id} onChange={(e) => expendatureCheckbox(e, dataItem.id)} />
                                                                                    </div>
                                                                                </td> */}

                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "heading",
                                          dataItem.heading
                                        )
                                      }
                                    >
                                      <div className="dkg-subheadingg">
                                        <ContextMenuTrigger
                                          className="dk-todo-dropdowncon"
                                          id={`delete_sub_heading${dataItem.id}`}
                                        >
                                          {dataItem.heading}
                                        </ContextMenuTrigger>
                                        <ContextMenu
                                          className="todorightDropmenu"
                                          id={`delete_sub_heading${dataItem.id}`}
                                        >
                                          <MenuItem
                                            data={{
                                              id: dataItem.id,
                                              type: "subheading",
                                            }}
                                            onClick={getDetails_heading}
                                          >
                                            <i className="far fa-trash-alt"></i>{" "}
                                            Delete
                                          </MenuItem>
                                        </ContextMenu>
                                      </div>
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "jan",
                                          dataItem.jan
                                        )
                                      }
                                    >
                                      {dataItem.jan != null
                                        ? Number(dataItem.jan).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "feb",
                                          dataItem.feb
                                        )
                                      }
                                    >
                                      {dataItem.feb != null
                                        ? Number(dataItem.feb).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "march",
                                          dataItem.march
                                        )
                                      }
                                    >
                                      {dataItem.march != null
                                        ? Number(dataItem.march).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "april",
                                          dataItem.april
                                        )
                                      }
                                    >
                                      {dataItem.april != null
                                        ? Number(dataItem.april).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "may",
                                          dataItem.may
                                        )
                                      }
                                    >
                                      {dataItem.may != null
                                        ? Number(dataItem.may).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "june",
                                          dataItem.june
                                        )
                                      }
                                    >
                                      {dataItem.june != null
                                        ? Number(dataItem.june).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "july",
                                          dataItem.july
                                        )
                                      }
                                    >
                                      {dataItem.july != null
                                        ? Number(dataItem.july).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "aug",
                                          dataItem.aug
                                        )
                                      }
                                    >
                                      {dataItem.aug != null
                                        ? Number(dataItem.aug).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "sep",
                                          dataItem.sep
                                        )
                                      }
                                    >
                                      {dataItem.sep != null
                                        ? Number(dataItem.sep).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "oct",
                                          dataItem.oct
                                        )
                                      }
                                    >
                                      {dataItem.oct != null
                                        ? Number(dataItem.oct).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "nov",
                                          dataItem.nov
                                        )
                                      }
                                    >
                                      {dataItem.nov != null
                                        ? Number(dataItem.nov).toFixed(2)
                                        : ""}
                                    </td>
                                    <td
                                      className="cursor-p"
                                      onClick={() =>
                                        updateExpendature(
                                          dataItem.id,
                                          "dec",
                                          dataItem.dec
                                        )
                                      }
                                    >
                                      {dataItem.dec != null
                                        ? Number(dataItem.dec).toFixed(2)
                                        : ""}
                                    </td>

                                    <td
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {(
                                        Number(
                                          dataItem.jan ? dataItem.jan : 0
                                        ) +
                                        Number(
                                          dataItem.feb ? dataItem.feb : 0
                                        ) +
                                        Number(
                                          dataItem.march ? dataItem.march : 0
                                        ) +
                                        Number(
                                          dataItem.april ? dataItem.april : 0
                                        ) +
                                        Number(
                                          dataItem.may ? dataItem.may : 0
                                        ) +
                                        Number(
                                          dataItem.june ? dataItem.june : 0
                                        ) +
                                        Number(
                                          dataItem.july ? dataItem.july : 0
                                        ) +
                                        Number(
                                          dataItem.aug ? dataItem.aug : 0
                                        ) +
                                        Number(
                                          dataItem.sep ? dataItem.sep : 0
                                        ) +
                                        Number(
                                          dataItem.oct ? dataItem.oct : 0
                                        ) +
                                        Number(
                                          dataItem.nov ? dataItem.nov : 0
                                        ) +
                                        Number(dataItem.dec ? dataItem.dec : 0)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          }
                        </>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <TableLoader colSpan="14" />
                )}

                <tbody className="dkg-table-workflow-tbody3 dkg-total-expense-tbody-78">
                  <tr className="total-expenditure-row dkg-total-expense-row-78 dkg-totalexpense-row-89">
                    {/* <th className="dkg-totalexpense-col-89"></th> */}
                    <th
                      className="text-left dkg-totalexpense-col-89"
                      style={{ textTransform: "uppercase" }}
                    >
                      TOTAL EXPENSE
                    </th>

                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1 && a.pid != null)
                        .reduce((a, v) => (a = a + Number(v.jan)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.feb)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.march)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.april)), 0)
                        .toFixed(2)}

                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.may)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.june)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.july)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.aug)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.sep)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.oct)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.nov)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {cashoutHeadingList
                        ?.filter((a) => a.pid != -1)
                        .reduce((a, v) => (a = a + Number(v.dec)), 0)
                        .toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                    <th className="dkg-totalexpense-col-89">
                      {(
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.feb)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.march)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.april)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.may)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.june)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.july)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.aug)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.sep)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.oct)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.nov)), 0) +
                        cashoutHeadingList
                          ?.filter((a) => a.pid != -1)
                          .reduce((a, v) => (a = a + Number(v.dec)), 0)
                      ).toFixed(2)}
                      <span className="ml-1">€</span>
                    </th>
                  </tr>

                  {/* <tr>
                                        <td colSpan={15} style={{ height: "42px", backgroundColor: "#fff" }}></td>
                                    </tr> */}

                  <tr>
                    {/* <td style={{ fontWeight: "bold", fontSize: "12px", textTransform: "uppercase" }}></td> */}
                    <td
                      style={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      Month Balance
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <>
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0)
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0)
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        </>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.feb)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.march)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.april)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.may)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.june)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.july)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.aug)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.sep)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.oct)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.nov)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.nov)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.nov)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.nov)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.dec)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.dec)), 0)
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.dec)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.dec)), 0)
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "12px" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.nov)),
                            0
                          ) +
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.dec)),
                            0
                          ) -
                          (cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.nov)), 0) +
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.dec)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                            (cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                ) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                ) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.june)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.july)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                            (cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                ) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                ) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.june)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.july)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </td>
                  </tr>
                  {openingBalance.map((openingData, openingIndex) => (
                    <tr key={openingIndex + 1}>
                      {/* <td></td> */}
                      <td
                        style={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Opening Balance
                      </td>
                      <td
                        className="cursor-p"
                        onClick={() =>
                          updateExpendature(
                            openingData.id,
                            "jan",
                            openingData.jan
                          )
                        }
                      >
                        {Math.sign(
                          openingData.jan == ""
                            ? openingData.jan
                            : Number(openingData.jan).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {openingData.jan == ""
                              ? openingData.jan
                              : Number(openingData.jan).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {openingData.jan == ""
                              ? openingData.jan
                              : Number(openingData.jan).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            )
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              )
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              )
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.feb)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.feb)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.march)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.april)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.may)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.may)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.june)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.july)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  ))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.aug)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.aug)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.sep)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.sep)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.oct)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.oct)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                      <td className="cursor-p">
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.oct)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.nov)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.nov)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.oct)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.nov)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.nov)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>

                      <td style={{ fontWeight: "bold", fontSize: "12px" }}>
                        {Math.sign(
                          (
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0))
                          ).toFixed(2)
                        ) > 0 ? (
                          <span className="positiveValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.oct)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.nov)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.nov)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.dec)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.dec)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        ) : (
                          <span className="negativeValues">
                            {(
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              openingBalance.reduce(
                                (a, v) => (a = a + Number(v.jan)),
                                0
                              ) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.oct)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.nov)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.nov)),
                                    0
                                  )) +
                              (listcashflow.reduce(
                                (a, v) => (a = a + Number(v.dec)),
                                0
                              ) -
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce((a, v) => (a = a + Number(v.dec)), 0))
                            ).toFixed(2)}
                            <span className="ml-1">€</span>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}

                  <tr className="total-expenditure-row dkg-total-expense-row-78 dkg-total-expense-last-row-78">
                    {/* <th></th> */}
                    <th
                      className="text-left"
                      style={{
                        textTransform: "uppercase",
                        paddingLeft: "8px",
                        backgroundColor: "#eaeaea",
                      }}
                    >
                      CLOSING BALANCE
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          )
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            )
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            )
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.march)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.march)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.april)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.april)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.june)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.june)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.july)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.july)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.nov)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.nov)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.nov)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.dec)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.dec)), 0))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>

                    <th style={{ backgroundColor: "#eaeaea" }}>
                      {Math.sign(
                        (
                          listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) -
                          cashoutHeadingList
                            ?.filter((a) => a.pid != -1)
                            .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                          openingBalance.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.feb)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.march)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.march)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.april)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.april)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.may)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.june)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.june)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.july)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.july)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.aug)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.sep)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.oct)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.nov)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.dec)),
                            0
                          ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.dec)), 0)) +
                          (listcashflow.reduce(
                            (a, v) => (a = a + Number(v.jan)),
                            0
                          ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) +
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                            (cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                ) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                ) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.june)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.july)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0) +
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0)))
                        ).toFixed(2)
                      ) > 0 ? (
                        <span className="positiveValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.nov)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.dec)),
                                0
                              ) -
                              (cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.oct)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.nov)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.dec)),
                                    0
                                  )))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      ) : (
                        <span className="negativeValues">
                          {(
                            listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) -
                            cashoutHeadingList
                              ?.filter((a) => a.pid != -1)
                              .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                            openingBalance.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.feb)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.feb)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.march)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.march)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.april)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.april)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.may)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.may)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.june)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.june)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.july)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce(
                                  (a, v) => (a = a + Number(v.july)),
                                  0
                                )) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.aug)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.aug)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.sep)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.sep)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.oct)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.oct)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.nov)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.nov)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.dec)),
                              0
                            ) -
                              cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.dec)), 0)) +
                            (listcashflow.reduce(
                              (a, v) => (a = a + Number(v.jan)),
                              0
                            ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.feb)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.march)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.april)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.may)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.june)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.july)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.aug)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.sep)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.oct)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.nov)),
                                0
                              ) +
                              listcashflow.reduce(
                                (a, v) => (a = a + Number(v.dec)),
                                0
                              ) -
                              (cashoutHeadingList
                                ?.filter((a) => a.pid != -1)
                                .reduce((a, v) => (a = a + Number(v.jan)), 0) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.feb)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.march)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.april)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.may)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.june)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.july)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.aug)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.sep)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.oct)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.nov)),
                                    0
                                  ) +
                                cashoutHeadingList
                                  ?.filter((a) => a.pid != -1)
                                  .reduce(
                                    (a, v) => (a = a + Number(v.dec)),
                                    0
                                  )))
                          ).toFixed(2)}
                          <span className="ml-1">€</span>
                        </span>
                      )}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`dk-smsMessage-selectMsg ${isActive_e ? "activePopup" : ""}`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="dk-selectNo">{countCheck_e}</div>
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
          <div className="dk-checkAll" onClick={toggleCheckAll_e}>
            <span>
              <i className="fas fa-check"></i>
            </span>
            {checkBtnTxt_e}
          </div>
          {bulkLoad_e ? (
            <div className={`dk-checkAll ` + classes.backdrop}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div className="dk-checkAll" onClick={deleteSelected_e}>
              <span>
                <i className="fas fa-trash-alt"></i>
              </span>
              Delete
            </div>
          )}
          <div
            className="dk-checkAll d-flex align-items-center justify-content-center"
            onClick={handleToggle_e}
          >
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
      <div
        className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="dk-selectNo">{countCheck}</div>
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
          <div className="dk-checkAll" onClick={toggleCheckAll}>
            <span>
              <i className="fas fa-check"></i>
            </span>
            {checkBtnTxt}
          </div>
          {bulkLoad ? (
            <div className={`dk-checkAll ` + classes.backdrop}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div className="dk-checkAll" onClick={deleteSelected}>
              <span>
                <i className="fas fa-trash-alt"></i>
              </span>
              Delete
            </div>
          )}
          <div
            className="dk-checkAll d-flex align-items-center justify-content-center"
            onClick={handleToggle}
          >
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashFlowTwentyTwo;
