import React, { useEffect, useState, useRef } from 'react';
import { Modal, Dropdown } from 'react-bootstrap'
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import SubmitButton from "../../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearState, updateDetails, plateformDataFetch, categoryDataFetch, servicesDataFetch, businessNameDataFetch, vatNoDataFetch } from "../../../../../slice/accounting/accountBanking";
import DatePicker from './DatePicker';
import moment from "moment-timezone";
import SelectDropsDown from "../../../../pages/sendouts/utils/searchDropdown";

const EditDetails = ({ onHide, currentYear }) => {

    const dispatch = useDispatch();

    const { isUpdate, tasksDetails, plateformList, categoryList, servicesList, businessNameList, vatNoList } = useSelector(state => state.bankingBooks);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [category, setCategory] = useState('')

    const [txnDate, setTxnDate] = useState(tasksDetails.transaction)
    const [year, setYear] = useState(currentYear)
    const [month, setMonth] = useState(tasksDetails.month)
    const [categoryType, setCategoryType] = useState(tasksDetails.category)
    const [paidOut, setPaidOut] = useState(tasksDetails.paidOut)
    const [paidIn, setPaidIn] = useState(tasksDetails.paidIn)
    const [ballance, setBallance] = useState(tasksDetails.ballance)
    const [vatType, setVatType] = useState(tasksDetails.vatNo)
    const [services, setServices] = useState(tasksDetails.services)
    const [business, setBusiness] = useState(tasksDetails.business)
    const [plateform, setPlateformType] = useState(tasksDetails.plateform)

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(plateformDataFetch({ id: SYSTEM_CONSTANTS.ACCOUNTING_BANKING.plateformId }))
                await dispatch(categoryDataFetch({ id: SYSTEM_CONSTANTS.ACCOUNTING_BANKING.categoryId }))
                await dispatch(servicesDataFetch({ id: SYSTEM_CONSTANTS.ACCOUNTING_BANKING.serviceId }))
                await dispatch(businessNameDataFetch({ id: SYSTEM_CONSTANTS.ACCOUNTING_BANKING.businessNameId }))
                await dispatch(vatNoDataFetch({ id: SYSTEM_CONSTANTS.ACCOUNTING_BANKING.vatNoId }))

            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        if (isUpdate) {
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate])

    const updateData = async (e) => {
        const key = e.target.dataset.key;
        const value = e.target.dataset.value;
        if (key === 'business') {
            setBusiness(value)
        }
        if (key === 'services') {
            setServices(value)
        }
        if (key === 'categoryType') {
            setCategoryType(value)
        }
        if (key === 'vatType') {
            setVatType(value)
        }
        if (key === 'plateformType') {
            setPlateformType(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!txnDate) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);

                try {
                    dispatch(updateDetails({ id: tasksDetails.id, transaction: txnDate, year, month, category: categoryType, paidOut, paidIn, ballance, vatNo: vatType, services, business, plateform }))
                } catch (err) {
                    console.log(err)
                }
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    const handleDateChange = async (e) => {
        setTxnDate(moment(e).format('DD MMM YY'))
    };

    return (
        <>

            <Modal show={true} onHide={onHide} className="dk-addInvoiceModal-main dkg-addnew-paymenttask-modal-21">

                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21" style={{ background: '#692D77' }}>
                    <Modal.Title>EDIT INVOICE/CREDIT</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label className="">Type</label>
                                <div  className="dkg-payment-task-drop12">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" className="form-control"  style={{ backgroundColor: "#3a9469", color: "#fff" }} >
                                    Income
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                                    <Dropdown.Item href="#"
                                        className="dkg-typecol-dropdown-item"
                                        style={{ backgroundColor: "#3a9469", color: "#fff" }} >
                                        Income
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#"
                                        className="dkg-typecol-dropdown-item"
                                        style={{ backgroundColor: "#d91010", color: "#fff" }} >
                                        Expense
                                    </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Month</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {month}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#884c5e", color: "#fff" }} onClick={() => setMonth("January")} >January</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#26596d", color: "#fff" }} onClick={() => setMonth("February")} >February</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5a4c72", color: "#fff" }} onClick={() => setMonth("March")} >March</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c4c62", color: "#fff" }} onClick={() => setMonth("April")} >April</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5c737d", color: "#fff" }} onClick={() => setMonth("May")} >May</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#4c5f59", color: "#fff" }} onClick={() => setMonth("June")} >June</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#01796f", color: "#fff" }} onClick={() => setMonth("July")} >July</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#144773", color: "#fff" }} onClick={() => setMonth("August")} >August</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#396a95", color: "#fff" }} onClick={() => setMonth("September")} >September</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#535255", color: "#fff" }} onClick={() => setMonth("October")} >October</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#b55355", color: "#fff" }} onClick={() => setMonth("November")} >November</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c97b6", color: "#fff" }} onClick={() => setMonth("December")} >December</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Transaction</label>
                                <div className='dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-hidelabel-text'>
                                    <DatePicker handleDateChange={(e) => handleDateChange(e)} selectedDate={txnDate} dateFormate="dd MMM yy" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Platform</label>
                                <div className='dkg-payment-task-drop12 dkg-banking-addnew-dropdown'>
                                    <SelectDropsDown txt={plateform} list={plateformList} updateProfile={updateData} column="plateformType" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Category</label>
                                <div className='dkg-payment-task-drop12 dkg-banking-addnew-dropdown'>
                                    <SelectDropsDown txt={categoryType} list={categoryList} updateProfile={updateData} column="categoryType" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Services</label>
                                <div className='dkg-payment-task-drop12 dkg-banking-addnew-dropdown'>
                                    <SelectDropsDown txt={services} list={servicesList} updateProfile={updateData} column="services" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Name of Business</label>
                                <div className='dkg-payment-task-drop12 dkg-banking-addnew-dropdown'>
                                    <SelectDropsDown txt={business} list={businessNameList} updateProfile={updateData} column="business" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label className="">Zone</label>
                                <div  className="dkg-payment-task-drop12">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" className="form-control">
                                       ---
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                                    <Dropdown.Item href="#"
                                    className="dkg-typecol-dropdown-item"
                                    style={{ backgroundColor: "#f2f2f2", color: "#333" }}>
                                    IRE
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#"
                                    className="dkg-typecol-dropdown-item"
                                    style={{ backgroundColor: "#f2f2f2", color: "#333" }} >
                                    EU
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#"
                                    className="dkg-typecol-dropdown-item"
                                    style={{ backgroundColor: "#f2f2f2", color: "#333" }} >
                                    NON-EU 
                                    </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>VAT</label>
                                <div className='dkg-payment-task-drop12 dkg-banking-addnew-dropdown'>
                                    {/* <SelectDropsDown txt={vatType} list={vatNoList} updateProfile={updateData} column="vatType" className={(category == '' ? "errorBorder" : null)} /> */}
                                    <input
                                        type="text"
                                        className="form-control dkg-addnew-banking-input"
                                        value={vatType}
                                        onChange={(e) => setVatType(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label htmlFor="">Paid Out</label>
                                <div className='dkg-payment-task-drop12'>
                                    <input type="text" name="" id="" className="form-control dkg-addnew-banking-input" value={paidOut} onChange={(e) => setPaidOut(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label htmlFor="">Paid IN</label>
                                <div className='dkg-payment-task-drop12'>
                                    <input type="text" name="" id="" className="form-control dkg-addnew-banking-input" value={paidIn} onChange={(e) => setPaidIn(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label htmlFor="">Balance</label>
                                <div className='dkg-payment-task-drop12'>
                                    <input type="text" name="" id="" className="form-control dkg-addnew-banking-input" value={ballance} onChange={(e) => setBallance(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 col-12'>
                            <div className='form-group justify-content-center mt-2'>
                                <SubmitButton txt="Update" loading={loading} success={success} onClick={handleSubmit} className="dkaddBtn dkg-banking-addnew-btn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditDetails