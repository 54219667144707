import React, { useState, useEffect, useRef } from "react";
import { Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./modulemenu.scss";
import Submenu from "../../subMenu/MainSubmenu";
import { countAllTask } from "../../../../../../slice/task/taskSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccess,
  showError,
} from "../../../../../utils/messages/messageSlice";
import { companyData } from "../../../../../../slice/companies/clients/clientSlice";
import { Redirect } from "react-router-dom";
import { getSystemUser } from "../../../../../../slice/mailbox/mailboxSlice";

const ModulesNavbar = (props) => {
  const dispatch = useDispatch();
  const { isSuccess, isMoved, countAll } = useSelector((state) => state.task);
  const { jobsCountList } = useSelector((state) => state.common);
  const { candidateDataSummaryList } = useSelector((state) => state.candidate);
  const {
    paymentDataCount,
    paidPaymentDataCount,
    issSuccess,
    isTaskUpdate,
    isDataMoveToPayment,
  } = useSelector((state) => state.paymentTask);

  const { applicationListStatus } = useSelector((state) => state.jobSpec);

  const { companyList } = useSelector((state) => state.companies);

  const [show, setShowMenu] = React.useState();
  const { userInfo } = useSelector((state) => state.auth);
  const { getSystemUsersData } = useSelector((state) => state.mailbox);
  //console.log(userInfo);
  const pathname = window.location.pathname;
  const segment1 = pathname.split("/")[1];
  //const segment2 = pathname.split("/")[2];
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(companyData());
        await dispatch(countAllTask({}));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
    dispatch(getSystemUser());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const fetchList = async () => {
        try {
          await dispatch(countAllTask({}));
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }
    if (isMoved) {
      const fetchList = async () => {
        try {
          await dispatch(countAllTask({}));
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }
  }, [isSuccess, isMoved]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const setModule = (type) => {
    if (type === "Payments") {
      dispatch(showError({ msg: "You are not authorized to view" }));
    }
  };

  if (userInfo.role === "candidate") {
    return <Redirect to="/" />;
  }
  return (
    <React.Fragment>
      <div className="dk-navBarMain dk-modulesNavbar" ref={ref}>
        <button className="mobileNav-btn" onClick={() => setShowMenu(true)}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Route>
          <ul className={`mobileNav ${show ? "show" : ""}`}>
            <button className="dk-closeBtn" onClick={() => setShowMenu(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
            <li>
              <Link
                to="/dashboard/plateform"
                className={`${segment1 === "dashboard" ? "active" : ""}`}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/mailbox/mail/inbox"
                className={`${segment1 === "mailbox" ? "active" : ""}`}
              >
                <span className="dk-workplan-badgeCon dkg-mailbox-badgeColor">
                  {getSystemUsersData
                    .map((items) => items.mailbox_count)
                    .reduce((a, b) => a + b, 0)}
                </span>
                Mailbox
              </Link>
            </li>
            {/* <li>
              <Link
                to="/cand-mailbox/mail/inbox-new"
                className={`${segment1 === "cand-mailbox" ? "active" : ""}`}
              >
                <span className="dk-workplan-badgeCon dkg-mailbox-badgeColor">
                  5
                </span>
                Mailbox
              </Link>
            </li> */}
            <li>
              <Link
                to="/clients/summary"
                className={`${segment1 === "clients" ? "active" : ""}`}
              >
                <span
                  style={{ width: "30px" }}
                  className="dk-workplan-badgeCon dk-jobs-badgeCon"
                >
                  <span>
                    {companyList.filter((data) => data.inStatus == 1).length}
                  </span>
                </span>
                Clients
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/summary"
                className={`${segment1 === "jobs" ? "active" : ""}`}
              >
                <span
                  style={{ width: "30px" }}
                  className="dk-workplan-badgeCon dk-jobs-badgeCon"
                >
                  <span>{jobsCountList}</span>
                </span>
                Jobs
              </Link>
            </li>
            <li>
              <Link
                to="/job-specs/summary"
                className={`${segment1 === "job-specs" ? "active" : ""}`}
              >
                <span
                  style={{ width: "30px" }}
                  className="dk-workplan-badgeCon dk-jobs-badgeCon"
                >
                  <span>0</span>
                </span>
                Job Specs
              </Link>
            </li>
            <li>
              <Link
                to="/job-apps/new-case"
                className={`${segment1 === "job-apps" ? "active" : ""}`}
              >
                <span className="dkg-taskbadgecon dkg-tasks-badgeColor">
                  <span>
                    {applicationListStatus.newCase +
                      applicationListStatus.inProgress}
                  </span>
                </span>
                Job Applied
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/summary"
                className={`${segment1 === "pipelines" ? "active" : ""}`}
              >
                {" "}
                <span
                  style={{ width: "30px" }}
                  className="dk-workplan-badgeCon dk-jobs-badgeCon"
                >
                  <span>
                    {
                      candidateDataSummaryList.filter(
                        (data) => data.status != "Closed"
                      ).length
                    }
                  </span>
                </span>
                Candidates
              </Link>
            </li>
            <li>
              <Link
                to="/kpis/summary"
                className={`${segment1 === "kpis" ? "active" : ""}`}
              >
                  <span className="dkg-taskbadgecon dkg-tasks-badgeColor">
                  <span>0</span>
                </span>
                KPI's
              </Link>
            </li>
            <li>
              <Link
                to="/tasks/summary"
                className={`${segment1 === "tasks" ? "active" : ""}`}
              >
                <span className="dkg-taskbadgecon dkg-tasks-badgeColor">
                  <span>{countAll}</span>
                </span>
                Tasks
              </Link>
            </li>
            <li>
              {userInfo.role === "Super Admin" ? (
                <>
                  <Link
                    to="/paytasks/summary"
                    className={`${segment1 === "paytasks" ? "active" : ""}`}
                  >
                    <span className="dkg-taskbadgecon dkg-tasks-badgeColor">
                      <span>{paymentDataCount}</span>
                    </span>
                    Payments
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="#"
                    onClick={() => setModule("Payments")}
                    className={`${segment1 === "paytasks" ? "active" : ""}`}
                  >
                    <span className="dkg-taskbadgecon dkg-tasks-badgeColor">
                      <span>{paymentDataCount}</span>
                    </span>
                    Payments
                  </Link>
                </>
              )}
            </li>
            {/* <li><Link to="/notifications/summary" className={`${(segment1 === 'notifications') ? 'active' : ''}`}><span className="dk-workplan-badgeCon dkg-notification-badgeColor"><span>12</span></span>Recruiters</Link></li> */}
          </ul>
        </Route>
      </div>

      <Submenu path={pathname} />
    </React.Fragment>
  );
};

export default ModulesNavbar;
