import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./duplicatecheck.scss";
import "../../../../../../assets/scss/tooltip.scss";
import { ImUsers } from "react-icons/im";
import { FaTimesCircle } from "react-icons/fa";
import Submit from "../../../../../ui/submitButton";
import {
  showSuccess,
  showError,
} from "../../../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  apiDppduplicateCheck,
  clearState,
} from "../../../../../../slice/candidates/list/listSlice";
import CaseStatus from "../../dropdown/caseStatus";
import CaseOther from "../../dropdown/other";
import CandidateDocumentModal from "../../modal/documents";
import { Dropdown } from "react-bootstrap";

import DataTable from "./DataTable";

const DuplicateCheck = () => {
  const dispatch = useDispatch();
  const { isduplicateMoved, message, isdduplicateMoved, apiDppDuplicateList } =
    useSelector((state) => state.candidates);

  const [show, setShow] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(true);
  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [name, setName] = useState("");
  const [namePlaceholder, setNamePlaceholder] = useState(
    "Enter candidate name"
  );
  const [email, setEmail] = useState("");
  const [phone, setPhoneNo] = useState("");
  const [emailPlaceholder, setEmailPlaceholder] = useState(
    "Enter candidate email"
  );
  const [phonePlaceholder, setPhonePlaceholder] = useState(
    "Enter candidate phone numder"
  );

  const handleClose = () => {
    setShow(false);
  };

  const searchByName = async (text) => {
    setName(text);
    setNamePlaceholder("");
    setEmailPlaceholder("");
    setPhonePlaceholder("");
  };

  const searchByEmail = async (text) => {
    setEmail(text);
    setNamePlaceholder("");
    setPhonePlaceholder("");
  };

  const searchByPhone = async (text) => {
    setPhoneNo(text);
    setNamePlaceholder("");
    setEmailPlaceholder("");
  };

  const handleShow = () => {
    setIsDuplicate(true);
    setList([]);
    setName("");
    setEmail("");
    setPhoneNo("");
    setLoading(false);
    setShow(true);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setName("");
    setEmail("");
    setPhoneNo("");
    setNamePlaceholder("Enter candidate name");
    setEmailPlaceholder("Enter candidate email");
    setPhonePlaceholder("Enter Candidate phone numder");
    setIsDuplicate(true);
    setList([]);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name && !email && !phone) {
      dispatch(showError({ msg: "Please fill either name, email or phone" }));
    } else {
      try {
        setLoading(true);
        await dispatch(apiDppduplicateCheck({ name, email, phone }));
      } catch (e) {
        dispatch(clearState());
        dispatch(showError({ msg: e }));
      }
    }
  };

  useEffect(() => {
    if (isduplicateMoved) {
      setLoading(false);
      dispatch(clearState());
      if (apiDppDuplicateList.length > 0) {
        setIsDuplicate(true);
        setList(apiDppDuplicateList);
      } else {
        setIsDuplicate(false);
        setList([]);
      }
    }
    if (isdduplicateMoved) {
      dispatch(clearState());
      dispatch(showSuccess({ msg: message }));
      setLoading(false);
      setSuccess(false);
      const fetchList = async () => {
        try {
          await dispatch(apiDppduplicateCheck({ name, email, phone }));
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }
  }, [isduplicateMoved, isdduplicateMoved]);

  return (
    <>
      {/* <button className="dk-reoderBtn">
                <ImUsers />
            </button> */}
      <Link to="#" onClick={handleShow}>
        <ImUsers />
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-pipecheckduplicateModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header id="example-custom-modal-styling-title" closeButton>
          <Modal.Title>CANDIDATE DUPLICATE CHECK-2</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dk-duplicateFormCon">
            <form action="">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="dk-duplicateFormGruop">
                    <label className="dkg-duplicate-title">
                      Enter Candidate Name
                    </label>
                    {/* <input type="text" className="form-control" placeholder={namePlaceholder} value={name} onChange={(e) => searchByName(e.target.value)} /> */}

                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={name}
                      onChange={(e) => searchByName(e.target.value)}
                    />
                    {name != "" ? (
                      <span className="dkg-crosssbtnCon14">
                        {list.length > 0 ? (
                          <FaTimesCircle onClick={handleClear} />
                        ) : null}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="dk-duplicateFormGruop df">
                    <label className="dkg-duplicate-title">
                      Enter Candidate Email
                    </label>
                    {/* <input type="text" className="form-control" placeholder={emailPlaceholder} value={email} onChange={(e) => searchByEmail(e.target.value)} /> */}

                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={email}
                      onChange={(e) => searchByEmail(e.target.value)}
                    />

                    {email != "" ? (
                      <span className="dkg-crosssbtnCon14">
                        {list.length > 0 ? (
                          <FaTimesCircle onClick={handleClear} />
                        ) : null}
                      </span>
                    ) : null}
                    {/* <span className='dkg-crosssbtnCon14'>
                                            <FaTimesCircle />
                                        </span> */}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="dk-duplicateFormGruop">
                    <label className="dkg-duplicate-title">
                      Enter Candidate Phone Numder
                    </label>
                    {/* <input type="text" className="form-control" placeholder={phonePlaceholder} value={phone} onChange={(e) => searchByPhone(e.target.value)} /> */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={phone}
                      onChange={(e) => searchByPhone(e.target.value)}
                    />
                    {phone != "" ? (
                      <span className="dkg-crosssbtnCon14">
                        {list.length > 0 ? (
                          <FaTimesCircle onClick={handleClear} />
                        ) : null}
                      </span>
                    ) : null}
                  </div>
                </div>
                {!isDuplicate ? (
                  <div className="col-12">
                    <div className="no-duplicate">
                      <b>No Duplicate</b>
                    </div>
                  </div>
                ) : null}
                {list.length > 0 ? null : (
                  <div className="col-12">
                    <div className="duplicateBtnCon">
                      <button
                        className="btn dk-srchBtn clear mr-2"
                        onClick={handleClear}
                        style={{color: "red"}}
                      >
                        CANCEL
                      </button>
                      <Submit
                        txt="Search"
                        loading={loading}
                        success={success}
                        onClick={handleSubmit}
                        position="justify-content-center"
                        className="btn dk-srchBtn"
                      />
                    </div>
                  </div>
                )}
                {list.length > 0 ? (
                  <div className="col-md-12">
                    <div className="dk-check-dupplicate-tableCon">
                      <DataTable dataList={list} />
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DuplicateCheck;
